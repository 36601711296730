/* eslint-disable eqeqeq */
import { Button } from "@mui/material";
import moment from "moment";
import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { InternalFindAll } from "../../../redux/actions/InternalInvoiceAction";
import ClientPlusIcon from "../../Icons/ClientPlusIcon";
import SalesReturnClientModel from "../../models/SalesReturnClientModel";

function ClientDetailsReturn({
	setCashAmount,
	dateFormat,
	setSelectedClientData,
	setNewexistProduct,
	paramsId,
	newExistProduct,
	invoicedate,
	setProductRows,
	TaxAllList,
	setTaxAllList,
	setSelectedPayment,
	handleChange,
	formData,
	setFormData,
	clientErrors,
	setReturnProducts,
	salesReturnDate,
	setSalesReturnDate,
	setProdcutErrors,
}: any) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [existingData, setExistingData] = useState<any>(null);
	const [salesClients, setSalesClients] = useState<any[]>([]);

	const handleConfirm = () => {
		if (!paramsId) {
			setIsModalOpen(true);
		}
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const dispatch = useDispatch();

	const CustomInputWithIcon = forwardRef(
		({ value, onClick }: any, ref: any) => (
			<div className="flex items-center border-2 border-[#949494] focus:border-[#07ad7b] group focus-within:outline-none focus-within:border-[#07ad7b] focus:outline-none rounded-[7px] px-1 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12">
				<input
					type="text"
					value={value}
					readOnly
					className={"w-full pl-1 sm:px-2 focus:outline-none"}
					style={{
						fontFamily: "poppins",
						fontSize: "14px",
						fontWeight: 400,
					}}
					ref={ref}
				/>
				<FaCalendarAlt className="sm:mr-2 cursor-pointer  " onClick={onClick} />
			</div>
		)
	);

	const handleSelect = (data: any) => {
		setReturnProducts([]);

		clientErrors.clientName = "";

		setFormData((formData: any) => ({
			...formData,
			clientName: data.clientName,
			clientMobile: data.clientMobile,
			clientEmail: data.clientEmail,
			clientPan: data?.clientPan ? data?.clientPan : "",
			clientId: data._id?.$oid,
			invoiceNo: "",
			invoiceDate: "",
			_id: data.invoiceNo,
			paymentType: "",
			selectedClient: "",
			toatalAmount: "",
			subtotal: "",
		}));
		setProductRows([
			{
				product: "",
				discount: "",
				hsn: "",
				rate: "",
				qty: "",
				igst: "",
				cgst: "",
				sgst: "",
				amount: 0,
				igstAmount: "",
				cgstAmount: "",
				sgstAmount: "",
				nontaxableAmount: "",
			},
		]);
		setSelectedPayment("");
		setExistingData(data);
		setIsModalOpen(false);
	};

	const handleChanges = (e: any) => {
		const selectedInvoiceNo = e.target.value;
		setNewexistProduct([]);

		const selectedClient = salesClients.find(
			(client) => client.invoiceNo === selectedInvoiceNo
		);
		setCashAmount(selectedClient.toatalAmount);
		if (selectedClient) {
			setSelectedClientData((prevState: any) => ({
				...prevState,
			}));

			const updatedProductRows = selectedClient.product.map((product: any) => ({
				prodcut: product.productName,
				hsn: product.hsn,
				rate: product.rate,
				qty: product.qty,
				amount: product.amount,
				igst: product.igst,
				cgst: product.cgst,
				sgst: product.sgst,
				discount: product.discount,
				productUnique_id: product.productUnique_id,
				salesInvoiceQty: product.salesInvoiceQty,
				nontaxableAmount: product.nontaxableAmount,
			}));

			const combinedTaxRow = selectedClient.tax.reduce(
				(acc: any, taxRow: any) => {
					return {
						cgstStatus: taxRow.cgstStatus ?? acc.cgstStatus,
						cgstPercentage:
							taxRow.cgstStatus === true
								? Number(taxRow?.taxPersent)
								: acc.cgstPercentage || 0,
						sgstStatus: taxRow.sgstStatus ?? acc.sgstStatus,
						sgstPercentage:
							taxRow.sgstStatus === true
								? Number(taxRow?.taxPersent)
								: acc.sgstPercentage || 0,
						igstStatus: taxRow.igstStatus ?? acc.igstStatus,
						igstPercentage:
							taxRow.igstStatus === true
								? Number(taxRow?.taxPersent)
								: acc.igstPercentage || 0,
						othersStatus: taxRow.othersStatus ?? acc.othersStatus,
						othersPercentage:
							taxRow.othersStatus === true
								? Number(taxRow?.taxPersent)
								: acc.othersPercentage || 0,
						discountStatus: taxRow.discountStatus ?? acc.discountStatus,
						discountPercentage:
							taxRow.discountStatus === true
								? Number(taxRow?.taxPersent)
								: acc.discountPercentage || 0,
						extraFeesStatus: taxRow.extraFeesStatus ?? acc.extraFeesStatus,
						extraFees:
							taxRow.extraFeesStatus === true
								? Number(taxRow?.taxPersent)
								: acc.extraFees || 0,
					};
				},
				{}
			);

			// Filter out any empty rows before adding the new data
			const containsEmptyRowsinExistProduct = newExistProduct.some((row: any) =>
				Object.values(row).some((value) => !value)
			);

			if (containsEmptyRowsinExistProduct) {
				const nonEmptyRows = newExistProduct.filter((row: any) =>
					Object.values(row).some(Boolean)
				);
				setNewexistProduct(nonEmptyRows);
			}

			// Add the updated product rows to newExistProduct
			setNewexistProduct((prev: any) => [
				...prev,
				...updatedProductRows.map((row: any) => ({
					exist: Number(row.qty),
					originalValue: Number(row.qty),
				})),
			]);
			let finalErrors: any = [];
			let values = updatedProductRows?.map((item: any, index: any) => {
				let object: any = {};
				object.prodcut = item.prodcut ? true : false;
				object.rate = item.rate ? true : false;
				object.qty = item.qty ? true : false;
				finalErrors.push(object);
				return object;
			});
			setProdcutErrors(values);
			setSelectedPayment("");
			setProductRows(updatedProductRows);
			setTaxAllList([combinedTaxRow]);
			setReturnProducts(selectedClient.product);
			setFormData((formData: any) => ({
				...formData,
				billTaxType: selectedClient.billTaxType,
				gstType: selectedClient.gstType,
				tax: selectedClient.tax,
				subtotal: selectedClient.subtotal,
				invoiceNo: selectedInvoiceNo,
				invoicedate: selectedClient.invoicedate,
				product: selectedClient.product,
				toatalAmount: selectedClient.toatalAmount,
				clientId: selectedClient.clientId,
				invoiceTemplateType: selectedClient.invoiceTemplateType,
				colorTemplateType: selectedClient.colorTemplateType,
				nontaxableTotal: selectedClient.nontaxableTotal,
				cashAmount: selectedClient.toatalAmount,
				roundOff: selectedClient.roundOff,
			}));
		}

		clientErrors.invoiceNo = "";
		// setCashAmount(formData?.toatalAmount);
	};

	const fetchData = () => {
		dispatch(InternalFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				// closeModal();
				setIsModalOpen(false);
				setSalesClients(response.payload);
			}
		});
	};

	const invoiceNo = salesClients
		.filter(
			(client) =>
				client.clientId === existingData?.clientId &&
				client.salesReturnStatus === "" &&
				client.saleType !== "POS"
		)
		.map((client) => client.invoiceNo);

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleDateChange = (date: any) => {
		const formattedDate = moment(date).format(dateFormat);
		if (formattedDate !== formData.invoicedate) {
			setSalesReturnDate(date);
			setFormData((prevFormData: any) => ({
				...prevFormData,
				salesReturnDate: formattedDate,
			}));
		}
	};

	return (
		<div className={"pt-3 px-2"}>
			<div className=" flex-1 mt-1">
				<div className="flex flex-col sm:flex-row gap-2 items-left w-1/2">
					<div
						className="flex flex-1 items-center whitespace-nowrap "
						style={{
							color: "#008065",
							fontFamily: "Poppins",
							fontSize: "14px",
							fontWeight: 700,
						}}
					>
						Client Details (Billed To)
					</div>

					<div className="flex flex-1 items-center">
						<ClientPlusIcon className="w-4 h-4" />
						<Button
							onClick={handleConfirm}
							sx={{ textTransform: "none", padding: 0 }}
						>
							<span
								style={{
									color: "#008065",
									fontFamily: "Poppins",
									fontSize: "15px",
									fontWeight: 700,
									cursor: paramsId ? "not-allowed" : "pointer",
								}}
								className="text-[#008065] ml-1 mt-1"
							>
								Existing
							</span>
						</Button>
					</div>

					{isModalOpen && (
						<SalesReturnClientModel
							closeModal={closeModal}
							handleSelect={handleSelect}
						/>
					)}
				</div>
			</div>

			<div className="flex flex-col sm:flex-row justify-between gap-4">
				<div className="w-full">
					<div className="flex flex-row justify-between items-center mb-2 mt-1">
						<div
							className="flex-1  required"
							style={{
								fontFamily: "poppins",
								fontSize: "14px",
								fontWeight: 700,
							}}
						>
							<span>Name</span>
						</div>
						<div className="font-title flex-1 text-left">
							<input
								autoComplete="nope"
								placeholder="Client Name"
								// className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
								className={`
                                border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12
                                ${
																	clientErrors.clientName
																		? "border-[#FF0000]"
																		: "border-[#949494]"
																}
                                ${
																	!clientErrors.clientName &&
																	"focus:border-[#07ad7b]"
																}
                            `}
								style={{
									fontFamily: "poppins",
									fontSize: "14px",
									fontWeight: 400,
									// borderColor: clientErrors.clientName ? "red" : "#008065",
								}}
								name="clientName"
								value={formData.clientName}
								onChange={(e) => handleChange(e)}
								readOnly
							/>
							{clientErrors.clientName && (
								<p
									style={{
										fontFamily: "poppins",
										fontWeight: 700,
										color: "#ff0000",
									}}
									className="text-red-400 text-xs"
								>
									{clientErrors.clientName}
								</p>
							)}
						</div>
					</div>

					<div className="flex flex-row justify-between items-center mb-2">
						<div
							className=" flex-1 mb-2"
							style={{
								fontFamily: "poppins",
								fontSize: "14px",
								fontWeight: 700,
							}}
						>
							Phone
						</div>
						<div className="font-title flex-1 text-left">
							<input
								type="text"
								autoComplete="nope"
								placeholder="Mobile Number"
								className={
									"border-2 border-[#949494] focus:border-[#07ad7b]  focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "
								}
								style={{
									fontFamily: "poppins",
									fontSize: "14px",
									fontWeight: 400,
									// borderColor: "#008065",
								}}
								maxLength={10}
								name="clientMobile"
								value={formData.clientMobile}
								readOnly
								onChange={(e) => {
									const re = /^[0-9\b]+$/;
									if (re.test(e.target.value) || e.target.value == "") {
										handleChange(e);
									}
								}}
							/>
						</div>
					</div>

					<div className="flex flex-row justify-between items-center mb-2">
						<div
							className="flex-1"
							style={{
								fontFamily: "poppins",
								fontSize: "14px",
								fontWeight: 700,
							}}
						>
							Email
						</div>
						<div className="font-title flex-1 text-left ">
							<input
								autoComplete="nope"
								placeholder="Email (Optional)"
								className={
									"border-2 border-[#949494] focus:border-[#07ad7b]  focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "
								}
								style={{
									fontFamily: "poppins",
									fontSize: "14px",
									fontWeight: 400,
									// borderColor: "#008065",
								}}
								name="clientEmail"
								readOnly
								value={formData.clientEmail}
								onChange={(e) => handleChange(e)}
							/>
						</div>
					</div>

					<div className="flex flex-row justify-between items-center mb-2">
						<div
							className="flex-1"
							style={{
								fontFamily: "poppins",
								fontSize: "14px",
								fontWeight: 700,
							}}
						>
							PAN
						</div>
						<div className="font-title flex-1 text-left ">
							<input
								autoComplete="nope"
								placeholder="PanNumber (Optional)"
								className={
									"border-2 border-[#949494] focus:border-[#07ad7b]  focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "
								}
								style={{
									fontFamily: "poppins",
									fontSize: "14px",
									fontWeight: 400,
									// borderColor: "#008065",
								}}
								name="clientPan"
								readOnly
								value={formData.clientPan}
								onChange={(e) => handleChange(e)}
							/>
						</div>
					</div>
				</div>

				<div className="w-full">
					<div className="flex flex-row justify-between items-center mb-2">
						<div
							className="flex-1  required"
							style={{
								fontFamily: "poppins",
								fontSize: "14px",
								fontWeight: 700,
							}}
						>
							<span>Invoice No</span>
						</div>
						<div className="font-title flex-1 text-left">
							<select
								autoComplete="nope"
								// className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"}
								className={`
                                        border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12
                                        ${
																					clientErrors.invoiceNo
																						? "border-[#FF0000]"
																						: "border-[#949494]"
																				}
                                        ${
																					!clientErrors.invoiceNo &&
																					"focus:border-[#07ad7b]"
																				}
                                    `}
								style={{
									fontFamily: "poppins",
									fontSize: "14px",
									fontWeight: 400,
									// borderColor: clientErrors.invoiceNo ? 'red' : "#008065",
								}}
								name="invoiceNo"
								value={formData.invoiceNo}
								onChange={(e) => handleChanges(e)}
							>
								<option value="" hidden>
									{paramsId ? formData.invoiceNo : "Select an Invoice No"}
								</option>
								{invoiceNo.map((invoiceNo, index) => (
									<option key={index} value={invoiceNo}>
										{invoiceNo}
									</option>
								))}
							</select>

							{clientErrors.invoiceNo && (
								<p
									style={{
										fontFamily: "poppins",
										fontWeight: 700,
										color: "#ff0000",
									}}
									className="text-red-400 text-xs"
								>
									{clientErrors.invoiceNo}
								</p>
							)}
						</div>
					</div>

					<div className="flex flex-row justify-between items-center mb-2">
						<div
							className=" flex-1 mb-2"
							style={{
								fontFamily: "poppins",
								fontSize: "14px",
								fontWeight: 700,
							}}
						>
							Sales Return No
						</div>
						<div className="font-title flex-1 text-left">
							<input
								type="text"
								autoComplete="nope"
								placeholder="Mobile Number"
								className={
									"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "
								}
								style={{
									fontFamily: "poppins",
									fontSize: "14px",
									fontWeight: 400,
									// borderColor: "#008065",
								}}
								maxLength={10}
								name="SalesreturnNo"
								value={formData.SalesreturnNo}
								onChange={(e) => {
									const re = /^[0-9\b]+$/;
									if (re.test(e.target.value) || e.target.value == "") {
										handleChange(e);
									}
								}}
							/>
						</div>
					</div>

					<div className="flex flex-row justify-between items-center mb-2">
						<div
							className="flex-1"
							style={{
								fontFamily: "poppins",
								fontSize: "14px",
								fontWeight: 700,
							}}
						>
							Invoice Date
						</div>
						<div className="font-title flex-1 text-left ">
							<input
								autoComplete="nope"
								placeholder="Invoice Date"
								className={
									"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "
								}
								style={{
									fontFamily: "poppins",
									fontSize: "14px",
									fontWeight: 400,
									// borderColor: "#008065",
								}}
								name="invoiceDate"
								value={formData.invoicedate}
								readOnly
							/>
						</div>
					</div>

					<div className="flex flex-row justify-between items-center mb-2">
						<div
							className="flex-1"
							style={{
								fontFamily: "poppins",
								fontSize: "14px",
								fontWeight: 700,
							}}
						>
							Sales Return Date
						</div>
						<div className=" font-title flex-1 text-left">
							<DatePicker
								selected={salesReturnDate}
								value={formData?.salesReturnDate}
								onChange={handleDateChange}
								dateFormat={"dd/MM/yyyy"}
								wrapperClassName="return-datepicker"
								scrollableYearDropdown
								customInput={<CustomInputWithIcon />}
								showPopperArrow={false}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ClientDetailsReturn;
