/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconStyle, defaultInputSmStyle } from "../../commen/defaultStyles";
import NumberFormat from "../../commen/NumberFormat";
import StockUpdateModel from "../../commen/StockUpdateModel";
import DeleteIcon from "../../Icons/DeleteIcon";
import InvoiceIcon from "../../Icons/InvoiceIcon";
import ProductModel from "../../models/ProductModel";
import { stockPurchaseGet } from "../../../redux/actions/stockAction";
import CalculateRoundOff from "../../commen/CalculateRoundOff";
import CalculateRoundTotalAmount from "../../commen/CalculateRoundTotalAmount";
import AddProduct from "./AddProduct";
import ClientPlusIcon from '../../Icons/ClientPlusIcon';
import { FiPlusCircle } from "react-icons/fi";

function ProductNewDetails({
  formData,
  setFormData,
  updateProductErrors,
  paramsId,
  stockList,
  setStockList,
  prodcutErrors,
  prodcutRows,
  setProductRows,
  productError,
  addRow,
  setProdcutErrors,
  editValue,
  setCashAmount,
  creditAmount,
  setchequeAmount,
  setcreditAmount,
  setbalanceAmount,
  setSelectedPayment,
  setreceivedAmount,
  fieldErrors,
  clientErrors,
  setConditionError,
  setQty,
  conditionError,
  roundOffValue,
  roundOffEnable
}: any) {
  // *******************************Product Sction*********************************

  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  const [newExistProduct, setNewexistProduct] = useState([{ exist: "", originalValue: '' }] as any);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;
  const dispatch = useDispatch();

  const [clientviewOpen, setClientOpenView] = useState(false)
  const handleOpen = () => setClientOpenView(true);

  const handleCancel = () => {
    setShowConfirmation(false);
  };


  const handleInputChange = (index: any, fieldName: any, value: any) => {
    const updatedExistProduct = [...newExistProduct];
    const currentStock = updatedExistProduct[index]["exist"];
    const newArray: any = [...prodcutRows];
    const originalValue = updatedExistProduct[index]["originalValue"];
    const totalStock = originalValue + currentStock;
    const productFlow = prodcutRows[index]?.product_flow;

    newArray[index][fieldName] = value;
    const newValue = Number(value);
    const currentQty = Number(newExistProduct[index]?.originalValue);

    if (fieldName === "qty" && paramsId) {
      setConditionError((prevErrors: any) => ({
        ...prevErrors,
        [index]: newValue < currentQty,
      }));
    }
    setQty(value);

    if (paramsId && productFlow === "Vendor Required") {
      // if (originalValue > Number(value)){
      //   updateProductErrors(index, fieldName, value)
      // }
      // else if(originalValue > Number(value)){
      // }
      if (Number(value) > totalStock) {
        setShowConfirmation(true);
        return;
      }

    } else {
      if (fieldName === "qty" && productFlow === "Vendor Required") {
        if (typeof newExistProduct[index]["originalValue"] === 'number' && newExistProduct[index]["originalValue"] < Number(value)) {
          setShowConfirmation(true);
          return
        }
      }
    }
    newArray[index][fieldName] = value;
    updateProductErrors(index, fieldName, value);
    if (fieldName === "qty" || fieldName === "rate" || fieldName === "discount") {
      if (
        fieldName === "discount" &&
        ((userdata.gstValue === "IGST" && userdata.taxType === "Product Wise") ||
          (formData.gstType === "IGST" && formData.billTaxType === "Product Wise"))
      ) {
        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
            : !newArray[index].rate
              ? 0
              : 0;
        let discount =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].amount) * (parseFloat(newArray[index].discount ? newArray[index].discount : 0) / 100)
            : !newArray[index].rate
              ? 0
              : 0;
        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].amount) - discount
            : !newArray[index].rate
              ? 0
              : 0;
        let igst =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].amount) * (parseFloat(newArray[index].igst) / 100)
            : !newArray[index].rate
              ? 0
              : 0;
        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].amount) + igst
            : !newArray[index].rate
              ? 0
              : 0;
        newArray[index]["amount"] = (newArray[index]["amount"]).toFixed(2);
        newArray[index]["nontaxableAmount"] = (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)) - ((newArray[index]["discount"] / 100) * (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)))
        newArray[index]["igstAmount"] = parseFloat(newArray[index].igst) / 100 * parseFloat(newArray[index].rate)
        newArray[index]["salesInvoiceQty"] = Number(value)
        setProductRows(newArray);
      } else if (fieldName === "discount" &&
        ((userdata.gstValue === "GST" && userdata.taxType === "Product Wise") ||
          (formData.gstType === "GST" && formData.billTaxType === "Product Wise"))) {

        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)) - parseFloat(newArray[index].discount ? newArray[index].discount : 0) / 100 * parseFloat(newArray[index].rate)
            : !newArray[index].rate
              ? 0
              : 0;
        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].amount) + ((parseFloat(newArray[index].sgst) + parseFloat(newArray[index].cgst)) / 100 * parseFloat(newArray[index].amount))
            : !newArray[index].rate
              ? 0
              : 0;
        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
            : !newArray[index].rate
              ? 0
              : 0;
        let discount =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].amount) * (parseFloat(newArray[index].discount ? newArray[index].discount : 0) / 100)
            : !newArray[index].rate
              ? 0
              : 0;

        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].amount) - discount
            : !newArray[index].rate
              ? 0
              : 0;
        let gst =
          newArray[index].rate && newArray[index].qty
            ? ((parseFloat(newArray[index].sgst) + parseFloat(newArray[index].cgst)) / 100 * parseFloat(newArray[index].amount))
            : !newArray[index].rate
              ? 0
              : 0;
        newArray[index]["amount"] =
          newArray[index].rate && newArray[index].qty
            ? parseFloat(newArray[index].amount) + gst
            : !newArray[index].rate
              ? 0
              : 0;
        newArray[index]["nontaxableAmount"] = (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)) - ((newArray[index]["discount"] / 100) * (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)))
        newArray[index]["cgstAmount"] = parseFloat(newArray[index].cgst) / 100 * parseFloat(newArray[index].rate)
        newArray[index]["sgstAmount"] = parseFloat(newArray[index].sgst) / 100 * parseFloat(newArray[index].rate)
        setProductRows(newArray);
      } else {
        if (fieldName === "qty" &&
          ((userdata.gstValue === "IGST" && userdata.taxType === "Product Wise") ||
            (formData.gstType === "IGST" && formData.billTaxType === "Product Wise"))
        ) {
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
              : !newArray[index].rate
                ? 0
                : 0;
          let discount =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) * (parseFloat(newArray[index].discount ? newArray[index].discount : 0) / 100)
              : !newArray[index].rate
                ? 0
                : 0;

          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) - discount
              : !newArray[index].rate
                ? 0
                : 0;
          let igst =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) * (parseFloat(newArray[index].igst) / 100)
              : !newArray[index].rate
                ? 0
                : 0;
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) + igst
              : !newArray[index].rate
                ? 0
                : 0;
          newArray[index]["amount"] = (newArray[index]["amount"]).toFixed(2);
          newArray[index]["nontaxableAmount"] = (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)) - ((newArray[index]["discount"] / 100) * (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)))
          newArray[index]["igstAmount"] = parseFloat(newArray[index].igst) / 100 * parseFloat(newArray[index].rate)
          newArray[index]["salesInvoiceQty"] = Number(value)
          setProductRows(newArray);
        } else if (
          fieldName === "qty" &&
          ((userdata.gstValue === "GST" && userdata.taxType === "Product Wise") ||
            (formData.gstType === "GST" && formData.billTaxType === "Product Wise"))
        ) {
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)) - parseFloat(newArray[index].discount ? newArray[index].discount : 0) / 100 * parseFloat(newArray[index].rate)
              : !newArray[index].rate
                ? 0
                : 0;
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
              : !newArray[index].rate
                ? 0
                : 0;
          let discount =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) * (parseFloat(newArray[index].discount ? newArray[index].discount : 0) / 100)
              : !newArray[index].rate
                ? 0
                : 0;

          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) - discount
              : !newArray[index].rate
                ? 0
                : 0;
          let gst =
            newArray[index].rate && newArray[index].qty
              ? ((parseFloat(newArray[index].sgst) + parseFloat(newArray[index].cgst)) / 100 * parseFloat(newArray[index].amount))
              : !newArray[index].rate
                ? 0
                : 0;
          newArray[index]["amount"] =
            newArray[index].rate && newArray[index].qty
              ? parseFloat(newArray[index].amount) + gst
              : !newArray[index].rate
                ? 0
                : 0;
          newArray[index]["nontaxableAmount"] = (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)) - ((newArray[index]["discount"] / 100) * (parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)))
          newArray[index]["cgstAmount"] = parseFloat(newArray[index].cgst) / 100 * parseFloat(newArray[index].rate)
          newArray[index]["sgstAmount"] = parseFloat(newArray[index].sgst) / 100 * parseFloat(newArray[index].rate)
          newArray[index]["salesInvoiceQty"] = Number(value)
          setProductRows(newArray);
        }
      }

      let subAmount: any = 0;
      for (let i = 0; i < newArray.length; i++) {
        subAmount += Number(newArray[i].amount);
      }
      let subAmountNonTax: any = 0;
      for (let i = 0; i < newArray.length; i++) {
        subAmountNonTax += newArray[i].nontaxableAmount;
      }
      subAmount = subAmount.toFixed(2);
      if (paramsId) {
        setFormData({
          ...formData,
          product: newArray,
          subtotal: subAmount,
          nontaxableTotal: subAmountNonTax,
          roundOff:roundOffEnable == true ? CalculateRoundOff(roundOffValue, subAmount):0,
          toatalAmount:roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, subAmount):subAmount
        });
        let subTotal=roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, subAmount):subAmount
        let receivedAmount = subTotal == 0 ? 0 : subTotal - formData.receivedAmount

        setbalanceAmount(parseFloat(receivedAmount.toFixed(2)))
        clientErrors["balanceAmount"] = ''
      }
      else {
        setFormData({
          ...formData,
          product: newArray,
          subtotal: subAmount,
          roundOff: roundOffEnable == true ? CalculateRoundOff(roundOffValue, subAmount):0,
          toatalAmount:roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, subAmount):Math.round(subAmount*100)/100,
          nontaxableTotal: subAmountNonTax
        });
        if (creditAmount) {
          setbalanceAmount(roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, subAmount):Math.round(subAmount*100)/100)
          setcreditAmount(roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue,subAmount):Math.round(subAmount*100)/100)
        }
        else {
          setCashAmount(roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue,subAmount):Math.round(subAmount*100)/100)
          setchequeAmount(roundOffEnable == true ? CalculateRoundTotalAmount (roundOffValue,subAmount):Math.round(subAmount*100)/100)
          setreceivedAmount(roundOffEnable == true ? CalculateRoundTotalAmount (roundOffValue,subAmount):Math.round(subAmount*100)/100)
          fieldErrors.cashAmount = ''
          fieldErrors.creditAmount = ''
          fieldErrors.chequeAmount = ''
          // clientErrors["chequeNum"] = ''
          formData.chequeNum = ''
        }
      }

    }
    else {
      setProductRows(newArray);
    }
  };

  const handleExistingCalulation = (arr: any) => {
    if (arr.length === 0)
      arr.push({ amount: "", nontaxableAmount: "", nontaxableTotal: "", hsn: "", prodcut: "", qty: "", gst: "", igst: "", cgst: "", sgst: "", rate: "", igstAmount: "", cgstAmount: "", sgstAmount: "", discount: "", });
    const newArray: any = [...arr];
    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i].qty || newArray[i].rate) {

        if (userdata.gstValue == "IGST" && userdata.taxType == "Product Wise") {
          newArray[i]["amount"] =
            newArray[i].rate && newArray[i].qty
              ? parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty) - parseFloat(newArray[i].discount) / 100 * parseFloat(newArray[i].rate)
              : !newArray[i].rate
                ? 0
                : 0;
          newArray[i]["amount"] =
            newArray[i].rate && newArray[i].qty
              ? parseFloat(newArray[i].amount) * parseFloat(newArray[i].igst) / 100 + parseFloat(newArray[i].amount)
              : !newArray[i].amount
                ? 0
                : 0;
          newArray[i]["igstAmount"] = parseFloat(newArray[i].igst) / 100 * parseFloat(newArray[i].rate)
          newArray[i]["igst"] = parseFloat(newArray[i].igst)
          newArray[i]["nontaxableAmount"] = (parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty)) - parseFloat(newArray[i].discount) / 100 * ((parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty)));
          setProductRows(newArray);

        }
        else if (userdata.gstValue == "GST" && userdata.taxType == "Product Wise") {
          newArray[i]["amount"] =
            newArray[i].rate && newArray[i].qty
              ? parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty) - parseFloat(newArray[i].discount) / 100 * parseFloat(newArray[i].rate)
              : !newArray[i].rate
                ? 0
                : 0;
          newArray[i]["amount"] =
            newArray[i].rate && newArray[i].qty
              ? parseFloat(newArray[i].amount) + ((parseFloat(newArray[i].sgst) + parseFloat(newArray[i].cgst)) / 100 * parseFloat(newArray[i].amount))
              : !newArray[i].rate
                ? 0
                : 0;
          newArray[i]["cgstAmount"] = parseFloat(newArray[i].cgst) / 100 * parseFloat(newArray[i].rate)
          newArray[i]["sgstAmount"] = parseFloat(newArray[i].sgst) / 100 * parseFloat(newArray[i].rate)
          newArray[i]["gst"] = parseFloat(newArray[i].sgst) + parseFloat(newArray[i].cgst)
          newArray[i]["nontaxableAmount"] = (parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty)) - parseFloat(newArray[i].discount) / 100 * ((parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty)));


          setProductRows(newArray);

        }

        let subAmount: any = 0;
        for (let i = 0; i < newArray.length; i++) {
          subAmount += newArray[i].amount;
        }
        let subAmountNonTax: any = 0;
        for (let i = 0; i < newArray.length; i++) {
          subAmountNonTax += newArray[i].nontaxableAmount;
        }

        if (paramsId) {
          setFormData({
            ...formData,
            product: newArray,
            subtotal: subAmount,
            // tax: formData.tax,
            roundOff:roundOffEnable == true ?  CalculateRoundOff(roundOffValue, subAmount):0,
          toatalAmount:roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, subAmount):Math.round(subAmount*100)/100
          });
        }
        else {

          setFormData({
            ...formData,
            product: newArray,
            subtotal: subAmount,
            roundOff:roundOffEnable == true ? CalculateRoundOff(roundOffValue, subAmount):0,
            toatalAmount:roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, subAmount):Math.round(subAmount*100)/100,
            nontaxableTotal: subAmountNonTax

          });
        }
      }
    }
    // else {
    setProductRows(newArray);
    // }

    let finalErrors: any = [];
    // let object: any = {};
    let values = newArray?.map((item: any, index: any) => {
      let object: any = {};
      object.prodcut = item.prodcut ? true : false;
      object.rate = item.rate ? true : false;
      object.qty = item.qty ? true : false;
      finalErrors.push(object);

      return object
    });

    setProdcutErrors(values);
  };

  const removeRow = (index: any, row: any) => {
    const newArray = [...prodcutRows];
    const newErrors = [...prodcutErrors];
    const existArray = [...newExistProduct];
    existArray.splice(index, 1)
    setNewexistProduct(() => {
      return existArray.length === 0 ? [{ exist: '', originalValue: '' }] : existArray
    });
    // if(existArray.length === 1) {
    //   setNewexistProduct([{exist: '',originalValue:''}]);
    // }
    newArray.splice(index, 1);
    handleExistingCalulation(newArray);
    setProductRows(newArray);
    let amountValue = 0
    for (let i = 0; i < newArray.length; i++) {
      amountValue += newArray[i].amount
    }
    if (paramsId) {
      setSelectedPayment(formData.paymentType)
      setbalanceAmount(parseFloat((amountValue - formData.receivedAmount).toFixed(2)))
    }
    else {
      setCashAmount('')
      setbalanceAmount("")
      setcreditAmount("")
      setchequeAmount("")
      setSelectedPayment('')
    }

    formData.paymentType = ''
    if (newErrors.length > 1)
      newErrors.splice(index, 1);
    setProdcutErrors(newErrors);

    if (existArray.length === 0) {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        ...prevFormData.tax = [],
        subtotal: 0,
        toatalAmount: 0,
      }));
    }

  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleConfirm = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };



  useEffect(() => {
    if (editValue) {
      const obj: any = [];
      dispatch(stockPurchaseGet(token?._id) as any).then((stockRes: any) => {
        formData.product.forEach((product: any) => {
          const matchingStockVendorRequired = stockRes.payload.find(
            (stock: any) =>
              stock.productName === product.productName &&
              stock.product_flow !== "Direct Sale" &&
              stock.qty > 0
          );

          if (matchingStockVendorRequired) {
            obj.push({
              exist: matchingStockVendorRequired.qty,
              originalValue: Number(product.qty),
            });
          }

          const matchingStockDirectSale = stockRes.payload.find(
            (stock: any) =>
              stock.productName === product.productName &&
              stock.product_flow === "Direct Sale"
          );

          if (matchingStockDirectSale) {
            obj.push({
              exist: matchingStockDirectSale.qty,
              originalValue: Number(product.qty),
            });
          }
        });

        setNewexistProduct(obj);
      });
      setProductRows(formData.product);
    }
  }, [editValue]);

  const handleSelect = (dataArray: any[]) => {

    const containsEmptyRows = prodcutRows.some((row: any) =>
      Object.values(row).some((value) => !value)
    );
    const containsEmptyRowsinExistProduct = newExistProduct.some((row: any) =>
      Object.values(row).some((value) => !value)
    );

    if (containsEmptyRowsinExistProduct) {
      const nonEmptyRows = newExistProduct.filter((row: any) =>
        Object.values(row).some(Boolean)
      );
      setNewexistProduct(nonEmptyRows);
    }
    if (containsEmptyRows) {
      const nonEmptyRows = prodcutRows.filter((row: any) =>
        Object.values(row).some(Boolean)
      );
      setProductRows(nonEmptyRows);
    }

    const newRows = dataArray.map((data) => ({
      prodcut: data.product_name,
      hsn: data.hsn,
      rate: data.sellingPrice,
      qty: data.qty,
      amount: (parseFloat(data.rate) ? parseFloat(data.rate) : 0) * (parseFloat(data.qty)),
      productName: data.productName,
      igst: userdata.gstValue == "IGST" && data.igstTax,
      cgst: userdata.gstValue == "GST" && data.CgstPercentage,
      sgst: userdata.gstValue == "GST" && data.SgstPercentage,
      discount: data.discount ? data.discount : 0,
      igstAmount: "",
      cgstAmount: "",
      sgstAmount: "",
      description: data.description,
      productUnique_id: data.productUnique_id,
      product_flow: data.product_flow,
      units: data.units,
      salesInvoiceQty: data.qty,
    }));
    setProductRows((prevRows: any[]) => {
      const updatedRows = [...prevRows, ...newRows];
      handleExistingCalulation(updatedRows);
      return updatedRows;
    });

    setNewexistProduct((prev: any) => [
      ...prev,
      ...dataArray.map((row: any) => ({
        exist: Number(row.stockQty) || Number(row.qty),
        originalValue: Number(row.stockQty) || Number(row.qty)
      }))
    ]);
    setSelectedPayment("")
    setIsModalOpen(false);
  };



  return (
    <div className="mb-4  overflow-x-auto">

      <div className="sm:overflow-x-auto xl:overflow-hidden custom-scrollbar">
        <div className="sm:w-[160%] xl:w-auto">
          <div
            className={
              "hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 mb-2 text-white"
            }
            style={{ backgroundColor: "#008065" }}
          >
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-6/12 sm:w-1/4 md:w-[17%] lg:w-[22%] xl:w-[23%] 2xl:w-[305] text-left"}
            >
              <span className="inline-block">S No</span>
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 md:w-6/12 2xl:w-10/12 text-left sm:pr-10"}
            >
              <span className="inline-block">Products</span>
            </div>
            {userdata?.hsnNo ? (
              <>
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={" w-full sm:w-1/4 text-right xl:ml-10"}
                >
                  <span className="inline-block">HSN/SAC</span>
                </div>
              </>
            ) : (<></>)}
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-right xl:mr-2 2xl:ml-6"}
            >
              Rate
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-right xl:w-ml-4 2xl:ml-6"}
            >
              Qty
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-right xl:ml-4 2xl:ml-6"}
            >
              Disc(%)
            </div>
            {formData?.billTaxType == "Product Wise" ? (
              <>
                {formData?.gstType == "IGST" ? (
                  <>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={" w-full sm:w-1/4 text-right 2xl:mr-6"}
                    >
                      <span className="inline-block">IGST(%)</span>
                    </div>
                  </>
                ) : (<>
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={" w-full sm:w-1/4 text-right xl:ml-6 2xl:ml-10"}
                  >
                    <span className="inline-block">CGST(%)</span>
                  </div>
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={" w-full sm:w-1/4 text-right xl:ml-4 2xl:ml-10"}
                  >
                    <span className="inline-block">SGST(%)</span>
                  </div>
                </>)}
              </>
            ) : <></>
            }
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={"  w-full sm:w-4/12 text-right sm:pr-10"}
            >
              Total
            </div>
          </div>

          {prodcutRows?.map((productrow: any, index: any) => (
            <div
              key={index}
              className={
                "flex flex-col sm:flex-row rounded-lg  w-full   items-center relative " +
                (index % 2 !== 0 ? " bg-gray-50 " : "")
              }
            >
              <div
                style={{
                  fontFamily: "poppins",
                  textAlign: "left",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={"w-full sm:w-5/12  text-left  pr-3  flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden w-1/2 flex flex-row items-center">
                  S No
                </span>

                <div className="font-title flex-1 text-right sm:text-center 2xl:text-left 2xl:ml-8 pr-4 sm:pr-0">
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 900,
                    }}

                  >
                    {index + 1}
                  </div>
                </div>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full sm:w-[310%] lg:w-[320%] 2xl:w-[380%] text-right pr-3 flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden w-1/2 flex flex-row items-center">
                  Products
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    readOnly
                    autoComplete="nope"
                    value={productrow.prodcut ? productrow.prodcut : ""}
                    placeholder="Product Name"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      // borderColor:
                      //   !prodcutErrors[index]?.prodcut && productError
                      //     ? "red"
                      //     : "#00A787",
                    }}
                    // className={
                    //   defaultInputSmStyle +
                    //   " text-left border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full "
                    // }
                    className={`
                      ${defaultInputSmStyle}  text-left border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full 
                      ${!prodcutErrors[index]?.prodcut && productError ? 'border-[#FF0000]' : 'border-[#949494]'} 
                      ${(!productError || prodcutErrors[index]?.prodcut) && 'focus:border-[#07ad7b]'}
                    `}
                    onChange={(event) => {
                      const value = event.target.value.trimStart();
                      handleInputChange(index, "prodcut", value);
                    }}
                    // disabled
                  />

                </div>
              </div>
              {userdata?.hsnNo ? (
                <>
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
                  >
                    <span className="sm:hidden w-1/2 flex flex-row items-center">
                      HSN/SAC
                    </span>

                    <div className="font-title flex-1 text-left">
                      <input
                        // disabled
                        readOnly
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          // borderColor: "#00A787",
                        }}
                        autoComplete="nope"
                        value={productrow.hsn}
                        onChange={(event) => {
                          const value = event.target.value.replace(/[^0-9]/g, '');
                          handleInputChange(index, "hsn", value)
                        }}
                        placeholder="HSN/SAC"
                        type=""
                        className={
                          defaultInputSmStyle +
                          "border-2 border-[#949494] focus:border-[#07ad7b] text-right  border-2 focus:outline-none rounded-[7px] px-2 h-9   w-full  "
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (<></>)}
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden  w-1/2 flex flex-row items-center">
                  Rate
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    readOnly
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      // borderColor:
                      //   !prodcutErrors[index]?.rate && productError
                      //     ? "red"
                      //     : "#00A787",
                    }}
                    autoComplete="nope"
                    value={productrow.rate}
                    onChange={(event) => {
                      const value = event.target.value.replace(/[^0-9.]/g, '');
                      handleInputChange(index, "rate", value);
                    }}
                    // disabled
                    placeholder="Rate"
                    type=""
                    // className={
                    //   defaultInputSmStyle +
                    //   " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                    // }
                    className={`
                      ${defaultInputSmStyle}   text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full 
                      ${!prodcutErrors[index]?.rate && productError ? 'border-[#FF0000]' : 'border-[#949494]'} 
                      ${(!productError || prodcutErrors[index]?.rate) && 'focus:border-[#07ad7b]'}
                    `}
                  />
                </div>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full text-right  pr-3 flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden md:hidden w-1/2 flex flex-row items-center">
                  Quantity
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    autoComplete="nope"
                    type="text"
                    value={productrow.qty}
                    onChange={(event) => {
                      let value = event.target.value;
                      if (productrow.units === "qty") {
                        value = value.replace(/[^0-9]/g, '');
                        if (value.startsWith('0')) {
                          value = value.substring(1);
                        }
                      } else {
                        value = value.replace(/[^0-9.]/g, '');
                        const [integerPart, decimalPart] = value.split('.');
                        if (decimalPart) {

                          value = `${integerPart}.${decimalPart.substring(0, 2)}`;
                        }
                        if ((value.match(/\./g) || []).length > 1) {
                          value = value.slice(0, value.lastIndexOf('.'));
                        }
                      }

                      handleInputChange(index, "qty", value);
                    }}
                    placeholder="Qty"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      // borderColor: paramsId
                      //   ? conditionError[index]
                      //     ? "red"
                      //     : "#00A787"
                      //   : (!prodcutErrors[index]?.qty && productError || parseFloat(productrow.qty) === 0)
                      //     ? "red"
                      //     : "#00A787",
                    }}
                    // className={
                    //   defaultInputSmStyle +
                    //   " text-right remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                    // }
                    className={`${defaultInputSmStyle} text-right remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full ${
                      conditionError[index] || 
                      (!prodcutErrors[index]?.qty && productError) || 
                      parseFloat(productrow.qty) === 0
                        ? "border-[#FF0000]"
                        : "focus:border-[#07ad7b] border-[#949494]"
                    }`}
                  />
                </div>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden md:hidden w-1/2 flex flex-row items-center">
                  Discount
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      // borderColor: "#00A787",
                    }}
                    autoComplete="nope"
                    value={productrow.discount}
                    onChange={(event) => {
                      const value = event.target.value.replace(/[^0-9.]/g, '');
                      if (parseFloat(value) > 100) {
                        return 0;
                      }
                      handleInputChange(index, "discount", value)
                    }}
                    placeholder="Discount"
                    type=""
                    className={
                      defaultInputSmStyle +
                      " text-right  border-2 border-[#949494] focus:border-[#07ad7b]  focus:outline-none rounded-[7px] px-2 h-9   w-full  "
                    }
                  />
                </div>

              </div>
              {formData?.billTaxType == "Product Wise" ? (
                <>
                  {formData?.gstType == "IGST" ? (
                    <>
                      <div
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 700,
                        }}
                        className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
                      >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                          IGST(%)
                        </span>

                        <div className="font-title flex-1 text-left">
                          <input
                            // disabled
                            readOnly
                            style={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              fontWeight: 400,
                              // borderColor: "#00A787",
                            }}
                            autoComplete="nope"
                            value={productrow.igst}
                            onChange={(event) => {
                              const value = event.target.value.replace(/[^0-9]/g, '');
                              handleInputChange(index, "igst", value)
                            }}
                            placeholder="IGST"
                            type=""
                            className={
                              defaultInputSmStyle +
                              " text-right border-2 border-[#949494] focus:border-[#07ad7b]  focus:outline-none rounded-[7px] px-2 h-9   w-full  "
                            }
                          />
                        </div>
                      </div>
                    </>
                  ) : (<>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
                    >
                      <span className="sm:hidden w-1/2 flex flex-row items-center">
                        CGST
                      </span>

                      <div className="font-title flex-1 text-left">
                        <input
                          // disabled
                          readOnly
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            // borderColor: "#00A787",
                          }}
                          autoComplete="nope"
                          value={productrow.cgst}
                          onChange={(event) => {
                            const value = event.target.value.replace(/[^0-9]/g, '');
                            handleInputChange(index, "cgst", value)
                          }}
                          placeholder="CGST"
                          type=""
                          className={
                            defaultInputSmStyle +
                            " text-right border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9   w-full  "
                          }
                        />
                      </div>

                    </div>
                    <div
                      style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      className={" w-full  text-right  pr-3  flex flex-row sm:block mb-2"}
                    >
                      <span className="sm:hidden w-1/2 flex flex-row items-center">
                        SGST
                      </span>

                      <div className="font-title flex-1 text-left">
                        <input
                          // disabled
                          readOnly
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            // borderColor: "#00A787",
                          }}
                          autoComplete="nope"
                          value={productrow.sgst}
                          onChange={(event) => {
                            const value = event.target.value.replace(/[^0-9]/g, '');
                            handleInputChange(index, "sgst", value)
                          }}
                          placeholder="SGST"
                          type=""
                          className={
                            defaultInputSmStyle +
                            " text-right border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9   w-full  "
                          }
                        />
                      </div>

                    </div>

                  </>)}
                </>) : <></>
              }

              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-right pr-2 sm:pr-12 flex flex-row sm:block whitespace-nowrap"}
              >
                <span className="sm:hidden w-1/2 flex flex-row items-center">
                  Total
                </span>
                <span className={"inline-block w-1/2 sm:w-full px-1 h-9 pt-2 overflow-hidden"}>
                  <NumberFormat value={productrow?.amount} />
                </span>
              </div>

              <div
                onClick={() => removeRow(index, productrow)}
                className="w-full sm:w-10 sm:absolute sm:right-0 pl-2 h-9 pt-2 sm:mt-[-17px] mb-2 md:mb-0"
              >
                <div className="w-full text-[#9C0000] font-semibold font-title sm:h-8 sm:w-8 cursor-pointer rounded-2xl bg-[#FFE0E0] flex justify-center items-center text-center p-[5px]">
                  <DeleteIcon className="h-4 w-4" style={IconStyle} />
                  <span className="block sm:hidden">Delete Product</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Add Product Actions */}

      <div
        style={{ fontFamily: "poppins", fontSize: "14px" }}
        className="flex flex-col sm:flex-row rounded-lg sm:visible w-full px-0 md:px-4 pl-2 py-2 items-center sm:justify-end"
      >
        <div
          style={{ fontFamily: "poppins", fontSize: "14px" }}
          className="w-full  text-right md:mr-5 2xl:mr-0 xl:mr-0 lg:mr-0 md:pr-0 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1"
        ></div>
        <div className="w-full  text-right md:mr-5 2xl:mr-0 xl:mr-0 lg:mr-0 md:pr-0 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1">
          <button
          type="button"
            onClick={handleOpen}
            className="rounded-xl font-bold text-white flex flex-row items-center justify-center bg-[#008065] text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"

          >
            <FiPlusCircle className="h-5 w-5" />
            <span>New Product</span>
          </button>
        </div>
        <div
          style={{ fontFamily: "poppins", fontSize: "14px" }}
          className="font-title w-full text-right md:pr-8 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1"
        >
          {/* {productlist.length ? > 0 ?} */}
          <button
            type="button"
            className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
            style={{ backgroundColor: "#008065" }}
            onClick={handleConfirm}
          >
            <InvoiceIcon style={IconStyle} className="h-5 w-5" />
            Add Existing Product
          </button>
        </div>
      </div>
      {
        isModalOpen && (
          <ProductModel
            setStockList={setStockList}
            closeModal={closeModal}
            setProductRows={setProductRows}
            prodcutRows={prodcutRows}
            handleSelect={handleSelect}
          />
        )
      }
      {
        showConfirmation && (
          <StockUpdateModel
            message="Are you sure you want to proceed?"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )
      }
      {

        clientviewOpen && (
          <AddProduct
            setNewexistProduct={setNewexistProduct}
            newExistProduct={newExistProduct}
            prodcutRows={prodcutRows}
            setProductRows={setProductRows}
            clientviewOpen={clientviewOpen}
            setClientOpenView={setClientOpenView}
            handleExistingCalulation={handleExistingCalulation}
            setSelectedPayment={setSelectedPayment} 
            setCashAmount={setCashAmount}
            setbalanceAmount={setbalanceAmount}
            setcreditAmount={setcreditAmount}
            setchequeAmount={setchequeAmount}/>
        )
      }
    </div >
  );
}

export default ProductNewDetails;

