/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { defaultInputSmStyle, IconStyle } from "../commen/defaultStyles";
import NumberFormat from "../commen/NumberFormat";
import DeleteIcon from "../Icons/DeleteIcon";
import InvoiceIcon from "../Icons/InvoiceIcon";
import ExpenseModel from "./ExpenseModel";
import ExpenseCategoryAlertPopoup from "../commen/ExpenseCategoryAlertPopoup";
import CalculateRoundOff from "../commen/CalculateRoundOff";
import CalculateRoundTotalAmount from "../commen/CalculateRoundTotalAmount";

function ExpenseTaxProductDetails({
  formData,
  setFormData,
  updateProductErrors,
  paramsId,
  setStockList,
  prodcutErrors,
  prodcutRows,
  setProductRows,
  productError,
  setProdcutErrors,
  editValue,
  setCashAmount,
  setbalanceAmount,
  setSelectedPayment,
  roundOffValue,
  roundOffEnable,
  setreceivedAmount
}: any) {
  // *******************************Product Sction*********************************

  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  const [newExistProduct, setNewexistProduct] = useState([{ exist: "", originalValue: '' }] as any);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleInputChange = (index: number, fieldName: string, value: any) => {
    newExistProduct[index]["exist"] = value;
    const newArray: any = [...prodcutRows];
    newArray[index][fieldName] = value;
    updateProductErrors(index, fieldName, value);

    // Function to perform calculations
    const calculateAmounts = (index: number) => {
      const rate = parseFloat(newArray[index].rate) || 0;
      const qty = parseFloat(newArray[index].qty) || 0;
      const discount = parseFloat(newArray[index].discount) || 0;
      // const sgst = parseFloat(newArray[index].sgst) || 0;
      // const cgst = parseFloat(newArray[index].cgst) || 0;
      // const igst = parseFloat(newArray[index].igst) || 0;

      // Base amount calculation
      let baseAmount = rate * qty;

      // Update amount based on discount
      let discountedAmount = baseAmount - (baseAmount * discount / 100);

      // Calculate tax based on selected expenseTax
      let igstPercentage = 0;
      let cgstPercentage = 0;
      let sgstPercentage = 0;

      if (newArray[index]?.expenseTax?.startsWith("GST")) {
        switch (newArray[index].expenseTax) {
          case "GST 0%":
            cgstPercentage = 0;
            sgstPercentage = 0;
            break;
          case "GST 0.25%":
            cgstPercentage = 0.125;
            sgstPercentage = 0.125;
            break;
          case "GST 3%":
            cgstPercentage = 1.5;
            sgstPercentage = 1.5;
            break;
          case "GST 5%":
            cgstPercentage = 2.5;
            sgstPercentage = 2.5;
            break;
          case "GST 12%":
            cgstPercentage = 6;
            sgstPercentage = 6;
            break;
          case "GST 18%":
            cgstPercentage = 9;
            sgstPercentage = 9;
            break;
          case "GST 28%":
            cgstPercentage = 14;
            sgstPercentage = 14;
            break;
        }
      } else if (newArray[index]?.expenseTax?.startsWith("IGST")) {
        switch (newArray[index].expenseTax) {
          case "IGST 0%":
            igstPercentage = 0;
            break;
          case "IGST 0.25%":
            igstPercentage = 0.25;
            break;
          case "IGST 3%":
            igstPercentage = 3;
            break;
          case "IGST 5%":
            igstPercentage = 5;
            break;
          case "IGST 12%":
            igstPercentage = 12;
            break;
          case "IGST 18%":
            igstPercentage = 18;
            break;
          case "IGST 28%":
            igstPercentage = 28;
            break;
        }
      }

      const igstAmount = (igstPercentage / 100) * discountedAmount;
      const cgstAmount = (cgstPercentage / 100) * discountedAmount;
      const sgstAmount = (sgstPercentage / 100) * discountedAmount;

      // Update amounts in the newArray
      newArray[index]["amount"] = (discountedAmount + igstAmount + cgstAmount + sgstAmount).toFixed(2);
      newArray[index]["nontaxableAmount"] = discountedAmount;
      newArray[index]["igstAmount"] = igstAmount.toFixed(2);
      newArray[index]["cgstAmount"] = cgstAmount.toFixed(2);
      newArray[index]["cgst"] = cgstPercentage
      newArray[index]["sgst"] = sgstPercentage
      newArray[index]["sgstAmount"] = sgstAmount.toFixed(2);
      newArray[index]["gst"] = cgstPercentage + sgstPercentage; // Total GST for CGST + SGST
      newArray[index]["igst"] = igstPercentage;
    };

    // Check if the field changed is qty, rate, discount, or expenseTax
    if (fieldName === "qty" || fieldName === "rate" || fieldName === "discount" || fieldName === "expenseTax") {
      calculateAmounts(index);
    }

    setProductRows(newArray);

    // Recalculate subtotal and total amounts
    let subAmount = newArray.reduce((acc: number, item: any) => acc + Number(item.amount), 0).toFixed(2);
    let subAmountNonTax = newArray.reduce((acc: number, item: any) => acc + item.nontaxableAmount, 0);

    if (paramsId) {
      setFormData({
        ...formData,
        product: newArray,
        subtotal: subAmount,
        roundOff: roundOffEnable == true ? CalculateRoundOff(roundOffValue, subAmount) : 0,
        toatalAmount: roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, subAmount) : subAmount,
      });
      // setBalanceAmount(parseFloat(subAmount) - formData.receivedAmount);
    } else {
      setFormData({
        ...formData,
        product: newArray,
        subtotal: subAmount,
        roundOff: roundOffEnable == true ? CalculateRoundOff(roundOffValue, subAmount) : 0,
        toatalAmount: roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, subAmount) : subAmount,
        nontaxableTotal: subAmountNonTax,
      });
      // setBalanceAmount(creditAmount ? subAmount : subAmount);
      setCashAmount(roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, subAmount) : subAmount)
      setreceivedAmount(roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, subAmount) : subAmount)
    }
  };


  const handleExistingCalulation = (arr: any) => {
    if (arr.length === 0)
      arr.push({ amount: "", nontaxableAmount: "",expense_Category:"", nontaxableTotal: "", hsn: "", prodcut: "", qty: "", gst: "", igst: "", cgst: "", sgst: "", rate: "", igstAmount: "", cgstAmount: "", sgstAmount: "", discount: "" });
    const newArray: any = [...arr];
    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i].qty || newArray[i].rate) {
        newArray[i]["amount"] =
          newArray[i].rate && newArray[i].qty
            ? parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty)
            : !newArray[i].rate
              ? 0
              : 0;
        // Function to perform calculations
        const calculateAmounts = (index: number) => {
          const rate = parseFloat(newArray[index].rate) || 0;
          const qty = parseFloat(newArray[index].qty) || 0;
          const discount = parseFloat(newArray[index].discount) || 0;
          const sgst = parseFloat(newArray[index].sgst) || 0;
          const cgst = parseFloat(newArray[index].cgst) || 0;
          const igst = parseFloat(newArray[index].igst) || 0;

          // Base amount calculation
          let baseAmount = rate * qty;

          // Update amount based on discount
          let discountedAmount = baseAmount - (baseAmount * discount / 100);

          // Calculate tax based on selected expenseTax
          let taxAmount = 0;
          let taxPercentage = 0
          let igstTaxPercentage = 0
          if (newArray[index]?.expenseTax?.startsWith("GST")) {
            switch (newArray[index].expenseTax) {
              case "GST 0%":
                taxAmount = 0
                taxPercentage = 0
                break;
              case "GST 0.25%":
                taxAmount = 0.25 / 100 * discountedAmount;
                taxPercentage = 0.25
                break;
              case "GST 3%":
                taxAmount = 3 / 100 * discountedAmount;
                taxPercentage = 3
                break;
              case "GST 5%":
                taxAmount = 5 / 100 * discountedAmount;
                taxPercentage = 5
                break;
              case "GST 12%":
                taxAmount = 12 / 100 * discountedAmount;
                taxPercentage = 12
                break;
              case "GST 18%":
                taxAmount = 18 / 100 * discountedAmount;
                taxPercentage = 18
                break;
              case "GST 28%":
                taxAmount = 28 / 100 * discountedAmount;
                taxPercentage = 28
                break;
              case "Exempt":
                taxAmount = 0;
                taxPercentage = 0;
                break;
            }
          } else if (newArray[index]?.expenseTax?.startsWith("IGST")) {
            switch (newArray[index].expenseTax) {
              case "IGST 0%":
                taxAmount = 0
                taxPercentage = 0
                break;
              case "IGST 0.25%":
                taxAmount = 0.25 / 100 * discountedAmount;
                taxPercentage = 0.25
                break;
              case "IGST 3%":
                taxAmount = 3 / 100 * discountedAmount;
                taxPercentage = 3
                break;
              case "IGST 5%":
                taxAmount = 5 / 100 * discountedAmount;
                igstTaxPercentage = 5;
                break;
              case "IGST 12%":
                taxAmount = 12 / 100 * discountedAmount;
                igstTaxPercentage = 12;
                break;
              case "IGST 18%":
                taxAmount = 18 / 100 * discountedAmount;
                igstTaxPercentage = 18;
                break;
              case "IGST 28%":
                taxAmount = 28 / 100 * discountedAmount;
                igstTaxPercentage = 28;
                break;
              case "Exempt":
                taxAmount = 0;
                taxPercentage = 0;
                break;
            }
          }

          // Update amounts in the newArray
          newArray[index]["amount"] = (discountedAmount + taxAmount).toFixed(2);
          newArray[index]["nontaxableAmount"] = baseAmount - (discount / 100 * baseAmount);
          newArray[index]["gst"] = taxPercentage
          newArray[index]["igst"] = igstTaxPercentage
          newArray[index]["cgstAmount"] = (cgst / 100) * rate;
          newArray[index]["sgstAmount"] = (sgst / 100) * rate;
          newArray[index]["igstAmount"] = (igst / 100) * rate;
        };

        // Check if the field changed is qty, rate, discount, or expenseTax
        if (newArray[i] === "qty" || newArray[i] === "rate" || newArray[i] === "discount" || newArray[i] === "expenseTax") {
          calculateAmounts(i);
        }

        setProductRows(newArray);

        // Recalculate subtotal and total amounts
        let subAmount = newArray.reduce((acc: number, item: any) => acc + Number(item.amount), 0).toFixed(2);
        let subAmountNonTax = newArray.reduce((acc: number, item: any) => acc + item.nontaxableAmount, 0);

        if (paramsId) {
          setFormData({
            ...formData,
            product: newArray,
            subtotal: subAmount,
            roundOff: roundOffEnable == true ? CalculateRoundOff(roundOffValue, subAmount) : 0,
            toatalAmount: roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, subAmount) : subAmount,
          });
          // setBalanceAmount(parseFloat(subAmount) - formData.receivedAmount);
        } else {
          setFormData({
            ...formData,
            product: newArray,
            subtotal: subAmount,
            roundOff: roundOffEnable == true ? CalculateRoundOff(roundOffValue, subAmount) : 0,
            toatalAmount: roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, subAmount) : subAmount,
            nontaxableTotal: subAmountNonTax,
          });
          // setBalanceAmount(creditAmount ? subAmount : subAmount);
          setCashAmount(roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, subAmount) : subAmount)
        }
      }
    }
    setProductRows(newArray);

    let finalErrors: any = [];
    let values = newArray?.map((item: any, index: any) => {
      let object: any = {};
      object.expense_Category = item.expense_Category ? true : false;
      object.rate = item.rate ? true : false;
      object.qty = item.qty ? true : false;
      finalErrors.push(object);
      return object

    });
    setProdcutErrors(values);
  };

  const removeRow = (index: number, row: any) => {
    const newArray = [...prodcutRows];
    const newErrors = [...prodcutErrors];
    const existArray = [...newExistProduct];
  
    existArray.splice(index, 1);
    newArray.splice(index, 1);
    if (newErrors.length > 1) newErrors.splice(index, 1);
  
    // ✅ Fix: If all rows are removed, reset the fields instead of keeping empty inputs
    if (existArray.length === 0) {
      setNewexistProduct([{ exist: '', originalValue: '' }]);
      setProductRows([{ productName: '', qty: '', rate: '', amount: '', discount: '', igstAmount: '', cgstAmount: '', sgstAmount: '',expenseTax: "", }]);
      setProdcutErrors([{ productName: '', qty: '', rate: '', amount: '', discount: '', igstAmount: '', cgstAmount: '', sgstAmount: '',expenseTax: "", }]);
      setreceivedAmount(0)
      setCashAmount(0)
    } else {
      setNewexistProduct(existArray);
      setProductRows(newArray);
      setProdcutErrors(newErrors);
    }
  
    // ✅ Discount and Tax Calculation (Added without changing your code)
    let subAmount = newArray.reduce((acc, item) => acc + Number(item.amount || 0), 0).toFixed(2);
    let subAmountNonTax = newArray.reduce((acc, item) => acc + (item.nontaxableAmount || 0), 0);
    let totalDiscount = newArray.reduce((acc, item) => acc + ((item.rate * item.qty * (item.discount || 0)) / 100), 0);
    let totalIGST = newArray.reduce((acc, item) => acc + Number(item.igstAmount || 0), 0).toFixed(2);
    let totalCGST = newArray.reduce((acc, item) => acc + Number(item.cgstAmount || 0), 0).toFixed(2);
    let totalSGST = newArray.reduce((acc, item) => acc + Number(item.sgstAmount || 0), 0).toFixed(2);
  
    setFormData((prevFormData:any) => ({
      ...prevFormData,
      product: newArray,
      subtotal: subAmount,
      roundOff: roundOffEnable ? CalculateRoundOff(roundOffValue, subAmount) : 0,
      toatalAmount: roundOffEnable ? CalculateRoundTotalAmount(roundOffValue, subAmount) : subAmount,
      nontaxableTotal: subAmountNonTax,
      totalDiscount: totalDiscount,
      totalIGST: totalIGST,
      totalCGST: totalCGST,
      totalSGST: totalSGST,
    }));
    setreceivedAmount(subAmount)
      setCashAmount(subAmount)
  };
  

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleConfirm = () => {
    if (!formData.expenseCategory) {
      setShowConfirmation(true);
      return;
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const taxOptions = [
		{ label: "GST 0%", value: "GST 0%" },
		{ label: "GST 0.25%", value: "GST 0.25%" },
		{ label: "GST 3%", value: "GST 3%" },
		{ label: "GST 5%", value: "GST 5%" },
		{ label: "GST 12%", value: "GST 12%" },
		{ label: "GST 18%", value: "GST 18%" },
		{ label: "GST 28%", value: "GST 28%" },
		{ label: "IGST 0%", value: "IGST 0%" },
		{ label: "IGST 0.25%", value: "IGST 0.25%" },
		{ label: "IGST 3%", value: "IGST 3%" },
		{ label: "IGST 5%", value: "IGST 5%" },
		{ label: "IGST 12%", value: "IGST 12%" },
		{ label: "IGST 18%", value: "IGST 18%" },
		{ label: "IGST 28%", value: "IGST 28%" },
	];

	const filteredTaxOptions = taxOptions?.filter(option => {
		if (paramsId) {
			return formData?.TaxName === "GST"
				? option?.value.startsWith("GST")
				: formData?.TaxName === "IGST"
				? option?.value.startsWith("IGST")
				: true;
		} else {
			return userdata?.gstValue === "GST"
				? option?.value.startsWith("GST")
				: userdata?.gstValue === "IGST"
				? option?.value.startsWith("IGST")
				: true;
		}
	});

  useEffect(() => {
    if (editValue) {
      setProductRows(formData.product);
      let obj: any = []
      formData.product.map((item: any) => {
        obj.push({ exist: item.qty, originalValue: item.qty })
      })
      setNewexistProduct(obj)
      // setNewexistProduct((prev:any)=>[...prev,{exist: Number(data.productQuantity),originalValue: Number(data.productQuantity)}])
    } else {
      setProductRows([
        { expense_Category: "", hsn: "", rate: "", qty: "", expenseTax: "", amount: 0, productName: "", igstAmount: "", cgstAmount: "", sgstAmount: "", igst: "", cgst: "", sgst: "", discount: "" },
      ]);
    }
  }, [editValue]);

  const handleSelect = (dataArray: any[]) => {

    const containsEmptyRows = prodcutRows.some((row: any) =>
      Object.values(row).some((value) => !value)
    );
    const containsEmptyRowsinExistProduct = newExistProduct.some((row: any) =>
      Object.values(row).some((value) => !value)
    );

    if (containsEmptyRowsinExistProduct) {
      const nonEmptyRows = newExistProduct.filter((row: any) =>
        Object.values(row).some(Boolean)
      );
      setNewexistProduct(nonEmptyRows);
    }
    if (containsEmptyRows) {
      const nonEmptyRows = prodcutRows.filter((row: any) =>
        Object.values(row).some(Boolean)
      );
      setProductRows(nonEmptyRows);
    }

    const newRows = dataArray.map((data) => ({
      expenseItemName: data.expenseItemName,
      expense_Category: data.expense_Category,
      hsn: data.hsn,
      rate: Number(data.rate),
      qty: 1,
      amount: 0,
      discount: "",
      expenseTax: "",
    }));
    

    setProductRows((prevRows: any[]) => {
      const updatedRows = [...prevRows, ...newRows];
      handleExistingCalulation(updatedRows);
      return updatedRows;
    });

    setNewexistProduct((prev: any) => [
      ...prev,
      ...dataArray.map((row: any) => ({
        exist: Number(row.productQty),
        originalValue: Number(row.productQty)
      }))
    ]);

    setIsModalOpen(false);
  };



  return (
    <div className="mb-4  overflow-x-auto">

      <div className="sm:overflow-x-auto xl:overflow-hidden custom-scrollbar">
        <div className="sm:w-[160%] xl:w-auto">
          <div
            className={
              "hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 mb-2 text-white"
            }
            style={{ backgroundColor: "#008065" }}
          >
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-6/12 sm:w-1/4 md:w-[17%] lg:w-[22%] xl:w-[23%] 2xl:w-[305] text-left"}
            >
              <span className="inline-block">S No</span>
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 md:w-6/12 2xl:w-10/12 text-left sm:pr-10"}
            >
              <span className="inline-block">Expenses</span>
            </div>
            {userdata?.hsnNo ? (
              <>
                <div
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className={" w-full sm:w-1/4 text-left xl:ml-10"}
                >
                  <span className="inline-block">HSN/SAC</span>
                </div>
              </>
            ) : (<></>)}
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-left xl:mr-2 2xl:ml-6"}
            >
              Rate
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-left xl:w-ml-4 2xl:ml-6"}
            >
              Qty
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-left xl:ml-4 2xl:ml-6 lg:mr-9"}
            >
              Disc(%)
            </div>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={" w-full sm:w-1/4 text-left xl:ml-4 2xl:ml-6"}
            >
              Tax
            </div>

            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={"  w-full sm:w-4/12 text-right sm:pr-10"}
            >
              Total
            </div>
          </div>

          {prodcutRows?.map((productrow: any, index: any) => (
            <div
              key={index}
              className={
                "flex flex-col sm:flex-row rounded-lg  w-full   items-center relative " +
                (index % 2 !== 0 ? " bg-gray-50 " : "")
              }
            >
              <div
                style={{
                  fontFamily: "poppins",
                  textAlign: "left",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={"w-full sm:w-5/12  text-left  pr-0 sm:pr-3  flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden w-1/2 flex flex-row items-center">
                  S No
                </span>

                <div className="font-title flex-1 text-right sm:text-center 2xl:text-left 2xl:ml-8 pr-1 sm:pr-0">
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 900,
                    }}

                  >
                    {index + 1}
                  </div>
                </div>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full sm:w-[310%] lg:w-[320%] 2xl:w-[380%] text-right pr-0 sm:pr-3 flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden w-1/2 flex flex-row items-center">
                  Expenses
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    readOnly
                    autoComplete="nope"
                    value={productrow.expenseItemName ? productrow.expenseItemName : ""}
                    placeholder="Expense Name"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      /* borderColor:
                        !prodcutErrors[index]?.expense_Category && productError
                          ? "red"
                          : "#00A787", */
                    }}
                    /* className={
                      defaultInputSmStyle +
                      " text-left border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full "
                    } */
                    className={`${defaultInputSmStyle} text-left border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full 
                    ${!prodcutErrors[index]?.expense_Category && productError ? 'border-[#FF0000]' : 'border-[#949494]'} 
                    ${(!productError || prodcutErrors[index]?.expense_Category)  && 'focus:border-[#07ad7b]'}`}
                    onChange={(event) => {
                      const value = event.target.value.trimStart();
                      handleInputChange(index, "expense_Category", value);
                    }}
                    
                  // disabled
                  />
                  {/* {!prodcutErrors[index]?.prodcut && productError && (
                    <p
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="absolute text-red-400 text-xs whitespace-nowrap"
                    >
                      Product is required
                    </p>
                  )} */}
                </div>
              </div>
              {userdata?.hsnNo ? (
                <>
                  <div
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className={" w-full  text-right  pr-0 sm:pr-3  flex flex-row sm:block mb-2"}
                  >
                    <span className="sm:hidden w-1/2 flex flex-row items-center">
                      HSN/SAC
                    </span>

                    <div className="font-title flex-1 text-left">
                      <input
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 400,
                          // borderColor: "#00A787",
                        }}
                        autoComplete="nope"
                        value={productrow.hsn}
                        onChange={(event) => {
                          const value = event.target.value.replace(/[^0-9]/g, '');
                          handleInputChange(index, "hsn", value)
                        }}
                        placeholder="HSN/SAC"
                        type=""
                        className={
                          defaultInputSmStyle +
                          " text-right  border-2 focus:border-[#07ad7b] border-[#949494] focus:outline-none rounded-[7px] px-2 h-9   w-full  "
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (<></>)}
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-right  pr-0 sm:pr-3  flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden  w-1/2 flex flex-row items-center">
                  Rate
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      /* borderColor:
                        !prodcutErrors[index]?.rate && productError
                          ? "red"
                          : "#00A787", */
                    }}
                    autoComplete="nope"
                    value={productrow.rate}
                    onChange={(event) => {
                      const value = event.target.value.replace(/[^0-9.]/g, '');
                      handleInputChange(index, "rate", value);
                    }}
                    placeholder="Rate"
                    type=""
                    /* className={
                      defaultInputSmStyle +
                      " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                    } */
                    className={`${defaultInputSmStyle} text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full 
                    ${!prodcutErrors[index]?.rate && productError ? 'border-[#FF0000]' : 'border-[#949494]'} 
                    ${(!productError || prodcutErrors[index]?.rate) && 'focus:border-[#07ad7b]'}
                    `}
                  />
                </div>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full text-right pr-0 sm:pr-3 flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden md:hidden w-1/2 flex flex-row items-center">
                  Quantity
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    autoComplete="nope"
                    type=""
                    value={productrow.qty}
                    onChange={(event) => {
                      let value = event.target.value.replace(/[^0-9]/g, '');
                      if (value.startsWith('0')) {
                        value = value.substring(1); // Remove the leading '0'
                      }
                      handleInputChange(index, "qty", value);
                    }}
                    placeholder="Qty"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      /* borderColor:
                        !prodcutErrors[index]?.qty && productError
                          ? "red"
                          : "#00A787", */
                    }}
                    /* className={
                      defaultInputSmStyle +
                      " text-right remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full  "
                    } */
                    className={`${defaultInputSmStyle}  text-right remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full 
                      ${!prodcutErrors[index]?.qty && productError ? 'border-[#FF0000]' : 'border-[#949494]'} 
                      ${(!productError || prodcutErrors[index]?.qty) && 'focus:border-[#07ad7b]'}
                      `}
                  />

                </div>
              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-right pr-0 sm:pr-3  flex flex-row sm:block mb-2"}
              >
                <span className="sm:hidden md:hidden w-1/2 flex flex-row items-center">
                  Discount
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      // borderColor: "#00A787",
                    }}
                    autoComplete="nope"
                    value={productrow.discount}
                    onChange={(event) => {
                      const value = event.target.value.replace(/[^0-9.]/g, '');
                      if (parseFloat(value) > 100) {
                        return 0;
                      }
                      handleInputChange(index, "discount", value)
                    }}
                    placeholder="Discount"
                    type=""
                    className={
                      defaultInputSmStyle +
                      " text-right border-2 focus:border-[#07ad7b] border-[#949494] focus:outline-none rounded-[7px] px-2 h-9   w-full  "
                    }
                  />
                </div>

              </div>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-left pr-0 sm:pr-3  flex flex-row sm:block mb-2 sm:w-[310%] lg:w-[110%] 2xl:w-[180%]"}
              >
                <span className="sm:hidden md:hidden w-1/2 flex flex-row items-center">
                  Tax
                </span>

                <div className="font-title flex-1 text-left">
                  <select
                    name="expenseTax" id=""
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      // borderColor: "#00A787",
                    }}
                    value={productrow.expenseTax}
                    onChange={(event) => {
                      const value = event.target.value.trimStart();
                      handleInputChange(index, "expenseTax", value)
                    }}
                    className={
                      defaultInputSmStyle +
                      " text-left  border-2 focus:border-[#07ad7b] border-[#949494] focus:outline-none rounded-[7px]  h-9   w-full  "
                    }>
                    <option value="" disabled >
                      Select Tax
                    </option>
                    {filteredTaxOptions?.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}

                  </select>
                </div>

              </div>


              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={" w-full  text-right pr-0 sm:pr-12 flex flex-row sm:block whitespace-nowrap"}
              >
                <span className="sm:hidden w-1/2 flex flex-row items-center">
                  Total
                </span>
                <span className={"inline-block w-1/2 sm:w-full sm:px-1 h-9 pt-2 overflow-hidden"}>
                  <NumberFormat value={productrow?.amount} />
                </span>
              </div>

              <div
                onClick={() => removeRow(index, productrow)}
                className="w-full sm:w-10 sm:absolute sm:right-0 sm:pl-2 h-9 pt-2 sm:mt-[-17px] mb-3 md:mb-0"
              >
                <div className="w-full text-[#9C0000] font-semibold font-title sm:h-8 sm:w-8 cursor-pointer rounded-2xl bg-[#FFE0E0] flex justify-center items-center text-center p-[5px]">
                  <DeleteIcon className="h-4 w-4" style={IconStyle} />
                  <span className="block sm:hidden">Delete Product</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Add Product Actions */}

      <div
        style={{ fontFamily: "poppins", fontSize: "14px" }}
        className="flex flex-col sm:flex-row rounded-lg sm:visible w-full px-0 md:px-4 pl-0 sm:pl-2 py-0 md:py-2 items-center sm:justify-end"
      >
        <div
          style={{ fontFamily: "poppins", fontSize: "14px" }}
          className="w-full  text-right md:mr-5 2xl:mr-0 xl:mr-0 lg:mr-0 md:pr-0 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1"
        ></div>
        <div className="w-full  text-right md:mr-5 2xl:mr-0 xl:mr-0 lg:mr-0 md:pr-0 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1">

        </div>
        <div
          style={{ fontFamily: "poppins", fontSize: "14px" }}
          className="font-title w-full text-right md:pr-8 2xl:pr-8 xl:pr-8 lg:pr-8 sm:pr-8 flex flex-row sm:block mb-1"
        >
          {/* {productlist.length ? > 0 ?} */}
          <button
            type="button"
            // disabled={formData.expenseCategoryId ? false : true}
            className={`rounded-xl font-bold text-white flex flex-row items-center bg-[#008065] justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm`}
            onClick={handleConfirm}
          >
            <InvoiceIcon style={IconStyle} className="h-5 w-5" />
            Add Existing Item
          </button>
        </div>
      </div>
      {
        isModalOpen && (
          <ExpenseModel
            formData={formData}
            setStockList={setStockList}
            closeModal={closeModal}
            setProductRows={setProductRows}
            prodcutRows={prodcutRows}
            handleSelect={handleSelect}
          />
        )
      }
      {/* {
        showConfirmation && (
          <StockUpdateModel
            message="Are you sure you want to proceed?"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )
      } */}
      {showConfirmation && (
        <ExpenseCategoryAlertPopoup
          message="Are you sure you want to proceed?"
          onCancel={handleCancel}
        />
      )}
    </div >
  );
}

export default ExpenseTaxProductDetails;