import React from "react";

const DownloadExcelSheet = () => {
  const downloadExcel = () => {
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/product_bulk_upload.xlsx`; // Path to your file
    link.setAttribute("download", "product_bulk_upload.xlsx"); // Suggested download file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after the click
  };

  return (
    <div className="flex justify-start">
  <div className="py-4 text-center w-full sm:w-[40%] md:w-[40%] lg:w-[40%] xl:w-[35%] 2xl:w-[19%]">
    <button
      onClick={downloadExcel}
      className="rounded-xl bg-[#008065] text-white font-bold w-full py-2 px-4 lg:text-base focus:outline-none focus:shadow-outline whitespace-nowrap"
    >
      Download Sample Excel
    </button>
  </div>
</div>

  );
};

export default DownloadExcelSheet;
