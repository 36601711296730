/* eslint-disable eqeqeq */
import { toWords } from "number-to-words";
import { useSelector } from "react-redux";
import NumberFormat from "../commen/NumberFormat";
import CalculateIndianFormattotal from "../commen/CalculateIndianFormattotal";


const DeliveryChallanProductPreview = ({ Invoice, componentRef, closeModal, selectedFont,roundOffEnable,amountInWords }: any) => {
  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  const totalAmount = Invoice.toatalAmount;
  const roundedTotalAmount = Math.round(totalAmount);
  const totalAmountInWords = Number.isFinite(roundedTotalAmount)
    ? amountInWords === "International Format"
      ? toWords(roundedTotalAmount)
      : "Rupees " + CalculateIndianFormattotal(roundedTotalAmount) + " Only"
    : "Invalid amount";
  const handleCancelClick = () => {
    closeModal();
  };
  return (
    <div content={componentRef} style={{ fontFamily: selectedFont }}>
      <div style={{ breakBefore: "page" }}></div>
      {/*   image and client information   */}

      <div className="grid grid-cols-6 ">
        <div className="col-start-1 col-end-2">
          {Invoice.imageData ? (
            <div className=" font-title  sm:text-left ">
              <label
                style={{ fontFamily: selectedFont }}
                className="   items-start  text-blue      "
              >
                <img
                  className="pr-0 sm:pr-8 md:pr-8 pt-7 h-20 2xl:ml-7 xl:ml-7 lg:ml-7 md:ml-7 sm:ml-4 ml-2"
                  src={Invoice.imageData}
                  alt="logonew "
                />
              </label>
            </div>
          ) : null}
        </div>

        <div className="col-start-2 col-span-4 ">
          {Invoice.companyName ? (
            <div className=" text-center  pt-7 text-lg font-semibold  sm:font-normal sm:text-4xl ">
              {Invoice.companyName}
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="capitalize text-center">
          <div className="mt-1">
            <span className='p-1 break-words whitespace-normal'>
              {Invoice.companyAddress && (
                <span className="inline-block">Address: {Invoice.companyAddress}, </span>
              )}
              {Invoice.companyCity && (
                <span className="inline-block">{Invoice.companyCity}, </span>
              )}
              {Invoice.companyState && (
                <span className="inline-block">{Invoice.companyState} </span>
              )}
              {Invoice.companyPostalCode && (
                <span className="inline-block">- {Invoice.companyPostalCode}</span>
              )}
            </span>

          </div>
          <div>
            <span className='flex flex-col sm:flex-row justify-center'>
              {Invoice.companygst ? (
                <span>
                  Gstn:
                  {Invoice.companygst},
                </span>
              ) : null}
              {Invoice.companyPan ? (
                <span>PAN: {Invoice.companyPan},</span>
              ) : null}
            </span>
            <span className='flex flex-col sm:flex-row justify-center items-center'>
              {Invoice.companyEmail ? (
                <span className="flex items-center mr-1 pl-0">
                  <span className="whitespace-nowrap">Email:</span>
                  <span className="lowercase  sm:w-full w-[190px] overflow-x-auto sm:overflow-visible whitespace-nowrap hide-scrollbar pl-1">{Invoice.companyEmail},</span>
                </span>
              ) : null}
              {Invoice.companyMobile ? (
                <span className="flex items-center">
                  <span className="whitespace-nowrap">Mobile:</span>
                  <span className="ml-1">{Invoice.companyMobile}</span>
                </span>
              ) : null}
            </span>
          </div>
        </div>
      </div>
      <div className="border "></div>
      {/*        *******************   invoice name                */}

      <div className="bg-white  overflow-x-auto" id="InvoiceWrapper">
        <div className="justify-center flex flex-row relative ">
          <span
            className="w-64 md:w-11/12 lg:w-11/12 2xl:w-11/12 truncate rounded-[7px] text-md sm:text-2xl text-center  focus:outline-none"
            style={{
              fontFamily: selectedFont,
              fontWeight: 700,
            }}
          >
            {Invoice.invoicehead}
          </span>
          {/* <span
                readOnly
                className="w-11/12 truncate rounded-[7px] text-md sm:text-2xl text-center  focus:outline-none "
                autoComplete="nope"
                style={{
                  fontFamily:selectedFont,
                  fontWeight: 700,
                }}
                id="input_focus"
                value={Invoice.invoicehead}
              /> */}
          {/* </h1> */}
        </div>
        {/*     client details           */}
        <div className=" px-4 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between ">
          <div className="flex-1 sm:mx-2 column-reverse">
            <div className="flex flex-col sm:flex-row pt-3 gap-2 justify-between pre-reverse">
              <div className="lg:w-[90%] xl:w-[60%] 2xl:w-[60%] sm:w-[70%] ">
                <div className="flex flex-row  items-center mb-2 ">
                  {Invoice.clientName ? (
                    <div
                      className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      style={{
                        color: "#008065",
                        fontFamily: selectedFont,

                        fontWeight: 700,
                      }}
                    >
                      Client Details (Shipped To)
                    </div>) : (<div
                      className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      style={{
                        color: "#008065",
                        fontFamily: selectedFont,

                        fontWeight: 700,
                      }}
                    >
                      Vendor Details
                    </div>)}
                </div>
                {Invoice.clientName ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Name
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%] sm:w-[70%] w-1/2 ">
                        <input
                          className="focus:outline-none  text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.clientName}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : Invoice.vendorName ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Name
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%] sm:w-[70%] w-1/2   ">
                        <input
                          className="focus:outline-none  text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.vendorName}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientAddress ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Address
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%] sm:w-[70%] w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.clientAddress}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : Invoice.vendorMobile ? (<>
                  <div className="flex justify-between">
                    <div
                      style={{
                        fontFamily: selectedFont,
                          marginRight: "0px",
                        fontWeight: 700,
                        verticalAlign: "top",
                      }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                    >
                      Mobile
                    </div>
                    <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%] sm:w-[70%] w-1/2">
                      <input
                        className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        readOnly
                        value={Invoice.vendorMobile}
                        style={{
                          fontFamily: selectedFont,
                          fontWeight: 400,
                          verticalAlign: "top",
                        }}
                      />
                    </div>
                  </div>
                </>) : null}

                {Invoice.clientCity ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        City
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]   sm:w-[70%]  w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.clientCity}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : Invoice.vendorEmail ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Email
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]   sm:w-[70%]  w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.vendorEmail}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientState ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        State
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%] w-1/2 ">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.clientState}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientPostalCode ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left whitespace-nowrap text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Postal Code
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%]  w-1/2">
                        <input
                          className="focus:outline-none text-xs w-full lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.clientPostalCode}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientMobile ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs whitespace-nowrap lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4 "
                      >
                        Mobile
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%]  w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.clientMobile}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientEmail ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Email
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%]  w-1/2 ">
                        <input
                          className="focus:outline-none  text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.clientEmail}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientPan ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs whitespace-nowrap lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md  w-1/2 sm:w-1/4"
                      >
                        PAN Number
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%]  w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.clientPan}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientgst ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md  w-1/2 sm:w-1/4"
                      >
                        GST
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%]  w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.clientgst}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="">
                <div className="flex flex-row items-center justify-between mb-2 ">
                  {Invoice.clientName ? (<div
                    style={{
                      color: "#008065",
                      fontFamily: selectedFont,
                      fontWeight: 700,
                    }}
                    className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Challan No
                  </div>) : (
                    <div
                      style={{
                        color: "#008065",
                        fontFamily: selectedFont,
                        fontWeight: 700,
                      }}
                      className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Purchase Return No
                    </div>
                  )}
                  <div className="font-title flex-1 text-right ">
                    <input
                      readOnly
                      value={Invoice.challanNo}
                      style={{
                        fontFamily: selectedFont,
                      }}
                      className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full  sm:ml-auto block focus:outline-none "
                    />
                  </div>
                  <div></div>
                </div>

                {Invoice.SalesreturnNo ? (
                  <>
                    <div className="flex flex-row items-center justify-between mb-2 ">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: "poppins",

                          fontWeight: 700,
                        }}
                        className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Sales Return No
                      </div>
                      <div className="font-title flex-1 text-right ">
                        <input
                          readOnly
                          value={Invoice.SalesreturnNo}
                          style={{
                            fontFamily: "poppins",
                          }}
                          className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full sm:ml-auto block focus:outline-none "
                        />
                      </div>
                      <div></div>
                    </div>
                  </>) : Invoice.purchaseReturnDate ? (
                    <>
                      <div className="flex flex-row items-center justify-between mb-2 ">
                        <div
                          style={{
                            color: "#008065",
                            fontFamily: selectedFont,

                            fontWeight: 700,
                          }}
                          className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        >
                          Purchase Return Date
                        </div>
                        <div className="font-title flex-1 text-right ">
                          <input
                            readOnly
                            value={Invoice.purchaseReturnDate}
                            style={{
                              fontFamily: selectedFont,
                            }}
                            className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full sm:ml-auto block focus:outline-none "
                          />
                        </div>
                        <div></div>
                      </div>
                    </>) : null}

                {Invoice.invoicedate ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2  ">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                        className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                      >
                        Challan Date
                      </div>
                      <div className="font-title flex-1 text-right">
                        <input
                          readOnly
                          value={Invoice.invoicedate}
                          style={{
                            fontFamily: selectedFont,
                          }}
                          className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full sm:ml-auto  focus:outline-none "
                        ></input>
                      </div>
                      <div></div>
                    </div>
                  </>
                ) : Invoice.purchaseEntryNo ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2  ">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                        className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                      >
                        Purchase Entry No
                      </div>
                      <div className="font-title flex-1 text-right">
                        <input
                          readOnly
                          value={Invoice.purchaseEntryNo}
                          style={{
                            fontFamily: selectedFont,
                          }}
                          className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full sm:ml-auto  focus:outline-none "
                        ></input>
                      </div>
                      <div></div>
                    </div>
                  </>
                ) : null}

                {Invoice.SalesreturnDate ? (
                  <>
                    <div className="flex flex-row items-center justify-between mb-2 ">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: "poppins",

                          fontWeight: 700,
                        }}
                        className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                      >
                        Sales Return Date
                      </div>
                      <div className="font-title flex-1 text-right ">
                        <input
                          readOnly
                          value={Invoice.SalesreturnDate}
                          style={{
                            fontFamily: "poppins",
                          }}
                          className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full sm:ml-auto block focus:outline-none "
                        />
                      </div>
                      <div></div>
                    </div>
                  </>) : null}

                {Invoice.duedate ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-6 ">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                        className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Due Date
                      </div>
                      <div className="font-title flex-1 text-right ">
                        <div>
                          <input
                            readOnly
                            value={Invoice.duedate}
                            style={{
                              fontFamily: selectedFont,
                            }}
                            className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md sm:ml-auto block w-full  focus:outline-none  "
                          ></input>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className=" px-4 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between ">
          <div className="flex-1 sm:mx-2 column-reverse">
            <div className="flex flex-col sm:flex-row pt-3 justify-between pre-reverse gap-4 xl:gap-0">
              <div className="sm:w-1/2  xl:w-[60%] 2xl:w-[60%]">
                <div className="flex flex-row  items-center mb-2 ">
                  {Invoice.shipFromName ? (
                    <div
                      className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      style={{
                        color: "#008065",
                        fontFamily: selectedFont,

                        fontWeight: 700,
                      }}
                    >
                      Shipping From:
                    </div>
                  ) : null}
                </div>
                {Invoice.shipFromName ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          // marginRight: "66px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="w-1/2 sm:w-1/4 text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Name:
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%] sm:w-[70%] w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                          readOnly
                          value={Invoice.shipFromName}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.shipFromAddress ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          // marginRight: "53px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="w-1/2 sm:w-1/4 text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Address:
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%] sm:w-[70%] w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.shipFromAddress}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.shipFromCity ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          // marginRight: "79px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="w-1/2 sm:w-1/4 text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        City:
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%] sm:w-[70%] w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.shipFromCity}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.shipFromState ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          // marginRight: "70px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="w-1/2 sm:w-1/4 text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        State:
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%] sm:w-[70%] w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.shipFromState}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.shipFromPostalCode ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          // marginRight: "28px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="w-1/2 sm:w-1/4 text-left whitespace-nowrap text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Postal Code:
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%] sm:w-[70%] w-1/2">
                        <input
                          className="focus:outline-none text-xs w-full lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.shipFromPostalCode}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              
              <div className="sm:w-1/2 xl:w-[40%]">
                <div className="flex flex-row  items-center mb-2 ">
                  {Invoice.shipToName ? (
                    <div
                      className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      style={{
                        color: "#008065",
                        fontFamily: selectedFont,

                        fontWeight: 700,
                      }}
                    >
                      Shipping To:
                    </div>
                  ) : null}
                </div>
                {Invoice.shipToName ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          // marginRight: "66px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="w-1/2 sm:w-4/12 text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Name:
                      </div>
                      <div className="w-1/2 sm:w-8/12">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                          readOnly
                          value={Invoice.shipToName}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.shipToAddress ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          // marginRight: "53px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="w-1/2 sm:w-4/12 text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Address:
                      </div>
                      <div className="w-1/2 sm:w-8/12">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.shipToAddress}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.shipToCity ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          // marginRight: "79px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="w-1/2 sm:w-4/12 text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        City:
                      </div>
                      <div className="w-1/2 sm:w-8/12">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.shipToCity}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.shipToState ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          // marginRight: "70px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="w-1/2 sm:w-4/12 text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        State:
                      </div>
                      <div className="w-1/2 sm:w-8/12">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.shipToState}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.shipToPostalCode ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          // marginRight: "28px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="w-1/2 sm:w-4/12 text-left whitespace-nowrap text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        Postal Code:
                      </div>
                      <div className="w-1/2 sm:w-8/12">
                        <input
                          className="focus:outline-none text-xs w-full lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={Invoice.shipToPostalCode}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* product details */}
        <div className="px-6 mt-3">
          {/* Header Row */}
          <div className="hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-2 py-2 text-white bg-[#008065]">
            <div style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
              className=" sm:w-1/6 md:w-1/6 lg:w-2/12 xl:w-1/12 2xl:w-1/12 text-left "
            >
              S No
            </div>
            <div style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
              className=" sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12 text-left "
            >
              Product
            </div>
            {userdata?.hsnNo ? (
              <div style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
                className="w-full sm:w-1/4 text-left sm:text-center"
              >
                HSN
              </div>
            ) : (<></>)}
            <div style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
              className=" w-full sm:w-1/4  sm:text-right"
            >
              Price
            </div>
            <div style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
              className="w-full sm:w-1/4 text-center sm:text-center   "
            >
              Qty
            </div>
            <div style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
              className="w-full sm:w-1/4 text-center sm:text-center"
            >
              Disc(%)
            </div>
            {Invoice?.gstType === "IGST" || Invoice?.gstValue == "IGST" ? (
              <div style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }} className="w-full sm:w-1/4 text-center sm:text-center">
                IGST(%)
              </div>
            ) : (
              <>
                <div style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }} className="w-full sm:w-1/4 text-center sm:text-center">
                  CGST(%)
                </div>
                <div style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }} className="w-full sm:w-1/4 text-center sm:text-center">
                  SGST(%)
                </div>
              </>
            )}
            <div style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }} className="w-full sm:w-1/4 text-right 2xl:text-right">
              Total
            </div>
          </div>
        </div>
        {Invoice?.product?.map((product: any, index: number) => {
          return (
            <div className="px-4 py-1 sm:px-8">
              <div
                key={`${index}_${product.id}`}
                className="flex lg:flex-row flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible md:flex-row xl:flex-row flex-col rounded-lg w-full items-center relative text-sm"
              >
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                className="sm:pl-2 w-full  mb-1 sm:mb-0 sm:w-1/6 md:w-1/6 lg:w-2/12 xl:w-1/12 2xl:w-1/12 text-left flex flex-row sm:block"
                >
                <span
                  className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                    S No
                  </span>
                  <span className="inline-block w-full product">
                    <input
                      readOnly
                      value={index + 1}
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                    className="font-title block w-full sm:text-left text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                className=" w-full   mb-1 sm:mb-0 sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12 text-left  flex flex-row  "
                >
                <span
                  className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                    Product
                  </span>
                  <span className="inline-block w-full product">
                    <input
                      readOnly
                      value={product.prodcut}
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                    className="font-title  block w-full sm:text-left  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>

                {userdata?.hsnNo ? (
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontWeight: 700,
                    }}
                  className=" w-full  mb-1 sm:mb-0 sm:w-1/4 text-left flex flex-row   "
                  >
                  <span
                    className="sm:hidden w-full flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                      Hsn
                    </span>
                  <span className="inline-block w-full  sm:w-full ">
                      <input
                        readOnly
                        style={{
                          fontFamily: selectedFont,
                          fontWeight: 400,
                        }}
                        value={product.hsn ? product.hsn : "N/A"}
                      className="font-title block w-full sm:text-center text-right focus:outline-none text-xs sm:text-sm  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      />
                    </span>
                  </div>
                ) : (<></>)}

                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                className="w-full mb-1 sm:mb-0  sm:w-1/4  sm:text-left flex flex-row  "
                >
                  <span className="sm:hidden w-full flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                    Price
                  </span>
                  <span className="inline-block w-full mb-1 sm:mb-0">
                    <input
                      readOnly
                      value={product?.rate}
                      type={"number"}
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                    className="font-title  block w-full  sm:text-right text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className=" w-full mb-1 sm:mb-0 sm:w-1/4 text-center sm:text-center  flex flex-row   "
                >
                  <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                    Qty
                  </span>
                  <span className="inline-block  w-full">
                    <input
                      readOnly
                      value={product.qty}
                      type={"number"}
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                        className="font-title text-right block w-full   sm:text-center  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className=" w-full mb-1 sm:mb-0 sm:w-1/4 text-center sm:text-center  flex flex-row   "
                >
                  <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                    Disc(%)
                  </span>
                  <span className="inline-block  w-full">
                    <input
                      readOnly
                      // value={product.discount}
                      value={product.discount ? product.discount : "N/A"}
                      type=""
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                        className="font-title text-right block w-full   sm:text-center  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
                {Invoice?.gstType == "IGST" ? (
                  <>
                    <div
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 700,
                      }}
               className=" w-full mb-1 sm:mb-0 sm:w-1/4 text-center sm:text-center  flex flex-row   "
                    >
                      <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                        IGST(%)
                      </span>
               <span className="inline-block  w-full">
                        <input
                          readOnly
                          value={product.igst || product.prodgst}
                          type={"number"}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                          }}
                     className="font-title text-right block w-full   sm:text-center  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        />
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 700,
                      }}
                  className=" w-full mb-1 sm:mb-0 sm:w-1/4 text-center sm:text-center  flex flex-row   "
                    >
                      <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                        CGST(%)
                      </span>
                  <span className="inline-block  w-full">
                        <input
                          readOnly
                          value={product.cgst || product.prodcgst}
                          type={"number"}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                          }}
                        className="font-title text-right block w-full   sm:text-center  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        />
                      </span>
                    </div>
                    <div
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 700,
                      }}
                  className=" w-full mb-1 sm:mb-0 sm:w-1/4 text-center sm:text-center  flex flex-row   "
                    >
                      <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                        SGST(%)
                      </span>
                  <span className="inline-block  w-full">
                        <input
                          readOnly
                          value={product.sgst || product.prodsgst}
                          type={"number"}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                          }}
                        className="font-title text-right block w-full   sm:text-center  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                        />
                      </span>
                    </div>
                  </>)}
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                    className=" w-full mb-1 sm:mb-0 sm:w-1/4 text-right flex flex-row "
                >
                    <span
                      className="sm:hidden w-1/2  flex flex-row items-center text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                    Total
                  </span>
                    <span className="font-block w-full sm:w-full  md:w-full text-xs  sm:text-sm lg:w-full ">
                    <NumberFormat value={product.amount}></NumberFormat>
                  </span>
                </div>
              </div>
            </div>
          );
        })}

        {/********************************** * subtotal *******************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Subtotal
          </div>

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-block w-1/2 sm:w-1/4 text-right sm:pr-4 flex flex-row justify-end sm:block mb-1"
          >
            <NumberFormat
              value={Invoice.subtotal}

            //   displayType={"text"}
            //   thousandSeparator={true}
            />
          </div>
        </div>
        {/* ******************************cgstPercentage**************/}

        {Invoice?.tax?.map((tax: any, index: number) => {
          return (
            <div key={index}>
              {/* Render each tax item with specific details */}
              <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className="font-title w-[60%] sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                >
                  {tax.taxName}
                  {tax.taxName !== "Extra Fees" ? (
                    <span className="ml-2">@</span>
                  ) : (
                    ""
                  )}
                  {tax.taxName !== "Extra Fees" ? tax.taxPersent : ""}
                  {tax.taxName !== "Extra Fees" ? <span>%</span> : ""}
                </div>

                <div
                  style={{
                    fontFamily: selectedFont,
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className="font-title w-1/2 sm:w-1/4 text-right sm:pr-7 flex flex-row justify-end sm:block mb-1"
                >
                  <NumberFormat value={tax.taxAmount} />
                </div>
              </div>


            </div>
          );
        })}
                 {roundOffEnable == true && (
          
            <div
              className={
                "flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center"
              }
            >
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={
                  "font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                }
              >
                Round Off
              </div>
              
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={
                  "font-title w-1/2 sm:w-1/4 text-right sm:pr-4 flex flex-row justify-end sm:block mb-1"
                }
              >
                <NumberFormat value={Invoice.roundOff} />
              </div>
            </div>
          
        )}
        {/*************************total****************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center ">

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Total
          </div>

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-4 flex flex-row justify-end sm:block mb-1"
          >
            <NumberFormat
              value={Invoice.toatalAmount}
            />
          </div>
        </div>

        {/*************************AMOUNT IN WORDS****************************/}
        <div className="flex flex-row sm:flex-row sm:justify-start rounded-lg sm:visible w-full  sm:px-4 py-1 items-center ">

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-full text-left pl-4 md:pl-5 lg:pl-4 flex flex-row sm:block mb-1 sm:mb-0  whitespace-normal capitalize"
          >
            {totalAmountInWords}

          </div>


        </div>
        {/********************************Cancel*********************************/}
        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            // onClick={() => setOpenView(!viewOpen)}
            // ref={cancelButtonRef}
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeliveryChallanProductPreview
