import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IconStyle, defaultInputSmStyle } from "../../commen/defaultStyles";
import NumberFormat from "../../commen/NumberFormat";
import StockUpdateModel from "../../commen/StockUpdateModel";
import DeleteIcon from "../../Icons/DeleteIcon";
import ServiceModel from "./ServiceModel";
import InvoiceIcon from "../../Icons/InvoiceIcon";
import CalculateRoundOff from "../../commen/CalculateRoundOff";
import CalculateRoundTotalAmount from "../../commen/CalculateRoundTotalAmount";

const QuotationServiceDetails = ({
    addRow,
    formData,
    setFormData,
    updateServiceErrors,
    serviceErrors,
    serviceRows,
    setServiceRows,
    serviceError,
    setServiceErrors,
    editValue,
    paramsId,
    roundOffValue,
    roundOffEnable,
}: any) => {
    const template = useSelector((state: any) => state.userColorTemplate);
    const { userdata } = template;
    const [newExistProduct, setNewexistProduct] = useState([{ exist: "", originalValue: '' }] as any);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [discountValue, setDiscountValue] = useState(0 as any);

    const [gstValue, setGstValue] = useState<string>(formData.gstServiceType ? formData.gstServiceType : "GST");


    const [taxValue, setTaxValue] = useState(0 as any);

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    const getCalculateTotal = (subAmount: any) => {


        let percentageOfdis = discountValue;

        let total = subAmount;
        let disAmount = percentageOfdis
            ? ((percentageOfdis / 100) * total)
            : 0;


        let TotalAmount = total - disAmount;

        let percentageOfTax = taxValue;
        let taxValuegst = taxValue / 2

        let cgst = taxValuegst;
        let sgst = taxValuegst;

        let taxAmount = (TotalAmount * (percentageOfTax / 100));
        let cgstValue = 0;
        let sgstValue = 0;
        cgstValue = taxAmount / 2;
        sgstValue = taxAmount / 2;

        let Total = taxAmount + TotalAmount


        let serviceTaxArray: any = []
        const GSTTaxArray = [

            {
                taxName: "Discount",
                discountStatus: "",
                taxPersent: percentageOfdis,
                taxAmount: disAmount.toFixed(2),
            },
            {
                taxName: gstValue,
                discountStatus: "",
                taxPersent: percentageOfTax,
                taxAmount: taxAmount.toFixed(2),
            },
            {
                taxName: "CGST",
                discountStatus: "",
                taxPersent: cgst,
                taxAmount: Number(cgstValue).toFixed(2),
            },
            {
                taxName: "SGST",
                discountStatus: "",
                taxPersent: sgst,
                taxAmount: Number(sgstValue).toFixed(2),
            },

        ];

        const IGSTTaxArray = [
            {
                taxName: "Discount",
                discountStatus: "",
                taxPersent: percentageOfdis,
                taxAmount: disAmount.toFixed(2),
            },
            {
                taxName: "IGST",
                discountStatus: "",
                taxPersent: percentageOfTax,
                taxAmount: taxAmount.toFixed(2),
            }
        ];


        if (formData.gstServiceType === "GST") {

            serviceTaxArray = GSTTaxArray
        }
        else if (formData.gstServiceType === "IGST") {
            serviceTaxArray = IGSTTaxArray

        }
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            tax: serviceTaxArray,
            // gstServiceType: gstValue
        }));
        return Total;


    }
    const handleInputChange = (index: any, fieldName: any, value: any) => {

        const newArray: any = [...serviceRows];
        newArray[index][fieldName] = value;
        updateServiceErrors(index, fieldName, value);

        if (fieldName === "qty" || fieldName === "rate") {
            newArray[index]["amount"] =
                newArray[index].rate && Number(newArray[index].qty)
                    ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
                    : !newArray[index].rate
                        ? 0
                        : 0;
            newArray[index]["amount"] = (newArray[index]["amount"]).toFixed(2);
            setServiceRows(newArray);
            let subAmount: any = 0;
            for (let i = 0; i < newArray.length; i++) {
                subAmount += Number(newArray[i].amount);
            }

            subAmount = Number(subAmount);
            // subAmount = !isNaN(subAmount) ? subAmount.toFixed(2) : 0;

            if (paramsId) {


                // for(let i=0; i< formData.tax.length; i++){
                let discount: any = (parseFloat(formData.tax[0].taxPersent))
                setDiscountValue(discount)
                let gst: any = (parseFloat(formData.tax[1].taxPersent))
                setTaxValue(gst)
                let Total = taxValue || discountValue ? getCalculateTotal(subAmount) : subAmount;

                // }
                setFormData((prevFormData: any) => ({
                    ...prevFormData,
                    product: newArray,
                    subtotal: subAmount,
                    // toatalAmount: Number(Total).toFixed(2),
                    roundOff: roundOffEnable == true ? CalculateRoundOff(roundOffValue,Total):0,
                    toatalAmount:roundOffEnable == true ?  CalculateRoundTotalAmount(roundOffValue,Total):Total,
                    // gstServiceType: gstValue
                }));

            } else {

                let Total = taxValue || discountValue ? getCalculateTotal(subAmount) : subAmount;

                setFormData((prevFormData: any) => ({
                    ...prevFormData,
                    product: newArray,
                    subtotal: subAmount,

                    roundOff:roundOffEnable == true ?  CalculateRoundOff(roundOffValue,Total):0,
                    toatalAmount:roundOffEnable == true ?  CalculateRoundTotalAmount(roundOffValue,Total):Total,
                    // toatalAmount: Number(Total).toFixed(2),
                    // gstServiceType: gstValue

                }));


            }
        } else {
            setServiceRows(newArray);

        }
    };

    const handleGstTypeChange = (e: any) => {
        const value = e.target.value || 0;

        setGstValue(value);  // Update GST value
        setTaxValue("");     // Reset tax value
        setFormData((prevFormData: any) => {
            const filteredTax =
                prevFormData.tax?.filter((item: any) => item.taxName === "Discount") || [];


            const discount = prevFormData.discount || 0;
            const newAmountAfterDiscount = prevFormData.subtotal - (prevFormData.subtotal * discount) / 100;

            return {
                ...prevFormData,
                gstServiceType: value,
                toatalAmount: newAmountAfterDiscount,
                amountAfterDiscount: newAmountAfterDiscount,
                tax: filteredTax,
            };
        });
    };


    const handleDiscountAndtaxChange = (e: any, name: string) => {

        const value = parseFloat(e.target.value);

        let total = formData.subtotal;

        const calculateDiscount = (discountPercentage: number) => {
            return discountPercentage ? (discountPercentage / 100) * total : 0;
        };

        const calculateTax = (taxPercentage: number, amountAfterDiscount: number) => {
            return taxPercentage ? (taxPercentage / 100) * amountAfterDiscount : 0;
        };


        let discountPercentage = formData.discount;
        let taxPercentage = taxValue;
        let taxValuegst = taxValue / 2

        let cgst = taxValuegst;
        let sgst = taxValuegst;

        if (name === "serviceDiscount") {

            discountPercentage = value;
            setDiscountValue(discountPercentage)
        } else if (name === "serviceTax") {

            taxPercentage = value;
            setTaxValue(taxPercentage)
            cgst = taxPercentage / 2;
            sgst = taxPercentage / 2;
        }


        const discountAmount = calculateDiscount(discountPercentage);
        const amountAfterDiscount = total - discountAmount;

        const taxAmount = calculateTax(taxPercentage, amountAfterDiscount);

        let cgstValue = 0;
        let sgstValue = 0;
        if (name === "serviceTax" || name === "serviceDiscount") {

            cgstValue = taxAmount / 2;
            sgstValue = taxAmount / 2;
        }
        let cgstTaxValue = cgstValue
        let sgstTaxValue = sgstValue

        const finalAmount = amountAfterDiscount + taxAmount;
        let serviceTaxArray: any = []
        const GSTTaxArray = [

            {
                taxName: "Discount",
                discountStatus: "",
                taxPersent: discountPercentage,
                taxAmount: discountAmount.toFixed(2),
            },
            {
                taxName: gstValue,
                discountStatus: "",
                taxPersent: taxPercentage,
                taxAmount: taxAmount.toFixed(2),
            },
            {
                taxName: "CGST",
                discountStatus: "",
                taxPersent: cgst,
                taxAmount: Number(cgstTaxValue).toFixed(2),
            },
            {
                taxName: "SGST",
                discountStatus: "",
                taxPersent: sgst,
                taxAmount: Number(sgstTaxValue).toFixed(2),
            },

        ];

        const IGSTTaxArray = [
            {
                taxName: "IGST",
                discountStatus: "",
                taxPersent: taxPercentage,
                taxAmount: taxAmount.toFixed(2),
            },
            {
                taxName: "Discount",
                discountStatus: "",
                taxPersent: discountPercentage,
                taxAmount: discountAmount.toFixed(2),
            },

        ];

        if (gstValue === "GST") {
            serviceTaxArray = GSTTaxArray
        } else if (gstValue === "IGST") {
            serviceTaxArray = IGSTTaxArray

        }

        setFormData((prevFormData: any) => ({
            ...prevFormData,
            tax: serviceTaxArray,
            // toatalAmount: finalAmount.toFixed(2),
            roundOff: roundOffEnable == true ? CalculateRoundOff(roundOffValue,finalAmount):0,
            toatalAmount: roundOffEnable == true ?  CalculateRoundTotalAmount(roundOffValue,finalAmount):finalAmount,
            discount: discountPercentage,
            // gstServiceType: gstValue
        }));


        setTaxValue(taxPercentage);
    };



    const handleExistingCalulation = (arr: any) => {

        if (arr.length === 0) arr.push({ amount: "", service_name: "", qty: "", rate: "", units: "" });
        const newArray: any = [...arr];
        for (let i = 0; i < newArray.length; i++) {
            if (newArray[i].qty || newArray[i].rate) {
                newArray[i]["amount"] =
                    newArray[i].rate && Number(newArray[i].qty)
                        ? parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty)
                        : !newArray[i].rate
                            ? 0
                            : 0;

                setServiceRows(newArray);

                let subAmount: any = 0;
                for (let i = 0; i < newArray.length; i++) {
                    subAmount += newArray[i].amount;
                }
                subAmount = Number(subAmount);
                subAmount = !isNaN(subAmount) ? subAmount.toFixed(2) : 0;
                let Total = getCalculateTotal(subAmount);
                setFormData({
                    ...formData,
                    product: newArray,
                    subtotal: subAmount,
                    // toatalAmount: Total.toFixed(2),
                    roundOff: roundOffEnable == true ? CalculateRoundOff(roundOffValue,Total):0,
                    toatalAmount: roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue,Total):Total,
                });


            }
        }
        // else {
        setServiceRows(newArray);
        // }
        let finalErrors: any = [];
        // let object: any = {};
        let values = newArray?.map((item: any, index: any) => {
            let object: any = {};
            object.service_name = item.service_name ? true : false;
            object.rate = item.rate ? true : false;
            object.qty = item.qty ? true : false;
            object.units = item.units ? true : false;
            finalErrors.push(object);
            return object

        });
        setServiceErrors(values);
    };


    const removeRow = (index: any, row: any) => {

        const newArray = [...serviceRows];
        const newErrors = [...serviceErrors];
        const existArray = [...newExistProduct];
        existArray.splice(index, 1)
        setNewexistProduct(() => {
            return existArray.length === 0 ? [{ exist: '', originalValue: '' }] : existArray
        });
        newArray.splice(index, 1);
        handleExistingCalulation(newArray);
        setServiceRows(newArray);
        if (newErrors.length > 1)
            newErrors.splice(index, 1);
        setServiceErrors(newErrors);

        if (existArray.length === 0) {
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                tax: [],
                subtotal: 0,
                toatalAmount: 0,
                discount: ""

            }));

        }

    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleConfirm = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (editValue) {

            setServiceRows(formData.product);
            setGstValue(formData.gstServiceType)
        } else {

            setServiceRows([
                { service_name: "", rate: "", qty: "", amount: 0,  serviceUnique_id: "" },
            ]);
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                gstServiceType: gstValue
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editValue]);

    const handleSelectService = (dataArray: any[]) => {
        const containsEmptyRows = serviceRows.some((row: any) =>
            Object.values(row).some((value) => !value)
        );
        const containsEmptyRowsinExistProduct = newExistProduct.some((row: any) =>
            Object.values(row).some((value) => !value)
        );

        if (containsEmptyRowsinExistProduct) {
            const nonEmptyRows = newExistProduct.filter((row: any) =>
                Object.values(row).some(Boolean)
            );
            setNewexistProduct(nonEmptyRows);
        }
        if (containsEmptyRows) {
            const nonEmptyRows = serviceRows.filter((row: any) =>
                Object.values(row).some(Boolean)
            );
            setServiceRows(nonEmptyRows);
        }

        const newRows = dataArray.map((data) => ({
            service_name: data.service_name,
            rate: data.rate,
            taxPercentage: data.taxPercentage,
            qty: 1,
            service_id: data.service_id,
            serviceUnique_id: data._id.$oid,
            amount: 0,
        }));


        setServiceRows((prevRows: any[]) => {
            const updatedRows = [...prevRows, ...newRows];
            handleExistingCalulation(updatedRows);
            return updatedRows;
        });

        setNewexistProduct((prev: any) => [
            ...prev,
            ...dataArray.map((row: any) => ({
                exist: row.service_name,
                originalValue: row.service_name,
            }))
        ]);

        setIsModalOpen(false);
    };

    const addRowValue = () => {
        addRow();
        setNewexistProduct((prev: any) => [
            ...prev,
            { exist: "", originalValue: "" },
        ]);
    };


    return (
        <div className="mb-4">
            <div
                className={
                    "hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 mb-2 text-white"
                }
                style={{ backgroundColor: "#008065" }}
            >
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={" w-full md:w-[17%] lg:w-[17%] xl:w-[10%] 2xl:w-[5%] text-left"}

                >
                    <span className="inline-block whitespace-nowrap">S No</span>
                </div>
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={" w-full sm:w-[192%] md:w-[90%] lg:w-[80%] xl:w-[81%] 2xl:w-[81%] text-center"}
                >
                    <span className="inline-block">Service</span>
                </div>
                {userdata?.hsnNo ? (
                    <>
                        <div
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                            className={" w-full md:w-[55%] lg:w-[15%] 2xl:w-[15%] text-left sm:pr-10 text-right"}
                        >
                            <span className="inline-block">HSN/SAC</span>
                        </div>
                    </>
                ) : (<></>)}
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={" w-full md:w-[14%] xl:w-[14%] 2xl:w-[14%] text-left sm:pr-5 text-right"}
                >
                    Rate
                </div>
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={" w-full md:w-[16%] xl:w-[16%] 2xl:w-[16%] text-left sm:pr-5 text-right"}
                >
                    Qty
                </div>
                <div
                    style={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                    className={"  w-full md:w-[17%] xl:w-[17%] 2xl:w-[17%] text-right sm:pr-10"}
                >
                    Total
                </div>
            </div>

            {serviceRows?.map((servicerow: any, index: any) => (
                <div
                    key={index}
                    className={
                        "flex flex-col sm:flex-row rounded-lg  w-full gap-2 md:gap-0 items-center relative " +
                        (index % 2 !== 0 ? "  " : "")
                    }
                >
                    <div
                        style={{
                            fontFamily: "poppins",
                            textAlign: "left",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={"w-full md:w-[25%] lg:w-[25%]  xl:w-[25%] 2xl:w-[7%]  text-center  pr-3  flex flex-row sm:block "}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center whitespace-nowrap">
                            S No
                        </span>

                        <div className="font-title flex-1 text-right sm:text-center 2xl:text-left 2xl:ml-8 pr-2 sm:pr-0">
                            <div
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                }}

                            >
                                {index + 1}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={" w-full md:w-[135%] xl:w-[141%] 2xl:w-[155%] text-right pr-3 flex flex-row sm:block "}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            Service
                        </span>

                        <div className="font-title flex-1 text-right sm-text-left ">
                            <input
                                autoComplete="nope"
                                value={servicerow.service_name ? servicerow.service_name : ""}
                                readOnly={servicerow.serviceUnique_id ? servicerow.serviceUnique_id : ""}
                                placeholder="Service Name"
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor:
                                    //     !serviceErrors[index]?.service_name && serviceError
                                    //         ? "red"
                                    //         : "#00A787",
                                }}
                                // className={
                                //     defaultInputSmStyle +
                                //     " text-right sm:text-left border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                // }
                                className={`
                                    ${defaultInputSmStyle}  text-right sm:text-left border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full
                                    ${!serviceErrors[index]?.service_name && serviceError ? 'border-[#FF0000]' : 'border-[#949494]'} 
                                    ${(!serviceError || serviceErrors[index]?.service_name) && 'focus:border-[#07ad7b]'}
                                `}
                                onChange={(event) => {
                                    const value = event.target.value.trimStart();
                                    handleInputChange(index, "service_name", value);
                                }}

                            />

                        </div>
                    </div>
                    {userdata?.hsnNo ? (
                        <>
                            <div
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                }}
                                className={" w-full md:w-[50%] xl:w-[33%] 2xl:w-[33%]  text-right  pr-3  flex flex-row sm:block"}
                            >
                                <span className="sm:hidden w-1/2 flex flex-row items-center">
                                    HSN/SAC
                                </span>

                                <div className="font-title flex-1 text-left">
                                    <input
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            // borderColor: "#00A787",
                                        }}
                                        autoComplete="nope"
                                        value={servicerow.hsn}
                                        onChange={(event) => {
                                            const value = event.target.value.replace(/[^0-9]/g, '');
                                            handleInputChange(index, "hsn", value)
                                        }}
                                        placeholder="HSN/SAC"
                                        type=""
                                        className={
                                            defaultInputSmStyle +
                                            " text-right border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  w-full "
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    ) : (<></>)}
                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={" w-full md:w-[40%] lg:w-[35%] 2xl:w-[35%]  text-right  pr-3  flex flex-row sm:block "}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            Rate
                        </span>

                        <div className="font-title flex-1 text-left">
                            <input
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor:
                                    //     !serviceErrors[index]?.rate && serviceError
                                    //         ? "red"
                                    //         : "#00A787",
                                }}
                                autoComplete="nope"
                                value={servicerow.rate}
                                readOnly={servicerow.serviceUnique_id ? servicerow.serviceUnique_id : ""}
                                onChange={(event) => {
                                    let value = event.target.value.replace(/[^0-9.]/g, '');
                                    if (value.startsWith('0')) {
                                        value = value.substring(1);
                                    }
                                    handleInputChange(index, "rate", value);
                                }}
                                placeholder="Rate"
                                type=""
                                // className={
                                //     defaultInputSmStyle +
                                //     " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                                // }
                                className={`
                                    ${defaultInputSmStyle}  text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full 
                                    ${!serviceErrors[index]?.rate && serviceError ? 'border-[#FF0000]' : 'border-[#949494]'} 
                                    ${(!serviceError || serviceErrors[index]?.rate) && 'focus:border-[#07ad7b]'}
                                `}
                            />

                        </div>
                    </div>
                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={"w-full md:w-[25%] xl:w-[25%] 2xl:w-[25%] text-right  pr-3 lg:pr-0 flex flex-row sm:block "}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            Quantity
                        </span>

                        <div className="font-title flex-1 text-left">
                            <input
                                autoComplete="nope"
                                type=""
                                value={servicerow.qty}
                                onChange={(event) => {
                                    let value = event.target.value.replace(/[^0-9]/g, '');
                                    if (value.startsWith('0')) {
                                        value = value.substring(1);
                                    }
                                    handleInputChange(index, "qty", value);
                                }}
                                placeholder="Qty"
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor:
                                    //     !serviceErrors[index]?.qty && serviceError
                                    //         ? "red"
                                    //         : "#00A787",
                                }}
                                // className={
                                //     defaultInputSmStyle +
                                //     " text-right remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full  "
                                // }
                                className={`
                                    ${defaultInputSmStyle}  text-right remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full 
                                    ${!serviceErrors[index]?.qty && serviceError ? 'border-[#FF0000]' : 'border-[#949494]'} 
                                    ${(!serviceError  || serviceErrors[index]?.qty) && 'focus:border-[#07ad7b]'}
                                `}
                            />

                        </div>
                    </div>


                    <div
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                        className={" w-full md:w-[36%] lg:w-[36%] xl:w-[36%] 2xl:w-[36%]  text-right pr-2 sm:pr-12 flex flex-row sm:block whitespace-nowrap"}
                    >
                        <span className="sm:hidden w-1/2 flex flex-row items-center">
                            Total
                        </span>
                        <span className={"inline-block w-1/2 sm:w-full px-1 h-9 overflow-hidden pt-2"}>
                            <NumberFormat value={servicerow?.amount} />
                        </span>
                    </div>

                    <div
                        onClick={() => removeRow(index, servicerow)}
                        className="w-full sm:w-10 sm:absolute sm:right-0 pl-2 h-9 sm:mt-[-17px] pt-2 mb-3 md:mb-0 "
                    >
                        <div className="w-full text-[#9C0000] font-semibold font-title sm:h-8 sm:w-8 cursor-pointer rounded-2xl bg-[#FFE0E0] flex justify-center items-center text-center p-[5px]">
                            <DeleteIcon className="h-4 w-4" style={IconStyle} />
                            <span className="block sm:hidden">Delete Product</span>
                        </div>
                    </div>
                </div>
            ))}
            <div className="flex flex-col sm:flex-row justify-end items-center w-full gap-4 py-2 sm:pr-12">
                <div
                    style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }}
                    className="w-full sm:w-auto flex justify-end">
                    <button
                        type="button"
                        className="w-full sm:w-60 md:w-50 lg:w-50 xl:w-60 2xl:w-96 rounded-2xl font-bold text-white flex items-center justify-center text-base h-9 whitespace-nowrap"
                        style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700, backgroundColor: "#008065" }}
                        onClick={addRowValue}
                    >
                        <InvoiceIcon style={IconStyle} className="h-5 w-5 mr-2" />
                        Add New Service
                    </button>
                </div>

                <div
                    style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }}
                    className="w-full sm:w-auto flex justify-end">
                    {/* {productlist.length ? > 0 ?} */}
                    <button
                        type="button"
                        style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700, backgroundColor: "#008065" }}
                        className="w-full sm:w-60 md:w-50 lg:w-50 xl:w-60 2xl:w-96 rounded-2xl font-bold text-white flex items-center justify-center text-base h-9 whitespace-nowrap"
                        onClick={handleConfirm}
                    >
                        <InvoiceIcon style={IconStyle} className="h-5 w-5 mr-2" />
                        Add Existing Service
                    </button>
                </div>
            </div>
            <div className={"flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full  px-0 sm:px-4 py-1 items-center "}>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"}>
                    SUBTOTAL
                </div>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"}>
                    <NumberFormat value={formData.subtotal ? formData.subtotal : 0} />
                </div>
            </div>
            <div className={"flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-0 sm:px-4 py-1 items-center"}>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }}
                    className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 pr-1 flex flex-row sm:block mb-1 sm:mb-0 flex flex-row whitespace-nowrap"}>
                    DISCOUNT %
                </div>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"}>
                    <input
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            // borderColor: "#00A787",
                        }}
                        type="number"
                        autoComplete="nope"
                        name="serviceDiscount"
                        value={
                            formData.tax.find((item: any) => item.taxName === "Discount")
                                ?.taxPersent
                        }
                        min={1}
                        max={100}
                        disabled={paramsId ? true : false}
                        onChange={(e) => {
                            const value = Number(e.target.value);
                            if (value <= 100) {
                                handleDiscountAndtaxChange(e, "serviceDiscount");
                            }
                        }}
                        placeholder="Discount"
                        className={
                            defaultInputSmStyle +
                            "border-2 border-[#949494] focus:border-[#07ad7b] text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                        }
                    />
                </div>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"}>
                    <NumberFormat
                        value={formData.tax.find((item: any) => item.taxName === 'Discount')?.taxAmount || 0}

                    />
                </div>
            </div>
            <div className={"flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-0 sm:px-4 py-1 items-center"}>

                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 pr-2 flex flex-row sm:block mb-1 sm:mb-0 flex flex-row"}>
                    <select
                        onChange={(e) => handleGstTypeChange(e)}
                        disabled={paramsId ? true : false}
                        name="taxPercentage"
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            // borderColor: "#00A787",
                        }}
                        value={gstValue}
                        className={
                            defaultInputSmStyle +
                            "border-2 border-[#949494] focus:border-[#07ad7b] text-left remove-spinner border-2 focus:outline-none rounded-[7px]  h-9 w-full  "
                        }>
                        <option value="GST">GST</option>
                        <option value="IGST">IGST</option>
                    </select>

                </div>
                {
                    formData.gstServiceType === 'GST' ?
                        (
                            <>
                                <div
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 700,
                                    }}
                                    className={
                                        "font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0 flex flex-row"
                                    }
                                >
                                    <select
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            // borderColor: "#00A787",
                                        }}
                                        name="serviceTax"
                                        value={
                                            formData.tax.find((item: any) => item.taxName === "GST")
                                                ?.taxPersent || 0
                                        }
                                        disabled={paramsId ? true : false}
                                        onChange={(e) => handleDiscountAndtaxChange(e, "serviceTax")}
                                        className={
                                            defaultInputSmStyle +
                                            "border-2 border-[#949494] focus:border-[#07ad7b] text-left hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                        }
                                    >
                                        <option value={0}>GST 0%</option>
                                        <option value={0.25}>GST 0.25%</option>
                                        <option value={3}>GST 3%</option>
                                        <option value={5}>GST 5%</option>
                                        <option value={12}>GST 12%</option>
                                        <option value={18}>GST 18%</option>
                                        <option value={28}>GST 28%</option>
                                    </select>
                                </div>
                            </>) :
                        (<>
                            <div
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                }}
                                className={
                                    "font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0 flex flex-row"
                                }
                            >
                                <select
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        // borderColor: "#00A787",
                                    }}
                                    name="serviceTax"
                                    value={
                                        formData.tax.find((item: any) => item.taxName === "IGST")
                                            ?.taxPersent || 0
                                    }
                                    disabled={paramsId ? true : false}
                                    onChange={(e) => handleDiscountAndtaxChange(e, "serviceTax")}
                                    className={
                                        defaultInputSmStyle +
                                        "border-2 border-[#949494] focus:border-[#07ad7b] text-left hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                    }
                                >
                                    <option value={0}>IGST 0%</option>
                                    <option value={0.25}>IGST 0.25%</option>
                                    <option value={3}>IGST 3%</option>
                                    <option value={5}>IGST 5%</option>
                                    <option value={12}>IGST 12%</option>
                                    <option value={12}>IGST 18%</option>
                                    <option value={28}>IGST 28%</option>
                                </select>
                            </div>
                        </>)
                }

                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"}>
                    {formData.gstServiceType === 'GST' ? (<><NumberFormat
                        value={formData.tax.find((item: any) => item.taxName === 'GST')?.taxAmount || 0}

                    /></>) : (<><NumberFormat
                        value={formData.tax.find((item: any) => item.taxName === 'IGST')?.taxAmount || 0}

                    /></>)}
                </div>
            </div>
            {roundOffEnable == true &&
                (<>
                
                    <div className={"flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-0 sm:px-4 py-1 items-center"}>
                         <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"}>
                             ROUND OFF
                        </div>
                        <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"}>
                            <NumberFormat value={formData.roundOff} />
                        </div>
                    </div>
                    
                </>)
            }
            <div className={"flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-0 sm:px-4 py-1 items-center"}>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"}>
                    TOTAL
                </div>
                <div style={{ fontFamily: "poppins", fontSize: "14px", fontWeight: 700 }} className={"font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"}>
                    <NumberFormat value={formData.toatalAmount ? formData.toatalAmount : 0} />
                </div>
            </div>

            {isModalOpen && (

                <ServiceModel
                    closeModal={closeModal}
                    setServiceRows={setServiceRows}
                    serviceRows={serviceRows}
                    handleSelectService={handleSelectService}
                />
            )}
            {showConfirmation && (
                <StockUpdateModel
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    )
}

export default QuotationServiceDetails
