/* eslint-disable eqeqeq */
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ConfirmationPopup from "../pages/commen/ConfirmationPopup";
import {
    fixedAssetAll,
    fixedAssetDelete,
    getPurchaseFAandFixedAsset,
} from "../redux/actions/fixedAssetsAction";
import { purchaseFixedAssetDelete } from "../redux/actions/purchaseFixedAssetAction";
import { salesFixedAssetDelete } from "../redux/actions/salesFixedAssetAction";
import FAdeletePopUp from "../pages/commen/FAdeletePopUp";

const FixedAssetsReport = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [alertPopup, setAlertPopup] = useState<boolean>(false);
    const [deleteid, setDeleteId] = useState([] as any);
    const [purchaseFAdeleteid, purchaseFASetDeleteId] = useState([] as any);
    const [salesFAdeleteid, setSalesFADeleteId] = useState([] as any);
    const [fixedAssetDetails, setFixedAssetDetails] = useState([] as any);
    const [currentValue, setCurrentValue] = useState(null as any);
    const [qtyValue, setQtyValue] = useState(null as any);
    const [hasPurchaseFA, setHasPurchaseFA] = useState(false);
    const [hasSalesFA, setHasSalesFA] = useState(false);
    const [assetList, setAssetList] = useState([] as any);
    const fetchData = () => {
        dispatch(fixedAssetAll() as any).then((response: any) => {
            const data = Array.isArray(response?.payload) ? response.payload : [];
            setFixedAssetDetails(data);
        });
    };
    const [deleteInfo, setDeleteInfo] = useState<{
        transactionType: string;
        deleteId: string | null;
    }>({
        transactionType: "",
        deleteId: null,
    });

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // *******************sort*********************
    useEffect(() => {
        const purchaseFA = assetList.some(
            (item: any) => item.transactionType === "Purchase FA"
        );
        setHasPurchaseFA(purchaseFA);
        const SalesFA = assetList.some(
            (item: any) => item.transactionType === "Sales FA"
        );
        setHasSalesFA(SalesFA);
    }, [assetList]);
    const [deleteValue_id, setDeleteValue_id] = useState(null as any);

    const handleChange = useCallback(
        (e: any) => {
            const assetId = e;
            setDeleteValue_id(assetId);
            setSelectedAsset(assetId);
            dispatch(getPurchaseFAandFixedAsset(assetId) as any).then((res: any) => {
                if (res) {
                    let asset: any[] = [];
                    asset = [...asset, ...res.payload];

                    const openingAssets = asset.find(
                        (item) => item.transactionType === "Opening Assets"
                    );
                    setCurrentValue(openingAssets?.NonTaxableAmount || "");
                    setQtyValue(openingAssets?.qty ? openingAssets?.qty : 0);
                    setAssetList(res.payload);
                }
            });
        },
        [dispatch]
    );

    const handleCreateInvoice = () => {
        navigate("/app/fixedassets");
    };

    const handleEdit = (params: any) => {
        if (params.transactionType === "Purchase FA") {
            navigate(`/app/purchaseFixedAssetentry/${params._id.$oid}`);
        } else if (params.transactionType === "Opening Assets") {
            navigate(`/app/fixedassets/${params._id.$oid}`);
        } else if (params.transactionType === "Sales FA") {
            navigate(`/app/salesFixedAssetPage/${params._id.$oid}`);
        }
    };

    const rows = assetList?.map((item: any, index: any) => ({
        id: uuidv4(),
        sl_No: index + 1,
        ...item,
    }));
    const handleDelete = (params: any) => {
        if (
            params.transactionType === "Opening Assets" &&
            !hasPurchaseFA &&
            !hasSalesFA
        ) {
            setShowConfirmation(true);
            setDeleteId(params?._id.$oid);
            setDeleteInfo({
                transactionType: params.transactionType,
                deleteId: params._id.$oid,
            });
        } else {
            // setQtyValue(0)
            setAlertPopup(true);
        }
    };
    const [selectedAsset, setSelectedAsset] = useState<string>("");
    const handleConfirm = () => {
        if (deleteInfo.transactionType === "Opening Assets") {
            dispatch(fixedAssetDelete(deleteid) as any).then((res: any) => {
                if (res) {
                    fetchData();
                    setDeleteId(null);
                    setShowConfirmation(false);
                    setCurrentValue("");
                    setQtyValue(0);
                    setSelectedAsset("");
                }
            });
            dispatch(getPurchaseFAandFixedAsset(deleteValue_id) as any).then(
                (res: any) => {
                    if (res) {
                        setAssetList(res.payload);
                    }
                }
            );
        } else if (deleteInfo.transactionType === "Purchase FA") {
            dispatch(purchaseFixedAssetDelete(purchaseFAdeleteid) as any).then(
                (res: any) => {
                    if (res) {
                        purchaseFASetDeleteId(null);
                        setShowConfirmation(false);
                        dispatch(getPurchaseFAandFixedAsset(deleteValue_id) as any).then(
                            (res: any) => {
                                if (res) {
                                    setAssetList(res.payload);
                                    let asset: any[] = [];
                                    asset = [...asset, ...res.payload];
                                    const openingAssets = asset.find(
                                        (item) => item.transactionType === "Opening Assets"
                                    );
                                    setCurrentValue(openingAssets?.NonTaxableAmount || "");
                                }
                            }
                        );
                    }
                }
            );
        } else if (deleteInfo.transactionType === "Sales FA") {
            dispatch(salesFixedAssetDelete(salesFAdeleteid) as any).then(
                (res: any) => {
                    if (res) {
                        setSalesFADeleteId(null);
                        setShowConfirmation(false);
                    }
                }
            );
            dispatch(getPurchaseFAandFixedAsset(deleteValue_id) as any).then(
                (res: any) => {
                    if (res) {
                        setAssetList(res.payload);
                        setCurrentValue(res.payload?.NonTaxableAmount || "");
                    }
                }
            );
        }
    };
    const handleCancel = () => {
        setShowConfirmation(false);
        setAlertPopup(false);
    };

    const columns: GridColDef[] = [
        {
            field: "date",
            headerName: "Date",
            width: 200,
            editable: false,
            renderCell: (params: any) => (
                <>
                    {params.row.date ||
                        params.row.salesFADate ||
                        params.row.purchaseFADate}
                </>
            ),
        },
        {
            field: "transactionType",
            headerName: "Transaction Type",
            width: 200,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.transactionType ? params.row.transactionType : "N/A"}</>
            ),
        },
        /*  {
                 field: "party",
                 headerName: "Party",
                 width: 200,
                 editable: false,
                 renderCell: (params: any) => (
                     <>{params.row.toatalAmount ? params.row.toatalAmount : "N/A"}</>
                 ),
             },
      */
        {
            field: "party",
            headerName: "Party",
            width: 200,
            editable: false,
            renderCell: (params: any) => {
                if (params.row.transactionType === "Purchase FA") {
                    return <>{params.row.vendorName}</>;
                } else if (params.row.transactionType === "Sales FA") {
                    return <>{params.row.clientName}</>;
                } else {
                    return <>N/A</>;
                }
            },
        },

        {
            field: "paymentType",
            headerName: "Payment Type",
            width: 200,
            editable: false,
            renderCell: (params: any) => (
                <>{params.row.paymentType ? params.row.paymentType : "N/A"}</>
            ),
        },
        {
            field: "amount",
            headerName: "Amount",
            width: 200,
            editable: false,
            renderCell: (params: any) => (
                <>
                    {params.row.transactionType === "Opening Assets"
                        ? params.row.amount
                        : params.row.toatal_Amount || "N/A"}
                </>
            ),
        },

        {
            field: "action",
            headerName: "Actions",
            editable: false,
            width: 200,
            renderCell: (params: any) => (
                <>
                    <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#008065"
                            className="w-5 h-5 cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                            />
                        </svg>
                    </IconButton>
                    {params.row.transactionType === "Opening Assets" && (
                        <IconButton
                            aria-label="delete"
                            onClick={() => handleDelete(params.row)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#008065"
                                className="w-5 h-5  cursor-pointer"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                            </svg>
                        </IconButton>
                    )}
                </>
            ),
        },
    ];

    return (
        <div>
            <div>
                <div className="flex justify-between flex-col md:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                    <div className="p-4  font-bold text-[#008065]">
                        Fixed Assets - Report
                    </div>
                    <div className="p-4  font-bold text-[#008065]">
                        <button
                            type="button"
                            className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
                            style={{ backgroundColor: "#008065" }}
                            onClick={handleCreateInvoice}
                        >
                            <FiPlusCircle className="h-5 w-5" />
                            <span
                                className={`flex-1 ml-3 text-left whitespace-nowrap  text-sm font-bold`}
                            >
                                Add Fixed Assets
                            </span>
                        </button>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row xl:flex-row gap-2 pl-4 px-4 mb-4">
                    <div className="flex flex-col mb-2 w-full md:w-1/4  lg:w-1/3 xl:w-1/4">
                        <label
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                                color: "#008065",
                            }}
                            className="text-[#008065] "
                        >
                            <span> Fixed Assets</span>
                        </label>
                        <select
                            className="border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 lg:w-full"
                            style={{
                                fontFamily: "poppins",
                                fontSize: "13px",
                                fontWeight: 400,
                                // borderColor: "#008065",
                            }}
                            value={selectedAsset}
                            onChange={(e) => handleChange(e.target.value)}
                        >
                            <option value="" disabled selected>
                                Select Assets
                            </option>
                            {fixedAssetDetails?.map((item: any, i: any) => (
                                <option className="w-full" key={i} value={item._id.$oid}>
                                    {item.asset_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex flex-col mb-2 w-full md:w-1/4  lg:w-1/3 xl:w-1/4">
                        <label
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 600,
                            }}
                            className="text-[#008065] "
                        >
                            <span>Current Value</span>
                        </label>
                        <div className="relative">
                            <input
                                type="text"
                                className={
                                    "border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                }
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065",
                                }}
                                placeholder="Current Value..."
                                value={currentValue ? currentValue : "0.00"}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="flex flex-col mb-2 w-full md:w-1/4  lg:w-1/3 xl:w-1/4">
                        <label
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 600,
                            }}
                            className="text-[#008065] "
                        >
                            <span>Qty</span>
                        </label>
                        <div className="relative">
                            <input
                                type="text"
                                className={
                                    "border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                }
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065",
                                }}
                                placeholder="Quantity"
                                value={qtyValue ? qtyValue : "0"}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 ml-3 mx-4">

                    <Box
                        sx={{
                            height: "80vh",
                            width: "100%",
                            overflowX: "auto",
                            overflowY: "auto",
                        }}
                    >
                        <DataGrid
                            // autoHeight
                            sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: 500,
                                ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                                {
                                    backgroundColor: "#fff",
                                    color: "#008060 !important",
                                    fontWeight: 600,
                                    fontSize: "14px",
                                },
                                "& .MuiDataGrid-checkboxInput": {
                                    color: "#008060 !important",
                                },
                                ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                                    fontWeight: 600,
                                    fontSize: "14px",
                                },
                                ".MuiDataGrid-cell.MuiDataGrid-cell--textRight": {
                                    fontWeight: 600,
                                    fontSize: "14px",
                                },
                                "@media (min-width: 2555px)": {
                                    ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor, .MuiDataGrid-cell.MuiDataGrid-cell--textLeft":
                                    {
                                        minWidth: "265px !important",
                                    },
                                },
                            }}
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 25, 50]}
                            // checkboxSelection
                            disableRowSelectionOnClick
                        />
                    </Box>
                </div>
                {showConfirmation && (
                    <ConfirmationPopup
                        message="Are you sure you want to proceed?"
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                    />
                )}
                {alertPopup && (
                    <FAdeletePopUp
                        message="Cannot delete Opening Assets when Purchase FA Assets exist or Sales FA Assets exist."
                        onCancel={handleCancel}
                    />
                )}
            </div>
        </div>
    );
};

export default FixedAssetsReport;
