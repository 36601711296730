import { styled } from "@mui/system";
import React, {
	useLayoutEffect,
	useRef,
	useState,
	useEffect,
	useCallback,
} from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { profitandlossGet } from "../../../redux/actions/profitAndLossAction";
import { currencyGet } from "../../../redux/actions/currencyAction";

const Profit = () => {
	//  date
	//Date picker
	//  select Date

	const [selectedPeriod, setSelectedPeriod] = useState<string>("All");
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());

	// Function to format date as dd/MM/yyyy
	// const formatDate = (date: Date | null): string =>
	// 	date ? date.toLocaleDateString("en-GB") : "";

	const handlePeriodChange = (period: string) => {
		setSelectedPeriod(period);

		const today = new Date();
		const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
		const lastDayOfMonth = new Date(
			today.getFullYear(),
			today.getMonth() + 1,
			0
		);

		if (period === "All") {
			setStartDate(today);
			setEndDate(today);
		} else if (period === "This Month") {
			setStartDate(firstDayOfMonth);
			setEndDate(lastDayOfMonth);
		} else if (period === "Last Month") {
			const firstDayLastMonth = new Date(
				today.getFullYear(),
				today.getMonth() - 1,
				1
			);
			const lastDayLastMonth = new Date(
				today.getFullYear(),
				today.getMonth(),
				0
			);
			setStartDate(firstDayLastMonth);
			setEndDate(lastDayLastMonth);
		} else if (period === "This Quarter") {
			const currentQuarter = Math.floor(today.getMonth() / 3);
			const firstDayQuarter = new Date(
				today.getFullYear(),
				currentQuarter * 3,
				1
			);
			const lastDayQuarter = new Date(
				today.getFullYear(),
				(currentQuarter + 1) * 3,
				0
			);
			setStartDate(firstDayQuarter);
			setEndDate(lastDayQuarter);
		} else if (period === "This Year") {
			const firstDayYear = new Date(today.getFullYear(), 0, 1);
			const lastDayYear = new Date(today.getFullYear(), 11, 31);
			setStartDate(firstDayYear);
			setEndDate(lastDayYear);
		} else if (period === "Custom") {
			setStartDate(null);
			setEndDate(null);
		}
	};

	useEffect(() => {
		if (selectedPeriod === "All") {
			const today = new Date();
			setStartDate(today);
			setEndDate(today);
		}
	}, [selectedPeriod]);

	const [openRows, setOpenRows] = useState<string[]>([]);
	const scrollRef = useRef<HTMLDivElement>(null);
	const scrollPositionRef = useRef<number>(0); // Store scroll position

	const toggleRow = (rowName: string, event: any) => {
		// Save current scroll position
		if (scrollRef.current) {
			scrollPositionRef.current = scrollRef.current.scrollTop;
		}
		// Update state for openRows
		if (openRows.includes(rowName)) {
			setOpenRows(openRows.filter((name) => name !== rowName));
		} else {
			setOpenRows([...openRows, rowName]);
		}
		// Prevent default scroll behavior
		event.preventDefault();
		event.stopPropagation();
	};

	// Restore scroll position after state changes
	useLayoutEffect(() => {
		if (scrollRef.current) {
			scrollRef.current.scrollTop = scrollPositionRef.current;
		}
	}, [openRows]);

	const StyledDiv = styled("div")({
		position: "relative",
		marginRight: "0.5rem",
		marginTop: "",
		overflowY: "auto",
		overflowX: "auto",
		height: "calc(100vh - 160px)",
		"@media (min-width: 640px)": {
	  height: "calc(100vh - 120px)",
	},
		"&::-webkit-scrollbar": {
			width: "5px",
			height: "5px",
		},
		"&::-webkit-scrollbar-track": {
			backgroundColor: "text-emerald-200",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "#008065",
			borderRadius: "4px",
		},
	});
	const dispatch = useDispatch();
	const [currencySymbol, setcurrencySymbol] = useState("₹");
	const usersignin = useSelector((state: any) => state.userSignin);
	const token = usersignin?.userInfo?.token;
	const [profitAndLoss, setProfitAndLoss] = useState<any>(null);
	const [profitData, setProfitData] = useState({
		closingStock: 0,
		salesReturnClosing: 0,
		totalPurchase: 0,
		totalPurchaseReturn: 0,
		totalPurchaseRevenue: 0,
		totalSalesTot: 0,
		totalSalesReturn: 0,
		totalRevenue: 0,
		directExpenses: 0,
		indirectExpenses: 0,
		excategory_totals: [],
		total_profit_amount: 0,
	});
	const fetchData = useCallback(() => {
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
		dispatch(profitandlossGet(selectedPeriod, startDate, endDate) as any)
			.then((res: any) => {
				if (res) {
					setProfitAndLoss(res.payload);
					const data = res.payload;
					setProfitData({
						closingStock: data.closingStock || 0,
						salesReturnClosing: data.salesReturnClosing || 0,
						totalPurchase: data.totalPurchase || 0,
						totalPurchaseReturn: data.totalPurchaseReturn || 0,
						totalPurchaseRevenue: data.totalPurchaseRevenue || 0,
						totalSalesTot: data.totalSalesTot || 0,
						totalSalesReturn: data.totalSalesReturn || 0,
						totalRevenue: data.totalRevenue || 0,
						directExpenses: data.directExpenses || 0,
						indirectExpenses: data.indirectExpenses || 0,
						excategory_totals: data.category_totals || 0,
						total_profit_amount: data.total_profit_amount || 0,
					});
				} else {
					console.error("No data returned from API");
				}
			})
			.catch((error: any) => {
				console.error("Error fetching data:", error);
			});
	}, [dispatch, token?._id, selectedPeriod, startDate, endDate]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	let purchaseAccounts = profitData.totalPurchaseRevenue;
	let totalExpences = profitData.directExpenses + profitData.indirectExpenses;
	let costofGoodsSold = purchaseAccounts;
	let expences = costofGoodsSold + totalExpences;
	let otherIncomeIndirect = profitData.total_profit_amount;
	// let saleAccounts = profitData.totalRevenue + otherIncomeIndirect;
	let saleAccounts = profitData.totalRevenue;
	let netIncome = saleAccounts - expences;

	const directArray: { category: string; amount: number }[] = [];
	const indirectArray: { category: string; amount: number }[] = [];
	let totalDirectAmount = 0;
	let totalIndirectAmount = 0;

	profitData.excategory_totals.forEach((item: any) => {
		if (item.main_category === "Direct") {
			directArray.push({
				category: item.expence_category,
				amount: item.total,
			});
			totalDirectAmount += item.total;
		} else {
			indirectArray.push({
				category: item.expence_category,
				amount: item.total,
			});
			totalIndirectAmount += item.total;
		}
	});

	return (
		<div className="sm:ml-2 rounded-lg font-semibold text-[#008065] font-poppins ">
			<div className="flex flex-col sm:flex-row  mt-2 text-[#008065] font-bold mb-2 ">
					<div className="flex  items-center justify-center sm:justify-start sm:w-3/12 md:w-4/12 w-full">
						<span className="font-bold text-[#045545] text-xl mt-1 px-2  ">
						{" "}
						Profit and Loss{" "}
					</span>
				</div>

				<div className=" text-[#045545] mt-2 sm:mt-0 sm:w-9/12 md:w-8/12 w-full ">
					{/* Period Dropdown */}
						<div className="flex flex-col sm:flex-row justify-center sm:justify-start lg:justify-center xl:pl-16 gap-2">
						<div className="flex items-center justify-center xl:pl-16">
								<div>
								<span className="text-[16px] mr-3">Period: </span>
								</div>
							<select
									className="border-2 !border-[#008065] rounded-md p-1.5 text-gray-700 bg-[#F1F7F6] text-[14px] focus:outline-none"
								value={selectedPeriod}
								onChange={(e) => handlePeriodChange(e.target.value)}
							>
								<option value="All">All</option>
								<option value="This Month">This Month</option>
								<option value="Last Month">Last Month</option>
								<option value="This Quarter">This Quarter</option>
								<option value="This Year">This Year</option>
								<option value="Custom">Custom</option>
							</select>
						</div>

							<div className="flex items-center  sm:w-[210px]   sm:pl-4 justify-center sm:justify-start ">
							{selectedPeriod === "Custom" ? (
								<>
									<DatePicker
										selected={startDate}
										onChange={(date) => {
											setStartDate(date);
											setSelectedPeriod("Custom");
										}}
										selectsStart
										startDate={startDate}
										endDate={endDate}
										placeholderText="Start Date"
											className=" text-gray-700 w-[105px] p-1 rounded-md bg-[#F1F7F6]  text-center focus:outline-none border-2 border-[#008065]"
										dateFormat="dd/MM/yyyy"
									/>

									<span className="mx-2">to</span>
									<DatePicker
										selected={endDate}
										onChange={(date) => {
											setEndDate(date);
											setSelectedPeriod("Custom");
										}}
										selectsEnd
										startDate={startDate}
										endDate={endDate}
										placeholderText="End Date"
											className=" text-gray-700 w-[105px] p-1 rounded-md bg-[#F1F7F6] text-center focus:outline-none border-2 border-[#008065]"
										dateFormat="dd/MM/yyyy"
									/>
								</>
							) : (
								<span>
									{/* <span className="p-1 px-4 bg-white rounded-md"> */}
									{/* {startDate && endDate
											? `${formatDate(startDate)} To ${formatDate(endDate)}`
											: "Select a period"} */}
								</span>
							)}
						</div>
					</div>
				</div>
			</div>

			{/* table starts */}
			<StyledDiv ref={scrollRef} className="mt-2">
				<div className="rounded-md">
					<div className="flex flex-col h-full">
						<table className="w-full border-separate border-spacing-x-0 border-spacing h-full">
							{/* parti and amt section */}
							<thead className="sticky top-0 w-full bg-gray-300 z-1000 ">
								<th className="text-left whitespace-nowrap text-[#008065] w-full px-2 py-3 rounded-l-md font-semibold text-[#008065]">
									Particular
								</th>
								<th className="text-right whitespace-nowrap text-[#008065] w-full px-2 py-3 rounded-r-md font-semibold text-[#008065]">
									Amount
								</th>
							</thead>
							<tr>
								<td></td>
							</tr>
							<tbody className="">
								{/* Income Row */}
								<tr
									className="group hover:bg-gray-300 cursor-pointer"
									onClick={(e) => toggleRow("income", e)}
								>
									<td colSpan={100} className="rounded-md ">
										<div className="custom-content flex flex-row items-center p-2">
											{openRows.includes("income") ? (
												<FaChevronDown className="mr-2" />
											) : (
												<FaChevronRight className="mr-2" />
											)}
											<span className="whitespace-nowrap font-semibold text-[#008065]">
												Incomes
											</span>
										</div>
									</td>
								</tr>

								{/* Income Dropdown Rows */}
								{openRows.includes("income") && (
									<>
										<tr
											className="cursor-pointer items-center "
											onClick={(e) => toggleRow("Sale", e)}
										>
											<td className="text-left pl-8 flex items-center">
												<div>
													{openRows.includes("Sale") ? (
														<FaChevronDown className="mr-2" />
													) : (
														<FaChevronRight className="mr-2" />
													)}
												</div>

												<span className="whitespace-nowrap py-2 py-2">
													Sale Accounts
												</span>
											</td>
											<td className="text-right  whitespace-nowrap px-2 items-center py-2">
												{`${currencySymbol}${saleAccounts.toFixed(2)}`}
											</td>
										</tr>
										{openRows.includes("Sale") && (
											<>
												<tr>
													<td className="text-left  pl-16">
														<ul className="list-disc pl-5">
															<li className="py-2  whitespace-nowrap text-neutral-700">
																Sale (Revenue)Account
															</li>
														</ul>
													</td>
													<td className="text-right  whitespace-nowrap text-neutral-700 px-2">
														{`${currencySymbol}${profitData.totalRevenue.toFixed(
															2
														)}`}
													</td>
												</tr>

												<tr>
													<td className="text-left  pl-16">
														<ul className="list-disc pl-5">
															<li className="py-2  text-neutral-700 whitespace-nowrap">
																Additional Charges on Sale
															</li>
														</ul>
													</td>
													<td className="text-right  whitespace-nowrap text-neutral-700 px-2">
														₹0.00
													</td>
												</tr>
											</>
										)}

										<tr
											className="cursor-pointer"
											onClick={(e) => toggleRow("Direct", e)}
										>
											<td className="text-left  pl-8 flex items-center">
												<div>
													{openRows.includes("Direct") ? (
														<FaChevronDown className="mr-2" />
													) : (
														<FaChevronRight className="mr-2" />
													)}
												</div>
												<span className="whitespace-nowrap py-2">
													Other Incomes (Direct)
												</span>
											</td>
											<td className="text-right  whitespace-nowrap px-2 items-center">
												₹0.00
											</td>
										</tr>
										{/* {openRows.includes("Direct") && (
											<>
												<tr>
													<td className="text-left  pl-16">
														<ul className="list-disc pl-5">
															<li className="py-2  text-neutral-700 whitespace-nowrap">
																Payment-out Discount
															</li>
														</ul>
													</td>
													<td className="text-right  whitespace-nowrap text-neutral-700 px-2">
														₹1200
													</td>
												</tr>
											</>
										)} */}

										<tr
											className="cursor-pointer"
											onClick={(e) => toggleRow("Indirect", e)}
										>
											<td className="text-left  pl-8 flex items-center">
												<div>
													{openRows.includes("Indirect") ? (
														<FaChevronDown className="mr-2" />
													) : (
														<FaChevronRight className="mr-2" />
													)}
												</div>
												<span className="whitespace-nowrap py-2">
													Other Incomes (Indirect)
												</span>
											</td>
											<td className="text-right  whitespace-nowrap px-2 items-center">
												{`${currencySymbol}${otherIncomeIndirect.toFixed(2)}`}
											</td>
										</tr>
										{openRows.includes("Indirect") && (
											<>
												<tr>
													<td className="text-left  pl-16">
														<ul className="list-disc pl-5">
															<li className="py-2  text-neutral-700 whitespace-nowrap">
																Profit on Sale of Assets
															</li>
														</ul>
													</td>
													<td className="text-right  whitespace-nowrap text-neutral-700 px-2">
														{`${currencySymbol}${profitData.total_profit_amount.toFixed(
															2
														)}`}
													</td>
												</tr>
												<tr>
													<td className="text-left  pl-16">
														<ul className="list-disc pl-5">
															<li className="py-2  text-neutral-700 whitespace-nowrap">
																Appreciation on Assets
															</li>
														</ul>
													</td>
													<td className="text-right  whitespace-nowrap text-neutral-700 px-2">
														₹0.00
													</td>
												</tr>
											</>
										)}
									</>
								)}

								<tr
									className="cursor-pointer group hover:bg-gray-300"
									onClick={(e) => toggleRow("expense", e)}
								>
									<td colSpan={100} className="rounded-md">
										<div className="custom-content flex flex-row items-center p-2">
											{openRows.includes("expense") ? (
												<FaChevronDown className="mr-2" />
											) : (
												<FaChevronRight className="mr-2" />
											)}
											<span className="whitespace-nowrap font-semibold text-[#008065]">
												Expenses
											</span>
										</div>
									</td>
								</tr>

								{openRows.includes("expense") && (
									<>
										<tr
											className="cursor-pointer"
											onClick={(e) => toggleRow("Expense_Dropdown", e)}
										>
											<td className="text-left  pl-8 flex items-center">
												<div>
													{openRows.includes("Expense_Dropdown") ? (
														<FaChevronDown className="mr-2" />
													) : (
														<FaChevronRight className="mr-2" />
													)}
												</div>
												<span className="whitespace-nowrap py-2 ">
													Cost of Goods sold
												</span>
											</td>
											<td className="text-right  whitespace-nowrap px-2 items-center">
												₹0.00
											</td>
										</tr>
										{openRows.includes("Expense_Dropdown") && (
											<>
												<tr
													className="cursor-pointer"
													onClick={(e) => toggleRow("purchase_account", e)}
												>
													<td className="text-left  pl-16 flex items-center">
														<div>
															{openRows.includes("purchase_account") ? (
																<FaChevronDown className="mr-2" />
															) : (
																<FaChevronRight className="mr-2" />
															)}
														</div>
														<span className="whitespace-nowrap py-2">
															Purchase Account
														</span>
													</td>
													<td className="text-right  whitespace-nowrap px-2 items-center">
														{`${currencySymbol}${purchaseAccounts.toFixed(2)}`}
													</td>
												</tr>
												{openRows.includes("purchase_account") && (
													<>
														<tr>
															<td className="text-left  pl-24">
																<ul className="list-disc pl-6">
																	<li className="py-2  text-neutral-700 whitespace-nowrap">
																		Purchases
																	</li>
																</ul>
															</td>
															<td className="text-right  whitespace-nowrap text-neutral-700 px-2">
																{`${currencySymbol}${profitData.totalPurchaseRevenue.toFixed(
																	2
																)}`}
															</td>
														</tr>
														<tr>
															<td className="text-left  pl-24">
																<ul className="list-disc pl-6">
																	<li className="py-2  text-neutral-700 whitespace-nowrap">
																		Additional Charges on Purchase
																	</li>
																</ul>
															</td>
															<td className="text-right  whitespace-nowrap text-neutral-700 px-2">
																₹0.00
															</td>
														</tr>
													</>
												)}

												<tr>
													<td className="text-left  pl-16">
														<ul className="list-disc pl-5">
															<li className="py-2  text-neutral-700 whitespace-nowrap">
																Opening Stock
															</li>
														</ul>
													</td>
													<td className="text-right  whitespace-nowrap text-neutral-700 px-2">
														₹0.00
													</td>
												</tr>
												<tr>
													<td className="text-left  pl-16">
														<ul className="list-disc pl-5">
															<li className="py-2  text-neutral-700 whitespace-nowrap">
																Closing Stock
															</li>
														</ul>
													</td>
													<td className="text-right  whitespace-nowrap text-neutral-700 px-2">
														{`${currencySymbol}${profitData.closingStock.toFixed(
															2
														)}`}
													</td>
												</tr>
											</>
										)}

										<tr
											className="cursor-pointer"
											onClick={(e) => toggleRow("Direct_expenses", e)}
										>
											<td className="text-left  pl-8 flex items-center">
												<div>
													{openRows.includes("Direct_expenses") ? (
														<FaChevronDown className="mr-2" />
													) : (
														<FaChevronRight className="mr-2" />
													)}
												</div>
												<span className="whitespace-nowrap py-2">
													Direct expenses
												</span>
											</td>
											<td className="text-right  whitespace-nowrap px-2 items-center">
												{`${currencySymbol}${totalDirectAmount.toFixed(2)}`}
											</td>
										</tr>
										{openRows.includes("Direct_expenses") && (
											<>
												{directArray?.map((expence: any, index) => (
													<tr key={index} className="mt-4 font-bold">
														<td className="text-left  pl-16">
															<ul className="list-disc list-inside">
																<li className="py-2  text-neutral-700 whitespace-nowrap">
																	{expence.category}
																</li>
															</ul>
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-black-700">
															{`${currencySymbol}${expence?.amount.toFixed(2)}`}
														</td>
														{/* <td className="px-4 py-2 text-right border-gray-300 text-black-700">
															---
														</td> */}
													</tr>
												))}
												{/* <tr>
													<td className="text-left  pl-16">
														<ul className="list-disc pl-5">
															<li className="py-2  text-neutral-700 whitespace-nowrap">
																Payment-in Discount
															</li>
														</ul>
													</td>
													<td className="text-right  whitespace-nowrap text-neutral-700 px-2">
														₹800
													</td>
												</tr> */}
												{/* <tr>
													<td className="text-left  pl-16">
														<ul className="list-disc pl-5">
															<li className="py-2  text-neutral-700 whitespace-nowrap">
																Manufacturing Expense
															</li>
														</ul>
													</td>
													<td className="text-right  whitespace-nowrap text-neutral-700 px-2">
														₹800
													</td>
												</tr> */}
											</>
										)}

										<tr
											className="cursor-pointer"
											onClick={(e) => toggleRow("Indirect_Expenses", e)}
										>
											<td className="text-left  pl-8 flex items-center">
												<div>
													{openRows.includes("Indirect_Expenses") ? (
														<FaChevronDown className="mr-2" />
													) : (
														<FaChevronRight className="mr-2" />
													)}
												</div>
												<span className="whitespace-nowrap py-2">
													Indirect_Expenses
												</span>
											</td>
											<td className="text-right  whitespace-nowrap px-2 items-center">
												{`${currencySymbol}${totalIndirectAmount.toFixed(2)}`}
											</td>
										</tr>

										{openRows.includes("Indirect_Expenses") && (
											<>
												{/* {indirectArray?.map((expence: any, index) => (
													<tr key={index} className="mt-4 font-bold">
														<td className="text-left  pl-16">
															<ul className="list-disc list-inside">
																<li className="py-2  text-neutral-700 whitespace-nowrap">
																	{expence.category}
																</li>
															</ul>
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-black-700">
															{`${currencySymbol}${expence?.amount.toFixed(2)}`}
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-black-700">
															---
														</td>
													</tr>
												))} */}
												{/* <tr
													className="cursor-pointer"
													onClick={(e) => toggleRow("cost_financing", e)}
												>
													<td className="text-left  pl-16 flex items-center">
														<div>
															{openRows.includes("cost_financing") ? (
																<FaChevronDown className="mr-2" />
															) : (
																<FaChevronRight className="mr-2" />
															)}
														</div>
														<span className="whitespace-nowrap py-2">
															Cost of Financing
														</span>
													</td>
													<td className="text-right  whitespace-nowrap px-2 items-center">
														₹200
													</td>
												</tr> */}
												{/* {openRows.includes("cost_financing") && (
													<>
														<tr>
															<td className="text-left  pl-24">
																<ul className="list-disc pl-6">
																	<li className="py-2  text-neutral-700 whitespace-nowrap">
																		Charges on Loan
																	</li>
																</ul>
															</td>
															<td className="text-right  whitespace-nowrap text-neutral-700 px-2">
																₹50
															</td>
														</tr>
														<tr>
															<td className="text-left  pl-24">
																<ul className="list-disc pl-6">
																	<li className="py-2  text-neutral-700 whitespace-nowrap">
																		Processing Fee for Loans
																	</li>
																</ul>
															</td>
															<td className="text-right  whitespace-nowrap text-neutral-700 px-2 items-center">
																₹50
															</td>
														</tr>
													</>
												)} */}
												<tr>
													<td className="text-left  pl-16">
														<ul className="list-disc pl-5">
															<li className="py-2  text-neutral-700 whitespace-nowrap">
																Depreciation on Assets
															</li>
														</ul>
													</td>
													<td className="text-right  whitespace-nowrap px-2 items-center">
														₹0.00
													</td>
												</tr>

												<tr
													className="cursor-pointer"
													onClick={(e) => toggleRow("Other_Expenses", e)}
												>
													<td className="text-left  pl-16 flex items-center">
														<div>
															{openRows.includes("Other_Expenses") ? (
																<FaChevronDown className="mr-2" />
															) : (
																<FaChevronRight className="mr-2" />
															)}
														</div>
														<span className="whitespace-nowrap py-2">
															Other_Expenses
														</span>
													</td>
													<td className="text-right  whitespace-nowrap px-2 items-center">
														{`${currencySymbol}${totalIndirectAmount.toFixed(
															2
														)}`}
													</td>
												</tr>
												{openRows.includes("Other_Expenses") && (
													<>
														<tr>
															<td className="text-left  pl-24">
																<ul className="list-disc pl-6">
																	<li className="py-2  text-neutral-700 whitespace-nowrap">
																		Expenses on Purchase of Assets
																	</li>
																</ul>
															</td>
															<td className="text-right  whitespace-nowrap text-neutral-700 px-2">
																₹0.00
															</td>
														</tr>
														{indirectArray?.map((expence: any, index) => (
															<tr key={index} className="mt-4 font-bold">
																<td className="text-left  pl-16">
																	<ul className="list-disc list-inside">
																		<li className="py-2  text-neutral-700 whitespace-nowrap">
																			{expence.category}
																		</li>
																	</ul>
																</td>
																<td className="px-4 py-2 text-right border-gray-300 text-black-700">
																	{`${currencySymbol}${expence?.amount.toFixed(
																		2
																	)}`}
																</td>
																{/* <td className="px-4 py-2 text-right border-gray-300 text-black-700">
																	---
																</td> */}
															</tr>
														))}
													</>
												)}
											</>
										)}
									</>
								)}
							</tbody>
							<tr>
								<td></td>
							</tr>

							<tfoot className=" w-full bg-gray-300 pt-4">
								<tr className="w-full">
									<td className="text-left whitespace-nowrap text-[#008065] w-full px-2 py-3 rounded-l-md  font-semibold">
										Net Loss/Income (Incomes - Expenses):
									</td>
									<td className="text-right whitespace-nowrap text-red-600 w-full px-2 py-3 rounded-r-md  font-semibold">
										{`${currencySymbol}${netIncome.toFixed(2)}`}
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</StyledDiv>
		</div>
	);
};

export default Profit;
