/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Transition } from "@headlessui/react";
import { Button, FormControlLabel, Switch } from "@mui/material";
import moment from "moment";
import { toWords } from "number-to-words";
import React, {
	forwardRef,
	Fragment,
	useEffect,
	useRef,
	useState,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { useData } from "../../layouts/shared/DataProvider";
import { fontFamilyGet } from "../../redux/actions/FontFamilyAction";
import { InternalGetCount } from "../../redux/actions/InternalInvoiceAction";
import { currencyGet } from "../../redux/actions/currencyAction";
import { expenseAll } from "../../redux/actions/expenseAction";
import {
	expenseDetailFindOne,
	expenseDetailsSave,
	expenseDetailUpdate,
} from "../../redux/actions/expensePageActions";
import { registerAll } from "../../redux/actions/userAction";
import api from "../../redux/axiosConfig";
import ClientPlusIcon from "../Icons/ClientPlusIcon";
import ConfirmationDownload from "../commen/ConfirmationDownload";
import NumberFormat from "../commen/NumberFormat";
import PaymentUpdateModel from "../commen/PaymentUpdateModel";
import { defaultInputSmStyle } from "../commen/defaultStyles";
import SendEmailModel from "../models/SendEmailModel";
import VendorModel2 from "../models/VendorModel2";
import "./../../layouts/App/App.css";
import ExpensePreview from "./ExpensePreview";
import ExpensePrint from "./ExpensePrint";
import ExpenseProducts from "./ExpenseProducts";
import ExpenseTaxPreview from "./ExpenseTaxPreview";
import ExpenseTaxPrint from "./ExpenseTaxPrint";
import ExpenseTaxProductDetails from "./ExpenseTaxProductDetails";
import CalculateIndianFormattotal from "../commen/CalculateIndianFormattotal";

function ExpensesPage() {
	const navigate = useNavigate();

	const CustomInputWithIcon = forwardRef(
		({ value, onClick }: any, ref: any) => (
			<div className="relative flex items-center border-2 border-[#949494] focus:border-[#07ad7b] group focus-within:outline-none focus-within:border-[#07ad7b] focus:outline-none rounded-[7px] px-1 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12">
				<input
					type="text"
					value={value}
					readOnly
					className={
						"w-full sm:px-2 focus:outline-none"
					}
					style={{
						fontFamily: "poppins",
						fontSize: "14px",
						fontWeight: 400,
						// borderColor: "#008065",
					}}
					ref={ref}
				/>
				<FaCalendarAlt
					className="sm:mr-2 mr-1 cursor-pointer mb-1"
					onClick={onClick}
				/>
			</div>
		)
	);
	// const ref = useRef(null);
	const [isToggled, setIsToggled] = useState(true);
	const ref = useRef<HTMLInputElement | null>(null);
	const dispatch = useDispatch<any>();
	const params = useParams();
	const { id } = params;
	const usersignin = useSelector((state: any) => state?.userSignin);
	const token = usersignin?.userInfo?.token;
	const template = useSelector((state: any) => state.userColorTemplate);
	const { userdata } = template;
	const { selectedFont, setSelectedFont }: any = useData();
	const [invoiceUpdateId, setInvoiceUpdateId] = useState(null as any);
	const [billModules, setbillModule] = useState(null as any);
	const [selectedPayment, setSelectedPayment] = useState("");
	const [balanceAmount, setbalanceAmount] = useState(0 as any);
	const [CashAmount, setCashAmount] = useState(0 as any);
	const [receivedAmount, setreceivedAmount] = useState(0 as any);
	const [dateFormat, setDateFormat] = useState("DD/MM/YYYY");
	const [ExpenseDate, setExpenseDate] = useState(new Date() as any);
	const formatDate = moment(ExpenseDate).format(dateFormat);
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [expenseCategoryValues, setExpenseCategoryValues] = useState([] as any);

	// ***********************************************************************

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const [formData, setFormData] = useState({
		errorMessage: "",
		errors: {},
		paymentInReceiptNo: "",
		invoicehead: "EXPENSES",
		invoiceNo: "",
		expenseDate: formatDate,
		vendorName: "",
		vendorMobile: "",
		subtotal: 0,
		toatalAmount: 0,
		product: [],
		tax: [],
		roundOff: 0,
		RoundOffType: "",
		roundOffEnable: "",
		AmountFormat: "",
		user_id: token?._id,
		To: "",
		Subject: "",
		billModule: billModules,
		paymentType: selectedPayment,
		cashAmount: CashAmount,
		paymentStatus: "PAID",
		totalInWords: "",
		gstValue: "",
		expenseType: "WithOutTax",
		expenseCategory: "",
		expense_type: "",
		expenseCategoryId: "",
		isSendEmail: true,
		isvendorMobile: true,
		TaxName:userdata?.gstValue,
	} as any);

	useEffect(() => {
		dispatch(fontFamilyGet(token?._id) as any).then((res: any) => {
			if (res?.type === "FONTFAMILY_GET_SUCCESS") {
				let data = res?.payload?.find(
					(value: any) => value?.defaultInfo === true
				);
				if (data) {
					setSelectedFont(data?.fontfamily);
				} else {
					setSelectedFont("poppins");
				}
			}
		});
	}, []);

	const [currencySymbol, setcurrencySymbol] = useState("₹");
	const handleDateChange = (date: any) => {
		const formattedDate = moment(date).format(dateFormat);
		if (formattedDate !== formData.expenseDate) {
			setExpenseDate(date);
			setFormData((prevFormData: any) => ({
				...prevFormData,
				expenseDate: formattedDate,
			}));
		}
	};
	useEffect(() => {
		setFormData((prevFormData: any) => ({
			...prevFormData,
			balanceAmount: balanceAmount ? balanceAmount : 0,
			receivedAmount: receivedAmount ? Number(receivedAmount) : 0,
		}));
	}, [balanceAmount, receivedAmount]);

	useEffect(() => {
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
	}, []);

	useEffect(() => {
		setFormData((prevFormData: any) => ({
			...prevFormData,
			balanceAmount: balanceAmount ? balanceAmount : "",
			receivedAmount: receivedAmount ? Number(receivedAmount) : 0,
			cashAmount: CashAmount ? CashAmount : 0,
			paymentType: selectedPayment ? selectedPayment : "",
		}));
	}, [balanceAmount, receivedAmount, CashAmount]);
	// ****************************************Image section***************************************************

	const [vendorErrors, setVendorErrors] = useState<{
		vendorName?: string;
		vendorMobile?: string;
		purchaseEntryNo?: string;
		paymentType?: string;
	}>({});

	const [sendEmailErrors, setSendEmailErrors] = useState<{ To?: string }>({});

	const validateProdForm = () => {
		if (formData.vendorName?.trim() === "") {
			vendorErrors["vendorName"] = "Vendor Name is Requried  ";
		}
		if (formData.purchaseEntryNo?.trim() === "") {
			vendorErrors["purchaseEntryNo"] = "Purchase Entry Number is Requried  ";
		}
		if (formData.paymentType?.trim() === "") {
			vendorErrors["paymentType"] = "Payment Type is Requried  ";
		}
	};
	const handleCategoryChange = (e: any) => {
		const { value } = e.target;
		// const parsedValue = typeof value === "string" && value.startsWith("{") ? JSON.parse(value) : value;
		const parsedValue = JSON.parse(value);
		setFormData((prevFormData: any) => ({
			...prevFormData,
			expenseCategory: parsedValue.expense_Category,
			expenseCategoryId: parsedValue.objectId,
			expense_type: parsedValue.expense_type,
			subtotal: 0,
			toatalAmount: 0,
			prodcut: [],
			tax: [],
			CashAmount: "",
		}));
		setProductRows([
			{
				expense_Category: "",
				rate: "",
				qty: "",
				amount: 0,
				expenseTax: "",
				hsn: "",
				discount: "",
			},
		]);
		setSelectedPayment("");
		setCashAmount("");
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		let newValue = value.trimStart();
		if (name == "paymentType") {
			for (let i = 0; i < prodcutRows.length; i++) {
				const { expense_Category, qty, rate } = prodcutRows[i];
				if (!expense_Category || !qty || !rate) {
					setShowConfirmation(true);
				}
			}
			setSelectedPayment(e.target.value);
			setbalanceAmount(0);
			// if (formData.product.length === 0) {
			//     setShowConfirmation(true)
			// }
		}
		if (name == "cashAmount") {
			let cashvalue = e.target.value;
			let balance = formData.toatalAmount - Number(cashvalue);
			setbalanceAmount(balance);
			setCashAmount(cashvalue);
			setreceivedAmount(cashvalue);
		}
		if (value == "CASH") {
			setCashAmount(formData.toatalAmount);
			setreceivedAmount(formData.toatalAmount);
			setbalanceAmount(0.0);
		}
		setErrorEmail(null);
		setSendEmailErrors({ ...sendEmailErrors, [e.target.name]: "" });
		setVendorErrors({ ...vendorErrors, [e.target.name]: "" });
		let isSendEmail = true;
		let isvendorMobile = true;
		if (e.target.name === "To" && value) {
			isSendEmail = emailRegex.test(value);
		}

		if (e.target.name === "vendorMobile" && value) {
			isvendorMobile = value.length === 10;
		}
		// Validate stock quantity

		setFormData({
			...formData,
			[e.target.name]: newValue,
			isvendorMobile:
				e.target.name === "vendorMobile"
					? isvendorMobile
					: formData.isvendorMobile,
			isSendEmail: e.target.name === "To" ? isSendEmail : formData.isSendEmail,
		});
	};
	const [newExistProduct, setNewexistProduct] = useState([
		{ exist: "", originalValue: "" },
	] as any);

	// ***********************************Start***********************************
	const fetchData = () => {
		dispatch(InternalGetCount() as any).then((data: any) => {
			if (data) {
				let newCountValue = data?.payload?.expenseCount;
				let match = newCountValue?.match(/\d{4}$/);
				let result = "";
				if (match) {
					let value = match[0];
					result = (parseInt(value) + 1).toString().padStart(4, "0");
				}
				let datevalue = moment(new Date()).format("YYYY-MM-DD");
				const [year, month] = datevalue.split("-");
				let invoice =
					`${"EX-"}` +
					String(`${month}/`) +
					String(`${year}/`) +
					String(result);

				setFormData((prevFormData: any) => ({
					...prevFormData,
					invoiceNo: invoice,
					paymentInReceiptNo: data?.payload?.paymentInReceiptCount,
					expenseDate: prevFormData.expenseDate,
				}));
			}
		});
		dispatch(expenseAll(token?._id) as any).then((data: any) => {
			setExpenseCategoryValues(data.payload);
		});
	};

	useEffect(() => {
		setFormData((prevFormData: any) => ({
			...prevFormData,
			expenseDate: formData?.expenseDate ? formData?.expenseDate : formatDate,
		}));
		// Function to format number in Indian format
	}, [ExpenseDate]);
	const [confirmDownload, setConfirmDownload] = useState(false);
	const [productError, setProductError] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [prodcutRows, setProductRows] = useState<any>([
		{ expense_Category: "", rate: "", qty: "", amount: 0, expenseTax: "" },
	] as any);

	const [prodcutErrors, setProdcutErrors] = useState<any>([
		{ expense_Category: false, rate: false, qty: false },
	] as any);

	const updateProductErrors = (index: any, fieldName: any, value: any) => {
		const newErrors = [...prodcutErrors];
		newErrors[index][fieldName] = value ? true : false;
		setProdcutErrors(newErrors);
	};

	const addRow = () => {
		setProductRows([
			...prodcutRows,
			{ expense_Category: "", rate: "", qty: "", amount: 0, prodcutRows: "" },
		]);

		setProdcutErrors([
			...prodcutErrors,
			{
				expense_Category: productError,
				rate: productError,
				qty: productError,
			},
		]);
	};

	const getProductCal = (values: any) => {
		let products = [...values.product];
		let finalProduct = products?.map((item: any) => {
			item.grandTotal =
				((parseFloat(item.totalgst) / 100) * parseFloat(item.rate) +
					parseFloat(item.rate)) *
				parseFloat(item.qty);
			item.total =
				(parseFloat(item.totalgst) / 100) * parseFloat(item.rate) +
				parseFloat(item.rate);
			item.creation_date = new Date();
			return item;
		});
		return finalProduct;
	};

	const downloadInvoice = () => {};
	const [invoiceUpdateNewId, setInvoiceUpdateNewId] = useState(null as any);
	const saveAndDownloadInvoice = () => {
		if (!id && !invoiceUpdateId) {
			if (formData.billTaxType != "Product Wise") {
				formData.product = getProductCal(formData);
			}
			formData.billTaxType = userdata?.taxType ? userdata?.taxType : "";
			formData.invoiceTemplateType = userdata?.invoiceType
				? userdata?.invoiceType
				: "";
			formData.colorTemplateType = userdata?.template ? userdata?.template : "";
			if (amountInWords === "International Format") {
				const toWords = (num: any) => {
					const numberToWords = require("number-to-words");
					return numberToWords.toWords(num);
				};

				const convertAmount = (amount: any) => {
					const roundedAmount = Math.round(amount);
					const amountInWords = Number.isFinite(roundedAmount)
						? toWords(roundedAmount)
						: "Invalid amount";
					const internationalAmountInWords = `${amountInWords} Only. `;

					return {
						internationalAmountInWords,
					};
				};

				const totalAmount = formData.toatalAmount;
				const result = convertAmount(totalAmount);
				formData.totalInWords = result.internationalAmountInWords;
			} else {
				const totalAmount = formData.toatalAmount;
				const roundedtotalAmount = Math.round(totalAmount);
				const totalAmountInWords = Number.isFinite(roundedtotalAmount)
					? CalculateIndianFormattotal(roundedtotalAmount)
					: "Invalid amount";
				const totalAmountInWordsPrint =
					"Rupees " + totalAmountInWords + " Only";
				formData.totalInWords = totalAmountInWordsPrint;
			}

			dispatch(expenseDetailsSave(formData) as any).then((response: any) => {
				setInvoiceUpdateNewId(response?.payload?.invoice_id);
				formData?.product.forEach((item: any) => {
					item.amount = Number(item.amount).toFixed(2);
				});
				if (response?.payload?.invoice_id) {
					const queryParams = new URLSearchParams();
					if (userdata?.template) {
						queryParams.append("param5", userdata?.template);
					}
					queryParams.append("param6", selectedFont);
					queryParams.append("param7", currencySymbol);
					queryParams.append("param8", formData?.expenseType);

					queryParams.append("param9", formData.totalInWords);
					const url = `/api/expensePdf/${
						response?.payload?.invoice_id
					}?${queryParams.toString()}`;
					fetch(url, {
						method: "GET",
					})
						.then((response) => {
							if (!response.ok) {
								throw new Error("Network response was not ok");
							}
							return response.blob();
						})
						.then((blob) => {
							const url = window.URL.createObjectURL(new Blob([blob]));
							const link: any = document.createElement("a");
							link.href = url;
							const formattedDate = moment().format("YYYY-MM-DD");
							link.setAttribute(
								"download",
								`invoice_${formData.vendorName}_${formattedDate}.pdf`
							);
							document.body.appendChild(link);
							link.click();
							link.parentNode.removeChild(link);
							setProductError(false);
							setInvoiceUpdateId(response?.payload?.invoice_id);
							setConfirmDownload(false);
						})
						.catch((error) => console.error("Error downloading PDF:", error));
				}
				toast.success("Saved Successfully!", {
					position: toast.POSITION.BOTTOM_CENTER,
					autoClose: 800,
				});
				navigate("/app/expensePageTable");
			});
		} else if (id || invoiceUpdateId) {
			let invoiceId = id ? id : invoiceUpdateId;
			if (formData.billTaxType != "Product Wise") {
				formData.product = getProductCal(formData);
			}
			formData?.product.forEach((item: any) => {
				item.amount = Number(item.amount).toFixed(2);
			});
			if (amountInWords === "International Format") {
				const toWords = (num: any) => {
					const numberToWords = require("number-to-words");
					return numberToWords.toWords(num);
				};

				const convertAmount = (amount: any) => {
					const roundedAmount = Math.round(amount);
					const amountInWords = Number.isFinite(roundedAmount)
						? toWords(roundedAmount)
						: "Invalid amount";
					const internationalAmountInWords = `${amountInWords} Only. `;

					return {
						internationalAmountInWords,
					};
				};

				const totalAmount = formData.toatalAmount;
				const result = convertAmount(totalAmount);
				formData.totalInWords = result.internationalAmountInWords;
			} else {
				const totalAmount = formData.toatalAmount;
				const roundedtotalAmount = Math.round(totalAmount);
				const totalAmountInWords = Number.isFinite(roundedtotalAmount)
					? CalculateIndianFormattotal(roundedtotalAmount)
					: "Invalid amount";
				const totalAmountInWordsPrint =
					"Rupees " + totalAmountInWords + " Only";
				formData.totalInWords = totalAmountInWordsPrint;
			}
			dispatch(expenseDetailUpdate(invoiceId, formData) as any).then(
				(response: any) => {
					const queryParams = new URLSearchParams();
					if (formData.colorTemplateType) {
						queryParams.append("param5", formData.colorTemplateType);
					}
					queryParams.append("param6", selectedFont);
					queryParams.append("param7", currencySymbol);
					queryParams.append("param8", formData?.expenseType);

					queryParams.append("param9", formData.totalInWords);

					// Construct the URL with the query parameters

					const url = `/api/expensePdf/${invoiceId}?${queryParams.toString()}`;
					fetch(url, {
						method: "GET",
					})
						.then((response) => {
							if (!response.ok) {
								throw new Error("Network response was not ok");
							}
							return response.blob();
						})
						.then((blob) => {
							const url = window.URL.createObjectURL(new Blob([blob]));
							const link: any = document.createElement("a");
							link.href = url;
							const formattedDate = moment().format("YYYY-MM-DD");
							link.setAttribute(
								"download",
								`invoice_${formData.vendorName}_${formattedDate}.pdf`
							);
							document.body.appendChild(link);
							link.click();
							link.parentNode.removeChild(link);
							setProductError(false);
							setConfirmDownload(false);
						})
						.catch((error) => console.error("Error downloading PDF:", error));
				}
			);
			toast.success("Saved Successfully!", {
				position: toast.POSITION.BOTTOM_CENTER,
				autoClose: 800,
			});
			navigate("/app/expensePageTable");
		}
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		let finalErrors = [...prodcutErrors];
		var ProductValidation = prodcutRows?.some(
			(item: any) => !item.expense_Category || !item.rate || !item.qty
		);

		prodcutRows.forEach((item: any, index: any) => {
			finalErrors[index].expense_Category = item.expense_Category
				? true
				: false;
			finalErrors[index].rate = item.rate ? true : false;
			finalErrors[index].qty = item.qty ? true : false;
		});
		setProdcutErrors(finalErrors);

		if (
			formData.expenseType === "WithTax" &&
			!ProductValidation &&
			!formData.errors?.vendorMobileErrorMessage &&
			formData.vendorName &&
			formData.paymentType
		) {
			setConfirmDownload(true);
		} else if (
			formData.expenseType === "WithOutTax" &&
			!ProductValidation &&
			!formData.errors?.vendorMobileErrorMessage &&
			formData.paymentType
		) {
			setConfirmDownload(true);
		} else {
			setProductError(true);
			validateProdForm();
			setConfirmDownload(false);
		}
	};

	const [openmail, setOpenMail] = useState(false as any);
	const handleSectionOpen = (e: any) => {
		let finalErrors = [...prodcutErrors];
		var ProductValidation = prodcutRows?.some(
			(item: any) => !item.expense_Category || !item.rate || !item.qty
		);

		prodcutRows.forEach((item: any, index: any) => {
			finalErrors[index].expense_Category = item.expense_Category
				? true
				: false;
			finalErrors[index].rate = item.rate ? true : false;
			finalErrors[index].qty = item.qty ? true : false;
		});
		setProdcutErrors(finalErrors);

		if (
			formData.expenseType === "WithTax" &&
			!ProductValidation &&
			!formData.errors?.vendorMobileErrorMessage &&
			formData.vendorName &&
			formData.paymentType
		) {
			setOpenMail(true);
		} else if (
			formData.expenseType === "WithOutTax" &&
			!ProductValidation &&
			formData.paymentType
		) {
			setOpenMail(true);
		} else {
			setProductError(true);
			validateProdForm();
		}
	};
	const [errorEmail, setErrorEmail] = useState(null as any);
	const handleSendEmail = () => {
		if (formData.To?.trim() !== "" && emailRegex.test(formData.To)) {
			const emailViewPdf = {
				fromEmail: formData.From,
				toEmail: formData.To,
				subject: formData.Subject,
				message: formData.Message,
				vendorName: formData.vendorName,
			};
			if (!id) {
				if (invoiceUpdateNewId) {
					if (amountInWords === "International Format") {
						const toWords = (num: any) => {
							const numberToWords = require("number-to-words");
							return numberToWords.toWords(num);
						};

						const convertAmount = (amount: any) => {
							const roundedAmount = Math.round(amount);
							const amountInWords = Number.isFinite(roundedAmount)
								? toWords(roundedAmount)
								: "Invalid amount";
							const internationalAmountInWords = `${amountInWords} Only. `;

							return {
								internationalAmountInWords,
							};
						};

						const totalAmount = formData.toatalAmount;
						const result = convertAmount(totalAmount);
						formData.totalInWords = result.internationalAmountInWords;
					} else {
						const totalAmount = formData.toatalAmount;
						const roundedtotalAmount = Math.round(totalAmount);
						const totalAmountInWords = Number.isFinite(roundedtotalAmount)
							? CalculateIndianFormattotal(roundedtotalAmount)
							: "Invalid amount";
						const totalAmountInWordsPrint =
							"Rupees " + totalAmountInWords + " Only";
						formData.totalInWords = totalAmountInWordsPrint;
					}
					dispatch(
						expenseDetailUpdate(invoiceUpdateNewId, formData) as any
					).then((response: any) => {
						const queryParams = new URLSearchParams();
						if (userdata?.template) {
							queryParams.append("param5", userdata?.template);
						}
						queryParams.append("param6", selectedFont);
						queryParams.append("param7", currencySymbol);
						queryParams.append("param8", formData?.expenseType);
						queryParams.append("param9", formData.totalInWords);
						fetch(
							`/api/expense_send_email_pdf/${invoiceUpdateNewId}?${queryParams.toString()}`
						)
							.then((response) => {
								if (!response.ok) {
									throw new Error("Network response was not ok");
								}
								return response.blob();
							})
							.then((blob) => {
								api
									.post(`/api/expense_upload`, emailViewPdf)
									.then((pdfResp: any) => {
										console.log("Blob uploaded successfully:", pdfResp);
										if (pdfResp.data.message) {
											toast.success("Email Send Successfully", {
												position: toast.POSITION.BOTTOM_CENTER,
												autoClose: 800,
											});
										}
									})
									.catch((error: any) => {
										console.error("Error uploading blob:", error);
									});
							})
							.catch((error) => console.error("Error downloading PDF:", error));
					});
				} else {
					if (amountInWords === "International Format") {
						const toWords = (num: any) => {
							const numberToWords = require("number-to-words");
							return numberToWords.toWords(num);
						};

						const convertAmount = (amount: any) => {
							const roundedAmount = Math.round(amount);
							const amountInWords = Number.isFinite(roundedAmount)
								? toWords(roundedAmount)
								: "Invalid amount";
							const internationalAmountInWords = `${amountInWords} Only. `;

							return {
								internationalAmountInWords,
							};
						};

						const totalAmount = formData.toatalAmount;
						const result = convertAmount(totalAmount);
						formData.totalInWords = result.internationalAmountInWords;
					} else {
						const totalAmount = formData.toatalAmount;
						const roundedtotalAmount = Math.round(totalAmount);
						const totalAmountInWords = Number.isFinite(roundedtotalAmount)
							? CalculateIndianFormattotal(roundedtotalAmount)
							: "Invalid amount";
						const totalAmountInWordsPrint =
							"Rupees " + totalAmountInWords + " Only";
						formData.totalInWords = totalAmountInWordsPrint;
					}
					dispatch(expenseDetailsSave(formData) as any).then(
						(response: any) => {
							setInvoiceUpdateNewId(response?.payload?.invoice_id);
							formData?.product.forEach((item: any) => {
								item.amount = Number(item.amount).toFixed(2);
							});
							const queryParams = new URLSearchParams();
							if (userdata?.template) {
								queryParams.append("param5", userdata?.template);
							}
							queryParams.append("param6", selectedFont);
							queryParams.append("param7", currencySymbol);

							queryParams.append("param8", formData?.expenseType);

							if (response?.payload?.invoice_id) {
								queryParams.append("param9", formData.totalInWords);

								fetch(
									`/api/expense_send_email_pdf/${
										response?.payload?.invoice_id
									}?${queryParams.toString()}`
								)
									.then((response) => {
										if (!response.ok) {
											throw new Error("Network response was not ok");
										}
										return response.blob();
									})
									.then((blob) => {
										api
											.post(`/api/expense_upload`, emailViewPdf)
											.then((pdfResp: any) => {
												console.log("Blob uploaded successfully:", pdfResp);
												if (pdfResp.data.message) {
													toast.success("Email Send Successfully", {
														position: toast.POSITION.BOTTOM_CENTER,
														autoClose: 800,
													});
												}
											})
											.catch((error: any) => {
												console.error("Error uploading blob:", error);
											});
									})
									.catch((error) =>
										console.error("Error downloading PDF:", error)
									);
							}
						}
					);
				}
			} else if (id || invoiceUpdateId) {
				let invoiceId = id ? id : invoiceUpdateId;
				formData.product = getProductCal(formData);
				formData?.product.forEach((item: any) => {
					item.amount = Number(item.amount).toFixed(2);
				});

				if (amountInWords === "International Format") {
					const toWords = (num: any) => {
						const numberToWords = require("number-to-words");
						return numberToWords.toWords(num);
					};

					const convertAmount = (amount: any) => {
						const roundedAmount = Math.round(amount);
						const amountInWords = Number.isFinite(roundedAmount)
							? toWords(roundedAmount)
							: "Invalid amount";
						const internationalAmountInWords = `${amountInWords} Only. `;

						return {
							internationalAmountInWords,
						};
					};

					const totalAmount = formData.toatalAmount;
					const result = convertAmount(totalAmount);
					formData.totalInWords = result.internationalAmountInWords;
				} else {
					const totalAmount = formData.toatalAmount;
					const roundedtotalAmount = Math.round(totalAmount);
					const totalAmountInWords = Number.isFinite(roundedtotalAmount)
						? CalculateIndianFormattotal(roundedtotalAmount)
						: "Invalid amount";
					const totalAmountInWordsPrint =
						"Rupees " + totalAmountInWords + " Only";
					formData.totalInWords = totalAmountInWordsPrint;
				}
				dispatch(expenseDetailUpdate(invoiceId, formData) as any).then(
					(response: any) => {
						const queryParams = new URLSearchParams();
						if (formData.colorTemplateType) {
							queryParams.append("param5", formData.colorTemplateType);
						}
						queryParams.append("param6", selectedFont);
						queryParams.append("param7", currencySymbol);
						queryParams.append("param8", formData?.expenseType);

						queryParams.append("param9", formData.totalInWords);

						// Construct the URL with the query parameters

						fetch(
							`/api/expense_send_email_pdf/${invoiceId}?${queryParams.toString()}`
						)
							.then((response) => {
								if (!response.ok) {
									throw new Error("Network response was not ok");
								}
								return response.blob();
							})
							.then((blob) => {
								api
									.post(`/api/expense_upload`, emailViewPdf)
									.then((pdfResp: any) => {
										console.log("Blob uploaded successfully:", pdfResp);
										if (pdfResp.data.message) {
											toast.success("Email Send Successfully", {
												position: toast.POSITION.BOTTOM_CENTER,
												autoClose: 800,
											});
										}
									})
									.catch((error: any) => {
										console.error("Error uploading blob:", error);
									});
							})
							.catch((error) => console.error("Error downloading PDF:", error));
					}
				);
			}

			setOpenMail(false);
			setErrorEmail(null);
			setOpenView(false);
			setFormData({
				...formData,
				To: "",
				Subject: "",
				Message: "",
				errors: { ...formData.errors, ToErrorMessage: "" },
			});
		} else {
			if (formData.To?.trim() === "") {
				sendEmailErrors["To"] = "Email is requried  ";
				setErrorEmail(sendEmailErrors);
			}
		}
	};

	// *********************************************EMail Sctions End**************************************
	const [editValue, setEditValue] = useState(false as any);

	useEffect(() => {
		if (!id) {
			fetchData();
			formData.billTaxType = userdata?.taxType;
			formData.invoiceTemplateType = userdata?.invoiceType;
			formData.colorTemplateType = userdata?.template;
		} else {
			dispatch(expenseDetailFindOne(id) as any).then((response: any) => {
				let invoiceDetails = JSON.parse(response?.payload.products);
				// let accountSettings = JSON.parse(response?.payload.app_settings);
				// setTaxAllList(accountSettings);
				let products = invoiceDetails.product;
				if (id) {
					setEditValue(true);

					let billTaxType = invoiceDetails.billTaxType;
					let gstType = invoiceDetails.gstType;
					let TaxName = invoiceDetails.TaxName;
					let invoiceTemplateType = invoiceDetails.invoiceTemplateType;
					let colorTemplateType = invoiceDetails.colorTemplateType;
					let expenseType = invoiceDetails.expenseType;

					setFormData({
						...invoiceDetails,
						toatalAmount: Number(invoiceDetails.subtotal),
						paymentType: invoiceDetails.paymentType,
						receivedAmount: invoiceDetails.receivedAmount,
						billTaxType: billTaxType,
						gstType: gstType,
						TaxName: TaxName,
						invoiceTemplateType: invoiceTemplateType,
						colorTemplateType: colorTemplateType,
						expenseType: expenseType,
						expenseCategory: invoiceDetails.expenseCategory,
					});
					if (invoiceDetails.expenseType == "WithTax") {
						setIsToggledForTax(true);
					}
					setreceivedAmount(invoiceDetails.receivedAmount);
					setCashAmount(invoiceDetails.cashAmount);
					setSelectedPayment(invoiceDetails.paymentType);
					setRoundoffValue(invoiceDetails.RoundOffType);
					setRoundoffEnable(invoiceDetails.RoundOffEnabled);
					setAmountInWord(invoiceDetails.AmountFormat);
					let finalErrors: any = [];

					let value = products?.map((item: any, index: any) => {
						let object: any = {};
						object.expense_Category = item.expense_Category ? true : false;
						object.rate = item.rate ? true : false;
						object.qty = item.qty ? true : false;
						finalErrors.push(object);
						return object;
					});

					setProdcutErrors(value);
					setIsToggled(!isToggled);
					setbillModule("PRODUCT");
				}
			});
		}
	}, [id]);

	const closeModal = () => {
		setErrorEmail(null);
		setOpenView(false);
		setOpenMail(false);
		setFormData({
			...formData,
			To: "",
			Subject: "",
			Message: "",
			errors: { ...formData.errors, ToErrorMessage: "" },
		});
	};
	const closeModalexisting = () => {
		setIsModalOpen(false);
	};
	const [viewOpen, setOpenView] = useState(false);
	const cancelButtonRef = useRef(null);
	const componentRef = useRef(null);

	// const [preViewdDis, setPreViewDis] = useState(true)

	const openChoosePreview = () => {
		if (amountInWords === "International Format") {
			const toWords = (num: any) => {
				const numberToWords = require("number-to-words");
				return numberToWords.toWords(num);
			};

			const convertAmount = (amount: any) => {
				const roundedAmount = Math.round(amount);
				const amountInWords = Number.isFinite(roundedAmount)
					? toWords(roundedAmount)
					: "Invalid amount";
				const internationalAmountInWords = `${amountInWords} Only. `;

				return {
					internationalAmountInWords,
				};
			};

			const totalAmount = formData.toatalAmount;
			const result = convertAmount(totalAmount);
			formData.totalInWords = result.internationalAmountInWords;
		} else {
			const totalAmount = formData.toatalAmount;
			const roundedtotalAmount = Math.round(totalAmount);
			const totalAmountInWords = Number.isFinite(roundedtotalAmount)
				? CalculateIndianFormattotal(roundedtotalAmount)
				: "Invalid amount";
			const totalAmountInWordsPrint = "Rupees " + totalAmountInWords + " Only";
			formData.totalInWords = totalAmountInWordsPrint;
		}
		let finalErrors = [...prodcutErrors];
		var ProductValidation = prodcutRows?.some(
			(item: any) => !item.expense_Category || !item.rate || !item.qty
		);

		prodcutRows.forEach((item: any, index: any) => {
			finalErrors[index].expense_Category = item.expense_Category
				? true
				: false;
			finalErrors[index].rate = item.rate ? true : false;
			finalErrors[index].qty = item.qty ? true : false;
		});
		setProdcutErrors(finalErrors);

		if (
			formData.expenseType === "WithTax" &&
			!ProductValidation &&
			!formData.errors?.vendorMobileErrorMessage &&
			formData.vendorName &&
			formData.paymentType
		) {
			setOpenView(true);
		} else if (
			formData.expenseType === "WithOutTax" &&
			!ProductValidation &&
			!formData.errors?.vendorMobileErrorMessage &&
			formData.paymentType
		) {
			setOpenView(true);
		} else {
			setProductError(true);
			validateProdForm();
		}
	};
	const handlePrint = useReactToPrint({
		content: () => getPrintContent(),
		documentTitle: "Invoice Letter",
		onAfterPrint: () => {
			console.log("Print completed!");
		},
	});

	const getPrintContent = (): any => {
		if (amountInWords === "International Format") {
			const toWords = (num: any) => {
				const numberToWords = require("number-to-words");
				return numberToWords.toWords(num);
			};

			const convertAmount = (amount: any) => {
				const roundedAmount = Math.round(amount);
				const amountInWords = Number.isFinite(roundedAmount)
					? toWords(roundedAmount)
					: "Invalid amount";
				const internationalAmountInWords = `${amountInWords} Only. `;

				return {
					internationalAmountInWords,
				};
			};

			const totalAmount = formData.toatalAmount;
			const result = convertAmount(totalAmount);
			formData.totalInWords = result.internationalAmountInWords;
		} else {
			const totalAmount = formData.toatalAmount;
			const roundedtotalAmount = Math.round(totalAmount);
			const totalAmountInWords = Number.isFinite(roundedtotalAmount)
				? CalculateIndianFormattotal(roundedtotalAmount)
				: "Invalid amount";
			const totalAmountInWordsPrint = "Rupees " + totalAmountInWords + " Only";
			formData.totalInWords = totalAmountInWordsPrint;
		}
		let finalErrors = [...prodcutErrors];
		var ProductValidation = prodcutRows?.some(
			(item: any) => !item.expense_Category || !item.rate || !item.qty
		);

		prodcutRows.forEach((item: any, index: any) => {
			finalErrors[index].expense_Category = item.expense_Category
				? true
				: false;
			finalErrors[index].rate = item.rate ? true : false;
			finalErrors[index].qty = item.qty ? true : false;
		});
		setProdcutErrors(finalErrors);

		if (
			formData.expenseType === "WithTax" &&
			!ProductValidation &&
			!formData.errors?.vendorMobileErrorMessage &&
			formData.vendorName &&
			formData.paymentType
		) {
			return componentRef.current;
		} else if (
			formData.expenseType === "WithOutTax" &&
			!ProductValidation &&
			formData.paymentType
		) {
			return componentRef.current;
		} else {
			setProductError(true);
			validateProdForm();
		}
	};
	const handleCancel = () => {
		setConfirmDownload(false);
		// setShowConfirmation(false)
	};
	const handleCancelForPaymentType = () => {
		setShowConfirmation(false);
		setSelectedPayment("");
	};
	const User_id = sessionStorage.getItem("userInfo");
	const userId = JSON.parse(User_id as any);

	const [roundOffValue, setRoundoffValue] = useState("" as any);
	const [roundOffEnable, setRoundoffEnable] = useState(Boolean);
	const [amountInWords, setAmountInWord] = useState("" as any);

	const fetchDataForPurchase = () => {
		dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
			setFormData((prev: any) => ({
				...prev,
				TaxName: res?.payload?.gstValue,
				expenseDate: res?.payload?.invoiceDateFormat
					? moment(ExpenseDate).format(
							res?.payload?.invoiceDateFormat
								? res?.payload?.invoiceDateFormat
								: "DD/MM/YYYY"
					  )
					: formatDate,
				RoundOffType: res?.payload?.RoundOffType,
				RoundOffEnabled: res?.payload?.RoundOffEnabled,
				AmountFormat: res?.payload?.AmountFormat,
			}));
			setDateFormat(res?.payload?.invoiceDateFormat);
			setRoundoffValue(res?.payload?.RoundOffType);
			setRoundoffEnable(res?.payload?.RoundOffEnabled);
			setAmountInWord(res?.payload?.AmountFormat);
		});
	};

	useEffect(() => {
		if (userId?.token?._id) {
			fetchDataForPurchase();
		}
	}, []);

	// ***********************************search******************************
	const handleConfirm = () => {
		setIsModalOpen(true);
	};
	const handleSelect = (data: any) => {
		vendorErrors.vendorName = "";
		setFormData({
			...formData,
			vendorName: data.vendor_name,
			vendorID: data._id?.$oid,
			vendorAddress: data.vendor_address,
			vendorCity: data.city,
			vendorState: data.state,
			vendorPostalCode: data.postal_code,
			vendorMobile: data.vendor_mobile,
			vendorEmail: data.vendor_email,
			vendorPan: data.pan_no,
			vendorgst: data.gst,
			isClientGst: true,
			isClientPan: true,
			isClientEmail: true,
			isvendorPostalCode: true,
			isvendorMobile: true,
			errors: {
				...formData.errors,
				vendorgstErrorMessage: "",
				vendorEmailErrorMessage: "",
				vendorPanErrorMessage: "",
				vendorMobileErrorMessage: "",
				vendorPostalCodeErrorMessage: "",
			},
		});

		setIsModalOpen(false);
	};

	const [isToggledForTax, setIsToggledForTax] = useState(false);

	const handleTaxToggleChange = (event: any) => {
		setIsToggledForTax(event.target.checked);
		if (event.target.checked) {
			setFormData((prevFormData: any) => ({
				...prevFormData,
				expenseType: "WithTax",
				subtotal: 0,
				toatalAmount: 0,
				expenseCategory: "",
				product: [],
				tax: [],
			}));
			setProductRows([
				{ expense_Category: "", rate: "", qty: "", amount: 0, expenseTax: "" },
			]);
			setCashAmount("");
			setSelectedPayment("");
			setProductError(false);
		} else {
			setFormData((prevFormData: any) => ({
				...prevFormData,
				expenseType: "WithOutTax",
				vendorName: "",
				vendorMobile: "",
				product: [],
				tax: [],
				expenseCategory: "",
				subtotal: 0,
				toatalAmount: 0,
			}));
			setProductRows([
				{ expense_Category: "", rate: "", qty: "", amount: 0, expenseTax: "" },
			]);
			setProductError(false);
			setCashAmount("");
			setSelectedPayment("");
		}
	};

	return (
		<>
			<div>
				{formData?.expenseType == "WithOutTax" ? (
					<>
						<div style={{ display: "none" }}>
							<ExpensePrint
								Invoice={formData}
								componentRef={componentRef}
								roundOffEnable={roundOffEnable}
								amountInWords={amountInWords}
							/>
						</div>
					</>
				) : (
					<>
						<div style={{ display: "none" }}>
							<ExpenseTaxPrint
								Invoice={formData}
								componentRef={componentRef}
								roundOffEnable={roundOffEnable}
								amountInWords={amountInWords}
							/>
						</div>
					</>
				)}
			</div>
			<div className="flex flex-wrap  ">
				<div className="w-full lg:w-10/12 pl-4 pr-4 sm:pl-4 sm:pr-0 mb-4 sm:mb-1 margin  items-start bg-[white] min-h-screen pb-5">
					<form>
						<div className="bg-[white] sticky z-10 top-0 left-0 right-0 pb-2 ">
							<div className="justify-center flex flex-row relative">
								<input
									className={` rounded-[7px] text-center xs:w-1/4 mt-2 focus:outline-none lg:text-3xl xl:text-3xl 2xl:text-3xl md:text-3xl text-base`}
									autoComplete="nope"
									style={{
										fontFamily: "poppins",
										fontWeight: 700,
									}}
									defaultValue={formData.invoicehead}
									type="text"
									ref={ref}
									id="input_focus"
									aria-label="Search"
									name="invoicehead"
								/>
								{/* </h1> */}
							</div>
						</div>

						<div className={"flex flex-col sm:flex-row pt-3  sm:pr-8"}>
							<div className="  flex-1 md:mx-2 mt-2 md:mt-[2px] ">
								{isToggledForTax && (
									<>
										<div>
											<div className="flex justify-between items-left">
												<div
													className="flex flex-1 items-center whitespace-nowrap justify-start"
													style={{
														color: "#008065",
														fontFamily: "Poppins",
														fontSize: "14px",
														fontWeight: 700,
													}}
												>
													Vendor Details
												</div>
												<div className="flex flex-1 items-center justify-center md:justify-end mr-2">
													<ClientPlusIcon className="w-4 h-4" />
													<Button
														onClick={handleConfirm}
														sx={{ textTransform: "none", padding: 0 }}
													>
														<span
															style={{
																color: "#008065",
																fontFamily: "Poppins",
																fontSize: "15px",
																fontWeight: 700,
															}}
															className="text-[#008065] ml-1 mt-1"
														>
															Existing
														</span>
													</Button>
												</div>
												{isModalOpen && (
													<VendorModel2
														closeModal={closeModalexisting}
														handleSelect={handleSelect}
													/>
												)}
											</div>
										</div>
										<div>
											<div className="flex flex-row justify-between items-center mb-2 mt-2">
												<div
													className="flex-1  required"
													style={{
														fontFamily: "poppins",
														fontSize: "14px",
														fontWeight: 700,
													}}
												>
													<span>Name</span>
												</div>
												<div className="font-title flex-1 text-left">
													<input
														autoComplete="nope"
														placeholder="vendor Name"
														className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12 +
                                                        ${vendorErrors.vendorName
																? "border-[#FF0000]"
																: "border-[#949494]"
															}
                                                         ${!vendorErrors.vendorName &&
															"focus:border-[#07ad7b]"
															}`}
														style={{
															fontFamily: "poppins",
															fontSize: "14px",
															fontWeight: 400,
															// borderColor: vendorErrors.vendorName ? "red" : "#008065",
														}}
														name="clientName"
														value={formData.vendorName}
														onChange={(e) => handleChange(e)}
														readOnly
													/>
													{vendorErrors.vendorName && (
														<p
															style={{
																fontFamily: "poppins",
																fontWeight: 700,
																color: "#ff0000",
															}}
															className="text-red-400 text-xs"
														>
															{vendorErrors.vendorName}
														</p>
													)}
												</div>
											</div>
											<div className="flex flex-row justify-between items-center mb-2">
												<div
													className=" flex-1 mb-2"
													style={{
														fontFamily: "poppins",
														fontSize: "14px",
														fontWeight: 700,
													}}
												>
													Mobile
												</div>
												<div className="font-title flex-1 text-left">
													<input
														type="text"
														autoComplete="nope"
														placeholder="Mobile Number"
														className={
															"border-2 focus:border-[#07ad7b] border-[#949494] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "
														}
														style={{
															fontFamily: "poppins",
															fontSize: "14px",
															fontWeight: 400,
															// borderColor: "#008065",
														}}
														maxLength={10}
														name="vendorMobile"
														value={formData.vendorMobile}
														onChange={(e) => {
															const re = /^[0-9\b]+$/;
															if (
																re.test(e.target.value) ||
																e.target.value == ""
															) {
																handleChange(e);
															}
														}}
														readOnly
													/>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
							<div className="  flex-1 md:mx-2">
								<div>
									<div className="sm:flex-1 mt-7 p-0 sm:p-2">
										<div className="flex flex-row justify-between items-center mb-2">
											<div
												className="flex-1"
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 700,
												}}
											>
												<span>Expense No</span>
											</div>
											<div className="font-title flex-1 text-left">
												<input
													autoComplete="nope"
													className={
														"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"
													}
													style={{
														fontFamily: "poppins",
														fontSize: "14px",
														fontWeight: 400,
														// borderColor: "#008065",
													}}
													name="invoiceNo"
													value={formData.invoiceNo}
													onChange={(e: any) => handleChange(e)}
													readOnly
												/>
											</div>
										</div>
										<div className="flex flex-row justify-between items-center mb-2">
											<div
												className=" flex-1 mb-2"
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 700,
												}}
											>
												Expense Date
											</div>
											<div className="font-title flex-1 text-left ">
												<DatePicker
													value={formData?.expenseDate}
													onChange={handleDateChange}
													dateFormat={"dd/MM/yyyy"}
													wrapperClassName="return-datepicker"
													scrollableYearDropdown
													customInput={<CustomInputWithIcon />}
													showPopperArrow={false}
												/>
											</div>
										</div>
										<div className="flex flex-row justify-between items-center mb-2">
											<div
												className=" flex-1 mb-2"
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 700,
												}}
											>
												Expense Category
											</div>
											<div className="font-title flex-1 text-left ">
												<select
													autoComplete="nope"
													className={
														"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"
													}
													style={{
														fontFamily: "poppins",
														fontSize: "14px",
														fontWeight: 400,
														// borderColor: "#008065",
													}}
													name="expenseCategory"
													// value={JSON.stringify(formData.expenseCategory)}
													value={
														formData.expenseCategory
															? formData.expenseCategory.expense_Category
															: ""
													}
													onChange={(e: any) => handleCategoryChange(e)}
												>
													<option value="" disabled hidden selected>
														{id ? formData.expenseCategory : "Select Category"}
													</option>
													{expenseCategoryValues.map((item: any) => (
														<option
															key={item._id.$oid}
															value={JSON.stringify({
																objectId: item._id.$oid,
																expense_Category: item.expense_Category,
																expense_type: item.expense_type,
															})}
														>
															{item.expense_Category}
														</option>
													))}
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<hr className="mr-8 ml-4 mt-2" style={{ borderColor: "#008065" }} />
						<div className="py-2  sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4">
							{isToggledForTax || formData.expenseType == "WithTax" ? (
								<>
									<ExpenseTaxProductDetails
										setSelectedPayment={setSelectedPayment}
										setCashAmount={setCashAmount}
										setreceivedAmount={setreceivedAmount}
										setbalanceAmount={setbalanceAmount}
										formData={formData}
										setFormData={setFormData}
										paramsId={id}
										updateProductErrors={updateProductErrors}
										prodcutErrors={prodcutErrors}
										prodcutRows={prodcutRows}
										setProductRows={setProductRows}
										productError={productError}
										addRow={addRow}
										setProductError={setProductError}
										setProdcutErrors={setProdcutErrors}
										editValue={editValue}
										newExistProduct={newExistProduct}
										roundOffValue={roundOffValue}
										roundOffEnable={roundOffEnable}
									/>
								</>
							) : (
								<>
									<ExpenseProducts
										setCashAmount={setCashAmount}
										setSelectedPayment={setSelectedPayment}
										setbalanceAmount={setbalanceAmount}
										newExistProduct={newExistProduct}
										formData={formData}
										setFormData={setFormData}
										paramsId={id}
										updateProductErrors={updateProductErrors}
										prodcutErrors={prodcutErrors}
										prodcutRows={prodcutRows}
										setProductRows={setProductRows}
										productError={productError}
										addRow={addRow}
										setProductError={setProductError}
										setProdcutErrors={setProdcutErrors}
										editValue={editValue}
										setNewexistProduct={setNewexistProduct}
										roundOffValue={roundOffValue}
										roundOffEnable={roundOffEnable}
									/>
								</>
							)}
							<div
								className={
									"flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full   sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 items-center"
								}
							>
								<div
									style={{
										fontFamily: "poppins",
										fontSize: "14px",
										fontWeight: 700,
									}}
									className={
										"font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
									}
								>
									SUBTOTAL
								</div>
								<div
									style={{
										fontFamily: "poppins",
										fontSize: "14px",
										fontWeight: 700,
									}}
									className={
										"font-title w-1/2 sm:w-1/4 text-right sm:pr-9 flex flex-row justify-end sm:block mb-1 whitespace-nowrap"
									}
								>
									<NumberFormat
										value={formData.subtotal ? formData.subtotal : 0}
									/>
								</div>
							</div>
							{roundOffEnable == true && (
								<>
									<div
										className={
											"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
										}
									>
										<div
											className={
												"w-full sm:w-1/2 sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
											}
										>
											<div
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 700,
												}}
												className={
													"w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
												}
											>
												ROUND OFF
											</div>
											<div
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 700,
												}}
												className={
													"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
												}
											>
												<NumberFormat value={formData.roundOff} />
											</div>
										</div>
									</div>
								</>
							)}

							<div
								className={
									"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
								}
							>
								<div
									className={
										"w-full sm:w-1/2   sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
									}
								>
									<div
										style={{
											fontFamily: "poppins",
											fontSize: "14px",
											fontWeight: 700,
										}}
										className={
											"w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
										}
									>
										TOTAL
									</div>
									<div
										style={{
											fontFamily: "poppins",
											fontSize: "14px",
											fontWeight: 700,
										}}
										className={
											"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
										}
									>
										<NumberFormat value={formData.toatalAmount} />
									</div>
								</div>
							</div>
							<div
								className={
									"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
								}
							>
								<div
									className={
										"w-full sm:w-1/2   sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
									}
								>
									<div
										style={{
											fontFamily: "poppins",
											fontSize: "14px",
											fontWeight: 700,
										}}
										className={
											"w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
										}
									>
										PAYMENT TYPE
									</div>
									<div
										style={{
											fontFamily: "poppins",
											fontSize: "14px",
											fontWeight: 700,
										}}
										className={
											"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
										}
									>
										<select
											name="paymentType"
											id="paymentType"
											// className={`focus:outline-none rounded-[7px] px-2 h-9 lg:w-full$ w-full border-2 border-[#008065]`}
											className={`focus:outline-none rounded-[7px] px-2 h-9 lg:w-full$ w-full border-2 +
                                                ${
																									vendorErrors.paymentType
																										? "border-[#FF0000]"
																										: "border-[#949494]"
																								}
                                                 ${
																										!vendorErrors.paymentType &&
																										"focus:border-[#07ad7b]"
																									}`}
											style={{
												fontFamily: "poppins",
												fontSize: "13px",
												fontWeight: 400,
												// borderColor: vendorErrors.paymentType ? "red" : "#008065",
											}}
											value={selectedPayment}
											onChange={(e: any) => handleChange(e)}
										>
											<option value="">PAYMENT TYPE</option>
											<option value="CASH">CASH</option>
										</select>
										{/* {vendorErrors.paymentType && (
                                            <p
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontWeight: 700,
                                                    color: "#ff0000",
                                                }}
                                                className="text-red-400 text-xs"
                                            >
                                                {vendorErrors.paymentType}
                                            </p>
                                        )} */}
									</div>
								</div>
							</div>
							{!id && selectedPayment === "CASH" && (
								<div
									className={
										"flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
									}
								>
									<div
										className={
											"w-full sm:w-1/2 	sm:px-0 md:px-4 lg:px-4 xl:px-4 2xl:px-4 py-1 flex flex-row rounded-lg items-center"
										}
									>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"w-full sm:w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
											}
										>
											CASH AMOUNT
										</div>
										<div
											style={{
												fontFamily: "poppins",
												fontSize: "14px",
												fontWeight: 700,
											}}
											className={
												"font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
											}
										>
											<input
												style={{
													fontFamily: "poppins",
													fontSize: "14px",
													fontWeight: 400,
													// borderColor: "#00A787",
												}}
												name="cashAmount"
												value={CashAmount}
												readOnly
												autoComplete="nope"
												onChange={(e: any) => handleChange(e)}
												placeholder="0.00"
												type="Number"
												className={
													defaultInputSmStyle +
													" text-right hide_arrow border-2 focus:border-[#07ad7b] border-[#949494] remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
												}
											/>
										</div>
									</div>
								</div>
							)}
							{/* <div
                                className={
                                    "flex flex-row sm:flex-row sm:justify-end w-full items-center text-black"
                                }
                            >
                                <div
                                    className={
                                        "w-full sm:w-1/2 px-4 py-1 flex flex-row rounded-lg items-center"
                                    }
                                >
                                    {selectedPayment === "CASH" && (
                                        <div
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                            }}
                                            className={
                                                "w-1/2 text-right sm:pr-9 flex flex-row sm:block items-center"
                                            }
                                        >
                                            CASH AMOUNT
                                        </div>
                                    )}
                                    {selectedPayment === "CASH" && (
                                        <div
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                            }}
                                            className={
                                                "font-title text-lg w-1/2 text-right sm:pr-9 flex flex-row justify-end sm:block items-center whitespace-nowrap"
                                            }
                                        >
                                            <input
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    borderColor: "#00A787",
                                                }}
                                                value={balanceAmount}
                                                // name="balanceAmt"
                                                autoComplete="nope"
                                                placeholder="0.00"
                                                type=""
                                                className={
                                                    defaultInputSmStyle +
                                                    " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                                                }
                                            />
                                        </div>
                                    )}


                                </div>
                            </div> */}
						</div>
					</form>
				</div>
				<div className="sticky top-[12px] right-0 w-full lg:w-2/12 pl-2 pr-4 sm:pr-2 mt-3 mx-4 md:mx-0 self-start xl:pb-20 sm:pb-40 xs:pb-40">
					<div style={{ fontFamily: "poppins", fontSize: "14px" }}>
						<button
							type="button"
							className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
							style={{ backgroundColor: "#008065" }}
							onClick={openChoosePreview}
						>
							Pre View
						</button>
					</div>
					<div
						style={{ fontFamily: "poppins", fontSize: "14px" }}
						className="mt-2"
					>
						<button
							className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
							style={{ backgroundColor: "#008065" }}
							onClick={handlePrint}
						>
							Print
						</button>
					</div>
					<div
						style={{ fontFamily: "poppins", fontSize: "14px" }}
						className=" w-full text-right mt-2 "
					>
						<button
							// size="sm"
							type="submit"
							className="rounded-xl font-bold text-white  flex flex-row-reverse flex-row items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full "
							style={{ backgroundColor: "#008065" }}
							onClick={(e: any) => handleSubmit(e)}
						>
							<svg
								className="pr-2 text-white"
								xmlns="http://www.w3.org/2000/svg"
								width="30"
								height="30"
								viewBox="0 0 20 20"
								fill="white"
							>
								<mask
									id="mask0_278_27"
									style={{ maskType: "alpha" }}
									maskUnits="userSpaceOnUse"
									x="0"
									y="0"
									width="20"
									height="20"
								>
									<rect width="20" height="20" fill="white" />
								</mask>
								<g mask="url(#mask0_278_27)">
									<path
										d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18V15H6V18H18V15H20V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6Z"
										fill="white"
									/>
								</g>
							</svg>
							{/* {params.id !== "new" ? "Update" : "Download"} */}
							Download
						</button>
					</div>
					<div
						style={{ fontFamily: "poppins", fontSize: "14px" }}
						className=" w-full text-right mt-2 "
					>
						<button
							type="button"
							className="rounded-xl font-bold text-white  flex flex-row-reverse  items-center justify-center text-sm sm:text-sm md:text-sm lg:text-xs xl:text-sm 2xl:text-sm h-8 px-2  block w-full whitespace-nowrap"
							style={{ backgroundColor: "#008065" }}
							onClick={(e: any) => handleSectionOpen(e)}
						>
							<svg
								className="pl-2"
								width="30"
								height="30"
								viewBox="0 0 20 20"
								fill="white"
								xmlns="http://www.w3.org/2000/svg"
							>
								<mask
									id="mask0_278_36"
									style={{ maskType: "alpha" }}
									maskUnits="userSpaceOnUse"
									x="0"
									y="0"
									width="23"
									height="23"
								>
									<rect
										y="12.0752"
										width="16"
										height="16"
										transform="rotate(-49 0 12.0752)"
										fill="white"
									/>
								</mask>
								<g mask="url(#mask0_278_36)">
									<path
										d="M11.3749 19.3134L3.32462 12.3154L15.6598 6.25476L11.3749 19.3134ZM10.7402 16.995L13.4073 8.84593L5.70878 12.6213L7.46977 14.1521L10.8487 11.7893L8.97919 15.4642L10.7402 16.995Z"
										fill="white"
									/>
								</g>
							</svg>
							Send Email
						</button>
					</div>

					<div className="flex flex-row items-center justify-center w-full mx-2">
						<div className="mr-6">
							<FormControlLabel
								className="text-md font-bold"
								label="Tax"
								labelPlacement="start"
								sx={{ fontFamily: "poppins", fontWeight: 700 }}
								control={
									<Switch
										disabled={id ? true : false}
										checked={isToggledForTax}
										onChange={handleTaxToggleChange}
										sx={{
											"& .MuiSwitch-track": {
												backgroundColor: isToggledForTax
													? "#134E4A"
													: "#B0B0B0",
											},
											"& .MuiSwitch-thumb": {
												color: isToggledForTax ? "#008065" : "#808080",
											},
										}}
									/>
								}
							/>
						</div>
					</div>
				</div>
			</div>

			{openmail && (
				<Transition.Root show={openmail} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						initialFocus={cancelButtonRef}
						onClose={setOpenMail}
					>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>
						<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
							<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
								<Transition.Child
									as={Fragment}
									enter="ease-out duration-300"
									enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
									enterTo="opacity-100 translate-y-0 sm:scale-100"
									leave="ease-in duration-200"
									leaveFrom="opacity-100 translate-y-0 sm:scale-100"
									leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								>
									<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
										<SendEmailModel
											closeModal={closeModal}
											handleChange={handleChange}
											formData={formData}
											setFormData={setFormData}
											handleSendEmail={handleSendEmail}
											sendEmailErrors={sendEmailErrors}
											errorEmail={errorEmail}
											setErrorEmail={setErrorEmail}
										/>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			)}
			{confirmDownload && (
				<ConfirmationDownload
					onDownload={downloadInvoice}
					onSaveDownload={saveAndDownloadInvoice}
					onCancel={handleCancel}
				/>
			)}
			{showConfirmation && (
				<PaymentUpdateModel
					message="Are you sure you want to proceed?"
					onCancel={handleCancelForPaymentType}
				/>
			)}
			<Transition.Root show={viewOpen} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-50"
					initialFocus={cancelButtonRef}
					onClose={setOpenView}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:mx-8 lg:w-10/12 md:w-11/12 ">
									{isToggledForTax ? (
										<ExpenseTaxPreview
											Invoice={formData}
											componentRef={componentRef}
											closeModal={closeModal}
											selectedFont={selectedFont}
											roundOffEnable={roundOffEnable}
											amountInWords={amountInWords}
										/>
									) : (
										<>
											<ExpensePreview
												Invoice={formData}
												componentRef={componentRef}
												closeModal={closeModal}
												selectedFont={selectedFont}
												roundOffEnable={roundOffEnable}
												amountInWords={amountInWords}
											/>
										</>
									)}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
export default ExpensesPage;
