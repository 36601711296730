/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currencyGet } from "../../redux/actions/currencyAction";

import ExpensesSvg from "../../Svg/ExpensesSvg";
import ProductSvg from "../../Svg/ProductSvg";
import Purchase from "../../Svg/Purchase";
import Revenue from "../../Svg/Revenue";
import SalesSvg from "../../Svg/SalesSvg";

// type SelectedRange = { range: string; startDate: string; endDate: string };

interface DashboardWidgetsProps {
	// selectedRange: SelectedRange;
	totalRevenue: Number;
	totalSlesWithTax: Number;
	totalPurchaseRevenue: Number;
	directExpenses: Number;
	stockAmount: Number;
}
const DashboardWidgets: React.FC<DashboardWidgetsProps> = ({
	// selectedRange,
	totalRevenue,
	totalSlesWithTax,
	totalPurchaseRevenue,
	directExpenses,
	stockAmount,
}) => {
	const dispatch = useDispatch();

	const usersignin = useSelector((state: any) => state.userSignin);
	const token = usersignin?.userInfo?.token;

	//

	const [currencySymbol, setcurrencySymbol] = useState("₹");
	useEffect(() => {
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
	}, []);

	return (
		<div className="font-poppins">
			<div className="grid grid-cols-1 gap-5 sm:gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-5 mt-6 ml-2">
				<div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-full">
					<div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
						<div className="rounded-lg p-2.5  bg-[#dcfce7] dark:bg-navy-700">
							<span className="flex items-center dark:text-white">
								<SalesSvg />
							</span>
						</div>
					</div>
					<div className="h-50 ml-4 flex w-auto flex-col justify-center">
						<p className="text-green-600 text-sm font-bold">Sales</p>
						<h4 className="text-md text-navy-700 dark:text-white w-[150px] md:w-[250px] lg:w-[138px] xl:w-[108px] 2xl:w-[150px] overflow-x-auto  whitespace-nowrap hide-scrollbar">
							{`${currencySymbol}${totalSlesWithTax.toFixed(2)}`}
						</h4>
					</div>
				</div>

				<div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-full ">
					<div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
						<div className="rounded-lg bg-[#fee2e2] p-1.5 dark:bg-navy-700">
							<span className="flex items-center  dark:text-white ">
								<Purchase />
							</span>
						</div>
					</div>
					<div className="h-50 ml-4 flex w-auto flex-col justify-center">
						<p className="text-green-600 text-sm font-bold">Purchase </p>
						<h4 className="text-md text-navy-700 dark:text-white w-[150px] md:w-[250px] lg:w-[138px] xl:w-[108px] 2xl:w-[150px] overflow-x-auto  whitespace-nowrap hide-scrollbar">
							{`${currencySymbol}${totalPurchaseRevenue.toFixed(2)}`}
						</h4>
					</div>
				</div>

				<div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-full">
					<div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
						<div className="rounded-lg bg-[#fef3c7]  dark:bg-[red]-700">
							<span className="flex items-center  dark:text-white">
								<Revenue />
							</span>
						</div>
					</div>
					<div className="h-50 ml-4 flex w-auto flex-col justify-center">
						<p className="text-green-600 text-sm font-bold">Total Revenue</p>
						<h4 className="text-md text-navy-700 dark:text-white w-[150px] md:w-[250px] lg:w-[138px] xl:w-[108px] 2xl:w-[150px] overflow-x-auto  whitespace-nowrap hide-scrollbar">
							{" "}
							{`${currencySymbol}${totalRevenue.toFixed(2)}`}
						</h4>
					</div>
				</div>

				<div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-full">
					<div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
						<div className="rounded-lg bg-[#ede9fe] dark:bg-navy-700">
							<span className="flex items-center  dark:text-white">
								<ExpensesSvg />
							</span>
						</div>
					</div>
					<div className="h-50 ml-4 flex w-auto flex-col justify-center">
						<p className="text-green-600 text-sm font-bold">Total Expenses</p>
						<h4 className="text-md text-navy-700 dark:text-white w-[150px] md:w-[250px] lg:w-[138px] xl:w-[108px] 2xl:w-[150px] overflow-x-auto  whitespace-nowrap hide-scrollbar">
							{`${currencySymbol}${directExpenses.toFixed(2)}`}
						</h4>
					</div>
				</div>

				<div className="relative flex flex-grow !flex-row flex-col items-center rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none w-full">
					<div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
						<div className="rounded-lg bg-[#ffedd5] p-1.5 dark:bg-navy-700">
							<span className="flex items-center  p-1.5 dark:text-white">
								<ProductSvg />
							</span>
						</div>
					</div>
					<div className="h-50 ml-4 flex w-auto flex-col justify-center">
						<p className="text-green-600 text-sm font-bold">
							Stock Value
							<span
								className="text-green-600 font-bold"
								style={{ fontSize: "0.65rem" }}
							>
								(Today)
							</span>
						</p>
						<h4 className="text-md text-navy-700  dark:text-white w-[150px] md:w-[250px] lg:w-[138px] xl:w-[108px] 2xl:w-[150px] overflow-x-auto  whitespace-nowrap hide-scrollbar">
							{`${currencySymbol}${stockAmount.toFixed(2)}`}
						</h4>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashboardWidgets;
