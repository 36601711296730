import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { expenseAll, expenseCategoryFindOne, expenseDelete, expenseSave, expenseUpdate } from '../../redux/actions/expenseAction';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import ConfirmationPopup from '../commen/ConfirmationPopup';
import { useNavigate, useParams } from 'react-router-dom';

function ExpenseCategory() {
    const User_id = sessionStorage.getItem("userInfo");
    const userId = JSON.parse(User_id as any);
    const params = useParams();
    const { id } = params;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const usersignin = useSelector((state: any) => state?.userSignin);
    const { userInfo } = usersignin;
    // const userid = userInfo.token._id;
    const {
        register,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            expense_Category: "",
            expense_type: "",
            rate: "",
            user_id: userId,
        },
    });

    // const dispatch = useDispatch();
    const [expenseList, setExpenseList] = useState([] as any)
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [expenseID, setExpenseID] = useState(null as any)
    const [searchValue, setSearchValue] = useState([] as any);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sortOrder, setSortOrder] = useState<string>("asc");

/*     const fetchData = () => {
        dispatch(expenseAll() as any).then((res: any) => {
            setExpenseList(res.payload);
            setSearchValue(res.payload);
        });
    }; */

      const fetchData = () => {
        dispatch(expenseAll(userId?.token?._id) as any).then((res: any) => {
          if (res) {
            setExpenseList(res.payload);
            setSearchValue(res.payload);
          }
        });
      };

    const deleteExpense = (data: any) => {
        setShowConfirmation(true);
        setExpenseID(data._id?.$oid);
    };
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (data: any) => {
        if (id) {
            dispatch(expenseUpdate(id, data) as any).then((res: any) => {
                // eslint-disable-next-line eqeqeq
                if (res.type == "EXPENSE_UPDATE_SUCCESS") {
                    fetchData();
                    setExpenseID("");
                    let value = {
                        expense_Category: "",
                        expense_type: "",
                        rate: ""
                    };
                    reset({
                        ...value,
                    });
                }
                toast.success("Update SuccessFully !", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 800,
                });
                navigate("/app/expenseCategoryReport");
            });
        } else {
            dispatch(expenseSave(data) as any).then((res: any) => {
                if (res.type === "EXPENSE_POST_SUCCESS") {
                    let value = {
                        expense_Category: "",
                        expense_type: "",
                        rate: ""
                    };
                    reset({
                        ...value,
                    });
                    fetchData()
                    toast.success("Saved Successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });
                    navigate("/app/expenseCategoryReport");
                }
            });
        }
    };

    const editExpense = (data: any) => {
        setExpenseID(data._id.$oid);

        let value = {
            expense_Category: data.expense_Category,
            expense_type: data.expense_type,
            rate: data.rate,
        };

        reset({
            ...value,
        });
    };

    const handleConfirm = () => {
        dispatch(expenseDelete(expenseID) as any).then((res: any) => {
            if (res) {
                fetchData();
                setExpenseID(null);
                setShowConfirmation(false);
                let value = {
                    expense_Category: "",
                    expense_type: "",
                    rate: ""
                };
                reset({
                    ...value,
                });
            }
        });
    };

    useEffect(() => {
        if (!id) {
          fetchData();
        } else {
          dispatch(expenseCategoryFindOne(id) as any).then((res: any) => {
            // console.log("res---->", res);
            if (res?.type === "EXPENSE_FIND_ONE_SUCCESS") {
              setValue("expense_Category", res?.payload?.expense_Category);
              setValue("expense_type", res?.payload?.expense_type);
              setValue("rate", res?.payload?.rate);
              setValue("user_id", res?.payload?.user_id || userId?.token?._id);
            }
          });
        }
      }, [id]);

    const handleCancel = () => {
        setShowConfirmation(false);
    };
    const handleCancelClick = () => {
        setExpenseID(false);

        let value = {
            expense_Category: "",
            expense_type: "",
            rate: ""
        };
        reset({
            ...value,
        });
    };

    return (
        <div className="bg-[#E1E8E7] pr-3">
            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset className="mx-3 mb-4 rounded-lg">
                    <legend className="p-4 font-bold text-[#008065] text-lg ml-2">
                        Expense Category
                    </legend>


                    <div className="flex flex-col sm:flex-row sm:flex-row xl:flex-row gap-2 px-4 mb-3 mt-3">
                        <div className="flex flex-col mb-2 w-full md:w-full  lg:w-1/3 xl:w-1/4  sm:px-0">
                            <label
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                }}
                                className="text-[#008065] required"
                            >
                                <span>Expense Category</span>
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    /* className={
                                        "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                    } */
                                    className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full +
                                        ${ errors.expense_Category ? 'border-[#FF0000]' : 'border-[#949494]'}
                                         ${! errors.expense_Category && 'focus:border-[#07ad7b]'}`}
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "13px",
                                        fontWeight: 400,
                                        // borderColor: errors.expense_Category
                                        //     ? "#FF0000"
                                        //     : "#008065",
                                    }}
                                    placeholder="Expense Category..."
                                    {...register("expense_Category", {
                                        required: "Expense Category is required",
                                        validate: (value) => {
                                            const isDuplicate = expenseList.some(
                                                (item: any) =>
                                                    item.expense_Category?.toLowerCase() === value.toLowerCase() &&
                                                    item._id.$oid !== id
                                            );
                                            return isDuplicate ? "This expense category already exists" : true;
                                        },
                                    })}

                                />
                                {errors.expense_Category && (
                                    <p style={{
                                        fontFamily: "poppins",
                                        fontWeight: 700,
                                        color: 'red',
                                    }}
                                        className="text-red-400 text-sm">
                                        {errors.expense_Category.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col mb-2 w-full md:w-full  lg:w-1/3 xl:w-1/4 px-0  sm:px-0 ">

                            <label
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                    color: "#008065",
                                }}
                                className="text-[#008065] required"
                            >
                                <span>Expense Type</span>
                            </label>
                            <select
                                // className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 lg:w-full"
                                className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full +
                                    ${ errors.expense_type ? 'border-[#FF0000]' : 'border-[#949494]'}
                                     ${!errors.expense_type && 'focus:border-[#07ad7b]'}`}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                    fontWeight: 400,
                                    /* borderColor: errors.expense_type
                                        ? "#FF0000"
                                        : "#008065", */
                                }}
                                {...register("expense_type", {
                                    required: "Expense Type is required",
                                })}

                            >
                                <option value="" disabled hidden>
                                    Select Expense Type
                                </option>
                                <option value="Direct Expense">Direct Expense</option>
                                <option value="Indirect Expense">Indirect Expense</option>
                            </select>
                            {errors.expense_type && (
                                <p
                                    style={{
                                        fontFamily: "poppins",
                                        fontWeight: 700,
                                        color: "#ff0000",
                                    }}
                                    className="text-red-400 text-sm"
                                >
                                    {errors.expense_type.message}
                                </p>
                            )}
                        </div>

                    </div>
                    <div className="flex w-full flex-col sm:flex-row px-4 sm:justify-end justify-center mb-3">
                            {/* <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                                <div className="flex flex-col  mb-4 "></div>
                            </div>

                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                                <div className="flex flex-col  mb-4 "> </div>
                            </div> */}

                            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5 self-center w-full md:w-auto">
                                <div className="flex flex-col flex-1 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40  rounded w-full">
                                    <button
                                        type="button"
                                        className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                                        style={{
                                            backgroundColor: "#008065",
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                        }}
                                        onClick={handleCancelClick}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>

                            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5 self-center w-full md:w-auto">
                                <div className="flex flex-col flex-1 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40 rounded w-full">
                                    <button
                                        // onClick={(e: any) => handleSubmit(e)}
                                        className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                                        style={{
                                            backgroundColor: "#008065",
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {id ? "Update" : "Save"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    {/* </div> */}

                </fieldset>
            </form>
            {showConfirmation && (
                <ConfirmationPopup
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
};

export default ExpenseCategory;