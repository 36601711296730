import { toWords } from "number-to-words";
import NumberFormat from "../commen/NumberFormat";
import { useDispatch, useSelector } from "react-redux";
import CalculateIndianFormattotal from "../commen/CalculateIndianFormattotal";
import { AppSignGet } from "../../redux/actions/appSettingsAction";
import { useEffect, useState } from "react";

const QuotationPreviewService = ({
  ServiceInvoice,
  componentRef,
  closeModal,
  selectedFont,
  roundOffEnable,
  amountInWords,
}: any) => {
  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  const totalAmount = ServiceInvoice.toatalAmount;
  const roundedTotalAmount = Math.round(totalAmount);
  const totalAmountInWords = Number.isFinite(roundedTotalAmount)
    ? amountInWords === "International Format"
      ? toWords(roundedTotalAmount) + " Only"
      : "Rupees " + CalculateIndianFormattotal(roundedTotalAmount) + " Only"
    : "Invalid amount";
    const [signlist, setsignlist] = useState([] as any);
    const dispatch = useDispatch();
      const usersignin = useSelector((state: any) => state.userSignin);
      const {
        userInfo: { token },
      } = usersignin;
    
          const fetchData = async () => {
            try {
              const signResponse = await dispatch(AppSignGet(token?._id) as any);
              if (signResponse) {
                setsignlist(signResponse.payload);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
          // console.log("logoimage",logoimage)
          useEffect(() => {
            fetchData();
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, []);
        
        const ComList = signlist
        ?.filter((item: any) => {
          return item._id.$oid === ServiceInvoice.seal;
        })
        ?.map((item: any) => {
          return {
            sealImage: item.sealImage,
            signImage: item.signImage,
          };
        });
  const handleCancelClick = () => {
    closeModal();
  };

  return (
    <div content={componentRef} style={{ fontFamily: selectedFont }}>
      <div style={{ breakBefore: "page" }}></div>
      {/*   image and client information                */}

      <div className="grid grid-cols-6">
        <div className="col-start-1 col-end-2">
          {ServiceInvoice.imageData ? (
            <div className=" font-title  sm:text-left ">
              <label
                style={{ fontFamily: selectedFont }}
                className="   items-start  text-blue      "
              >
                <img
                  className="pr-0 sm:pr-8 md:pr-8 pt-7 h-20 2xl:ml-7 xl:ml-7 lg:ml-7 md:ml-7 sm:ml-4 ml-2"
                  src={ServiceInvoice.imageData}
                  alt="logonew "
                />
              </label>
            </div>
          ) : null}
        </div>

        <div className="col-start-2 col-span-4 ">
          {ServiceInvoice.companyName ? (
            <div className=" text-center  pt-7 text-lg font-semibold  sm:font-normal sm:text-4xl ">
              {ServiceInvoice.companyName}
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="capitalize text-center">
          <div className="mt-1">
            <span className="p-1 break-words whitespace-normal">
              {ServiceInvoice.companyAddress ? (
                <span className="inline-block">Address: {ServiceInvoice.companyAddress},</span>
              ) : null}
              {ServiceInvoice.companyCity ? (
                <span className="inline-block">{ServiceInvoice.companyCity},</span>
              ) : null}
              {ServiceInvoice.companyState ? (
                <span className="inline-block">{ServiceInvoice.companyState}-</span>
              ) : null}
              {ServiceInvoice.companyPostalCode ? (
                <span className="inline-block">{ServiceInvoice.companyPostalCode},</span>
              ) : null}
            </span>
          </div>
          <div>
            <span className="flex flex-col sm:flex-row justify-center">
              {ServiceInvoice.companygst ? (
                <span>
                  Gstn:
                  {ServiceInvoice.companygst},
                </span>
              ) : null}
              {ServiceInvoice.companyPan ? (
                <span>PAN: {ServiceInvoice.companyPan},</span>
              ) : null}
            </span>
            <span className="flex flex-col sm:flex-row justify-center items-center">
              {ServiceInvoice.companyEmail ? (
                <span className="flex items-center mr-1 pl-0">
                  <span className="whitespace-nowrap">Email:</span>
                  <span className="lowercase  sm:w-full w-[190px] overflow-x-auto sm:overflow-visible whitespace-nowrap hide-scrollbar pl-1">
                    {ServiceInvoice.companyEmail},
                  </span>
                </span>
              ) : null}
              {ServiceInvoice.companyMobile ? (
                <span className="flex items-center">
                  <span className="whitespace-nowrap">Mobile:</span>
                  <span className="ml-1">{ServiceInvoice.companyMobile}</span>
                </span>
              ) : null}
            </span>
          </div>
        </div>
      </div>
      <div className="border "></div>
      {/*        *******************   invoice name                */}

      <div className="bg-white  overflow-x-auto" id="InvoiceWrapper">
        <div className="justify-center flex flex-row relative ">
          <span
            className="w-64 md:w-11/12 lg:w-11/12 2xl:w-11/12 truncate rounded-[7px] text-md sm:text-2xl text-center  focus:outline-none"
            style={{
              fontFamily: selectedFont,
              fontWeight: 700,
            }}
          >
            {ServiceInvoice.invoicehead}
          </span>
        </div>
        {/*     client details           */}
        <div className=" px-4 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between ">
          <div className="flex-1 sm:mx-2 column-reverse">
            <div className="flex flex-col sm:flex-row pt-3 gap-2 justify-between pre-reverse">
              <div className="lg:w-[90%] xl:w-[60%] 2xl:w-[60%] sm:w-[70%] ">
                <div className="flex flex-row  items-center mb-2 ">
                  <div
                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    style={{
                      color: "#008065",
                      fontFamily: selectedFont,

                      fontWeight: 700,
                    }}
                  >
                    Client Details (Shipped To)
                  </div>
                </div>
                {ServiceInvoice.clientName ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Name
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%] sm:w-[70%] w-1/2 ">
                        <input
                          className="focus:outline-none  text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={ServiceInvoice.clientName}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {ServiceInvoice.clientAddress ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Address
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%] sm:w-[70%] w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={ServiceInvoice.clientAddress}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {ServiceInvoice.clientCity ? (
                  <>
                    <div className="flex justify-between">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        City
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]   sm:w-[70%]  w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={ServiceInvoice.clientCity}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {ServiceInvoice.clientState ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        State
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%] w-1/2 ">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={ServiceInvoice.clientState}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {ServiceInvoice.clientPostalCode ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left whitespace-nowrap text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Postal Code
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%]  w-1/2">
                        <input
                          className="focus:outline-none text-xs w-full lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                          readOnly
                          value={ServiceInvoice.clientPostalCode}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {ServiceInvoice.clientMobile ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs whitespace-nowrap lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4 "
                      >
                        Mobile
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%]  w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={ServiceInvoice.clientMobile}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {ServiceInvoice.clientEmail ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Email
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%]  w-1/2 ">
                        <input
                          className="focus:outline-none  text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={ServiceInvoice.clientEmail}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {ServiceInvoice.clientPan ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs whitespace-nowrap lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        PAN Number
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%]  w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={ServiceInvoice.clientPan}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {ServiceInvoice.clientgst ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md  w-1/2 sm:w-1/4"
                      >
                        GST
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%]  w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={ServiceInvoice.clientgst}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="">
                <div className="flex flex-row items-center justify-between mb-2 ">
                  <div
                    style={{
                      color: "#008065",
                      fontFamily: selectedFont,

                      fontWeight: 700,
                    }}
                    className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Quotation No
                  </div>
                  <div className="font-title flex-1 text-right ">
                    <input
                      readOnly
                      value={ServiceInvoice.invoiceNo}
                      style={{
                        fontFamily: selectedFont,
                      }}
                      className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full  sm:ml-auto block focus:outline-none "
                    />
                  </div>
                  <div></div>
                </div>
                {ServiceInvoice.invoicedate ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2  ">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                        className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                      >
                        Quotation Date
                      </div>
                      <div className="font-title flex-1 text-right">
                        <input
                          readOnly
                          value={ServiceInvoice.invoicedate}
                          style={{
                            fontFamily: selectedFont,
                          }}
                          className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full sm:ml-auto  focus:outline-none "
                        ></input>
                      </div>
                      <div></div>
                    </div>
                  </>
                ) : null}
                {ServiceInvoice.poNumber ? (
                  <>
                    <div className="flex flex-row items-center justify-between mb-2 ">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: "poppins",

                          fontWeight: 700,
                        }}
                        className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                      >
                        PO No
                      </div>
                      <div className="font-title flex-1 text-right">
                        <input
                          readOnly
                          value={ServiceInvoice.poNumber}
                          style={{
                            fontFamily: "poppins",
                          }}
                          className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full sm:ml-auto block focus:outline-none "
                        ></input>
                      </div>
                      <div></div>
                    </div>
                  </>
                ) : null}
                {ServiceInvoice.poDate ? (
                  <>
                      <div className="flex flex-row items-center justify-between mb-2">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                          className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                      >
                        PO Date
                      </div>
                      <div className="font-title flex-1 text-right">
                        <div>
                        <input
                          readOnly
                          value={ServiceInvoice.poDate}
                          style={{
                            fontFamily: selectedFont,
                          }}
                              className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full sm:ml-auto block focus:outline-none  "
                        ></input>
                      </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* Service details */}
        <div className="px-6 mt-3">
          <div className="hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-2 py-2 text-white bg-[#008065]">
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" sm:w-1/6 md:w-1/6 lg:w-2/12 xl:w-1/12 2xl:w-1/12 text-left "
            >
              <span className="inline-block">S No</span>
            </div>
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12 text-left "
            >
              <span className="inline-block">Service</span>
            </div>
            {userdata?.hsnNo ? (
              <div
                style={{
                  fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="w-full sm:w-1/4 text-left sm:text-center"
              >
                <span className="inline-block ">Hsn </span>
              </div>
            ) : (
              <></>
            )}
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" w-full sm:w-1/4  sm:text-right"
            >
              <span className="inline-block ">Rate</span>
            </div>
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="w-full sm:w-1/4 text-center sm:text-center   "
            >
              Qty
            </div>
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" w-full sm:w-1/4 text-right 2xl:text-right   "
            >
              Total
            </div>
          </div>
        </div>

        {ServiceInvoice?.product?.map((product: any, index: number) => {
          return (
            <div className="px-4 py-1 sm:px-8">
            <div
              key={`${index}_${product.id}`}
              className="flex lg:flex-row flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible md:flex-row  xl:flex-row flex-col rounded-lg w-full  items-center relative text-sm"
            >
              <div
                style={{
                  fontFamily: selectedFont,
                  fontWeight: 700,
                }}
                className="sm:pl-2 w-full  mb-1 sm:mb-0 sm:w-1/6 md:w-1/6 lg:w-2/12 xl:w-1/12 2xl:w-1/12 text-left flex flex-row sm:block"
              >
                <span
                  className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                  S No
                </span>
                <span className="inline-block w-full  product  ">
                  <input
                    readOnly
                    value={index + 1}
                    style={{
                      fontFamily: selectedFont,
                      fontWeight: 400,
                    }}
                    className="font-title block w-full sm:text-left text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  />
                </span>
              </div>
              <div
                style={{
                  fontFamily: selectedFont,
                  fontWeight: 700,
                }}
                className=" w-full   mb-1 sm:mb-0 sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12 text-left  flex flex-row  "
              >
                <span
                  className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                  Service
                </span>
                <span className="inline-block w-full product ">
                  <input
                    readOnly
                    value={product.service_name}
                    style={{
                      fontFamily: selectedFont,
                      fontWeight: 400,
                    }}
                    className="font-title  block w-full sm:text-left  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  />
                </span>
              </div>
              {userdata?.hsnNo ? (
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className=" w-full  mb-1 sm:mb-0 sm:w-1/4 text-left flex flex-row   "
                >
                  <span
                    className="sm:hidden w-full flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    HSN
                  </span>
                  <span className="inline-block w-full  sm:w-full ">
                    <input
                      readOnly
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                      value={product.hsn ? product.hsn : "N/A"}
                      className="font-title block w-full sm:text-center text-right focus:outline-none text-xs sm:text-sm  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
              ) : (
                <></>
              )}
              <div
                style={{
                  fontFamily: selectedFont,
                  fontWeight: 700,
                }}
                className="w-full mb-1 sm:mb-0  sm:w-1/4  sm:text-left flex flex-row  "
              >
                <span
                  className="sm:hidden w-full flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                  Rate
                </span>
                <span className="inline-block  w-full ">
                  <input
                    readOnly
                    style={{
                      fontFamily: selectedFont,
                      fontWeight: 400,
                    }}
                    value={product.rate}
                    className="font-title  block w-full  sm:text-right text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  />
                </span>
              </div>

              <div
                style={{
                  fontFamily: selectedFont,
                  fontWeight: 700,
                }}
                className=" w-full mb-1 sm:mb-0 sm:w-1/4 text-center sm:text-center  flex flex-row "
              >
                <span
                  
                  className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                >
                  Qty
                </span>
                <span className="inline-block  w-full ">
                  <input
                    style={{
                      fontFamily: selectedFont,
                      marginTop: "",
                      fontWeight: 400,
                    }}
                    readOnly
                    value={product?.qty}
                    type={"number"}
                    className="font-title text-right block w-full   sm:text-center  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  />
                </span>
              </div>

              <>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className=" w-full mb-1 sm:mb-0 sm:w-1/4 text-right flex flex-row"
                >
                  <span
                   
                    className="sm:hidden w-1/2  flex flex-row items-center text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Total
                  </span>
                  <span className="font-block w-full sm:w-full  md:w-full text-xs  sm:text-sm lg:w-full ">
                    <NumberFormat value={product.amount}></NumberFormat>
                  </span>
                </div>
              </>
            </div>
          </div>
          );
        })}

        {/********************************** * subtotal *******************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Subtotal
          </div>

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-block w-1/2 sm:w-1/4 text-right sm:pr-4 flex flex-row justify-end sm:block mb-1"
          >
            <NumberFormat
              value={ServiceInvoice.subtotal}

              //   displayType={"text"}
              //   thousandSeparator={true}
            />
          </div>
        </div>
        {/* ******************************cgstPercentage**************/}

        {ServiceInvoice?.tax?.map((tax: any, index: number) => {
          // Check if tax.totalamount is not zero and tax.taxName is not "GST"
          if (tax.taxAmount !== "0.00" && tax.taxName !== "GST") {
            return (
              <div key={index}>
                {/* Render each tax item with specific details */}
                <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center">
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className="font-title w-[60%] sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                  >
                    {tax.taxName}
                    {tax.taxName !== "Extra Fees" ? (
                      <span className="ml-2">@</span>
                    ) : (
                      ""
                    )}
                    {tax.taxName !== "Extra Fees" ? tax.taxPersent : ""}
                    {tax.taxName !== "Extra Fees" ? <span>%</span> : ""}
                  </div>

                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                    className="font-title w-1/2 sm:w-1/4 text-right sm:pr-4 flex flex-row justify-end sm:block mb-1"
                  >
                    <NumberFormat value={tax.taxAmount} />
                  </div>
                </div>

                {/* Similarly render other tax details */}
                {/* Example: SCGST, IGST, vat, OTHERS, extrafees, DISCOUNT */}
              </div>
            );
          }
          // Return null if conditions are not met
          return null;
        })}

        {roundOffEnable == true && (
         
            <div
              className={
                "flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center "
              }
            >
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={
                  "font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                }
              >
                Round Off
              </div>

              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={
                  "font-title w-1/2 sm:w-1/4 text-right sm:pr-4 flex flex-row justify-end sm:block mb-1"
                }
              >
                <NumberFormat value={ServiceInvoice.roundOff} />
              </div>
            </div>
        )}
        {/*************************total****************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Total
          </div>

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-4 flex flex-row justify-end sm:block mb-1"
          >
            <NumberFormat value={ServiceInvoice.toatalAmount} />
          </div>
        </div>

        {/*************************AMOUNT IN WORDS****************************/}
        <div className="flex flex-row sm:flex-row sm:justify-start rounded-lg sm:visible w-full  sm:px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-full  text-left pl-4 md:pl-5 lg:pl-4 flex flex-row sm:block mb-1 sm:mb-0 whitespace-normal md:whitespace-nowrap capitalize"
          >
            {totalAmountInWords}
          </div>
        </div>

        {/************************************* bank details *********************/}
        {ServiceInvoice?.bankNme ? (
          <>
            <div
              className="flex justify-between  ml-5 mr-5"
              style={{
                color: "#008065",
                fontFamily: selectedFont,

                fontWeight: 700,
              }}
            >
              <label className="block my-2 2xl:ml-3 xl:ml-3 md:ml-5  text-sm font-semibold text-(#008065)">
                BANK DETAILS:
              </label>
            </div>
            <div className=" 2xl:ml-5   2xl:mr-5  xl:ml-5   xl:mr-5  lg:ml-5   lg:mr-5 md:ml-5   md:mr-5">
              <div className="text md:flex  md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3">
                  <label
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    BankName:
                  </label>
                </div>

                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 text-base focus:outline-none "
                    type="text"
                    value={ServiceInvoice?.bankNme}
                  />
                </div>
              </div>

              <div className="text md:flex md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3">
                  <label
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Account HolderName:
                  </label>
                </div>
                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 text-base focus:outline-none "
                    type="text"
                    value={ServiceInvoice?.accounHolder}
                  />
                </div>
              </div>

              <div className="text md:flex md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3">
                  <label
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Account Number:
                  </label>
                </div>
                <div className="md:w-2/3 md:flex-grow ">
                  <input
                    readOnly
                    className="w-full h-10 px-3 text-base     focus:outline-none"
                    type="text"
                    value={ServiceInvoice?.accounNumber}
                  />
                </div>
              </div>
              <div className="text md:flex md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3">
                  <label
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    IFSC code:
                  </label>
                </div>
                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 text-base      focus:outline-none "
                    type="text"
                    value={ServiceInvoice.ifscCode}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {/***********************************UPI PAY*****************************************/}
        {ServiceInvoice.upiType ? (
          <>
            <div
              className="flex justify-between  ml-5 mr-5"
              style={{
                color: "#008065",
                fontFamily: selectedFont,

                fontWeight: 700,
              }}
            >
              <label className="block my-2 2xl:ml-3 xl:ml-3 md:ml-5  text-sm font-semibold text-(#008065)">
                UPI PAYMENT:
              </label>
            </div>
            <div className="2xl:ml-5   2xl:mr-5  xl:ml-5   xl:mr-5  lg:ml-5   lg:mr-5 md:ml-5   md:mr-5 ">
              <div className="text md:flex md:items-center mx-6  ">
                <div className="mb-1 md:mb-0 md:w-1/3">
                  <label
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    UPI PAY
                  </label>
                </div>

                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 focus:outline-none "
                    value={ServiceInvoice.upiType}
                  />
                </div>
              </div>

              <div className=" md:flex md:items-center mx-6 ">
                <div className="mb-1 md:mb-0 md:w-1/3">
                  <label
                    style={{
                      fontFamily: selectedFont,
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    UPI NUMBER
                  </label>
                </div>
                <div className="md:w-2/3 md:flex-grow">
                  <input
                    readOnly
                    className="w-full h-10 px-3 focus:outline-none "
                    value={ServiceInvoice.upiNumber}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
        {/**************************  term and condition ******************/}
        {ServiceInvoice.termsanddcon ? (
          <>
            <div
              className="flex justify-between  ml-5 mr-5 "
              style={{
                color: "#008065",
                fontFamily: selectedFont,

                fontWeight: 700,
              }}
            >
              <label className=" 2xl:ml-2 xl:ml-3 lg:ml-3 md:ml-3 text-base text-#008065 font-semibold">
                Terms and Conditions:
              </label>
            </div>
            <div className="2xl:ml-8 xl:ml-8 lg:ml-8 md:ml-8 ml-8 ">
              <p className="sm:text-wrap lg:text-wrap md:text-wrap text-sm break-words break-all mr-4">
                {ServiceInvoice.termsanddcon}
              </p>
            </div>
          </>
        ) : null}

        {/********************* notes  ***************************** */}

        {ServiceInvoice.notes ? (
          <>
            <div
              className="flex justify-between  ml-5 mr-5 "
              style={{
                color: "#008065",
                fontFamily: selectedFont,

                fontWeight: 700,
              }}
            >
              <label className=" 2xl:ml-2 xl:ml-3 lg:ml-3 md:ml-3 text-base text-#008065 font-semibold">
                Notes:
              </label>
            </div>
            <div className="2xl:ml-8 xl:ml-8 lg:ml-8 md:ml-8 ml-8 ">
              <p className="sm:text-wrap lg:text-wrap md:text-wrap text-sm break-words break-all mr-4">
                {ServiceInvoice.notes}
              </p>
            </div>
          </>
        ) : null}

        {/********************************signature*******************************/}
        {(ServiceInvoice?.signature || ServiceInvoice?.seal) ? (
            <div className="flex flex-row justify-between">
              <div className={ServiceInvoice?.signPostion === "R" ? "lg:order-2 xl:order-2 2xl:order-2 md:order-2 flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12 w-1/4 2xl:w-1/3 items-left pl-8 justify-center w-full h-40 rounded-lg mt-2  " : "flex flex-col lg:w-[48%] xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-left pl-8 w-full h-32  rounded-lg mt-2"}>
                {ServiceInvoice?.signature && (<p className="font-semibold text-[#008065] pt-2">Signature:</p>)}
                <div className="relative">
                  {ServiceInvoice?.signature ? (
                    <>
                      {ComList.length > 0
                        ? ComList.map((item: any, index: any) => (
                          <div key={index}>
                            <img
                              className="w-[230px] h-[80px] inline-block shrink-0 pb-2 "
                              src={item.signImage}
                              alt={`Signature ${index}`}
                            />
                          </div>
                        ))
                        : <div >
                        <img
                          className="w-[230px] h-[80px] inline-block shrink-0 pb-2 "
                          src={ServiceInvoice.signature}
                          alt='signature'
                        />
                      </div>}
                    </>
                  ) : (
                    <>
                    </>
                  )}
                </div>
              </div>
              <div className={ServiceInvoice?.sealPostion === "L" ? "lg:order-1 xl:order-1 2xl:order-1 md:order-1 flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/4 2xl:w-1/3 items-center pr-8 justify-center w-full h-40  mt-2" : " flex flex-col lg:w-1/2 xl:w-1/3 md:w-5/12 sm:w-1/3 2xl:w-1/3 items-center pr-8 justify-center w-full h-32 mt-2 "}>
                {ServiceInvoice?.seal && (<p className="font-semibold text-[#008065] pt-2">Seal:</p>)}
                <div className="relative">
                  <label
                    style={{ fontFamily: "poppins" }}
                    className="object-cover  pb-2 flex flex-col items-center px-4    "
                  >
                    {ServiceInvoice?.seal ? (
                      <>

                        {ComList.length > 0
                          ? ComList.map((item: any, index: any) => (
                            <div key={index}>
                              <img
                                className="w-[230px] h-[80px] inline-block shrink-0 pb-2 "
                                src={item.sealImage}
                                alt={`Seal ${index}`}
                              />
                            </div>
                          ))
                          : <div >
                          <img
                            className="w-[230px] h-[80px] inline-block shrink-0 pb-2 "
                            src={ServiceInvoice.seal}
                            alt='signature'
                          />
                        </div>}
                      </>
                    ) : (
                      <>
                      </>
                    )}
                  </label>
                </div>
              </div>
            </div>

          ) : null}
        {/********************************Cancel*********************************/}
        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            // onClick={() => setOpenView(!viewOpen)}
            // ref={cancelButtonRef}
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuotationPreviewService;
