// src/components/FontDropdown.tsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useData } from "../../layouts/shared/DataProvider";
import { fontFamilyDelete, fontFamilyGet, fontFamilySave, fontFamilyUpdate } from "../../redux/actions/FontFamilyAction";
import { FontFamilyTable } from "../../Tables/FontFamilyTable";
import ConfirmationPopup from "../commen/ConfirmationPopup";

const fontFamilies = [
  "Arial, sans-serif",
  "Courier New, monospace",
  "Georgia, serif",
  "Verdana, sans-serif",
  "Times New Roman, serif",
  "Trebuchet MS, sans-serif",
  "Times, serif",
];

const FontDropdown = () => {

  const { selectedFont, setSelectedFont }: any = useData();

  const [error, setError] = useState(false);

  const errorValidation = () => {
    if (!selectedFont) {
      setError(true);
    } else {
      setError(false);
    }
    return true;
  }


  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;

  const fontdetails = useSelector((state: any) => state.GetFontDetails);
  const { fontDetail } = fontdetails;


  const handleFontChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedFont(event.target.value);
    setError(false);
  };

  const [defaultFontInfo, setdefaultFontInfo] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const handleDefaultCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setdefaultFontInfo(e.target.checked);
  };

  const dispatch = useDispatch();

  const [fontId, setfontId] = useState(null as any);


  const handleedit = (data: any) => {
    setSelectedFont(data.fontfamily);
    setdefaultFontInfo(data.defaultInfo);
    setfontId(data._id.$oid);
    setError(false);
  }

  const handleConfirm = () => {
    dispatch(fontFamilyDelete(fontId) as any).then((res: any) => {
      if (res) {
        resetfont();
        fetchData();
        setShowConfirmation(false);
      }
    });
  };

  const handlePopupCancel = () => {
    setShowConfirmation(false);
  };

  const onDelete = (data: any) => {
    setShowConfirmation(true);
    setfontId(data._id?.$oid);
  }

  const handleCancel = () => {
    setSelectedFont('');
    setdefaultFontInfo(false);
    setError(false);
    setfontId(null);
  }

  const resetfont = () => {
    setdefaultFontInfo(false);
    setfontId(null);
    setSelectedFont('');
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let obj = {
      fontfamily: selectedFont,
      defaultInfo: defaultFontInfo,
    };
    if (errorValidation() && selectedFont) {
      if (fontId) {
        dispatch(fontFamilyUpdate(fontId, obj) as any).then((res: any) => {
          if (res.type === "FONTFAMILY_UPDATE_SUCCESS") {
            resetfont();
            fetchData();
          }
          toast.success("Update SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        })
      } else {
        dispatch(fontFamilySave(obj) as any).then((res: any) => {
          if (res.type === "FONTFAMILY_POST_SUCCESS") {
            resetfont();
            fetchData();
          }
          toast.success("Saved SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        });
      }
    }
  }

  const [fontFamilyList, setfontFamilyList] = useState([] as any)

  const fetchData = () => {
    dispatch(fontFamilyGet(token?._id) as any).then((res: any) => {
      if (res?.type === "FONTFAMILY_GET_SUCCESS") {
        setfontFamilyList(res.payload);
      }
    });
  };

  useEffect(() => {
    fetchData();
    setSelectedFont('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div>
      <form>
        <fieldset className=" mb-4 rounded-lg  h-auto mx-2">
          <legend className="p-4  font-bold text-[#045545]  text-lg   ml-2">
            Font Settings
          </legend>
          <div className="flex  gap-2 mb-3 px-4">
            <div>
              <input
                type="checkbox"
                name="defaultInfo"
                placeholder="defaultInfo"
                checked={defaultFontInfo}
                onChange={handleDefaultCheckboxChange}
                className={
                  "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                }
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              />
            </div>
            <div className="text-[#008065] font-semibold">
              <label>Default Font Family Info</label>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 px-4">
            <div>
              <label
                htmlFor="font-family-select"
                className="block mb-2 text-sm text-[#008065] font-semibold"
              >
                Select Font Family
              </label>
              <select
                id="font-family-select"
                value={selectedFont}
                onChange={handleFontChange}
                className={`block w-full p-2 bg-white border-2 rounded-md shadow-sm focus:outline-none sm:text-sm ${error
                  ? " border-[#FF0000]"
                  : "border-[#949494] focus:border-[#07ad7b]"
                  }`}
              >
                <option value="" disabled hidden>Please select</option>
                {fontFamilies.map((font) => (
                  <option key={font} value={font}>
                    {font}
                  </option>
                ))}
              </select>
              {error && <p style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 700,
                color: "red",
                marginLeft: "3px",
              }}>Font Family is required</p>}
            </div>
          </div>
          <div className="flex w-full flex-col sm:flex-row px-4 mb-3">
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
              <div className="flex flex-col mb-6 "></div>
            </div>

            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
              <div className="flex flex-col  ">
                <div className="relative"></div>
              </div>
            </div>

            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
              <div className="flex flex-col w-full sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40  rounded">
                <button
                  type="button"
                  className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                  style={{
                    backgroundColor: "#008065",
                    fontSize: "14px",
                    fontFamily: "poppins",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
              <div className="flex flex-col w-full sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40 rounded">
                <button
                  type="submit"
                  className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                  style={{
                    backgroundColor: "#008065",
                    fontSize: "14px",
                    fontFamily: "poppins",
                  }}
                  onClick={(e: any) => handleSubmit(e)}
                >
                  {fontId ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <FontFamilyTable
        fontFamilyList={fontFamilyList}
        setfontFamilyList={setfontFamilyList}
        fontDetail={fontDetail}
        edit={handleedit}
        deletefont={onDelete}
      />
      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handlePopupCancel}
        />
      )}
    </div>
  );
};

export default FontDropdown;
