
/* eslint-disable eqeqeq */
import { Button } from '@mui/material';
import { Dialog, Transition } from "@headlessui/react";
import moment from 'moment';
import { forwardRef, Fragment, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { IconStyle } from '../../commen/defaultStyles';
import ClientPlusIcon from '../../Icons/ClientPlusIcon';
import EmailIcon from '../../Icons/EmailIcon';
import MobileIcon from '../../Icons/MobileIcon';
import PlusCircleIcon from '../../Icons/PlusCircleIcon';
import ClientModel from '../../models/ClientModel';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ClientAll, ClientSave } from '../../../redux/actions/clientAction';
import { FiPlusCircle } from 'react-icons/fi';

type FieldErrors = {
    [key: string]: string; // Ensure errors are strings for rendering
};

function ClientDetails({
    handleChange, formData, setFormData, clientErrors, setClientErrors,
    invoiceDate, setInvoiceDate, billModules, poDate,
    setDueDate, setPoDate, dateFormat, currentDate, paramsId, dueDate
}: any) {
    const [addclientMobile, setAddClientMobile] = useState(false);
    const [addClientPan, setAddClientPan] = useState(false);
    const [addClientGst, setAddClientGst] = useState(false);
    const [addClientEmail, setAddClientEmail] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const template = useSelector((state: any) => state.userColorTemplate);
    const usersignin = useSelector((state: any) => state.userSignin);
    const { userInfo } = usersignin;
    const userid = userInfo.token._id;
    const [clientviewOpen, setClientOpenView] = useState(false)
    const { userdata } = template;
    const dispatch = useDispatch();
    const handleConfirm = () => {
        if (!paramsId) {
            setIsModalOpen(true)
        }

    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const clientMobile = (value: any) => {
        setAddClientMobile(!addclientMobile);
        if (value == "addtTrueClientno") {
            setFormData({ ...formData, clientMobile: "", isclientMobile: true, errors: { ...formData.errors, clientMobileErrorMessage: '' } })
        }
    };
    const handleClientAddPan = (value: any) => {
        setAddClientPan(!addClientPan);
        if (value == "addTrueClientPanNo") {
            setFormData({ ...formData, clientPan: "", isClientPan: true, errors: { ...formData.errors, clientPanErrorMessage: '' } })
        }
    };

    const handleClientAddEmail = (value: any) => {
        setAddClientEmail(!addClientEmail);
        if (value == "addTrueClientEmail") {
            setFormData({ ...formData, clientEmail: "", isClientEmail: true, errors: { ...formData.errors, clientEmailErrorMessage: '' } })
        }
    };

    const handleClientAddGst = (value: any) => {
        setAddClientGst(!addClientGst);
        if (value == "addTrueClientGst") {
            setFormData({ ...formData, clientgst: "", isClientGst: true, errors: { ...formData.errors, clientgstErrorMessage: '' } })
        }
    };

    const handleSelect = (data: any) => {
        clientErrors.clientName = null;
        setFormData({
            ...formData,
            clientName: data.client_name,
            clientAddress: data.client_address,
            clientCity: data.city,
            clientState: data.state,
            clientPostalCode: data.postal_code,
            clientMobile: data.client_mobile,
            clientEmail: data.client_email,
            clientPan: data.pan_no,
            clientgst: data.gst,
            clientId: data._id?.$oid,
            isClientGst: true,
            isClientPan: true,
            isClientEmail: true,
            isclientPostalCode: true,
            isclientMobile: true,
            errors: { ...formData.errors, clientgstErrorMessage: '', clientEmailErrorMessage: '', clientPanErrorMessage: '', clientMobileErrorMessage: '', clientPostalCodeErrorMessage: "" }
        });


        setIsModalOpen(false);
        setAddClientEmail(Boolean(data.client_email))
        setAddClientMobile(Boolean(data.client_mobile))
        setAddClientPan(Boolean(data.pan_no))
        setAddClientGst(Boolean(data.gst))

    };

    useEffect(() => {
        formData.clientEmail && setAddClientEmail(true)
        formData.clientMobile && setAddClientMobile(true)
        formData.clientPan && setAddClientPan(true)
        formData.clientgst && setAddClientGst(true)
    }, [formData.clientEmail, formData.clientMobile, formData.clientPan, formData.clientgst, formData.shipToAddress, formData.shipToName])

    const CustomInputWithIcon = forwardRef(({ value, onClick }: any, ref: any) => (
        <div className='flex items-center border-2 border-[#949494] focus:border-[#07ad7b] group focus-within:outline-none focus-within:border-[#07ad7b] focus:outline-none rounded-[7px] px-1 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12'>
            <input
                type="text"
                value={value}
                readOnly
                className={"w-full pl-1 sm:px-2 focus:outline-none"}
                style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                }}
            />
            <FaCalendarAlt
                className="sm:mr-2 cursor-pointer  "
                onClick={onClick}
            />
        </div>
    ));

    const CustomDueDate = ({ value, onClick }: any) => (
        <div className='relative'>
            <input
                type="text"
                value={value}
                readOnly
                className={"border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12 "}
                style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                    borderColor: "#008065",
                }}
            />
            <FaCalendarAlt
                className="absolute right-0 top-0 mt-2 mr-1 cursor-pointer lg:right-0 lg:mr-0 xl:right-12 xl:mr-0 2xl:right-8 2xl:mr-4 md:mr-2 lg:mr-2 "
                onClick={onClick}
            />
        </div>
    );
    const handleDateChange = (date: any) => {
        const formattedDate = moment(date).format(dateFormat);
        if (formattedDate !== formData.invoicedate) {
            setInvoiceDate(date);
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                invoicedate: formattedDate,
                // invoicedate: formattedDate,
            }));
        }
    };
    const handleDueDateChange = (date: any) => {
        const formattedDate = moment(date).format(dateFormat);
        if (formattedDate !== formData.duedate) {
            setDueDate(date);
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                duedate: formattedDate,
            }));
        }
    };

    const [clientList, setClientList] = useState([] as any);
    const fetchData = () => {
        dispatch(ClientAll(userid) as any).then((res: any) => {
            if (res) {
                setClientList(res.payload);
            }
        });
    };

    useEffect(() => {
        if (userid) {
            fetchData();
        }
    }, []);

    const { register, reset, setValue, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            client_name: "",
            client_email: "",
            state: "",
            city: "",
            postal_code: "",
            client_mobile: "",
            client_address: "",
            pan_no: "",
            gst: "",
            user_id: userid,
        },
    });

    const handleOpen = (e: any) => {
        e.preventDefault()
        setClientOpenView(true)
    };
    const handleClose = () => {
        setFieldErrors({})
        reset();
        setClientOpenView(false);
    };
    const onSubmit = async (data: any) => {

        if (
            !fieldErrors.postal_code &&
            !fieldErrors.pan_no &&
            !fieldErrors.client_mobile &&
            !fieldErrors.gst
        ) {
            try {
                const response: any = await dispatch(ClientSave(data) as any);

                if (response && response.clientId) {
                    setClientErrors({});
                    setFormData((prevFormData: any) => ({
                        ...prevFormData,
                        clientName: data.client_name,
                        clientAddress: data.client_address,
                        clientCity: data.city,
                        clientState: data.state,
                        clientPostalCode: data.postal_code,
                        clientMobile: data.client_mobile,
                        clientEmail: data.client_email,
                        clientPan: data.pan_no,
                        clientgst: data.gst,
                        clientId: response.clientId,
                    }));
                    reset();
                    setClientOpenView(false);
                    setFieldErrors({});
                }
            } catch (error) {
                console.error("Error saving client:", error);
            }
        }
    };



    const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
    const emailRegex =
        /^(?!.*\.\.)(?!(?:[a-zA-Z0-9._%+-]{1,4}@gmail\.com$))[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        let newValue = value.trimStart();
        const fieldName = name as keyof FieldErrors;


        let error: any = "";
        let isValidEmail = true;

        /********* Validation logic ************/
        // Format validation
        if (fieldName === "postal_code" && !value.match(/^[0-9]{6}$/) && value) {
            error = "Invalid postal code";
        } else if (
            fieldName === "client_mobile" &&
            !value.match(/^(?:[0-9]{10}|[0-9]{13})$/) &&
            value
        ) {
            error = "Invalid Mobile No";
        } else if (
            fieldName === "client_email" && newValue) {

            newValue = newValue.toLowerCase();
            isValidEmail = emailRegex.test(newValue);
            error = isValidEmail ? "" : "Invalid Email Address";
        }
        else if (
            fieldName === "pan_no" &&
            value
        ) {
            const cursorPosition = e.target.selectionStart;
            const formattedValue = value.toUpperCase();
            e.target.value = formattedValue;
            if (!formattedValue.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)) {
                error = "Invalid PAN number";
            }
            setValue("pan_no", formattedValue);
            e.target.selectionStart = e.target.selectionEnd = cursorPosition;
        }
        else if (
            fieldName === "gst" &&
            !value.match(
                /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
            ) &&
            value
        ) {
            setValue("gst", value.toUpperCase());
            error = "Invalid GST number";
        }

        // Duplicate check (only if no format error exists)
        if (!error) {
            if (fieldName === "client_email" && value) {
                const duplicate = clientList.some(
                    (client: any) => client.client_email === value
                );
                if (duplicate) error = "This email is already saved";
            }

            if (fieldName === "client_mobile" && value) {
                const duplicate = clientList.some(
                    (client: any) => client.client_mobile === value
                );
                if (duplicate) error = "This mobile number is already saved";
            }

            if (fieldName === "pan_no" && value) {
                const duplicate = clientList.some(
                    (client: any) => client.pan_no === value
                );
                if (duplicate) error = "This PAN number is already saved";
            }

            if (fieldName === "gst" && value) {
                const duplicate = clientList.some(
                    (client: any) => client.gst === value
                );
                if (duplicate) error = "This GST number is already saved";
            }
        }

        // Update fieldErrors state
        setFieldErrors((prevErrors: any) => ({
            ...prevErrors,
            [fieldName]: error,
        }));
    };


    return (
        <>
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
                {/* First Row: Client Details */}
                <div
                    className="flex items-center justify-center md:justify-start whitespace-nowrap mt-1 pl-2"
                    style={{
                        color: "#008065",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 700,
                    }}
                >
                    Client Details (Billed To)
                </div>

                {/* Second Row: Buttons (New Client and Existing) */}
                <div className="flex items-center  gap-4 md:gap-2 whitespace-nowrap col-span-1 md:col-auto lg:col-auto">
                    <button
                        type="button"
                        className="rounded-xl font-bold text-white flex items-center justify-center text-xs h-8 w-auto whitespace-nowrap lg:text-sm md:text-sm"
                        style={{ color: "#008065", cursor: paramsId ? "not-allowed" : "pointer", }}
                        onClick={handleOpen}
                        disabled={paramsId}
                    >
                        <FiPlusCircle className="h-5 w-5 mt-1" />
                        <span className="ml-1 mt-1 px-1 pr-2 text-left whitespace-nowrap text-sm font-bold">
                            New Client
                        </span>
                    </button>

                    <div className="flex items-center mr-5">
                        <ClientPlusIcon className="w-5 h-5 text-[#008065]" />
                        <Button onClick={handleConfirm} disabled={!!paramsId} sx={{ textTransform: 'none', padding: 0, }} >
                            <span
                                style={{
                                    color: "#008065",
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                    cursor: paramsId ? "not-allowed" : "pointer",
                                }}
                                className="text-[#008065] mt-1"
                            >
                                Existing
                            </span>
                        </Button>
                    </div>
                </div>
            </div>



            <div className={"flex flex-col sm:flex-row pt-2 gap-2"}>
                <div className="  flex-1 md:mx-2 mt-2 md:mt-[2px] ">
                    <div className="flex flex-col md:flex-row justify-between items-center md:items-start gap-4">
                        {/* Left Section */}
                        {/* <div
                            className="flex flex-1 items-center whitespace-nowrap"
                            style={{
                                color: "#008065",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            Client Details (Billed To)
                        </div> */}

                        {/* Right Section */}
                        {/* <div className="flex flex-1 items-center justify-center md:justify-end gap-3">
                            <button
                                onClick={handleOpen}
                                style={{
                                    textAlign: "center",
                                    color: "white",
                                    backgroundColor: "#008065",
                                    borderRadius: "7px",
                                    padding: "4px 8px",
                                    border: "none",
                                    cursor: paramsId ? "not-allowed" : "pointer",
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                }}
                                disabled={paramsId}
                            >
                                Add New Client
                            </button>


                            <ClientPlusIcon className="w-4 h-4 text-[#008065]" />

                            <Button onClick={handleConfirm}>
                                <span
                                    style={{
                                        color: "#008065",
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                        fontWeight: 700,
                                        cursor: paramsId ? "not-allowed" : "pointer",
                                    }}
                                    className="text-[#008065] ml-1"
                                >
                                    EXISTING
                                </span>
                            </Button>
                        </div>  */}

                        {/* Modal Section */}
                        {isModalOpen && (
                            <ClientModel closeModal={closeModal} handleSelect={handleSelect} />
                        )}
                    </div>




                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className="flex-1  required"
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            <span>Name</span>
                        </div>
                        <div className="font-title flex-1 text-left">

                            <input
                                autoComplete="nope"
                                placeholder="Client Name"
                                // className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                className={`
                                    border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12 
                                    ${ clientErrors.clientName  ? 'border-[#FF0000]' : 'border-[#949494]'}
                                    ${! clientErrors.clientName && 'focus:border-[#07ad7b]'}
                                `}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: clientErrors.clientName ? "#ff0000" : "#008065",
                                }}
                                name='clientName'
                                value={formData.clientName}
                                onChange={(e) => handleChange(e)}
                                readOnly
                            />
                            {clientErrors.clientName && (
                                <p
                                    style={{
                                        fontFamily: "poppins",
                                        fontWeight: 700,
                                        color: "#ff0000",
                                    }}
                                    className="text-red-400 text-xs"
                                >
                                    {clientErrors.clientName}
                                </p>
                            )}

                        </div>
                    </div>

                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className=" flex-1 mb-2"
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >

                            Address
                        </div>
                        <div className="font-title flex-1 text-left">
                            <input
                                autoComplete="nope"
                                placeholder="Address (Optional)"
                                className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065",
                                }}
                                name='clientAddress'
                                value={formData.clientAddress}
                                onChange={(e) => handleChange(e)}
                                readOnly
                            />

                        </div>
                    </div>

                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className="flex-1"
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            City
                        </div>
                        <div className="font-title flex-1 text-left ">

                            <input
                                autoComplete="nope"
                                placeholder="City (Optional)"
                                className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065",
                                }}
                                name='clientCity'
                                value={formData.clientCity}
                                onChange={(e) => handleChange(e)}
                                readOnly
                            />


                        </div>
                    </div>

                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className="flex-1"
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            State
                        </div>
                        <div className="font-title flex-1 text-left ">

                            <input
                                autoComplete="nope"
                                placeholder="State (Optional)"
                                className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065",
                                }}
                                name='clientState'
                                value={formData.clientState}
                                onChange={(e) => handleChange(e)}
                                readOnly
                            />


                        </div>
                    </div>

                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className="flex-1"
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            Postal Code
                        </div>
                        <div className="font-title flex-1 text-left ">
                            <input
                                type=""
                                autoComplete="nope"
                                placeholder="Postalcode (Optional)"
                                className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065"
                                }}
                                maxLength={6}
                                name='clientPostalCode'
                                value={formData.clientPostalCode}
                                onChange={(e) => {
                                    const re = /^[0-9\b]+$/;

                                    if (re.test(e.target.value) || (e.target.value == "")) {
                                        handleChange(e)
                                    }

                                }}
                                readOnly
                            />
                            {formData.errors?.clientPostalCodeErrorMessage && (
                                <span
                                    style={{
                                        fontFamily: "poppins",
                                        fontWeight: 700,
                                        color: "#ff0000",
                                    }}
                                    className="text-red-400 text-xs"
                                >
                                    {formData.errors.clientPostalCodeErrorMessage}
                                </span>
                            )}
                        </div>
                    </div>


                </div >
                <div className="  flex-1">
                    <div>
                        <div className="sm:flex-1">
                            <div className="flex flex-row justify-between items-center mb-2 mr-0 sm:mr-6 whitespace-nowrap">
                                <div
                                    style={{
                                        color: "#008065",
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 700,
                                    }}
                                    className=" flex-1 pr-0 md:pr-8 ml-1 invoiceUniqNo"
                                >
                                    INVOICE #
                                </div>
                                <div className="font-title flex-1 text-right ">
                                    <input
                                        name="invoiceNo"
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            // borderColor: "#008065",
                                        }}
                                        value={formData.invoiceNo}
                                        onChange={(e) => handleChange(e)}
                                        autoComplete="nope"
                                        placeholder="Invoice No"
                                        className={
                                            "border-2 border-[#949494] focus:border-[#07ad7b] text-left font-medium h-10 text-sm flex-1 font-title text-md px-2 block border-solid border-2 rounded-[7px] focus:outline-none lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                        }
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="flex flex-row justify-between items-center mb-2 mr-0 sm:mr-6 whitespace-nowrap">
                                <div
                                    // id="creationDate"
                                    style={{
                                        color: "#008065",
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 700,
                                    }}
                                    className="flex-1 pr-0 md:pr-8 ml-1 "
                                >
                                    Invoice Date
                                </div>
                                <div className=" font-title flex-1 text-left">
                                    <DatePicker
                                        // style={{ fill: "red !important" }}
                                        selected={invoiceDate}
                                        value={formData?.invoicedate}
                                        // onChange={(date) => {
                                        //     const formattedDate = moment(date).format(dateFormat);
                                        //     console.log("formattedDate----------->",formattedDate)
                                        //     setInvoiceDate(formattedDate);
                                        //     setFormData({
                                        //         ...formData,
                                        //         invoicedate: formattedDate,
                                        //     });
                                        // }}
                                        onChange={handleDateChange}
                                        dateFormat={"dd/MM/yyyy"}
                                        wrapperClassName="return-datepicker"
                                        scrollableYearDropdown
                                        customInput={<CustomInputWithIcon />}
                                        showPopperArrow={false}

                                    />
                                </div>
                            </div>
                            {userdata?.ponumberanddate ? (
                                <>
                                    <div className="flex flex-row justify-between items-center mb-2 mr-0 sm:mr-6 whitespace-nowrap">
                                        <div
                                            style={{
                                                color: "#008065",
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                            }}
                                            className=" flex-1 pr-0 md:pr-8 invoiceUniqNo ml-1"
                                        >
                                            PO #
                                        </div>
                                        <div className="font-title flex-1 text-right ">
                                            <input
                                                name="poNumber"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    borderColor: "#008065",
                                                }}
                                                value={formData.poNumber}
                                                onChange={(e) => handleChange(e)}
                                                autoComplete="nope"
                                                placeholder="PO No (Optional)"
                                                className={
                                                    " text-center font-medium h-9 text-sm flex-1 font-title text-md px-2 block border-solid border-2 rounded-[7px] focus:outline-none lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (<></>)}
                            {userdata?.ponumberanddate ? (
                                <>
                                    <div className="flex flex-row justify-between items-center mb-2 mr-0 sm:mr-6 whitespace-nowrap">
                                        <div
                                            // id="creationDate"
                                            style={{
                                                color: "#008065",
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                            }}
                                            className="flex-1 pr-0 md:pr-8 ml-1"
                                        >
                                            PO Date
                                        </div>
                                        <div className=" font-title flex-1 text-left">
                                            <DatePicker
                                                // style={{ fill: "red !important" }}
                                                selected={poDate}
                                                onChange={(date) => {
                                                    const formattedDate = moment(date).format(dateFormat ? dateFormat : "DD/MM/YYYY");
                                                    setPoDate(formattedDate);
                                                    setFormData({
                                                        ...formData,
                                                        poDate: formattedDate,
                                                    });
                                                }}
                                                value={formData.poDate}
                                                dateFormat={"dd/MM/yyyy"}
                                                wrapperClassName="return-datepicker"
                                                scrollableYearDropdown
                                                customInput={<CustomDueDate />}
                                                showPopperArrow={false}
                                            />

                                        </div>

                                    </div>
                                </>
                            ) : (<></>)}



                            {billModules == 'PRODUCT' && (
                                <>

                                    <div className="flex flex-row justify-between items-center mb-2 mr-0 sm:mr-6 whitespace-nowrap">
                                        <div
                                            // id="creationDate"
                                            style={{
                                                color: "#008065",
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 700,
                                            }}
                                            className="flex-1 pr-0 md:pr-8 ml-1"
                                        >
                                            Due Date
                                        </div>
                                        <div className=" font-title flex-1 text-left">
                                            <DatePicker
                                                selected={dueDate}
                                                value={formData?.duedate}
                                                // onChange={(date) => {
                                                //     if (date) {
                                                //         const formattedDate = moment(date).format("DD/MM/YYYY");
                                                //         setDueDate(formattedDate);
                                                //         setFormData((prev: any) => ({
                                                //             ...prev,
                                                //             duedate: formattedDate,
                                                //         }));
                                                //     }
                                                // }}
                                                onChange={handleDueDateChange}
                                                dateFormat="dd/MM/yyyy"
                                                wrapperClassName="return-datepicker"
                                                scrollableYearDropdown
                                                customInput={<CustomInputWithIcon />}
                                                showPopperArrow={false}
                                                minDate={currentDate.toDate()}
                                            />
                                        </div>
                                    </div>

                                </>
                            )}

                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2">
                                <div className="flex mb-2 ">
                                    {addClientEmail ? (
                                        <>
                                            <div className="font-title flex-1 text-left ml-1">
                                                {/* <div
                                                className="flex-1"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Email
                                            </div> */}
                                                <input
                                                    autoComplete="nope"
                                                    placeholder="Email (Optional)"
                                                    className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 w-full"}
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        // borderColor: "#008065"
                                                    }}
                                                    name='clientEmail'
                                                    value={formData.clientEmail}
                                                    onChange={(e) => handleChange(e)}
                                                    readOnly
                                                />

                                                {formData.errors?.clientEmailErrorMessage && (
                                                    <span
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontWeight: 700,
                                                            color: "#ff0000",
                                                        }}
                                                        className="text-red-400 text-xs"
                                                    >
                                                        {formData?.errors?.clientEmailErrorMessage}
                                                    </span>
                                                )}
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    handleClientAddEmail("addTrueClientEmail")
                                                }
                                            >
                                                <svg
                                                    // className="h-6 mr-3 text-grey  "
                                                    className={`h-6 mr-3  text-grey ${formData?.errors?.clientEmailErrorMessage
                                                        ? "mb-6"
                                                        : ""
                                                        }`}
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1"
                                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                </svg>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className=" flex-1"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                <button
                                                    type="button"
                                                    className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8  ml-1 block w-48 "
                                                    onClick={() =>
                                                        handleClientAddEmail("addClientEmail")
                                                    }
                                                >
                                                    <EmailIcon
                                                        style={IconStyle}
                                                        className="h-5 w-5 "
                                                    />
                                                    Add Email
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div className="flex mb-2">
                                    {addclientMobile ? (
                                        <>
                                            <div className="font-title flex-1 text-left ml-1">
                                                {/* <div
                                                className="flex-1"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Mobile
                                            </div> */}
                                                <input
                                                    type="text"
                                                    autoComplete="nope"
                                                    placeholder="Mobile Number"
                                                    className={`border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 w-full border-[#008065]`}
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                    }}
                                                    maxLength={10}
                                                    name="clientMobile"
                                                    value={formData.clientMobile}
                                                    onChange={(e) => {
                                                        const re = /^[0-9\b]+$/;
                                                        if (re.test(e.target.value) || (e.target.value == "")) {
                                                            handleChange(e)
                                                        }

                                                    }}
                                                    readOnly

                                                />

                                                {formData.errors?.clientMobileErrorMessage && (
                                                    <p
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontWeight: 700,
                                                            color: "#008065",
                                                        }}
                                                        className="text-red-400 text-xs"
                                                    >
                                                        {formData.errors.clientMobileErrorMessage}
                                                    </p>
                                                )}

                                            </div>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    clientMobile("addTrueClientEmail")
                                                }
                                            >
                                                <svg
                                                    className={`h-6 mr-3  text-grey ${formData.errors?.clientMobileErrorMessage
                                                        ? "mb-5"
                                                        : ""
                                                        }`}
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1"
                                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                </svg>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className=" flex-1"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                <button
                                                    type="button"
                                                    className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8  ml-1 block w-48 "
                                                    onClick={() =>
                                                        clientMobile("addtTrueClientno")
                                                    }
                                                >
                                                    <MobileIcon
                                                        style={IconStyle}
                                                        className="h-5 w-5 "
                                                    />
                                                    Add Mobile
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>


                                <div className="flex mb-2">
                                    {addClientPan ? (
                                        <>
                                            <div className="font-title flex-1 text-left ml-1">
                                                {/* <div
                                                className="flex-1"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Pan No
                                            </div> */}
                                                <input
                                                    autoComplete="nope"
                                                    placeholder="PanNumber (Optional)"
                                                    className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 w-full"}
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        // borderColor: "#008065",
                                                    }}
                                                    name='clientPan'
                                                    value={formData.clientPan}
                                                    onChange={(e) => handleChange(e)}
                                                    readOnly
                                                />
                                                {formData.errors?.clientPanErrorMessage && (
                                                    <span
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontWeight: 700,
                                                            color: "#ff0000",
                                                        }}
                                                        className="text-red-400 text-xs"
                                                    >
                                                        {formData.errors.clientPanErrorMessage}
                                                    </span>
                                                )}

                                            </div>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    handleClientAddPan("addTrueClientPanNo")
                                                }
                                            >
                                                <svg
                                                    className={`h-6 mr-3  text-grey ${formData.errors?.clientPanErrorMessage
                                                        ? "mb-6"
                                                        : ""
                                                        }`}
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1"
                                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                </svg>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className=" flex-1"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                <button
                                                    type="button"
                                                    className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8 ml-1 block w-48 "
                                                    onClick={() =>
                                                        handleClientAddPan("addClientPan")
                                                    }
                                                >
                                                    <PlusCircleIcon
                                                        style={IconStyle}
                                                        className="h-5 w-5"
                                                    />
                                                    Add PAN
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="flex mb-2">
                                    {addClientGst ? (
                                        <>
                                            <div className="font-title flex-1 text-left ml-1">
                                                {/* <div
                                                className="flex-1"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                GST
                                            </div> */}
                                                <input
                                                    autoComplete="nope"
                                                    placeholder="GST (Optional)"
                                                    className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 w-full"}
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 400,
                                                        // borderColor: "#008065",
                                                    }}
                                                    name='clientgst'
                                                    value={formData.clientgst}
                                                    onChange={(e) => handleChange(e)}
                                                    readOnly
                                                />
                                                {formData.errors?.clientgstErrorMessage && (
                                                    <span
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontWeight: 700,
                                                            color: "#FF0000",
                                                        }}
                                                        className="text-red-400 text-xs"
                                                    >
                                                        {formData.errors.clientgstErrorMessage}
                                                    </span>
                                                )}
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    handleClientAddGst("addTrueClientGst")
                                                }
                                            >
                                                <svg
                                                    className={`h-6 mr-3  text-grey ${formData.errors?.clientgstErrorMessage
                                                        ? "mb-6"
                                                        : ""
                                                        }`}
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1"
                                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                </svg>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className=" flex-1"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                <button
                                                    type="button"
                                                    className="rounded-xl font-bold text-[#008065] flex flex-row  md:h-10 text-sm h-8  ml-1 block  w-full "
                                                    onClick={() =>
                                                        handleClientAddGst("addClientGst")
                                                    }
                                                >
                                                    <PlusCircleIcon
                                                        style={IconStyle}
                                                        className="h-5 w-5"
                                                    />
                                                    Add GST
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div >
            <Transition.Root show={clientviewOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={handleClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:w-full md:w-11/12 lg:w-8/12 xl:w-6/12 border border-[#008065]">
                                    <div>
                                        <div className='items-start justify-between p-2 border-solid border-slate-200 rounded-t bg-[#008065]'>
                                            <div className='flex p-1 border-solid border-slate-200 rounded-b justify-between  ' >
                                                <h2 className="md:text-xl 2xl:text-xl font-semibold text-white text-xl">Add New Client</h2>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className='cursor-pointer mt-1.5' viewBox="0 0 384 512" fill='#fff' onClick={handleClose}><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
                                            </div>
                                        </div>
                                        {/* <h2
                                            className="text-lg font-semibold mb-4"
                                            style={{ fontFamily: "Poppins", color: "#008065" }}
                                        >
                                            Add New Client
                                        </h2> */}

                                        {/* Form Inputs */}
                                        <form onSubmit={handleSubmit(onSubmit)} className='p-6'>

                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                                {/* Client Name */}
                                                <div className="flex flex-row justify-between items-center mb-2">
                                                    <div
                                                        className="flex-1 mb-2 required"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        <span>Client Name</span>
                                                    </div>
                                                    <div className="font-title flex-1 text-left">
                                                        <input
                                                            {...register("client_name", { required: "Client Name is Required" })}
                                                            // className="border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                            className={`
                                                                border-2 focus:outline-none rounded-[7px] px-2 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12
                                                                ${ errors?.client_name ? 'border-[#FF0000]' : 'border-[#949494] focus:border-[#07ad7b]'}                                                                
                                                            `}
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                // borderColor: errors?.client_name ? "#ff0000" : "#008065",
                                                            }}
                                                            placeholder="Enter Client Name"
                                                        />
                                                        {errors?.client_name && (
                                                            <p
                                                                style={{
                                                                    fontFamily: "poppins",
                                                                    fontWeight: 700,
                                                                    color: "#ff0000",
                                                                }}
                                                                className="text-red-400 text-sm"
                                                            >
                                                                {errors?.client_name?.message}
                                                            </p>
                                                        )}
                                                    </div>


                                                </div>


                                                {/* Address */}
                                                <div className="flex flex-row justify-between items-center mb-2">
                                                    <div
                                                        className="flex-1 mb-2"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Address
                                                    </div>
                                                    <div className="font-title flex-1 text-left">
                                                        <input
                                                            {...register("client_address")}
                                                            className="border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                // borderColor: "#008065",
                                                            }}
                                                            placeholder="Enter Address"
                                                        />
                                                    </div>
                                                </div>

                                                {/* City */}
                                                <div className="flex flex-row justify-between items-center mb-2">
                                                    <div
                                                        className="flex-1 mb-2"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        City
                                                    </div>
                                                    <div className="font-title flex-1 text-left">
                                                        <input
                                                            {...register("city")}
                                                            className="border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                // borderColor: "#008065",
                                                            }}
                                                            placeholder="Enter City"
                                                        />
                                                    </div>
                                                </div>

                                                {/* State */}
                                                <div className="flex flex-row justify-between items-center mb-2">
                                                    <div
                                                        className="flex-1 mb-2"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        State
                                                    </div>
                                                    <div className="font-title flex-1 text-left">
                                                        <input
                                                            {...register("state")}
                                                            className="border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                // borderColor: "#008065",
                                                            }}
                                                            placeholder="Enter State"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Postal Code */}
                                                <div className="flex flex-row justify-between items-center mb-2">
                                                    <div
                                                        className="flex-1 mb-2"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Postal Code
                                                    </div>
                                                    <div className="font-title flex-1 text-left">
                                                        <input
                                                            {...register("postal_code")}
                                                            onChange={(e) => {
                                                                handleInputChange(e);
                                                            }}
                                                            // className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                            className={`
                                                                 border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12
                                                                ${ fieldErrors?.postal_code ? 'border-[#FF0000]' : 'border-[#949494] focus:border-[#07ad7b]'}                               
                                                            `}
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                // borderColor: fieldErrors?.postal_code ? "#ff0000" : "#008065",
                                                            }}
                                                            placeholder="Enter Postal Code"
                                                        />
                                                        {fieldErrors?.postal_code && (
                                                            <p
                                                                style={{
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: 700,
                                                                    color: "#ff0000",
                                                                }}
                                                                className="text-red-400 text-sm"
                                                            >
                                                                {fieldErrors?.postal_code}
                                                            </p>
                                                        )}

                                                    </div>
                                                </div>

                                                {/* Email */}
                                                <div className="flex flex-row justify-between items-center mb-2">
                                                    <div
                                                        className="flex-1 mb-2"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Email
                                                    </div>
                                                    <div className="font-title flex-1 text-left">
                                                        <input
                                                            {...register("client_email")}
                                                            onChange={(e) => {
                                                                handleInputChange(e);
                                                            }}
                                                            type="email"
                                                            // className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                            className={`
                                                                border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12
                                                               ${ fieldErrors?.client_email ? 'border-[#FF0000]' : 'border-[#949494] focus:border-[#07ad7b]'}
                                                           `}
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                // borderColor: fieldErrors?.client_email ? "#ff0000" : "#008065",
                                                            }}
                                                            placeholder="Enter Email"
                                                        />
                                                        {fieldErrors?.client_email && (
                                                            <p
                                                                style={{
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: 700,
                                                                    color: "#ff0000",
                                                                }}
                                                                className="text-red-400 text-sm"
                                                            >
                                                                {fieldErrors?.client_email}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Mobile */}
                                                <div className="flex flex-row justify-between items-center mb-2">
                                                    <div
                                                        className="flex-1 mb-2"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Mobile
                                                    </div>
                                                    <div className="font-title flex-1 text-left">
                                                        <input
                                                            {...register("client_mobile")}
                                                            onChange={(e) => {
                                                                handleInputChange(e);
                                                            }}
                                                            type="tel"
                                                            // className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                            className={`
                                                                border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12
                                                               ${ fieldErrors?.client_mobile ? 'border-[#FF0000]' : 'border-[#949494] focus:border-[#07ad7b]'}
                                                           `}
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                // borderColor: fieldErrors?.client_mobile ? "#ff0000" : "#008065",
                                                            }}
                                                            placeholder="Enter Mobile"
                                                        />
                                                        {fieldErrors?.client_mobile && (
                                                            <p
                                                                style={{
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: 700,
                                                                    color: "#ff0000",
                                                                }}
                                                                className="text-red-400 text-sm"
                                                            >
                                                                {fieldErrors?.client_mobile}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* PAN */}
                                                <div className="flex flex-row justify-between items-center mb-2">
                                                    <div
                                                        className="flex-1 mb-2"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        PAN
                                                    </div>
                                                    <div className="font-title flex-1 text-left">
                                                        <input
                                                            {...register("pan_no")}
                                                            onChange={(e) => {
                                                                handleInputChange(e);
                                                            }}
                                                            // className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                            className={`
                                                                border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12
                                                               ${ fieldErrors?.pan_no  ? 'border-[#FF0000]' : 'border-[#949494] focus:border-[#07ad7b]'}
                                                           `}
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                // borderColor: fieldErrors?.pan_no ? "#ff0000" : "#008065",
                                                            }}
                                                            placeholder="Enter PAN"
                                                        />
                                                        {fieldErrors?.pan_no && (
                                                            <p
                                                                style={{
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: 700,
                                                                    color: "#ff0000",
                                                                }}
                                                                className="text-red-400 text-sm"
                                                            >
                                                                {fieldErrors?.pan_no}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>

                                                {/* GST */}
                                                <div className="flex flex-row justify-between items-center mb-2">
                                                    <div
                                                        className="flex-1 mb-2"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        GST
                                                    </div>
                                                    <div className="font-title flex-1 text-left">
                                                        <input
                                                            {...register("gst")}
                                                            onChange={(e) => {
                                                                handleInputChange(e);
                                                            }}
                                                            // className="border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12"
                                                            className={`
                                                                border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12
                                                               ${ fieldErrors?.gst ? 'border-[#FF0000]' : 'border-[#949494] focus:border-[#07ad7b]'}
                                                           `}
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontSize: "14px",
                                                                fontWeight: 400,
                                                                // borderColor: fieldErrors?.gst ? "#ff0000" : "#008065",
                                                            }}
                                                            placeholder="Enter GST"
                                                        />
                                                        {fieldErrors?.gst && (
                                                            <p
                                                                style={{
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: 700,
                                                                    color: "#ff0000",
                                                                }}
                                                                className="text-red-400 text-sm"
                                                            >
                                                                {fieldErrors?.gst}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Buttons */}
                                            <div className="mt-6 flex justify-end space-x-4">
                                                <button
                                                    type="button"
                                                    onClick={handleClose}
                                                    className="px-4 py-2 bg-red-500 hover:bg-red-600 rounded text-white"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="px-4 py-2 bg-[#008065] hover:bg-green-800 rounded text-white"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>

    )


}

export default ClientDetails


