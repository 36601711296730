/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'

const ShippmentFrom = ({ handleChange, formData, setFormData }: any) => {
    const [shippmentCheckBox, setShippmentCheckBox] = useState(false);
    const handleCheckboxChange = () => {
        setShippmentCheckBox(!shippmentCheckBox)
        if (!shippmentCheckBox === false)
            setFormData({
                ...formData,
                shipFromName: "",
                shipFromAddress: "",
                shipFromCity: "",
                shipFromState: "",
                shipFromPostalCode: "",
            });
    }
    useEffect(() => {
        formData.shipFromName && setShippmentCheckBox(true)
        formData.shipFromAddress && setShippmentCheckBox(true)
    }, [ formData.shipFromName, formData.shipFromAddress])
    return (
        <div>
            <div className="w-auto md:w-[50%] lg:w-[60%] xl:w-auto 2xl:w-auto whitespace-nowrap flex mt-4 "
                style={{
                    color: "#008065",
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                }}
            >
                Shipping From
                <div className='ml-2'>
                    <input
                        type="checkbox"
                        name="defaultBankInfo"
                        placeholder="defaultBankInfo"
                        // checked={shippmentCheckBox}
                        onChange={handleCheckboxChange}
                        className={
                            "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700 cursor-pointer"
                        }
                        style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                        }}
                    />
                </div>
            </div>
            {shippmentCheckBox && (
                <>
                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className="flex-1  "
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            <span> Name</span>
                        </div>
                        <div className="font-title flex-1 text-left">

                            <input
                                autoComplete="nope"
                                placeholder="Shipping Name"
                                className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065",
                                }}
                                name='shipFromName'
                                value={formData.shipFromName}
                                onChange={(e) => handleChange(e)}

                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className="flex-1  "
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            <span>  Address</span>

                        </div>
                        <div className="font-title flex-1 text-left">

                            <input
                                autoComplete="nope"
                                placeholder="Shipping Address"
                                className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065",
                                }}
                                name='shipFromAddress'
                                value={formData.shipFromAddress}
                                onChange={(e) => handleChange(e)}

                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className="flex-1  "
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >

                            <span> City</span>
                        </div>
                        <div className="font-title flex-1 text-left">

                            <input
                                autoComplete="nope"
                                placeholder="Shipping City"
                                className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065",
                                }}
                                name='shipFromCity'
                                value={formData.shipFromCity}
                                onChange={(e) => handleChange(e)}

                            />


                        </div>
                    </div>

                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className="flex-1  "
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            <span> State</span>
                        </div>
                        <div className="font-title flex-1 text-left">

                            <input
                                autoComplete="nope"
                                placeholder="Shipping State"
                                className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065",
                                }}
                                name='shipFromState'
                                value={formData.shipFromState}
                                onChange={(e) => handleChange(e)}

                            />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className="flex-1  "
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            <span> Postal Code</span>
                        </div>
                        <div className="font-title flex-1 text-left">

                            <input
                                autoComplete="nope"
                                placeholder="Shipping Postal Code"
                                // className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                className={`
                                    border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12 
                                    ${ ! formData.isshippingValidPostalCode  ? 'border-[#FF0000]' : 'border-[#949494]'}
                                    ${ formData.isshippingValidPostalCode  && 'focus:border-[#07ad7b]'}
                                `}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: formData.isshippingValidPostalCode
                                    //     ? "#008065"
                                    //     : "#FF0000",
                                }}
                                maxLength={6}
                                name='shipFromPostalCode'
                                value={formData.shipFromPostalCode}
                                onChange={(e) => {
                                    const re = /^[0-9\b]+$/;

                                    if (re.test(e.target.value) || (e.target.value == "")) {
                                        handleChange(e)
                                    }

                                }}
                            />
                            {formData.errors?.shipFromPostalCodeErrorMessage
                                && (
                                    <span
                                        style={{
                                            fontFamily: "poppins",
                                            fontWeight: 700,
                                            color: "#ff0000",
                                        }}
                                        className='text-red-400 text-xs'
                                    >
                                        {formData.errors.shipFromPostalCodeErrorMessage}
                                    </span>
                                )}
                        </div>
                    </div>

                </>
            )}
        </div>
    )
}

export default ShippmentFrom
