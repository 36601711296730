import React from 'react';
const CalculateIndianFormattotal = (number: number) => {
  if (isNaN(number) || number < 0) return "Invalid number";
  const units = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
  const teens = ["ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
  const tens = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
  if (number === 0) return "zero";
  let words = "";
  function convertTwoDigits(num: number) {
    if (num < 10) return units[num];
    if (num < 20) return teens[num - 10];
    return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? " " + units[num % 10] : "");
  }
  function convertChunk(num: number, scale: string) {
    if (num > 0) {
      words += convertTwoDigits(num) + " " + scale + " ";
    }
  }
  if (number >= 10000000) {
    convertChunk(Math.floor(number / 10000000), "crore");
    number %= 10000000;
  }
  if (number >= 100000) {
    convertChunk(Math.floor(number / 100000), "lakh");
    number %= 100000;
  }
  if (number >= 1000) {
    convertChunk(Math.floor(number / 1000), "thousand");
    number %= 1000;
  }
  if (number >= 100) {
    words += units[Math.floor(number / 100)] + " hundred ";
    number %= 100;
  }
  if (number > 0) {
    if (words.length > 0) {
      words += "and "; // Add "and" only if the number has higher values (100+)
    }
    words += convertTwoDigits(number);
  }
  return words.trim();
};
export default CalculateIndianFormattotal;






