import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const VerifyCodePage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const navigateTo = useNavigate();

  const location = useLocation();

  const onSubmit = async (data: any) => {
    if (data?.code === location?.state?.code) {
      Swal.fire({
        position: "center",
        icon: "success",
        text: "Verification Success",
        timer: 3000,
        showConfirmButton: false,
      });
      setTimeout(() => {
        navigateTo("/changepassword", { state: location?.state?.email });
      }, 3000);
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "Incorrect Code",
        timer: 3000,
        showConfirmButton: false,
      });
    }
  };
  return (
    <div>
      <section className=" flex items-center justify-center 2xl:mt-20 mb-10">
        <div className="  flex rounded-2xl  ">
          <div className="w-full lg:w-full  rounded-lg lg:rounded-l-none mt-8">
            <div className=" flex flex-col items-center justify-center relative">
              <div className="flex flex-col bg-[#008065] text-center   shadow-md px-4 sm:px-6 md:px-10 lg:px-10 py-16 h-full  w-full max-w-md rounded-t">
                <span
                  style={{ fontFamily: "poppins" }}
                  className="text-[#FFF] text-center text-xl font-bold mb-6 py-1 place-self-center"
                >
                  
                  Verify Your Code
                </span>
              </div>
              <div className="flex flex-col shadow-lg shadow-gray-400 px-4 sm:px-6 md:px-10 lg:px-10 py-8  w-full max-w-md rounded-b ">
                <div className="flex flex-col bg-white  -mt-28 shadow-lg shadow-gray-400  px-4 sm:px-6 md:px-10 lg:px-10 py-8 rounded-md w-full max-w-md ">
                  <div className="pt-1  ">
                    <form
                      action="#"
                      method="POST"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="flex flex-col mb-6">
                        <label className="mb-1 text-xs sm:text-sm tracking-wide text-[#008065]">
                          Please check your emails for a message with your code.
                        </label>
                        <div className="relative">
                          <input
                            type="text"
                            {...register("code", {
                              required: "Enter code",
                            })}
                            style={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              fontWeight: 400,
                              // borderColor: errors?.code ? "" : "#008065",
                            }}
                            className={
                              `${errors?.code ? 'border-[#FF0000]' : 'focus:border-[#07ad7b] border-[#949494]'} ` +
                              "text-sm sm:text-base  pr-4 rounded-lg  py-2 border-2 focus:outline-none rounded-[7px] px-2 h-9 w-[100%]"
                            }
                            placeholder="Enter Code"
                          />
                        </div>
                        {errors?.code && (
                          <p className="text-red-400 text-xs">
                            {errors?.code?.message as any}
                          </p>
                        )}
                      </div>

                      <div className="flex w-full">
                        <button
                          type="submit"
                          className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#008065] rounded py-2 w-full transition duration-150 ease-in"
                        >
                          <span className="mr-2 ">Next</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VerifyCodePage;
