/* eslint-disable eqeqeq */
import { forwardRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { assetSave, fixedAssetUpdate, fixedEditFindOne, getPurchaseFAandFixedAsset } from "../../redux/actions/fixedAssetsAction";
import moment from "moment";
import { registerAll } from "../../redux/actions/userAction";
import DatePicker from 'react-datepicker';
import { FaCalendarAlt } from "react-icons/fa";

const FixedAssets = () => {
    const template = useSelector((state: any) => state.userColorTemplate);
    const { userdata } = template;
    const usersignin = useSelector((state: any) => state?.userSignin);
    const { userInfo } = usersignin;
    const fixedasset = useSelector((state: any) => state?.fixedAsset);
    const { fixedAssetlist } = fixedasset;
    const userid = userInfo.token._id;
    const User_id = sessionStorage.getItem("userInfo");
    const userId = JSON.parse(User_id as any);
    const [fixedAssetDate, setfixedAssetDate] = useState(new Date() as any);
    const formatDate = moment(new Date()).format("DD/MM/YYYY");
    const [dateFormat, setDateFormat] = useState("DD/MM/YYYY")



    const [formData, setFormData] = useState({
        fixedAssetDate: formatDate,
    } as any);

    const {
        register,
        reset,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            asset_name: "",
            transactionType: "Opening Assets",
            hsn: "",
            qty: 0,
            rate: 0,
            fixedAssetDate: formatDate,
            NonTaxableAmount: 0,
            user_id: userid,
        },
    });
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const CustomInputWithIcon = forwardRef(({ value, onClick }: any, ref: any) => (
        <div className='relative'>
            <input
                type="text"
                value={value}
                readOnly
                className={"border-2 focus:border-[#07ad7b] border-[#949494] focus:outline-none rounded-[7px] px-2 h-9 w-full"}
                style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                    // borderColor: "#008065",
                }}
                ref={ref}
           
            />
            <FaCalendarAlt
                className="absolute right-0 top-0 mt-2 mr-2 cursor-pointer"
                onClick={onClick}
            />
        </div>
    ));

    const onSubmit = (data: any) => {
        let obj = {
            asset_name: data.asset_name,
            transactionType: "Opening Assets",
            hsn: data.hsn,
            qty: data.qty,
            rate: data.rate,
            Openingasset_qty: data.qty,
            Openingasset_rate: parseFloat(data.qty ? data.qty : 0) * parseFloat(data.rate ? data.rate : 0),
            amount: parseFloat(data.qty ? data.qty : 0) * parseFloat(data.rate ? data.rate : 0),
            totalAmount: parseFloat(data.qty) * parseFloat(data.rate),
            fixedRate: "",
            date: formData.fixedAssetDate,
            user_id: userid,
            NonTaxableAmount: parseFloat(data.qty) * parseFloat(data.rate),
        }
        if (id) {
            dispatch(fixedAssetUpdate(id, obj) as any).then((res: any) => {
                if (res.type == "FIXED_ASSETS_UPDATE_SUCCESS") {
                    reset();
                    navigate(`/app/fixedassetsReport`)
                }
                toast.success("Update SuccessFully !", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 800,
                });
            });
        } else {
            dispatch(assetSave(obj) as any).then((res: any) => {
                if (res.type === "FIXED_ASSETS_POST_SUCCESS") {
                    reset();
                    toast.success("Saved Successfully!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 800,
                    });
                    navigate(`/app/fixedassetsReport`)
                }
            });

        }

    };
    const handleDateChange = (date: any) => {
        console.log("handelchange-------->", date)
        const formattedDate = moment(date).format(dateFormat);
        console.log("formattedDate-------->", formattedDate)

        if (formattedDate !== formData.fixedAssetDate) {
            setfixedAssetDate(date);
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                fixedAssetDate: formattedDate,
            }));
        }
    };
    const fetchData = () => {
        if (id) {
            dispatch(fixedEditFindOne(id) as any).then((res: any) => {
                let value = res.payload
                setValue('qty', value[0]?.Openingasset_qty)
                setValue('hsn', value[0]?.hsn)
                setValue('asset_name', value[0]?.asset_name)
                setValue('transactionType', value[0]?.tranactionType)
                setValue('rate', value[0]?.rate)
                setValue('fixedAssetDate', value[0]?.date);
                setFormData((prevData: any) => ({
                    ...prevData,
                    fixedAssetDate: value[0]?.date,
                }));
            })
        }
    }

    const isAssetNameUnique = (value: string) => {
        if (!id) {
            return Array.isArray(fixedAssetlist) && fixedAssetlist.some((asset: any) => asset.asset_name === value)
                ? "Asset name already exists"
                : true;
        }
    };



    useEffect(() => {
        dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
            if (res && res.type === "REGISTER_GET_SUCCESS") {

                setDateFormat(res?.payload?.invoiceDateFormat)
                setFormData((prev: any) => ({
                    ...prev,
                    fixedAssetDate: res?.payload?.invoiceDateFormat ? moment(fixedAssetDate).format(res?.payload?.invoiceDateFormat ? res?.payload?.invoiceDateFormat : "DD/MM/YYYY") : formatDate,
                }))
            }
        });

        fetchData();
    }, []);

    useEffect(() => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            fixedAssetDate: formData?.fixedAssetDate
                ? formData?.fixedAssetDate
                : formatDate,
        }));
    }, []);

    const handleCancelClick = () => {
        reset();
    }
    return (
        <div className="mr-5 lg:mr-0">
            <form
                onSubmit={handleSubmit(onSubmit)}
            >
                <fieldset className="mx-3 mb-4 rounded-lg">
                    <legend className="p-4 font-bold text-[#008065] text-lg ml-2">
                        Fixed Assets
                    </legend>


                    <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mb-4 px-4 md:px-6 xl:px-4">
                        <div className="flex flex-col mb-2 w-full px-2 md:px-0 xl:px-2">
                            <label
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                }}
                                className="text-[#008065] required"
                            >
                                <span>Fixed Asset Name</span>
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    // className={
                                    //     "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                    // }
                                    className={`border-2 rounded-[7px] px-2 h-9 w-full focus:outline-none 
                                        ${errors.asset_name ? 'border-[#FF0000]' : 'border-[#949494]'} 
                                        ${!errors.asset_name && 'focus:border-[#07ad7b]'}`}
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        // borderColor: errors.asset_name
                                        //     ? "#FF0000"
                                        //     : "#008065",
                                    }}
                                    placeholder="Name..."
                                    {...register("asset_name", {
                                        required: "Name is required",
                                        validate: isAssetNameUnique
                                    })}

                                />
                                {errors.asset_name && <p className="text-red-400 text-sm" style={{ fontFamily: "poppins", color: "#ff0000", fontWeight: 700 }}>{errors.asset_name.message}</p>}
                            </div>
                        </div>
                        {userdata?.hsnNo ? (
                            <>
                                <div className="flex flex-col mb-2 w-full  px-2 md:px-0 xl:px-2">
                                    <label
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            paddingTop: "2px",
                                        }}
                                        className="text-[#008065] "
                                    >
                                        <span>HSN</span>
                                    </label>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            className={
                                                "border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                            }
                                            style={{
                                                fontFamily: "poppins",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                // borderColor: "#008065",
                                            }}
                                            placeholder="HSN"
                                            {...register("hsn", {
                                            })}

                                        />
                                    </div>
                                </div>
                            </>
                        ) : (<></>)}

                        <div className="flex flex-col mb-2 w-full px-2 md:px-0 xl:px-2">
                            <label
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    paddingTop: "2px",
                                }}
                                className="text-[#008065] "
                            >
                                <span>Opening Quantity</span>
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    className={
                                        "border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                    }
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        // borderColor: "#008065",
                                    }}
                                    placeholder="Quantity..."
                                    {...register("qty", {
                                    })}

                                />
                            </div>
                        </div>

                        <div className="flex flex-col mb-2 w-full px-2 md:px-0 xl:px-2">
                            <label
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    paddingTop: "2px",
                                }}
                                className="text-[#008065] "
                            >
                                <span>Price/Unit</span>
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    className={
                                        "border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                                    }
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        // borderColor: "#008065",
                                    }}
                                    placeholder="Price..."
                                    {...register("rate", {
                                    })}

                                />
                            </div>
                        </div>

                        <div className="flex flex-col mb-2 w-full px-2 md:px-0 xl:px-2">
                            <label
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    paddingTop: "2px",
                                }}
                                className="text-[#008065]"
                            >
                                <span>Date</span>
                            </label>
                            <DatePicker
                                selected={fixedAssetDate}
                                value={formData?.fixedAssetDate}
                                onChange={handleDateChange}
                                dateFormat={dateFormat}
                                wrapperClassName="return-datepicker"
                                scrollableYearDropdown
                                customInput={<CustomInputWithIcon />}
                                showPopperArrow={false}
                            />

                        </div>


                    </div>
                    <div className="rounded-xl  mb-3">
                        <div className="flex w-full flex-col sm:flex-row px-4 sm:justify-end justify-center">
                            {/* <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                                <div className="flex flex-col  mb-4 "></div>
                            </div> */}

                            {/* <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                                <div className="flex flex-col  mb-4 "> </div>
                            </div> */}

                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 justify-center w-full md:w-auto">
                                <div className="flex flex-col flex-1 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4 w-full">
                                    <button
                                        type="button"
                                        className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                                        style={{
                                            backgroundColor: "#008065",
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                        }}
                                        onClick={handleCancelClick}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>

                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 justify-center w-full md:w-auto">
                                <div className="flex flex-col flex-1 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4 w-full">
                                    <button
                                        type="submit"
                                        className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                                        style={{
                                            backgroundColor: "#008065",
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {id ? "Update" : "Save"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </fieldset>
            </form>


        </div>
    )
}

export default FixedAssets
