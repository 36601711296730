import React, { useEffect, useState } from "react";
import { registerAll, updateUser } from "../../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const AmountInWords = () => {
	const dispatch = useDispatch();
	const [selectedFormat, setSelectedFormat] = useState("");
	const [isRoundOffChecked, setIsRoundOffChecked] = useState(false); // State for checkbox
	const [roundOffOption, setRoundOffOption] = useState("Nearest");
	const User_id = sessionStorage.getItem("userInfo");
	console.log("User_id", User_id);
	const userId = JSON.parse(User_id as any);

	const handleFormatChange = (event: any) => {
		setSelectedFormat(event.target.value);
	};

	const handleRoundOffCheckboxChange = (event: any) => {
		setIsRoundOffChecked(event.target.checked);
	};

	const handleRoundOffOptionChange = (event: any) => {
		setRoundOffOption(event.target.value);
	};

	const fetchData = () => {
		dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
			if (res && res.type === "REGISTER_GET_SUCCESS") {
				const format = res?.payload?.AmountFormat || "";
				const roundOffEnabled = res?.payload?.RoundOffEnabled || false;
				const roundOffType = res?.payload?.RoundOffType || "Nearest";
				setSelectedFormat(format);
				setIsRoundOffChecked(roundOffEnabled);
				setRoundOffOption(roundOffType);
			}
		});
	};

	useEffect(() => {
		if (userId?.token?._id) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		let obj = {
			AmountFormat: selectedFormat,
			RoundOffEnabled: isRoundOffChecked,
			RoundOffType: roundOffOption,
		};
		dispatch(updateUser(userId?.token?._id, obj) as any).then((res: any) => {
			if (res) {
				fetchData();
				toast.success("Saved Successfully!", {
					position: toast.POSITION.BOTTOM_CENTER,
					autoClose: 800,
				});
			}
		});
	};

	return (
		<div className="bg-[#E1E8E7] h-full" style={{ fontFamily: "poppins" }}>
			<div>
				<form onSubmit={handleSubmit}>
					<fieldset className="mx-2 mb-4 rounded-lg p-2 md:p-3 h-auto">
						<legend className="p-4 font-bold text-[#008065] ml-2">
							Amount In Words
						</legend>
						<div className="grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 px-4 gap-4">
							<div>
								<label
									htmlFor="format-select"
									className="block mb-2 text-sm text-[#008065] font-semibold"
								>
									Select Format
								</label>
								<select
									id="format-select"
									value={selectedFormat}
									onChange={handleFormatChange}
									className="block w-full p-2 bg-white border-2 rounded-md shadow-sm focus:outline-none sm:text-sm border-[#949494] focus:border-[#07ad7b]"
								>
									<option value="" disabled hidden>
										Select Format
									</option>
									<option value="Indian Format">Indian Format</option>
									<option value="International Format">
										International Format
									</option>
								</select>
							</div>
							<div className="flex flex-col justify-start gap-2">
								<label
									htmlFor="round-off-checkbox"
									className="block mb-2 text-sm text-[#008065] font-semibold"
								>
									Enable Round Off
								</label>
								<input
									id="round-off-checkbox"
									type="checkbox"
									checked={isRoundOffChecked}
									onChange={handleRoundOffCheckboxChange}
									className="w-6 h-6 text-[#008065] border-2 border-[#008065] rounded focus:outline-none accent-green-700" // Added margin-left (ml-2)
								/>
							</div>

							{isRoundOffChecked && (
								<div>
									<label
										htmlFor="round-off-select"
										className="block mb-2 text-sm text-[#008065] font-semibold"
									>
										Round Off Type
									</label>
									<select
										id="round-off-select"
										value={roundOffOption}
										onChange={handleRoundOffOptionChange}
										className="block w-full p-2 bg-white border-2 rounded-md shadow-sm focus:outline-none sm:text-sm border-[#949494] focus:border-[#07ad7b]"
									>
										<option value="Nearest">Nearest</option>
										<option value="Up To">Up To</option>
										<option value="Down To">Down To</option>
									</select>
								</div>
							)}
						</div>
						{isRoundOffChecked == true ? (
							<div className="flex w-full flex-col sm:flex-row px-4">
							<div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2  ">
								<div className="flex flex-col mb-6 "></div>
							</div>

							<div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
								<div className="flex flex-col  ">
									<div className="relative"></div>
								</div>
							</div>
							<div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 mt-5">
								<div className="flex flex-col w-48 sm:w-40 rounded">
									<button
										type="button"
										className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
										style={{
											backgroundColor: "#008065",
											fontSize: "14px",
											fontFamily: "poppins",
										}}
									>
										Cancel
									</button>
								</div>
							</div>
							<div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2 mt-5">
								<div className="flex flex-col w-48 sm:w-40">
									<button
										type="submit"
										className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
										style={{
											backgroundColor: "#008065",
											fontSize: "14px",
											fontFamily: "poppins",
										}}
									>
										Save
									</button>
								</div>
							</div>
						</div>
						):(
						<div className="flex w-full flex-col sm:flex-row px-4">
							<div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 mt-5">
								<div className="flex flex-col w-48 sm:w-40 rounded">
									<button
										type="button"
										className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
										style={{
											backgroundColor: "#008065",
											fontSize: "14px",
											fontFamily: "poppins",
										}}
									>
										Cancel
									</button>
								</div>
							</div>
							<div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2 mt-5">
								<div className="flex flex-col w-48 sm:w-40">
									<button
										type="submit"
										className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
										style={{
											backgroundColor: "#008065",
											fontSize: "14px",
											fontFamily: "poppins",
										}}
									>
										Save
									</button>
								</div>
							</div>
							</div>
						)}
						
					</fieldset>
				</form>
			</div>
		</div>
	);
};

export default AmountInWords;
