/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { registerAll, updateUser } from '../../../redux/actions/userAction';

const DatesSettings = () => {
    const dispatch = useDispatch();
    const usersignin = useSelector((state: any) => state.userSignin);
    const token = usersignin?.userInfo?.token;
    const [selectedFormat, setSelectedFormat] = useState<string>('MM/DD/YYYY');
    const [formattedDate, setFormattedDate] = useState<string>('');

    const dateFormats = [
        'MM/DD/YYYY',
        'DD/MM/YYYY',
        'YYYY-MM-DD',
        'DD-MM-YYYY',
    ];
    useEffect(() => {
        let formattedDateValue = '';
        switch (selectedFormat) {
            case 'MM/DD/YYYY':
                formattedDateValue = `MM/DD/YYYY`;
                break;
            case 'DD/MM/YYYY':
                formattedDateValue = `DD/MM/YYYY`;
                break;
            case 'YYYY-MM-DD':
                formattedDateValue = `YYYY-MM-DD`;
                break;
            case 'DD-MM-YYYY':
                formattedDateValue = `DD-MM-YYYY`;
                break;
            default:
                formattedDateValue = '';
        }
        setFormattedDate(formattedDateValue);
    }, [selectedFormat]);
    const handleSubmit = (e: any) => {
        e.preventDefault();
        let dateFormat = {
            invoiceDateFormat: formattedDate,
            user_id: token?._id,
        };
        dispatch(updateUser(token?._id, dateFormat) as any).then((res: any) => {
            if (res) {
                // fetchData();
                toast.success("Saved Successfully!", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 800,
                });
            }
        });
    };
    const CustomFormControl = styled(FormControl)({
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
            },
        },
    });

    const CustomSelect = styled(Select)({
        '&.MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    });
    const fetchData = () => {
        dispatch(registerAll(token?._id) as any).then((res: any) => {
            setSelectedFormat(res.payload.invoiceDateFormat)
        });
    };
    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div className="bg-[#E1E8E7] h-full" style={{ fontFamily: "poppins" }}>
            <div>
                <form
                    onSubmit={handleSubmit}
                >
                    <fieldset className="mx-2 mb-4 rounded-lg  md:p-3 h-auto">
                        <legend className="p-4  font-bold text-[#008065]  ml-2">
                            Date Format
                        </legend>
                        <div className="grid lg:grid-cols-2 xl:grid-cols-4 md:grid-cols-1 sm:grid-cols-1">
                            <div className='flex flex-col sm:flex-row sm:items-center  gap-4 ml-6'>
                                <label
                                    className=' whitespace-nowrap'
                                >
                                    <span
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            color: "#008065",
                                        }}>
                                        Invoice Date Format:  </span>
                                </label>
                                <CustomFormControl variant="outlined" fullWidth>
                                    <CustomSelect
                                        sx={{
                                            "&:focus": { borderColor: "#07ad7b" }, 
                                            "&.Mui-focused": { borderColor: "#07ad7b !important" },
                                        }}
                                        labelId="date-format-label"
                                        id="date-format"
                                        defaultValue={selectedFormat}
                                        label="Date Format"
                                        className="border-2 border-[#949494] focus:border-[#07ad7b] !rounded-[7px] px-2 h-9 w-48 sm:w-48 md:w-48 lg:w-48 xl:w-48 2xl:w-56 bg-[white]"
                                        onChange={(event) => setSelectedFormat(event.target.value as string)}
                                        renderValue={() => formattedDate}
                                    >
                                        {dateFormats.map((format, index) => (
                                            <MenuItem key={index} value={format}>
                                                {format}
                                            </MenuItem>
                                        ))}
                                    </CustomSelect>
                                </CustomFormControl>
                            </div>
                        </div>
                        <div className="flex w-full flex-col sm:flex-row px-4 mb-3">
                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                <div className="flex flex-col mb-6"></div>
                            </div>
                            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2">
                                <div className="flex flex-col">
                                    <div className="relative"></div>
                                </div>
                            </div>
                            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 mt-5">
                                <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40 rounded">
                                    <button
                                        type="button"
                                        className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
                                        style={{
                                            backgroundColor: "#008065",
                                            fontSize: "14px",
                                            fontFamily: "poppins",
                                        }}
                                    // onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1 px-2 mt-5">
                                <div className="flex flex-col w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40">
                                    <button
                                        type="submit"
                                        className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
                                        style={{
                                            backgroundColor: "#008065",
                                            fontSize: "14px",
                                            fontFamily: "poppins",
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}

export default DatesSettings
