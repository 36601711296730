/* eslint-disable eqeqeq */
import { Box, Checkbox, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridOverlay } from "@mui/x-data-grid";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { ProductsGet } from "../../redux/actions/productAction";
import { stockPurchaseGet } from "../../redux/actions/stockAction";
import { VendorProductAll } from "../../redux/actions/vendorAction";
import { useNavigate } from "react-router-dom";
import AddExistProduct from "../components/InvoiceDetails/AddExistProduct";
import { FiPlusCircle } from "react-icons/fi";

function ProductModel({
  closeModal,
  setStockList,
  handleSelect,
  prodcutRows,
}: any) {
  const dispatch = useDispatch();
  const [existingProductList, setExistingProductList] = useState([] as any);
  const [searchTable, setSearchTable] = useState([] as any);
  const [selectedProducts, setSelectedProducts] = useState([] as any);
  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {

      const newSelectedProducts = existingProductList
        .filter(
          (item: any) =>
            item.qty &&
            !prodcutRows.some(
              (value: any) => value.productUnique_id === item.productUnique_id
            )
        )
        .map((item: any) => item.productUnique_id);
      setSelectedProducts(newSelectedProducts);
    } else {
      setSelectedProducts([]);
    }
  };

  const handleSelectProduct = (productName: string) => {
    setSelectedProducts((prevSelectedProducts: any) =>
      prevSelectedProducts.includes(productName)
        ? prevSelectedProducts.filter((name: string) => name !== productName)
        : [...prevSelectedProducts, productName]
    );
  };

  const handleOk = () => {
    const selectedProductsData = selectedProducts.map((productName: string) =>
      existingProductList.find((item: any) => item.productUnique_id === productName)
    );
    handleSelect(selectedProductsData);
    closeModal();
  };

  const handleCancelClick = () => {
    closeModal();
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    const filteredData = searchTable.filter((invoiceDetail: any) =>
      invoiceDetail.productName?.toLowerCase().includes(query)
    );
    setExistingProductList(filteredData);
  };

  const fetchData = () => {
    dispatch(ProductsGet(token?._id) as any)
      .then((productRes: any) => {
        if (productRes.type === "PRODUCT_GET_SUCCESS") {
          dispatch(stockPurchaseGet(token?._id) as any).then((stockRes: any) => {
            setStockList(stockRes.payload);
            if (stockRes.type === "STOCK_PURCHASE_GET_SUCCESS") {
              let tempArr = [];
              for (let i = 0; i < stockRes.payload.length; i++) {
                for (let j = 0; j < productRes.payload.length; j++) {
                  if (
                    stockRes.payload[i].productName ===
                    productRes.payload[j].product_name &&
                    stockRes.payload[i].qty > 0
                  ) {
                    // Existing condition preserved
                    tempArr.push({
                      ...stockRes.payload[i],
                      ...productRes.payload[j],
                      stockQty: stockRes.payload[i].qty,
                    });
                  }
                  // New condition added for Direct Sale
                  if (
                    stockRes.payload[i].productName ===
                    productRes.payload[j].product_name &&
                    stockRes.payload[i].product_flow === "Direct Sale"
                  ) {
                    tempArr.push({
                      ...stockRes.payload[i],
                      ...productRes.payload[j],
                      stockQty: stockRes.payload[i].qty,
                    });
                  }
                }
              }
              setExistingProductList(tempArr);
              setSearchTable(tempArr);
            }
          });
        }
      })
      .catch((error: any) => {
        console.error("Error fetching products:", error);
      });
  };
  const fetchDatas = () => {
    dispatch(VendorProductAll(token._id) as any).then((res: any) => {

    })
  }

  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/app/products"); // navigate to the products page
  };

  const [isSecondPopupOpen, setIsSecondPopupOpen] = useState(false);

  const toggleSecondPopup = () => {
    setIsSecondPopupOpen((prev: any) => !prev);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Disable scroll on body
    return () => {
      document.body.style.overflow = 'auto'; // Re-enable scroll when modal is closed
    };
  }, []);

  useEffect(() => {
    fetchData();
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rows = existingProductList.map((product: any, index: any) => ({
    id: uuidv4(),
    sno: index + 1,
    ...product,
  }));

  const columns: GridColDef[] = [
    {
      field: "select",
      headerName: "",
      width: 80,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Checkbox
          indeterminate={
            selectedProducts.length > 0 && selectedProducts.length < rows.length
          }
          checked={selectedProducts.length === rows.length}
          onChange={(event) => handleSelectAll(event)}
        />
      ),
      renderCell: (params) => (
        <Checkbox
          onChange={() => handleSelectProduct(params.row.productUnique_id)}
          checked={
            selectedProducts.includes(params.row.productUnique_id) &&
            !prodcutRows.some(
              (value: any) => value.productUnique_id === params.row.productUnique_id
            )
          }
          disabled={
            !params.row.qty ||
            prodcutRows?.some(
              (value: any) => value.productUnique_id === params.row.productUnique_id
            )
          }
        />
      ),
    },
    { field: "sno", headerName: "S No", width: 120 },
    {
      field: "product_name",
      headerName: "Product Name",
      flex: 2,
      minWidth: 190,
    },
    {
      field: "price",
      headerName: "Rate",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "stockQty",
      headerName: "Current Stock",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "units",
      headerName: "Units",
      flex: 2,
      minWidth: 150,
    },
  ];

  const CustomNoRowsOverlay = () => (
    <GridOverlay style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',background:"#F9FAFB",height:"45px" }}>
      <div style={{ position: 'absolute', top: 0, transform: 'translateY(50%)', fontSize: '18px', textAlign: 'center' ,color:"#008065"}}>
        No Data Found
      </div>
    </GridOverlay>
  );

  return (
    <div>
      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
        <motion.div
          className="modal-container"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: "spring", damping: 18 }}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 overflow-y-auto">
            <motion.div className="relative z-20 rounded bg-[white] text-center shadow-xl transform transition-all my-8 w-11/12 mx-auto border border-[#008065]">
              <div className='items-start justify-between p-2 border-solid border-slate-200 bg-[#008065]'>
                <div className='flex p-1 border-solid border-slate-200 rounded-b justify-between  ' >
                  <h2 className="md:text-xl 2xl:text-xl font-semibold text-white text-xl">Existing Products</h2>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className='cursor-pointer mt-1.5' viewBox="0 0 384 512" fill='#fff' onClick={closeModal}><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
                </div>
              </div>
              <div className="bg-[#F1F7F6] p-[8px] m-[5px]" style={{ borderRadius: "8px" }}>
                <div className="flex flex-wrap justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                  <div className="flex items-center">
                    <div className="relative flex flex-wrap mb-2">
                      <div className="relative flex mb-2 mr-4">
                        <input
                          onChange={(e) => onSearch(e)}
                          type="text"
                          id="simple-search"
                          className="w-44 bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                          placeholder="Search"
                        />
                        <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                          <svg
                            className="w-4 h-4"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                            />
                          </svg>
                        </span>
                      </div>
                      <button
                      type="button"
                        onClick={toggleSecondPopup}
                        className="w-44 flex items-center bg-[#008065] text-white px-4 py-2 rounded-md mb-2"
                      >
                        <FiPlusCircle className="h-5 mr-2" />
                        <span className="ml-2">Add Product</span>
                      </button>
                    </div>
                  </div>
                </div>

                <Box sx={{ height: "60vh", width: "100%" }}>
                  {/* Existing DataGrid logic */}
                  {existingProductList.length === 0 ? (
                    <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    pageSizeOptions={[5, 10, 25, 50]}
                    disableRowSelectionOnClick
                    
                    />
                  ) : (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 25, 50]}
                      disableRowSelectionOnClick
                    />
                  )}
                </Box>
              </div>
              <div className="rounded bg-[white] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[white] text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#008065] text-base font-medium text-[white] hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleOk}
                >
                  OK
                </button>
              </div>
            </motion.div>

            {/* Second Popup */}
            {isSecondPopupOpen && (
              <AddExistProduct
                toggleSecondPopup={toggleSecondPopup}
                setIsSecondPopupOpen={setIsSecondPopupOpen}
                fetchData={fetchData} />
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default ProductModel;