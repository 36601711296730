/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IconStyle, defaultInputSmStyle } from "../../commen/defaultStyles";
import NumberFormat from "../../commen/NumberFormat";
import StockUpdateModel from "../../commen/StockUpdateModel";
import DeleteIcon from "../../Icons/DeleteIcon";
import PurchaseProductModel from "../../models/PurchaseProductModel";
import CalculateRoundOff from "../../commen/CalculateRoundOff";
import CalculateRoundTotalAmount from "../../commen/CalculateRoundTotalAmount";

function PurchaseReturnProduct({
  newExistProduct,
  setNewexistProduct,
  formData,
  setFormData,
  TaxAllList,
  updateProductErrors,
  paramsId,
  prodcutErrors,
  prodcutRows,
  setProductRows,
  productError,
  setVendorErrors,
  setProdcutErrors,
  editValue,
  setCashAmount,
  creditAmount,
  setbalanceAmount,
  setreceivedAmount,
  setSelectedPayment,
  roundOffValue,
  roundOffEnable,
}: any) {
  // *******************************Product Sction*********************************
  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  // const [newExistProduct, setNewexistProduct] = useState([{ exist: "", originalValue: '' }] as any);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleInputChange = (index: any, fieldName: any, value: any) => {
    newExistProduct[index]["exist"] = value;
    const newArray: any = [...prodcutRows];
    if (fieldName === "qty") {
      if (paramsId) {
        if (newExistProduct[index]["originalValue"] < Number(value)) {
          setShowConfirmation(true);
          return;
        }
      } else if (newExistProduct[index]["originalValue"] < Number(value)) {
        setShowConfirmation(true);
        return;
      }
    }
    newArray[index][fieldName] = value;
    updateProductErrors(index, fieldName, value);

    if (fieldName === "qty" || fieldName === "rate") {
      newArray[index]["amount"] =
        newArray[index].rate && newArray[index].qty
          ? parseFloat(newArray[index].rate) * parseFloat(newArray[index].qty)
          : !newArray[index].rate
          ? 0
          : 0;
      newArray[index]["amount"] = newArray[index]["amount"].toFixed(2);
      setProductRows(newArray);
      let subAmount: any = 0;
      for (let i = 0; i < newArray.length; i++) {
        subAmount += Number(newArray[i].amount);
      }
      subAmount = subAmount.toFixed(2);

      if (paramsId) {
        let discounts: any = 0;
        let ExtraFees: any = 0;
        let taxAmounst: any = 0;
        for (let i = 0; i < formData.tax.length; i++) {
          if (formData.tax[i].taxName === "Extra Fees") {
            ExtraFees = formData.tax[i].taxPersent;
          }
        }


        for (let i = 0; i < formData.tax.length; i++) {
          let Totals =
            // parseFloat(taxAmounst) +
            // parseFloat(ExtraFees) +
            parseFloat(subAmount);
          if (formData.tax[i].taxName === "Discount") {
            discounts = Totals * (parseFloat(formData.tax[i].taxPersent) / 100);
            formData.tax[i].taxAmount = discounts;
          }
        }
        for (let i = 0; i < formData.tax.length; i++) {
          if (
            formData.tax[i].taxName !== "Extra Fees" &&
            formData.tax[i].taxName !== "Discount"
          ) {
            let subAmountWithoutDiscount = parseFloat(subAmount) - discounts;
            let taxPercentage = parseFloat(formData.tax[i].taxPersent) || 0;
            let currentTaxAmount = subAmountWithoutDiscount * (taxPercentage / 100);

            formData.tax[i].taxAmount = currentTaxAmount;
            taxAmounst += currentTaxAmount;
          }
        }


        let finalamount = discounts
          ? parseFloat(taxAmounst) +
          parseFloat(ExtraFees) +
          parseFloat(subAmount) -
          discounts
          : parseFloat(taxAmounst) +
          parseFloat(ExtraFees) +
          parseFloat(subAmount);

        // if (finalamount < formData.receivedAmount) {
        //   setShowConfirmation(true)
        //   return; // Exit the function if the condition is met
        // }
        // let receivedAmount: any = finalamount == 0 ? 0 : finalamount - formData.receivedAmount
        // setbalanceAmount(parseFloat(receivedAmount.toFixed(2)))
        // clientErrors["balanceAmount"] = ''
        // clientErrors["chequeNum"] = ''
        setreceivedAmount(finalamount)
        setFormData({
          ...formData,
          product: newArray,
          subtotal: subAmount,
          tax: formData.tax,
          roundOff: roundOffEnable == true ? CalculateRoundOff(roundOffValue, finalamount):0,
          toatalAmount:roundOffEnable == true ? CalculateRoundTotalAmount(roundOffValue, finalamount):Math.round(finalamount*100)/100,
        });
      } else {
        let discount: any = TaxAllList[0]?.discountStatus
          ? (parseFloat(TaxAllList[0]?.discountPercentage) / 100) * subAmount
          : 0;
        let discountAmount: any = subAmount - discount;

        let cgst: any = TaxAllList[0]?.cgstStatus
          ? (parseFloat(TaxAllList[0]?.cgstPercentage) / 100) * discountAmount
          : 0;

        let sgst: any = TaxAllList[0]?.sgstStatus
          ? (parseFloat(TaxAllList[0]?.sgstPercentage) / 100) * discountAmount
          : 0;
        let igst: any = TaxAllList[0]?.igstStatus
          ? (parseFloat(TaxAllList[0]?.igstPercentage) / 100) * discountAmount
          : 0;
        let vat: any = TaxAllList[0]?.vatStatus
          ? (parseFloat(TaxAllList[0]?.vatPercentage) / 100) * discountAmount
          : 0;
        let others: any = TaxAllList[0]?.othersStatus
          ? (parseFloat(TaxAllList[0]?.othersPercentage) / 100) * discountAmount
          : 0;
        let extrafees: any = TaxAllList[0]?.extraFeesStatus
          ? TaxAllList[0]?.extraFees
          : 0;
        newArray[index]["nontaxableAmount"] = TaxAllList[0]?.discountStatus
          ? parseFloat(newArray[index]["amount"]) -
            (parseFloat(TaxAllList[0]?.discountPercentage) / 100) *
              newArray[index]["amount"]
          : newArray[index]["amount"];

        let Totals: any =
          parseFloat(cgst) +
          parseFloat(sgst) +
          parseFloat(igst) +
          parseFloat(vat) +
          parseFloat(others) +
          parseFloat(extrafees) +
          parseFloat(discountAmount);

        let taxArray: any = [];
        for (let i = 0; i < TaxAllList.length; i++) {
          if (TaxAllList[0]?.cgstStatus) {
            let cgstobject = {
              taxName: "CGST",
              cgstStatus: TaxAllList[0]?.cgstStatus,
              taxPersent: TaxAllList[i]?.cgstPercentage,
              taxAmount: cgst.toFixed(2),
            };
            taxArray.push(cgstobject);
          }
          if (TaxAllList[0]?.sgstStatus) {
            let sgstobject = {
              taxName: "SGST",
              sgstStatus: TaxAllList[0]?.sgstStatus,
              taxPersent: TaxAllList[i]?.sgstPercentage,
              taxAmount: sgst.toFixed(2),
            };
            taxArray.push(sgstobject);
          }
          if (TaxAllList[0]?.igstStatus) {
            let igstobject = {
              taxName: "IGST",
              igstStatus: TaxAllList[0]?.igstStatus,
              taxPersent: TaxAllList[i]?.igstPercentage,
              taxAmount: igst.toFixed(2),
            };
            taxArray.push(igstobject);
          }
          if (TaxAllList[0]?.vatStatus) {
            let vatobject = {
              taxName: "VAT",
              vatStatus: TaxAllList[0]?.vatStatus,
              taxPersent: TaxAllList[i]?.vatPercentage,
              taxAmount: vat.toFixed(2),
            };
            taxArray.push(vatobject);
          }
          if (TaxAllList[0]?.othersStatus) {
            let othersobject = {
              taxName: "OTHERS",
              othersStatus: TaxAllList[0]?.othersStatus,
              taxPersent: TaxAllList[i]?.othersPercentage,
              taxAmount: others.toFixed(2),
            };
            taxArray.push(othersobject);
          }
          if (TaxAllList[0]?.extraFeesStatus) {
            let extobject = {
              taxName: "Extra Fees",
              extraFeesStatus: TaxAllList[0]?.extraFeesStatus,
              taxPersent: TaxAllList[i]?.extraFees,
              taxAmount: Number(extrafees).toFixed(2),
            };
            taxArray.push(extobject);
          }
          if (TaxAllList[0]?.discountStatus) {
            let disobject = {
              taxName: "Discount",
              discountStatus: TaxAllList[0]?.discountStatus,
              taxPersent: TaxAllList[i]?.discountPercentage,
              taxAmount: discount.toFixed(2),
            };
            taxArray.push(disobject);
          }
        }
        console.log("taxArray--------->",taxArray);
        // let Totalamount =
        //     parseFloat(cgst) +
        //     parseFloat(sgst) +
        //     parseFloat(igst) +
        //     parseFloat(vat) +
        //     parseFloat(others) +
        //     parseFloat(extrafees) +
        //     parseFloat(subAmount);
        // let finalamount = TaxAllList[0]?.discountStatus
        //     ? Totalamount - dicount
        //     : Totalamount;
        setFormData({
          ...formData,
          product: newArray,
          subtotal: subAmount,
          tax: taxArray,
          roundOff:
            roundOffEnable == true
              ? CalculateRoundOff(roundOffValue, Totals)
              : 0,
          toatalAmount:
            roundOffEnable == true
              ? CalculateRoundTotalAmount(roundOffValue, Totals)
              : Totals,
        });
        if (creditAmount) {
          setCashAmount(Totals);
        } else {
          setCashAmount(Totals);
          setbalanceAmount("");
        }
      }
    } else {
      setProductRows(newArray);
    }
  };

  const handleExistingCalulation = (arr: any) => {
    if (arr.length === 0)
      arr.push({
        amount: "",
        hsn: "",
        prodcut: "",
        qty: "",
        rate: "",
        units: "",
      });
    const newArray: any = [...arr];
    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i].qty || newArray[i].rate) {
        newArray[i]["amount"] =
          newArray[i].rate && newArray[i].qty
            ? parseFloat(newArray[i].rate) * parseFloat(newArray[i].qty)
            : !newArray[i].rate
            ? 0
            : 0;

        setProductRows(newArray);
        let subAmount: any = 0;
        for (let i = 0; i < newArray.length; i++) {
          subAmount += newArray[i].amount;
        }
        if (paramsId) {
          let discounts: any = 0;
          let ExtraFees: any = 0;
          let taxAmounst: any = 0;
          for (let i = 0; i < formData.tax.length; i++) {
            if (formData.tax[i].taxName === "Extra Fees") {
              ExtraFees = formData.tax[i].taxPersent;
            }
          }
          for (let i = 0; i < formData.tax.length; i++) {
            if (
              formData.tax[i].taxName !== "Extra Fees" &&
              formData.tax[i].taxName !== "Discount"
            ) {
              taxAmounst +=
                subAmount * (parseFloat(formData.tax[i].taxPersent) / 100);
            }
          }

          for (let i = 0; i < formData.tax.length; i++) {
            let Totals =
              parseFloat(taxAmounst) +
              parseFloat(ExtraFees) +
              parseFloat(subAmount);
            if (formData.tax[i].taxName === "Discount") {
              discounts =
                Totals * (parseFloat(formData.tax[i].taxPersent) / 100);
              formData.tax[i].taxAmount = discounts;
            }
          }
          let finalamount = discounts
            ? parseFloat(taxAmounst) +
              parseFloat(ExtraFees) +
              parseFloat(subAmount) -
              discounts
            : parseFloat(taxAmounst) +
              parseFloat(ExtraFees) +
              parseFloat(subAmount);
          setFormData({
            ...formData,
            product: newArray,
            subtotal: subAmount,
            tax: formData.tax,
            roundOff:
              roundOffEnable == true
                ? CalculateRoundOff(roundOffValue, finalamount)
                : 0,
            toatalAmount:
              roundOffEnable == true
                ? CalculateRoundTotalAmount(roundOffValue, finalamount)
                : finalamount,
          });
          if (creditAmount) {
            setCashAmount(
              roundOffEnable == true
                ? CalculateRoundOff(roundOffValue, finalamount)
                : finalamount
            );
          } else {
            setCashAmount(
              roundOffEnable == true
                ? CalculateRoundOff(roundOffValue, finalamount)
                : finalamount
            );
            setbalanceAmount("");
          }
        } else {
          let cgst: any = TaxAllList[0]?.cgstStatus
            ? (parseFloat(TaxAllList[0]?.cgstPercentage) / 100) * subAmount
            : 0;
          let sgst: any = TaxAllList[0]?.sgstStatus
            ? (parseFloat(TaxAllList[0]?.sgstPercentage) / 100) * subAmount
            : 0;
          let igst: any = TaxAllList[0]?.igstStatus
            ? (parseFloat(TaxAllList[0]?.igstPercentage) / 100) * subAmount
            : 0;
          let vat: any = TaxAllList[0]?.vatStatus
            ? (parseFloat(TaxAllList[0]?.vatPercentage) / 100) * subAmount
            : 0;
          let others: any = TaxAllList[0]?.othersStatus
            ? (parseFloat(TaxAllList[0]?.othersPercentage) / 100) * subAmount
            : 0;
          let extrafees: any = TaxAllList[0]?.extraFeesStatus
            ? TaxAllList[0]?.extraFees
            : 0;
          let Totals: any =
            parseFloat(cgst) +
            parseFloat(sgst) +
            parseFloat(igst) +
            parseFloat(vat) +
            parseFloat(others) +
            parseFloat(extrafees) +
            parseFloat(subAmount);
          let dicount: any = TaxAllList[0]?.discountStatus
            ? (parseFloat(TaxAllList[0]?.discountPercentage) / 100) * Totals
            : 0;
          let discountValue: any = TaxAllList[0]?.discountStatus
            ? (parseFloat(TaxAllList[0]?.discountPercentage) / 100) *
              parseFloat(subAmount)
            : 0;
          let taxArray: any = [];
          for (let i = 0; i < TaxAllList.length; i++) {
            if (TaxAllList[0]?.cgstStatus) {
              let cgstobject = {
                taxName: "CGST",
                cgstStatus: TaxAllList[0]?.cgstStatus,
                taxPersent: TaxAllList[i]?.cgstPercentage,
                taxAmount: cgst.toFixed(2),
              };
              taxArray.push(cgstobject);
            }
            if (TaxAllList[0]?.sgstStatus) {
              let sgstobject = {
                taxName: "SGST",
                sgstStatus: TaxAllList[0]?.sgstStatus,
                taxPersent: TaxAllList[i]?.sgstPercentage,
                taxAmount: sgst.toFixed(2),
              };
              taxArray.push(sgstobject);
            }

            if (TaxAllList[0]?.igstStatus) {
              let igstobject = {
                taxName: "IGST",
                igstStatus: TaxAllList[0]?.igstStatus,
                taxPersent: TaxAllList[i]?.igstPercentage,
                taxAmount: igst.toFixed(2),
              };
              taxArray.push(igstobject);
            }
            if (TaxAllList[0]?.vatStatus) {
              let vatobject = {
                taxName: "VAT",
                vatStatus: TaxAllList[0]?.vatStatus,
                taxPersent: TaxAllList[i]?.vatPercentage,
                taxAmount: vat.toFixed(2),
              };
              taxArray.push(vatobject);
            }
            if (TaxAllList[0]?.othersStatus) {
              let othersobject = {
                taxName: "OTHERS",
                othersStatus: TaxAllList[0]?.othersStatus,
                taxPersent: TaxAllList[i]?.othersPercentage,
                taxAmount: others.toFixed(2),
              };
              taxArray.push(othersobject);
            }
            if (TaxAllList[0]?.extraFeesStatus) {
              let extobject = {
                taxName: "Extra Fees",
                extraFeesStatus: TaxAllList[0]?.extraFeesStatus,
                taxPersent: TaxAllList[i]?.extraFees,
                taxAmount: Number(extrafees).toFixed(2),
              };
              taxArray.push(extobject);
            }

            if (TaxAllList[0]?.discountStatus) {
              let disobject = {
                taxName: "Discount",
                discountStatus: TaxAllList[0]?.discountStatus,
                taxPersent: TaxAllList[i]?.discountPercentage,
                taxAmount: dicount.toFixed(2),
                discountValueNonTax: discountValue,
              };
              taxArray.push(disobject);
            }
          }
          let Totalamount =
            parseFloat(cgst) +
            parseFloat(sgst) +
            parseFloat(igst) +
            parseFloat(vat) +
            parseFloat(others) +
            parseFloat(extrafees) +
            parseFloat(subAmount);
          let finalamount = TaxAllList[0]?.discountStatus
            ? Totalamount - dicount
            : Totalamount;
          setFormData({
            ...formData,
            product: newArray,
            subtotal: subAmount,
            tax: taxArray,
            roundOff:
              roundOffEnable == true
                ? CalculateRoundOff(roundOffValue, finalamount)
                : 0,
            toatalAmount:
              roundOffEnable == true
                ? CalculateRoundTotalAmount(roundOffValue, finalamount)
                : finalamount,
            nontaxableTotal: 0,
          });
          if (creditAmount) {
            setCashAmount(
              roundOffEnable == true
                ? CalculateRoundOff(roundOffValue, Totals)
                : Totals
            );
          } else {
            setCashAmount(
              roundOffEnable == true
                ? CalculateRoundOff(roundOffValue, Totals)
                : Totals
            );
            setbalanceAmount("");
          }
        }
      }
    }
    // else {
    setProductRows(newArray);
    // }
    let finalErrors: any = [];
    let values = newArray?.map((item: any, index: any) => {
      let object: any = {};
      object.prodcut = item.prodcut ? true : false;
      object.rate = item.rate ? true : false;
      object.qty = item.qty ? true : false;
      finalErrors.push(object);
      return object;
    });
    setProdcutErrors(values);
  };

  const removeRow = (index: any, row: any) => {
    const newArray = [...prodcutRows];
    const newErrors = [...prodcutErrors];
    const existArray = [...newExistProduct];
    existArray.splice(index, 1);
    setNewexistProduct(() => {
      return existArray.length === 0
        ? [{ exist: "", originalValue: "" }]
        : existArray;
    });
    // if(existArray.length === 1) {
    //   setNewexistProduct([{exist: '',originalValue:''}]);
    // }
    newArray.splice(index, 1);
    handleExistingCalulation(newArray);
    setProductRows(newArray);
    setbalanceAmount("");
    setCashAmount("");
    setSelectedPayment("");
    formData.paymentType = "";
    if (newErrors.length > 1) newErrors.splice(index, 1);
    setProdcutErrors(newErrors);

    if (existArray.length === 0) {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        ...(prevFormData.tax = []),
        subtotal: 0,
        toatalAmount: 0,
      }));
      setVendorErrors({});
    }
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      cashAmount: "",
      paymentType: "",
    }));
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleConfirm = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (editValue) {
      setProductRows(formData.product);
      let obj: any = [];
      formData.product.map((item: any) =>
        obj.push({ exist: item.qty, originalValue: item.qty })
      );
      setNewexistProduct(obj);
    } else {
      // setProductRows([
      //     { prodcut: "", hsn: "", rate: "", qty: "", amount: 0, productName: "" },
      // ]);
    }
  }, [editValue]);

  const handleSelect = (dataArray: any[]) => {
    const containsEmptyRows = prodcutRows.some((row: any) =>
      Object.values(row).some((value) => !value)
    );
    const containsEmptyRowsinExistProduct = newExistProduct.some((row: any) =>
      Object.values(row).some((value) => !value)
    );

    if (containsEmptyRowsinExistProduct) {
      const nonEmptyRows = newExistProduct.filter((row: any) =>
        Object.values(row).some(Boolean)
      );
      setNewexistProduct(nonEmptyRows);
    }
    if (containsEmptyRows) {
      const nonEmptyRows = prodcutRows.filter((row: any) =>
        Object.values(row).some(Boolean)
      );
      setProductRows(nonEmptyRows);
    }

    const newRows = dataArray.map((data) => ({
      prodcut: data.prod_Name,
      hsn: data.hsn,
      rate: data.purchasePrice,
      qty: data.qty,
      amount:
        (parseFloat(data.saleAmount) ? parseFloat(data.saleAmount) : 0) *
        parseFloat(data.qty),
      productName: data.prod_Name,
      // units: data.units
    }));

    setProductRows((prevRows: any[]) => {
      const updatedRows = [...prevRows, ...newRows];
      handleExistingCalulation(updatedRows);
      return updatedRows;
    });

    setNewexistProduct((prev: any) => [
      ...prev,
      ...dataArray.map((row: any) => ({
        exist: Number(row.qty),
        originalValue: Number(row.qty),
      })),
    ]);

    setIsModalOpen(false);
  };

  return (
    <div className="mb-4 ">
      <div
        className={
          "hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-4 py-2 mb-2 text-white"
        }
        style={{ backgroundColor: "#008065" }}
      >
        <div
          style={{
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 700,
          }}
          className={
            " w-full md:w-[17%] lg:w-[17%] xl:w-[10%] 2xl:w-[6%] text-left"
          }
        >
          <span className="inline-block">S No</span>
        </div>
        <div
          style={{
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 700,
          }}
          className={
            " w-full sm:w-[192%] md:w-[90%] lg:w-[80%] xl:w-[81%] 2xl:w-[81%] text-center"
          }
        >
          <span className="inline-block">Products</span>
        </div>
        {userdata?.hsnNo ? (
          <>
            <div
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: 700,
              }}
              className={
                " w-full md:w-[55%] lg:w-[15%] 2xl:w-[15%] text-left sm:pr-10 text-right"
              }
            >
              <span className="inline-block">HSN/SAC</span>
            </div>
          </>
        ) : (
          <></>
        )}
        <div
          style={{
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 700,
          }}
          className={
            " w-full md:w-[14%] xl:w-[14%] 2xl:w-[14%] text-left sm:pr-5 text-right"
          }
        >
          Rate
        </div>
        <div
          style={{
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 700,
          }}
          className={
            " w-full md:w-[16%] xl:w-[16%] 2xl:w-[16%] text-left sm:pr-5 text-right"
          }
        >
          Qty
        </div>
        <div
          style={{
            fontFamily: "poppins",
            fontSize: "14px",
            fontWeight: 700,
          }}
          className={
            "  w-full md:w-[17%] xl:w-[17%] 2xl:w-[17%] text-right sm:pr-10"
          }
        >
          Total
        </div>
      </div>

      {prodcutRows?.map((productrow: any, index: any) => (
        <div
          key={index}
          className={
            "flex flex-col sm:flex-row rounded-lg  w-full gap-2 md:gap-0 py-1 items-center relative " +
            (index % 2 !== 0 ? " bg-gray-50 " : "")
          }
        >
          <div
            style={{
              fontFamily: "poppins",
              textAlign: "left",
              fontSize: "14px",
              fontWeight: 700,
            }}
            className={
              "w-full md:w-[25%] lg:w-[25%]  xl:w-[25%] 2xl:w-[7%]  text-center  sm:pr-3  flex flex-row sm:block "
            }
          >
            <span className="sm:hidden w-1/2 flex flex-row items-center">
              S No
            </span>

            <div className="font-title flex-1 text-right sm:text-center 2xl:text-left 2xl:ml-8 pr-1 sm:pr-0">
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 900,
                }}
              >
                {index + 1}
              </div>
            </div>
          </div>
          <div
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
            className={
              " w-full md:w-[135%] xl:w-[141%] 2xl:w-[155%] text-right sm:pr-3 flex flex-row sm:block"
            }
          >
            <span className="sm:hidden w-1/2 flex flex-row items-center">
              Products
            </span>

            <div className="font-title flex-1 text-right sm-text-left ">
              <input
                readOnly
                autoComplete="nope"
                value={productrow.prodcut ? productrow.prodcut : ""}
                placeholder="Product Name"
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                  // borderColor:
                  //     !prodcutErrors[index]?.prodcut && productError
                  //         ? "red"
                  //         : "#00A787",
                }}
                // className={
                //     defaultInputSmStyle +
                //     " text-right sm:text-left border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                // }
                className={`${defaultInputSmStyle} text-right sm:text-left border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full
                                ${
                                  !prodcutErrors[index]?.prodcut && productError
                                    ? "border-[#FF0000]"
                                    : "border-[#949494]"
                                } 
                                ${
                                  (!productError ||
                                    prodcutErrors[index]?.prodcut) &&
                                  "focus:border-[#07ad7b]"
                                }`}
                onChange={(event) => {
                  const value = event.target.value.trimStart();
                  handleInputChange(index, "prodcut", value);
                }}
                // disabled
              />
            </div>
          </div>
          {userdata?.hsnNo ? (
            <>
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={
                  " w-full md:w-[50%] xl:w-[33%] 2xl:w-[33%]  text-right  sm:pr-3  flex flex-row sm:block "
                }
              >
                <span className="sm:hidden w-1/2 flex flex-row items-center">
                  HSN/SAC
                </span>

                <div className="font-title flex-1 text-left">
                  <input
                    // disabled
                    readOnly
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      // borderColor: "#00A787",
                    }}
                    autoComplete="nope"
                    value={productrow.hsn}
                    onChange={(event) => {
                      const value = event.target.value.replace(/[^0-9]/g, "");
                      handleInputChange(index, "hsn", value);
                    }}
                    placeholder="HSN/SAC"
                    type=""
                    className={
                      defaultInputSmStyle +
                      " text-right  border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  w-full "
                    }
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
            className={
              " w-full md:w-[40%] lg:w-[35%] 2xl:w-[35%]  text-right  sm:pr-3  flex flex-row sm:block "
            }
          >
            <span className="sm:hidden w-1/2 flex flex-row items-center">
              Rate
            </span>

            <div className="font-title flex-1 text-left">
              <input
                readOnly
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                  // borderColor:
                  //     !prodcutErrors[index]?.rate && productError
                  //         ? "red"
                  //         : "#00A787",
                }}
                autoComplete="nope"
                value={productrow.rate}
                onChange={(event) => {
                  const value = event.target.value.replace(/[^0-9.]/g, "");
                  handleInputChange(index, "rate", value);
                }}
                placeholder="Rate"
                type=""
                // className={
                //     defaultInputSmStyle +
                //     " text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full   "
                // }
                className={`${defaultInputSmStyle} text-right hide_arrow remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full 
                                ${
                                  !prodcutErrors[index]?.rate && productError
                                    ? "border-[#FF0000]"
                                    : "border-[#949494]"
                                } 
                                ${
                                  (!productError ||
                                    prodcutErrors[index]?.rate) &&
                                  "focus:border-[#07ad7b]"
                                }`}
                // disabled
              />
            </div>
          </div>
          <div
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
            className={
              "w-full md:w-[25%] xl:w-[25%] 2xl:w-[25%] text-right  sm:pr-3 lg:pr-0 flex flex-row sm:block "
            }
          >
            <span className="sm:hidden w-1/2 flex flex-row items-center">
              Quantity
            </span>

            <div className="font-title flex-1 text-left">
              <input
                autoComplete="nope"
                type=""
                value={productrow.qty}
                // onChange={(event) => {
                //     const value = event.target.value.replace(/[^0-9]/g, '');
                //     handleInputChange(index, "qty", value);
                // }}
                onChange={(event) => {
                  let value = event.target.value.replace(/[^0-9]/g, "");
                  if (value.startsWith("0")) {
                    value = value.substring(1);
                  }
                  handleInputChange(index, "qty", value);
                }}
                placeholder="Qty"
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                  // borderColor:
                  //     !prodcutErrors[index]?.qty && productError
                  //         ? "red"
                  //         : "#00A787",
                }}
                // className={
                //     defaultInputSmStyle +
                //     " text-right remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full  "
                // }
                className={`${defaultInputSmStyle} text-right remove-spinner border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full
                                ${
                                  !prodcutErrors[index]?.qty && productError
                                    ? "border-[#FF0000]"
                                    : "border-[#949494]"
                                } 
                                ${
                                  (!productError ||
                                    prodcutErrors[index]?.qty) &&
                                  "focus:border-[#07ad7b]"
                                }`}
              />
            </div>
          </div>
          <div
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 700,
            }}
            className={
              " w-full md:w-[36%] lg:w-[36%] xl:w-[36%] 2xl:w-[36%]  text-right pr-0 sm:pr-12 flex flex-row sm:block whitespace-nowrap"
            }
          >
            <span className="sm:hidden w-1/2 flex flex-row items-center">
              Total
            </span>
            <span
              className={
                "inline-block w-1/2 sm:w-full px-1 pt-2 h-9 overflow-hidden"
              }
            >
              <NumberFormat value={productrow?.amount} />
            </span>
          </div>

          <div
            onClick={() => removeRow(index, productrow)}
            className="w-full sm:w-10 sm:absolute sm:right-0 sm:pl-2 sm:pt-2  h-9  sm:mt-[-17px]"
          >
            <div className="w-full text-[#9C0000] font-semibold font-title sm:h-8 sm:w-8 cursor-pointer rounded-2xl bg-[#FFE0E0] flex justify-center items-center text-center p-[5px]">
              <DeleteIcon className="h-4 w-4" style={IconStyle} />
              <span className="block sm:hidden">Delete Product</span>
            </div>
          </div>
        </div>
      ))}

      {/* Add Product Actions */}

      {isModalOpen && (
        <PurchaseProductModel
          closeModal={closeModal}
          setProductRows={setProductRows}
          prodcutRows={prodcutRows}
          handleSelect={handleSelect}
          vendorId={formData.vendorID}
        />
      )}
      {showConfirmation && (
        <StockUpdateModel
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
}

export default PurchaseReturnProduct;
