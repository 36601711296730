/* eslint-disable eqeqeq */
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AppSettingDelete,
  AppSettingGet,
  AppSettingSave,
  AppSettingUpdate,
} from "../../redux/actions/appSettingsAction";
import { registerAll } from "../../redux/actions/userAction";
import AppTable from "../../Tables/AppTable";
import ConfirmationPopup from "../commen/ConfirmationPopup";
const AppTab = () => {
  const dispatch = useDispatch();
  const usersignin = useSelector((state: any) => state.userSignin);
  const token = usersignin?.userInfo?.token;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      gstPercentage: "",
      cgstPercentage: "",
      sgstPercentage: "",
      igstPercentage: "",
      vatPercentage: "",
      othersPercentage: "",
      discountPercentage: "",
      extraFees: "",
      taxType: "",
      gstValue: "",
    },

  });

  const [AppSettingList, setAppSettingList] = useState([] as any);
  const [updateId, setUpdateId] = useState(null as any);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [gstChecked, setgstChecked] = useState(true);
  const [CgstChecked, setCgstChecked] = useState(true);
  const [SgstChecked, setSgstChecked] = useState(true);
  const [IgstChecked, setIgstChecked] = useState(false);
  const [VATChecked, setVATChecked] = useState(false);
  const [OthersChecked, setOthersChecked] = useState(false);
  const [DiscountChecked, setDiscountChecked] = useState(false);
  const [ExtraFeesChecked, setExtraFeesChecked] = useState(false);
  const [selectedTaxType, setSelectedTaxType] = useState("Bill Wise");
  const [selectedGstTaxType, setSelectedGstTaxType] = useState("GST");
  const [initialState, setInitialState] = useState({
    taxType: "Bill Wise",
    gstType: "GST",
    isAppTabVisible: true,
  });
  const [isAppTabVisible, setAppTabVisibility] = useState(() => {
    const storedValue = sessionStorage.getItem("isAppTabVisible");
    return storedValue ? JSON.parse(storedValue) : true;
  });
  const userId = JSON.parse(sessionStorage.getItem("userInfo") || "{}");

  // Fetch data and initialize the state
  const fetchDataForTax = useCallback(async () => {
    if (!userId?.token?._id) return;
    const res = await dispatch(registerAll(userId.token._id) as any);
    if (res?.type === "REGISTER_GET_SUCCESS") {
      const taxType = res.payload?.taxType || "Bill Wise";
      const gstType = res.payload?.gstValue ? res.payload?.gstValue : "GST";
      const isVisible = taxType === "Bill Wise";
      setValue("gstValue", gstType)
      setSelectedTaxType(taxType);
      setSelectedGstTaxType(gstType);
      setAppTabVisibility(isVisible);

      setInitialState({ taxType, gstType, isAppTabVisible: isVisible });
    }
  }, [dispatch, setAppTabVisibility, userId?.token?._id]);


  const handleGstCheckboxChange = () => {
    setgstChecked(!gstChecked);
    setCgstChecked(!CgstChecked)
    setSgstChecked(!SgstChecked);
    setIgstChecked(false);
    setValue(`gstPercentage`, "")
    setValue(`cgstPercentage`, "");
    setValue(`sgstPercentage`, "");
    setValue(`igstPercentage`, "");
  };



  const handleCheckboxChange3 = () => {
    setIgstChecked(!IgstChecked);
    setgstChecked(false)
    setSgstChecked(false);
    setCgstChecked(false);
    setValue(`gstPercentage`, "")
    setValue(`igstPercentage`, "");
    setValue(`cgstPercentage`, "");
    setValue(`sgstPercentage`, "");
  };
  const handleCheckboxChange4 = () => {
    setVATChecked(!VATChecked);
    setValue(`vatPercentage`, "");
  };
  const handleCheckboxChange5 = () => {
    setOthersChecked(!OthersChecked);
    setValue(`othersPercentage`, "");
  };

  const handleCheckboxChange6 = () => {
    setDiscountChecked(!DiscountChecked);
    setValue(`discountPercentage`, "");
  };

  const handleCheckboxChange7 = () => {
    setExtraFeesChecked(!ExtraFeesChecked);
    setValue(`extraFees`, "");
  };

  // ***************************cancel button section*****************************
  const resetProduct = () => {
    let defaultValues = {
      gstPercentage: "",
      cgstPercentage: "",
      sgstPercentage: "",
      igstPercentage: "",
      vatPercentage: "",
      othersPercentage: "",
      discountPercentage: "",
      extraFees: "",
    };
    setUpdateId(null);
    setCgstChecked(true)
    setSgstChecked(true)
    setIgstChecked(false)
    setVATChecked(false)
    setOthersChecked(false)
    setDiscountChecked(false)
    setExtraFeesChecked(false)
    setSelectedTaxType(initialState.taxType);
    setSelectedGstTaxType(initialState.gstType);
    setAppTabVisibility(initialState.isAppTabVisible);
    reset({
      ...defaultValues,
    });
  };


  const onSubmit = (data: any) => {
    const { gstPercentage, igstPercentage } = data;
    clearErrors(['gstPercentage', 'igstPercentage']);

    if (!gstPercentage && !igstPercentage && selectedTaxType != "Product Wise") {
      setError('gstPercentage', {
        type: 'manual',
        message: 'GST% is required if IGST is not provided',
      });
      setError('igstPercentage', {
        type: 'manual',
        message: 'IGST% is required if GST is not provided',
      });
    } else {
      let prodObj = {}
      if (selectedTaxType === "Product Wise") {
        prodObj = {
          taxType: selectedTaxType,
          template: selectedTaxType === "Product Wise" ? "" : undefined,
          invoiceType: "invoice-type-02",
          gstValue: selectedGstTaxType,
          user_id: token?._id,
        }
      } else {
        prodObj = {
          taxType: selectedTaxType,
          template: selectedTaxType === "Product Wise" ? "" : undefined,
          invoiceType: selectedTaxType === "Bill Wise" ? "invoice-type-01" : "invoice-type-02",
          billModule: selectedTaxType !== "None" ? "PRODUCT" : "",
          gstPercentage: data?.gstPercentage,
          gstStatus: !data.gstPercentage ? false : gstChecked,
          cgstPercentage: data?.cgstPercentage,
          cgstStatus: !data.cgstPercentage ? false : CgstChecked,
          sgstPercentage: data?.sgstPercentage,
          sgstStatus: !data.sgstPercentage ? false : SgstChecked,
          igstPercentage: data?.igstPercentage,
          igstStatus: !data.igstPercentage ? false : IgstChecked,
          vatPercentage: data?.vatPercentage,
          vatStatus: !data?.vatPercentage ? false : VATChecked,
          othersPercentage: data?.othersPercentage,
          othersStatus: !data?.othersPercentage ? false : OthersChecked,
          discountPercentage: data?.discountPercentage,
          discountStatus: !data?.discountPercentage ? false : DiscountChecked,
          extraFees: data?.extraFees,
          extraFeesStatus: !data?.extraFees ? false : ExtraFeesChecked,
          user_id: token?._id,
        };
      }

      if (AppSettingList?.length > 0 && selectedTaxType === "Bill Wise") {
        dispatch(AppSettingUpdate(AppSettingList[0]._id?.$oid, prodObj) as any).then((res: any) => {
          if (res?.payload) {
            fetchData();
            setValue(`gstPercentage`, "");
            setValue(`cgstPercentage`, "");
            setValue(`sgstPercentage`, "");
            setValue(`igstPercentage`, "");
            setValue(`vatPercentage`, "");
            setValue(`othersPercentage`, "");
            setValue(`discountPercentage`, "");
            setValue(`extraFees`, "");
            setUpdateId(null);
            toast.success("Update SuccessFully !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
          }

        });
      } else {
        dispatch(AppSettingSave(prodObj) as any).then((res: any) => {
          if (res) {
            fetchData();
            toast.success("Saved SuccessFully !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
          }
        });
      }
    }


  };

  const handleEdit = (item: any) => {
    setUpdateId(item?._id?.$oid);
    setgstChecked(item?.gstStatus);
    setCgstChecked(item?.cgstStatus);
    setSgstChecked(item?.sgstStatus);
    setIgstChecked(item?.igstStatus);
    setVATChecked(item?.vatStatus);
    setOthersChecked(item?.othersStatus);
    setDiscountChecked(item?.discountStatus);
    setExtraFeesChecked(item?.extraFeesStatus);
    let value = {
      gstPercentage: item?.gstPercentage,
      cgstPercentage: item?.cgstPercentage,
      sgstPercentage: item?.sgstPercentage,
      igstPercentage: item?.igstPercentage,
      vatPercentage: item?.vatPercentage,
      othersPercentage: item?.othersPercentage,
      discountPercentage: item?.discountPercentage,
      extraFees: item?.extraFees,
    };
    reset({
      ...value,
    });
    // setUpdate(true);
  };
  const handleDelete = (data: any) => {
    setShowConfirmation(true);
    setUpdateId(data._id?.$oid);
  };

  const handleConfirm = () => {
    dispatch(AppSettingDelete(updateId) as any).then((res: any) => {
      if (res?.type === 'APPSETTING_DELETE_SUCCESS') {
        resetProduct();
        setShowConfirmation(false);
        reset();
        fetchData();
        setUpdateId(null);
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const fetchData = () => {
    dispatch(AppSettingGet(token?._id) as any).then((res: any) => {
      if (res) {
        setAppSettingList(res.payload);
      }
    });
  };

  useEffect(() => {
    fetchData();
    sessionStorage.setItem("isAppTabVisible", JSON.stringify(isAppTabVisible));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppTabVisible]);

  useEffect(() => {
    if (AppSettingList) {
      setgstChecked(
        AppSettingList[0]?.gstStatus === undefined
          ? gstChecked
          : AppSettingList[0]?.gstStatus,
      );
      setCgstChecked(
        AppSettingList[0]?.cgstStatus === undefined
          ? CgstChecked
          : AppSettingList[0]?.cgstStatus,
      );
      setSgstChecked(
        AppSettingList[0]?.sgstStatus === undefined
          ? SgstChecked
          : AppSettingList[0]?.sgstStatus,
      );
      // setgstChecked(AppSettingList[0]?.gstStatus);
      setIgstChecked(AppSettingList[0]?.igstStatus);
      setVATChecked(AppSettingList[0]?.vatStatus);
      setOthersChecked(AppSettingList[0]?.othersStatus);
      setDiscountChecked(AppSettingList[0]?.discountStatus);
      setExtraFeesChecked(AppSettingList[0]?.extraFeesStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppSettingList]);
  useEffect(() => {
    fetchDataForTax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="bg-[#E1E8E7] h-full" style={{ fontFamily: "poppins" }}>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset className="mx-3 md:mx-2 mb-4 rounded-lg  ">
              <legend className="p-4  font-bold text-[#008065]  ml-2">
                Taxes & Discount
              </legend>
              <div className="grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 px-4">
                <div>
                  <label
                    htmlFor="tax-type-select"
                    className="block mb-2 text-sm text-[#008065] font-semibold"
                  >
                    Select Sales Tax Type
                  </label>
                  <select
                    id="tax-type-select"
                    value={selectedTaxType}
                    disabled={updateId ? true : false}
                    {...register("taxType", {
                      onChange: (e) => {
                        const value = e.target.value;
                        setSelectedTaxType(value);
                        setAppTabVisibility(value === "Bill Wise");

                      },
                    })}
                    className="block w-full p-2 bg-white border-2 focus:border-[#07ad7b] border-[#949494] rounded-md shadow-sm focus:outline-none sm:text-sm cursor-pointer"
                  >
                    <option value="Bill Wise">Bill Wise Tax</option>
                    <option value="Product Wise">Product Wise Tax</option>
                  </select>
                  {selectedTaxType === "Product Wise" && (
                    <div className="mt-2">
                      <label
                        htmlFor="gst-tax-type-select"
                        className="block mb-2 text-sm text-[#008065] font-semibold"
                      >
                        Select GST Type
                      </label>
                      <select
                        id="gst-tax-type-select"
                        // value={selectedGstTaxType}
                        {...register("gstValue", {
                          onChange: (e) => {
                            const value = e.target.value;
                            setSelectedGstTaxType(value)
                          },
                        })}
                        className="block w-full p-2 bg-white border-2 focus:border-[#07ad7b] border-[#949494] rounded-md shadow-sm focus:outline-none sm:text-sm cursor-pointer"
                      >
                        <option value="GST">GST</option>
                        <option value="IGST">IGST</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
              <div className="rounded-xl px-4 py-3 mb-3  bg-[#E1E8E7]">
                {isAppTabVisible && (
                  <>
                  <div className="grid md:grid-cols-4 sm:grid-cols-3 ">
                      <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1  self-center">
                        <div className="flex flex-col mb-6 ">
                          <label
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: 700,
                              color: "#008065",
                            }}
                          >
                            GST(%)
                          </label>
                          <div className="relative flex ">
                            <div className="relative m-2">
                              <input
                                type="checkbox"
                                name="GST"
                                placeholder="GST"
                                checked={gstChecked}
                                onChange={handleGstCheckboxChange}
                                className={
                                  "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                                }
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              />
                            </div>

                            <div className="relative ">
                              <select
                                disabled={!gstChecked}
                                {...register("gstPercentage", {
                                  pattern: {
                                    value: /^(?:\d*\.\d+|\d+)$/,
                                    message: "Enter a valid CGST %",
                                  },
                                  onChange: (e) => {
                                    let { value } = e.target;
                                    value = parseFloat(value);
                                    const val = value / 2
                                    clearErrors("igstPercentage");

                                    if (value === 5 || value === 3) {
                                      setValue("cgstPercentage", val.toFixed(1));
                                      setValue("sgstPercentage", val.toFixed(1));
                                    } else if (value === 0.25) {
                                      setValue("cgstPercentage", val.toFixed(3));
                                      setValue("sgstPercentage", val.toFixed(3));
                                    } else {
                                      setValue("cgstPercentage", val.toFixed());
                                      setValue("sgstPercentage", val.toFixed());
                                    }
                                  },
                                })}
                                // className={
                                //   "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-44  md:w-28 lg:w-30 xl:w-48 2xl:w-56 "
                                // }
                                className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-44  sm:w-28 lg:w-30 xl:w-48 2xl:w-56 
                                  ${errors?.gstPercentage 
                                    ? "border-[#ff0000]" // Error state
                                    : "focus:border-[#07ad7b] border-[#949494]"
                                }`}
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  // borderColor: errors?.gstPercentage ? "red" : "#008065",
                                }}
                              >
                                <option value="" selected hidden>
                                  Select GST
                                </option>
                                <option value={0}>GST 0%</option>
                                <option value={0.25}>GST 0.25%</option>
                                <option value={3}>GST 3%</option>
                                <option value={5}>GST 5%</option>
                                <option value={12}>GST 12%</option>
                                <option value={18}>GST 18%</option>
                                <option value={28}>GST 28%</option>
                                <option value={0}>Exempt</option>
                              </select>
                              {errors?.gstPercentage && (
                                <p className="text-[red] font-semibold text-xs">
                                  {errors?.gstPercentage?.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-1  self-center">
                        <div className="flex flex-col mb-6 ">
                          <label
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: 700,
                              color: "#008065",
                            }}
                          >
                            CGST(%)
                          </label>
                          <div className="relative flex ">
                            <div className="relative m-2">
                              <input
                                disabled
                                readOnly
                                type="checkbox"
                                name="GST"
                                placeholder="GST"
                                checked={CgstChecked}
                                // onChange={handleCheckboxChange}
                                className={
                                  "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                                }
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              />
                            </div>

                            <div className="relative ">
                              <input
                                readOnly
                                placeholder="CGST%"
                                disabled={!CgstChecked}
                                {...register("cgstPercentage", {
                                  pattern: {
                                    value: /^(?:\d*\.\d+|\d+)$/,
                                    message: "Enter a valid CGST %",
                                  },
                                  // onChange: (e) => {
                                  //   let { value } = e.target;
                                  //   value = value.replace(/[^0-9.]/g, "");
                                  //   if (parseFloat(value) > 14) {
                                  //     value = "0";
                                  //   }
                                  //   setValue("cgstPercentage", value);
                                  //   setValue("sgstPercentage", value);
                                  // },
                                })}
                                className={
                                  "border-2 focus:border-[#07ad7b] border-[#949494] focus:outline-none rounded-[7px] px-2 h-9 w-44  sm:w-28 lg:w-30 xl:w-48 2xl:w-56 "
                                }
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  // borderColor: "#008065",
                                }}
                              />
                              {/* <option value="" selected hidden>
                                Select CGST
                              </option>
                              <option value={0}>0%</option>
                              <option value={0.25}>0.125%</option>
                              <option value={1.5}>1.5%</option>
                              <option value={2.5}>2.5%</option>
                              <option value={6}>6%</option>
                              <option value={9}>9%</option>
                              <option value={14}>14%</option>
                            </select> */}
                              {errors?.cgstPercentage && (
                                <p className="text-red-600 font-semibold text-xs">
                                  {errors?.cgstPercentage?.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1  self-center">
                        <div className="flex flex-col mb-6 ">
                          <label
                            style={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              fontWeight: 700,
                              color: "#008065",
                            }}
                          >
                            SGST(%)
                          </label>
                          <div className="relative flex ">
                            <div className="relative m-2">
                              <input
                                readOnly
                                disabled
                                type="checkbox"
                                name="GST"
                                checked={SgstChecked}
                                // onChange={handleCheckboxChange2}
                                placeholder="GST"
                                className={
                                  "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                                }
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              />
                            </div>
                            <div className="relative ">
                              <input
                                readOnly
                                // type="text"
                                disabled={!SgstChecked}
                                {...register("sgstPercentage", {
                                  pattern: {
                                    value: /^(?:\d*\.\d+|\d+)$/,
                                    message:
                                      "Enter a valid SGST %",
                                  },
                                  // onChange: (e) => {
                                  //   let { value } = e.target;
                                  //   value = value.replace(/[^0-9.]/g, '');
                                  //   if (parseFloat(value) > 14) {
                                  //     value = "0"
                                  //   }
                                  //   setValue("sgstPercentage", value);
                                  //   setValue("cgstPercentage", value);

                                  // }
                                })}
                                placeholder="SGST%"
                                className={
                                  "border-2 focus:border-[#07ad7b] border-[#949494] focus:outline-none rounded-[7px] px-2 h-9 w-44  sm:w-28 lg:w-30 xl:w-48 2xl:w-56 "
                                }
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  // borderColor: "#008065",
                                }}
                              />
                              {/* <option value="" selected hidden>
                                Select SGST
                              </option>
                              <option value={0}>0%</option>
                              <option value={0.25}>0.125%</option>
                              <option value={1.5}>1.5%</option>
                              <option value={2.5}>2.5%</option>
                              <option value={6}>6%</option>
                              <option value={9}>9%</option>
                              <option value={14}>14%</option>   
                            </select> */}
                              {errors?.sgstPercentage && (
                                <p className="text-red-600 font-semibold text-xs">
                                  {errors?.sgstPercentage?.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1  self-center">
                        <div className="flex flex-col mb-6 ">
                          <label
                            style={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              fontWeight: 700,
                              color: "#008065",
                            }}
                          >
                            IGST(%)
                          </label>
                          <div className="relative flex ">
                            <div className="relative m-2">
                              <input
                                type="checkbox"
                                name="GST"
                                checked={IgstChecked}
                                onChange={handleCheckboxChange3}
                                placeholder="GST"
                                className={
                                  "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                                }
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              />
                            </div>
                            <div className="relative">
                              <select
                                // type="text"
                                disabled={!IgstChecked}
                                {...register("igstPercentage", {
                                  pattern: {
                                    value: /^(?:\d*\.\d+|\d+)$/,
                                    message:
                                      "Enter a valid IGST %",
                                  },
                                  onChange: (e) => {
                                    let { value } = e.target;
                                    value = value.replace(/[^0-9.]/g, '')
                                    clearErrors(['gstPercentage']);

                                    if (parseFloat(value) > 100) {
                                      value = "0"
                                    }
                                    setValue("igstPercentage", value);
                                  }
                                })}
                                // placeholder="IGST"
                                // className={
                                //   "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-44  md:w-28 lg:w-30 xl:w-48 2xl:w-56 "
                                // }
                                className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-44  sm:w-28 lg:w-30 xl:w-48 2xl:w-56 
                                  ${errors?.igstPercentage 
                                    ? "border-[#ff0000]" // Error state
                                    : "focus:border-[#07ad7b] border-[#949494]"
                                }`}
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  // borderColor: errors?.igstPercentage ? "red" : "#008065",
                                }}
                              >
                                <option value="" selected hidden>
                                  Select IGST
                                </option>
                                <option value={0}>IGST 0%</option>
                                <option value={0.25}>IGST 0.25%</option>
                                <option value={3}>IGST 3%</option>
                                <option value={5}>IGST 5%</option>
                                <option value={12}>IGST 12%</option>
                                <option value={18}>IGST 18%</option>
                                <option value={28}>IGST 28%</option>
                                <option value={0}>Exempt</option>
                              </select>
                              {errors?.igstPercentage && (
                                <p className="text-[red] font-semibold text-xs">
                                  {errors?.igstPercentage?.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1  self-center">
                        <div className="flex flex-col mb-6 ">
                          <label
                            style={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              fontWeight: 700,
                              color: "#008065",
                            }}
                          >
                            VAT(%)
                          </label>
                          <div className="relative flex ">
                            <div className="relative m-2">
                              <input
                                type="checkbox"
                                name="GST"
                                checked={VATChecked}
                                onChange={handleCheckboxChange4}
                                placeholder="GST"
                                className={
                                  "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                                }
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              />
                            </div>
                            <div className="relative ">
                              <input
                                type="text"
                                // name="VAT"
                                readOnly={!VATChecked}
                                {...register("vatPercentage", {
                                  pattern: {
                                    value: /^(?:\d*\.\d+|\d+)$/,
                                    message:
                                      "Enter a valid VAT %",
                                  },
                                  onChange: (e) => {
                                    let { value } = e.target;
                                    value = value.replace(/[^0-9.]/g, '');
                                    if (parseFloat(value) > 100) {
                                      value = "0"
                                    }
                                    setValue("vatPercentage", value);
                                  }
                                })}
                                placeholder="VAT"
                                // className={
                                //   "border-2 focus:border-[#07ad7b] border-[#949494] focus:outline-none rounded-[7px] px-2 h-9 w-44  md:w-28 lg:w-30 xl:w-48 2xl:w-56"
                                // }
                                className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-44  sm:w-28 lg:w-30 xl:w-48 2xl:w-56 ${
                                  errors?.vatPercentage
                                    ? "border-red-600 focus:border-red-600"
                                    : "border-[#949494] focus:border-[#07ad7b]"
                                }`}
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  // borderColor: "#008065",
                                }}
                              />
                              {errors?.vatPercentage && (
                                <p className="text-red-600 font-semibold text-xs">
                                  {errors?.vatPercentage?.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1  self-center">
                        <div className="flex flex-col mb-6 ">
                          <label
                            style={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              fontWeight: 700,
                              color: "#008065",
                            }}
                          >
                            Others(%)
                          </label>
                          <div className="relative flex ">
                            <div className="relative m-2">
                              <input
                                type="checkbox"
                                name="GST"
                                checked={OthersChecked}
                                onChange={handleCheckboxChange5}
                                placeholder="GST"
                                className={
                                  "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700 "
                                }
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              />
                            </div>
                            <div className="relative ">
                              <input
                                type="text"
                                // name="OTHERS"
                                placeholder="Others"
                                {...register("othersPercentage", {
                                  pattern: {
                                    value: /^(?:\d*\.\d+|\d+)$/,
                                    message:
                                      "Enter a valid Others %",
                                  },
                                  onChange: (e) => {
                                    let { value } = e.target;
                                    value = value.replace(/[^0-9.]/g, '');
                                    if (parseFloat(value) > 100) {
                                      value = "0"
                                    }
                                    setValue("othersPercentage", value);
                                  }
                                })}
                                readOnly={!OthersChecked}
                                // className={
                                //   "border-2 focus:border-[#07ad7b] border-[#949494] focus:outline-none rounded-[7px] px-2 h-9 w-44  md:w-28 lg:w-30 xl:w-48 2xl:w-56"
                                // }
                                className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-44  sm:w-28 lg:w-30 xl:w-48 2xl:w-56 ${
                                  errors?.othersPercentage
                                    ? "border-red-600 focus:border-red-600"
                                    : "border-[#949494] focus:border-[#07ad7b]"
                                }`}
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  // borderColor: "#008065",
                                }}
                              />
                              {errors?.othersPercentage && (
                                <p className="text-red-600 font-semibold text-xs">
                                  {errors?.othersPercentage?.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1  self-center">
                        <div className="flex flex-col mb-6 ">
                          <label
                            style={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              fontWeight: 700,
                              color: "#008065",
                            }}
                          >
                            Discount(%)
                          </label>
                          <div className="relative flex ">
                            <div className="relative m-2">
                              <input
                                type="checkbox"
                                name="GST"
                                checked={DiscountChecked}
                                onChange={handleCheckboxChange6}
                                placeholder="GST"
                                className={
                                  "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700 "
                                }
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              />
                            </div>
                            <div className="relative ">
                              <input
                                type="text"
                                placeholder="Discount"
                                {...register("discountPercentage", {
                                  pattern: {
                                    value: /^(?:\d*\.\d+|\d+)$/,
                                    message:
                                      "Enter a valid Discount %",
                                  },
                                  onChange: (e) => {
                                    let { value } = e.target;
                                    if (parseFloat(value) > 100) {
                                      value = "0"
                                    }
                                    setValue("discountPercentage", value);
                                  }
                                })}
                                readOnly={!DiscountChecked}
                                // className={
                                //   "border-2 focus:border-[#07ad7b] border-[#949494] focus:outline-none rounded-[7px] px-2 h-9 w-44  md:w-28 lg:w-30 xl:w-48 2xl:w-56"
                                // }
                                className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-44  sm:w-28 lg:w-30 xl:w-48 2xl:w-56 ${
                                  errors?.discountPercentage
                                    ? "border-red-600 focus:border-red-600"
                                    : "border-[#949494] focus:border-[#07ad7b]"
                                }`}
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  // borderColor: "#008065",
                                }}
                              />
                              {errors?.discountPercentage && (
                                <p className="text-red-600 font-semibold text-xs">
                                  {errors?.discountPercentage?.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1  self-center">
                        <div className="flex flex-col mb-6 ">
                          <label
                            style={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              fontWeight: 700,
                              color: "#008065",
                            }}
                          >
                            Extra Fees
                          </label>
                          <div className="relative flex ">
                            <div className="relative m-2">
                              <input
                                type="checkbox"
                                name="GST"
                                checked={ExtraFeesChecked}
                                onChange={handleCheckboxChange7}
                                placeholder="GST"
                                className={
                                  "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700 "
                                }
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              />
                            </div>
                            <div className="relative ">
                              <input
                                type="text"
                                placeholder="Extra Fees"
                                {...register("extraFees", {
                                  pattern: {
                                    value: /^(?:\d*\.\d+|\d+)$/,
                                    message:
                                      "Enter a valid ExtraFees %",
                                  },
                                  onChange: (e) => {
                                    let { value } = e.target;
                                    value = value.replace(/[^0-9.]/g, '');
                                    if (parseFloat(value) > 100) {
                                      value = "0"
                                    }
                                    setValue("extraFees", value);
                                  }
                                })}
                                readOnly={!ExtraFeesChecked}
                                className={
                                  "border-2 focus:border-[#07ad7b] border-[#949494] focus:outline-none rounded-[7px] px-2 h-9 w-44  sm:w-28 lg:w-30 xl:w-48 2xl:w-56"
                                }
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  // borderColor: "#008065",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="flex w-full flex-col sm:flex-row pl-4">
                  <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 "></div>
                  <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 "></div>
                  <div className="sm:mb-0 sm:text-left text-default-color  flex flex-row  font-title flex-2  px-2  mt-5 sm:self-center">
                    <div className="flex flex-col w-44 rounded">
                      <button
                        type="button"
                        className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                        style={{
                          backgroundColor: "#008065",
                          fontSize: "14px",
                          fontFamily: "poppins",
                        }}
                        onClick={resetProduct}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div className="sm:mb-0 sm:text-left text-default-color flex flex-row   font-title flex-1 px-2 mt-5 sm:self-center">
                    <div className="flex flex-col w-44 ">
                      <button
                        type="submit"
                        // className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                        style={{
                          backgroundColor: "#008065",
                          fontSize: "14px",
                          fontFamily: "poppins",
                        }}
                        className={`${isAppTabVisible && AppSettingList?.length > 0 && !updateId
                          ? "rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] opacity-50  cursor-not-allowed disabled"
                          : "rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                          }`}
                        disabled={isAppTabVisible && AppSettingList?.length > 0 && !updateId ? true : false}
                      >
                        {updateId ? "Update" : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
      {isAppTabVisible && (
        <AppTable
          AppSettingList={AppSettingList}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />)}

      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default AppTab;
