import styled from "@emotion/styled";
import React, { useState, useEffect, useCallback } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { currencyGet } from "../../../redux/actions/currencyAction";
import { trialBalanceGet } from "../../../redux/actions/trialBalanceAction";
import { useDispatch, useSelector } from "react-redux";
import { companyAll } from "../../../redux/actions/companyAction";
const StyledDiv = styled("div")({
	position: "relative",
	// marginRight: "0.5rem",
	marginTop: "0rem",
	overflowY: "auto",
	height: "calc(100vh - 215px)",
	"@media (min-width: 640px)": {
		height: "calc(100vh - 160px)",
	},
	"&::-webkit-scrollbar": {
		width: "5px",
		height: "5px",
	},
	"&::-webkit-scrollbar-track": {
	  backgroundColor: "text-emerald-200",
	},
	"&::-webkit-scrollbar-thumb": {
	  backgroundColor: "#008065",
	  borderRadius: "2px",
	},
  });
  


interface ExpenseCategoryDetail {
	main_category: string;
	category: Array<{
		expence_category: string;
		total: number;
	}>;
}
interface ProfitData {
	excategory_totals: ExpenseCategoryDetail[];
}
const TrialBalanceSheet = () => {
	const [companyLists, setCompanyList] = useState(
		[] as { company_name: string }[]
	);

	const [selectedPeriod, setSelectedPeriod] = useState<string>("All");
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(new Date());

	// Function to format date as dd/MM/yyyy
	const formatDate = (date: Date | null): string =>
		date ? date.toLocaleDateString("en-GB") : "";

	const handlePeriodChange = (period: string) => {
		setSelectedPeriod(period);

		const today = new Date();
		const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
		const lastDayOfMonth = new Date(
			today.getFullYear(),
			today.getMonth() + 1,
			0
		);

		if (period === "All") {
			setStartDate(today);
			setEndDate(today);
		} else if (period === "This Month") {
			setStartDate(firstDayOfMonth);
			setEndDate(lastDayOfMonth);
		} else if (period === "Last Month") {
			const firstDayLastMonth = new Date(
				today.getFullYear(),
				today.getMonth() - 1,
				1
			);
			const lastDayLastMonth = new Date(
				today.getFullYear(),
				today.getMonth(),
				0
			);
			setStartDate(firstDayLastMonth);
			setEndDate(lastDayLastMonth);
		} else if (period === "This Quarter") {
			const currentQuarter = Math.floor(today.getMonth() / 3);
			const firstDayQuarter = new Date(
				today.getFullYear(),
				currentQuarter * 3,
				1
			);
			const lastDayQuarter = new Date(
				today.getFullYear(),
				(currentQuarter + 1) * 3,
				0
			);
			setStartDate(firstDayQuarter);
			setEndDate(lastDayQuarter);
		} else if (period === "This Year") {
			const firstDayYear = new Date(today.getFullYear(), 0, 1);
			const lastDayYear = new Date(today.getFullYear(), 11, 31);
			setStartDate(firstDayYear);
			setEndDate(lastDayYear);
		} else if (period === "Custom") {
			setStartDate(null);
			setEndDate(null);
		}
	};

	useEffect(() => {
		if (selectedPeriod === "All") {
			const today = new Date();
			setStartDate(today);
			setEndDate(today);
		}
	}, [selectedPeriod]);

	const [isCurrent, setIsCurrent] = useState(false);
	const [isSubAccordion1, setIsSubAccordion1] = useState(false);
	const [isInputduties, setInputduties] = useState(false);
	const [isCaseAccount, setCase] = useState(false);
	const [isSubAccordion2, setIsSubAccordion2] = useState(false);
	const [isOpening, setOpening] = useState(false);
	const [isCase1, setCase1] = useState(false);
	const [isCaseAdjument, setCaseAdjument] = useState(false);
	const [isOutput, setOutput] = useState(false);
	const [isCurrents, setCurrents] = useState(false);
	const [isGst, setGst] = useState(false);
	const [isCurrentAssets, setCurrentAssets] = useState(false);
	const [isBankAccount, setBankAcccount] = useState(false);
	const [isSundry, setSundry] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isEquities, setEquities] = useState(false);
	const [isSale, setSale] = useState(false);
	const [isPurchase, setPurchase] = useState(false);
	const [isDirect, setDirect] = useState(false);
	const [isIndirect, setIndirect] = useState(false);
	const [isCurrentLiabilities, setCurrentLiabilities] = useState(false);
	const [isCapital, setCapital] = useState(false);

	const toggleAccordion1 = (e: any) => {
		setIsCurrent((prev) => !prev);
	};
	const toggleCaseAdjument = (e: any) => {
		e.stopPropagation(); //
		setCaseAdjument((prev) => !prev);
	};
	const toggleSubAccordion1 = (e: any) => {
		e.stopPropagation(); //
		setIsSubAccordion1((prev) => !prev);
	};
	const toggleSundry = (e: any) => {
		e.stopPropagation(); //
		setSundry((prev) => !prev);
	};

	const toggleCash = (e: any) => {
		e.stopPropagation(); //
		setCase1((prev) => !prev);
	};
	const toggleOutput = () => {
		setOutput(!isOutput);
	};

	const toggleOpening = (e: any) => {
		e.stopPropagation(); //
		setOpening((prev) => !prev);
	};

	const toggleInputduties = (e: any) => {
		e.stopPropagation();
		setInputduties((prev) => !prev);
	};
	const toggleInputgst = (e: any) => {
		e.stopPropagation();
		setGst((prev) => !prev);
	};
	const toggleCashAccount = (e: any) => {
		e.stopPropagation();
		setCase((prev) => !prev);
	};

	// const toggleOwnerEquity = (e: any) => {
	//   e.stopPropagation();
	//   setIsSubAccordion4((prev) => !prev);
	// };

	const toggleSubAccordion2 = (e: any) => {
		e.stopPropagation();
		setIsSubAccordion2((prev) => !prev);
	};
	const toggleCurrent = (e: any) => {
		e.stopPropagation();
		setCurrents((prev) => !prev);
	};
	const toggleCurrentAssets = (e: any) => {
		e.stopPropagation();
		setCurrentAssets((prev) => !prev);
	};
	const toggleBankAccount = (e: any) => {
		e.stopPropagation();
		setBankAcccount((prev) => !prev);
	};

	const toggleAccordion = (e: any) => {
		e.stopPropagation();

		setIsOpen((prev) => !prev);
	};
	const toggleIndirect = (e: any) => {
		e.stopPropagation();
		setIndirect((prev) => !prev);
	};
	const toggleDirect = (e: any) => {
		e.stopPropagation();
		setDirect((prev) => !prev);
	};

	const togglePurchase = (e: any) => {
		e.stopPropagation();
		setPurchase((prev) => !prev);
	};

	const toggleSale = (e: any) => {
		e.stopPropagation();
		setSale((prev) => !prev);
	};
	// const toggleSubAccordion = () => {
	//   setSubAccordionOpen(!isSubAccordionOpen);
	// };

	const toggleEquities = (e: any) => {
		e.stopPropagation();
		setEquities((prev) => !prev);
	};
	// const toggleEquities1 = () => {
	//   setEquities(!isReserve);
	// };

	const toggleCapital = (e: any) => {
		e.stopPropagation();
		setCapital((prev) => !prev);
	};

	const toggleCurrentLiabilities = (e: any) => {
		e.stopPropagation();
		setCurrentLiabilities((prev) => !prev);
	};

	const dispatch = useDispatch();
	const [currencySymbol, setcurrencySymbol] = useState("₹");
	const usersignin = useSelector((state: any) => state.userSignin);
	const token = usersignin?.userInfo?.token;

	const [profitData, setProfitData] = useState({
		closingStock: 0,
		salesReturnClosing: 0,
		totalPurchase: 0,
		totalPurchaseReturn: 0,
		totalPurchaseRevenue: 0,
		totalSalesTot: 0,
		totalSalesReturn: 0,
		totalRevenue: 0,
		opening_bank_accounts: [],
		total_opening_amt: 0,
		sundry_debtors: [],
		sundrytotal_balance_amt: 0,
		sundry_creditors: [],
		total_balance_amount_creditors: 0,
		fixed_assets_data: [],
		fixedasset_total: 0,
		total_input_igst: 0,
		total_input_cgst: 0,
		total_outward_cgst: 0,
		total_outward_igst: 0,
		sale_cheque_array: [],
		purchase_cheque_array: [],
		other_current_asset: 0,
		other_with_current_asset: 0,
		cash_in_hand: 0,
		cashAdjustment: 0,
		cashAdding: 0,
		cashReducing: 0,
		openingBalFixedData: 0,
		directExpenses: 0,
		indirectExpenses: 0,
		excategory_totals: [],
		total_profit_amount: 0,
		assetprofitAmount: 0,
		assetnegativeAmount: 0,
		positive_cashinhand: 0,
		negative_cashinhand: 0,
	});
	const fetchData = useCallback(() => {
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});

		dispatch(companyAll() as any).then((res: any) => {
			if (res && res.payload) {
				const companyNames = res.payload.map((company: any) => ({
					company_name: company.company_name,
				}));
				setCompanyList(companyNames);
			}
		});
		dispatch(trialBalanceGet(selectedPeriod, startDate, endDate) as any)
			.then((res: any) => {
				if (res) {
					const data = res.payload;
					setProfitData({
						closingStock: data.closingStock || 0,
						salesReturnClosing: data.salesReturnClosing || 0,
						totalPurchase: data.totalPurchase || 0,
						totalPurchaseReturn: data.totalPurchaseReturn || 0,
						totalPurchaseRevenue: data.totalPurchaseRevenue || 0,
						totalSalesTot: data.totalSalesTot || 0,
						totalSalesReturn: data.totalSalesReturn || 0,
						totalRevenue: data.totalRevenue || 0,
						opening_bank_accounts: data.opening_bank_accounts || 0,
						total_opening_amt: data.total_opening_amt || 0,
						sundry_debtors: data.sundry_debtors || 0,
						sundrytotal_balance_amt: data.total_balance_amount || 0,
						sundry_creditors: data.sundry_creditors || 0,
						total_balance_amount_creditors:
							data.total_balance_amount_creditors || 0,
						fixed_assets_data: data.fixed_assets_data || 0,
						fixedasset_total: data.fixedasset_total || 0,
						total_input_igst: data.total_input_igst || 0,
						total_input_cgst: data.total_input_cgst || 0,
						total_outward_cgst: data.total_outward_gst || 0,
						total_outward_igst: data.total_outward_igst || 0,
						sale_cheque_array: data.sale_cheque_array || 0,
						purchase_cheque_array: data.purchase_cheque_array || 0,
						other_current_asset: data.other_current_asset || 0,
						other_with_current_asset: data.other_with_current_asset || 0,
						cash_in_hand: data.cash_in_hand || 0,
						cashAdjustment: data.cashAdjustment || 0,
						cashAdding: data.cashAdding || 0,
						cashReducing: data.cashReducing || 0,
						openingBalFixedData: data.openingBalFixedData || 0,
						directExpenses: data.directExpenses || 0,
						indirectExpenses: data.indirectExpenses || 0,
						excategory_totals: data.category_totals || 0,
						total_profit_amount: data.total_profit_amount || 0,
						assetprofitAmount: data.assetprofitAmount || 0,
						assetnegativeAmount: data.assetnegativeAmount || 0,
						positive_cashinhand: data.positive_cashinhand || 0,
						negative_cashinhand: data.negative_cashinhand || 0,
					});
				} else {
					console.error("No data returned from API");
				}
			})
			.catch((error: any) => {
				console.error("Error fetching data:", error);
			});
	}, [dispatch, token?._id, selectedPeriod, startDate, endDate]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	let totalgst = profitData.total_input_cgst * 2;
	let totalinputTax = totalgst + profitData.total_input_igst;

	let totaloutwardgst = profitData.total_outward_cgst * 2;
	let totaloutputtax = totaloutwardgst + profitData.total_outward_igst;

	let openingBalanceEquity =
		profitData.total_opening_amt + profitData.openingBalFixedData;
	// let openingBalanceEquity = profitData.total_opening_amt;
	let saleAccounts = profitData.totalRevenue;
	let ownersEquity = openingBalanceEquity + profitData.cashAdding;
	let currentAssets =
		profitData.sundrytotal_balance_amt +
		totalinputTax +
		profitData.total_opening_amt +
		profitData.positive_cashinhand +
		profitData.other_current_asset;

	let currentLiabilities =
		profitData.total_balance_amount_creditors +
		totaloutputtax +
		profitData.other_with_current_asset;
	let creditSides =
		openingBalanceEquity +
		profitData.total_balance_amount_creditors +
		totaloutputtax +
		profitData.other_with_current_asset +
		saleAccounts +
		profitData.cashAdding +
		profitData.assetprofitAmount +
		profitData.negative_cashinhand;

	let debitSides =
		profitData.sundrytotal_balance_amt +
		totalinputTax +
		profitData.other_current_asset +
		profitData.total_opening_amt +
		profitData.cashReducing +
		profitData.positive_cashinhand +
		profitData.totalPurchaseRevenue +
		profitData.indirectExpenses +
		profitData.directExpenses +
		profitData.assetnegativeAmount +
		profitData.fixedasset_total;

	let directArray: any[] = [];
	let indirectArray: any[] = [];

	profitData.excategory_totals.forEach((item: any) => {
		if (item.main_category === "Direct") {
			directArray.push({
				category: item.expence_category,
				amount: item.total,
			});
		} else {
			indirectArray.push({
				category: item.expence_category,
				amount: item.total,
			});
		}
	});

	return (
		<>
			<div className="flex flex-col justify-center items-center">
				<span className="font-bold text-[#045545] text-xl items-center mt-2">
					{companyLists.map((company, index) => (
						<div key={index}>{company.company_name}</div>
					))}
				</span>
			</div>

			{/* new one */}
			<div className="flex flex-col sm:flex-row  mt-2 text-[#008065] font-bold mb-2 ">
				<div className="flex  items-center justify-center sm:justify-start sm:w-3/12 md:w-4/12 w-full">
					<span className="font-bold text-[#045545] text-xl mt-1 px-2  ">
						{" "}
						Trial BalanceSheet{" "}
					</span>
				</div>

				<div className=" text-[#045545] mt-2 sm:mt-0 sm:w-9/12 md:w-8/12 w-full ">
					{/* Period Dropdown */}
					<div className="flex flex-col sm:flex-row justify-center sm:justify-start lg:justify-center xl:pl-16 gap-2">
						<div className="flex items-center justify-center xl:pl-16">
							<div>
								<span className="text-[16px] mr-3">Period: </span>
							</div>
							<select
								className="border-2 !border-[#008065] rounded-md p-1.5 text-gray-700 bg-[#F1F7F6] text-[14px] focus:outline-none"
								value={selectedPeriod}
								onChange={(e) => handlePeriodChange(e.target.value)}
							>
								<option value="All">All</option>
								<option value="This Month">This Month</option>
								<option value="Last Month">Last Month</option>
								<option value="This Quarter">This Quarter</option>
								<option value="This Year">This Year</option>
								<option value="Custom">Custom</option>
							</select>
						</div>

						<div className="flex items-center  sm:w-[210px]   sm:pl-4 justify-center sm:justify-start ">
							{selectedPeriod === "Custom" ? (
								<>
									<DatePicker
										selected={startDate}
										onChange={(date) => {
											setStartDate(date);
											setSelectedPeriod("Custom");
										}}
										selectsStart
										startDate={startDate}
										endDate={endDate}
										placeholderText="Start Date"
										className=" text-gray-700 w-[105px] p-1 rounded-md bg-[#F1F7F6]  text-center focus:outline-none border-2 border-[#008065]"
										dateFormat="dd/MM/yyyy"
									/>

									<span className="mx-2">to</span>
									<DatePicker
										selected={endDate}
										onChange={(date) => {
											setEndDate(date);
											setSelectedPeriod("Custom");
										}}
										selectsEnd
										startDate={startDate}
										endDate={endDate}
										placeholderText="End Date"
										className=" text-gray-700 w-[105px] p-1 rounded-md bg-[#F1F7F6] text-center focus:outline-none border-2 border-[#008065]"
										dateFormat="dd/MM/yyyy"
									/>
								</>
							) : (
								<span>
									{/* <span className="p-1 px-4 bg-white rounded-md"> */}
									{/* {startDate && endDate
											? `${formatDate(startDate)} To ${formatDate(endDate)}`
											: "Select a period"} */}
								</span>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="max-w-8xl  mx-2 shadow-md rounded-md    overflow-x-auto mt-4">
				<StyledDiv>
				<div className="flex flex-col h-full">
					<table className="min-w-full  border-collapse relative h-full">
						<thead className="sticky top-0 bg-gray-300   text-[#008065]">
							<tr>
								<th className="px-4 py-3 text-left font-semibold border-gray-300 text-green  ">
									Account
								</th>
								<th
									colSpan={2}
									className="px-4 pl-12 text-center py-3  font-semibold text-green "
								>
									Closing Balance
								</th>
							</tr>
							<tr className="bg-gray-200">
								<th className="px-4 py-1 text-left   w-8/12"></th>
								<th className="px-4 py-1 text-right font-semibold text-green ">
									Debit
								</th>
								<th className="px-4 py-1 text-right font-semibold text-green ">
									Credit
								</th>
							</tr>
						</thead>
						<tbody>
							<h2 className="font-bold mb-2 px-4 text-lg mt-3 text-[#008065]">
								Assets
							</h2>

							<tr
								className="px-4 cursor-pointer mb-2 ml-2 mt-4 hover:bg-gray-300"
								onClick={toggleAccordion}
							>
								<td className="md:px-4 px-3 py-2 font-semibold space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md">
									<div className="flex items-center text-[#008065]">
										<span className="mr-2 ">
											{isOpen ? <FaChevronDown /> : <FaChevronRight />}
										</span>
										Fixed Assets
									</div>
								</td>
								<td className="px-4 py-2 text-right font-bold text-[#008065]">
									{`${profitData.fixedasset_total.toFixed(2)}`}
								</td>
								<td className="px-4 py-2 text-right text-[#008065] font-bold rounded-r-md">
									---
								</td>
							</tr>

							{isOpen && (
								<>
									{profitData.fixed_assets_data?.map((asset: any, index) => (
										<tr className="mt-4 font-bold">
											<td className="md:px-6 px-4 py-2 text-black">
												<ul className="list-disc list-inside">
													<li>{asset.asset_name}</li>
												</ul>
											</td>
											<td className="px-4 py-2 text-right text-black">
												{asset.NonTaxableAmount.toFixed(2)}
											</td>
											<td className="px-4 py-2 text-right text-black">---</td>
										</tr>
									))}
								</>
							)}
						</tbody>

						<tbody className="space-y-3 mt-3">
							<tr
								className="cursor-pointer hover:bg-gray-300"
								onClick={toggleAccordion1}
							>
								<td className="md:px-4 px-3 py-2 font-semibold  space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md ml-4 p-4">
									<div className="flex items-center text-[#008065]">
										<span className="mr-2  ">
											{isCurrent ? <FaChevronDown /> : <FaChevronRight />}
										</span>
										Current Assets
									</div>
								</td>
								<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
									{`${currentAssets.toFixed(2)}`}
								</td>
								<td className="px-4 py-2 text-right border-gray-300 rounded-r-md text-[#008065] font-bold">
									{parseFloat(profitData.negative_cashinhand.toFixed(2)) !== 0
										? profitData.negative_cashinhand.toFixed(2)
										: "---"}
								</td>
							</tr>
							{isCurrent && (
								<>
									<tr
										className=" cursor-pointer mt-4"
										onClick={toggleSubAccordion1}
									>
										<td className="md:px-8 px-6 py-2 font-semibold border-gray-300">
											<div className="flex items-center text-[#008065]">
												<span className="mr-2 ">
													{isSubAccordion1 ? (
														<FaChevronDown />
													) : (
														<FaChevronRight />
													)}
												</span>
												Sundry Debtors
											</div>
										</td>
										<td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
											{`${profitData.sundrytotal_balance_amt.toFixed(2)}`}
										</td>
										<td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
											---
										</td>
									</tr>

									{isSubAccordion1 && (
										<>
											{profitData.sundry_debtors?.map((debtor: any, index) => (
												<tr key={index} className="mt-4 font-bold">
													<td className="md:px-9 px-8 py-2 text-black border-gray-300">
														<ul className="list-disc list-inside">
															<li>{debtor.clientName}</li>
														</ul>
													</td>
													<td className="px-4 py-2 text-right border-gray-300 text-black-700">
														{debtor?.balanceAmount.toFixed(2)}{" "}
													</td>
													<td className="px-4 py-2 text-right border-gray-300 text-black-700">
														---
													</td>
												</tr>
											))}
										</>
									)}

									<tr
										className=" cursor-pointer mt-4"
										onClick={toggleInputduties}
									>
										<td className="md:px-8  px-6 py-2 font-semibold border-gray-300">
											<div className="flex items-center text-[#008065]">
												<span className="mr-2 ">
													{isInputduties ? (
														<FaChevronDown />
													) : (
														<FaChevronRight />
													)}
												</span>
												Input Duties and Taxes
											</div>
										</td>
										<td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
											{totalinputTax.toFixed(2)}{" "}
										</td>
										<td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
											---
										</td>
									</tr>

									{isInputduties && (
										<>
											<tr className=" mt-4" onClick={toggleInputgst}>
												<td className="md:px-12 px-10 py-2 font-semibold border-gray-300">
													<div className="flex items-center text-[#008065]">
														<span className="mr-2 ">
															{isGst ? <FaChevronDown /> : <FaChevronRight />}
														</span>
														Input GST
													</div>
												</td>
												<td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
													{totalinputTax.toFixed(2)}{" "}
												</td>
												<td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
													---
												</td>
											</tr>

											{isGst && (
												<>
													<tr className="mt-4">
														<td className="md:px-14 px-12 py-2 text-black font-bold border-gray-300">
															<ul className="list-disc list-inside">
																<li>CGST </li>
															</ul>
														</td>
														<td className="px-4  py-2 text-right border-gray-300 text-black font-bold">
															{profitData.total_input_cgst.toFixed(2)}
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
															---
														</td>
													</tr>
													<tr className="mt-4">
														<td className="md:px-14 px-12 py-2 text-black font-bold border-gray-300">
															<ul className="list-disc list-inside">
																<li>SGST </li>
															</ul>
														</td>
														<td className="px-4 py-2 text-right border-gray-300 font-bold text-black">
															{profitData.total_input_cgst.toFixed(2)}
														</td>
														<td className="px-4 py-2 text-right border-gray-300 font-bold text-black">
															---
														</td>
													</tr>
													<tr className="mt-4">
														<td className="md:px-14 px-12 py-2 text-black font-bold border-gray-300">
															<ul className="list-disc list-inside">
																<li>IGST </li>
															</ul>
														</td>
														<td className="px-4 py-2 text-right border-gray-300 font-bold text-black">
															{profitData.total_input_igst.toFixed(2)}
														</td>
														<td className="px-4 py-2 text-right border-gray-300 font-bold text-black">
															---
														</td>
													</tr>
												</>
											)}
										</>
									)}

									<tr className="mt-4 font-bold">
										<td className="md:px-9 px-6 py-2 text-black border-gray-300">
											<ul className="list-disc list-inside">
												<li>Opening Stock </li>
											</ul>
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-black">
											---
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-black">
											---
										</td>
									</tr>

									{/* Cash Accounts */}
									<tr
										className=" cursor-pointer mt-4"
										onClick={toggleBankAccount}
									>
										<td className="px-8 py-2 font-semibold border-gray-300">
											<div className="flex items-center text-[#008065]">
												<span className="mr-2  ">
													{isBankAccount ? (
														<FaChevronDown />
													) : (
														<FaChevronRight />
													)}
												</span>
												Bank Accounts
											</div>
										</td>
										<td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
											{profitData.total_opening_amt.toFixed(2)}
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-[#008065]">
											---
										</td>
									</tr>
									{isBankAccount && (
										<>
											{profitData.opening_bank_accounts.map(
												(bank: any, index) => (
													<tr key={index} className="mt-4 font-bold">
														<td className="md:px-9 px-8 py-2 text-black border-gray-300">
															<ul className="list-disc list-inside">
																<li>
																	{bank.bankname}-{bank.holder_name}
																</li>
															</ul>
														</td>

														<td className="px-4 py-2 text-right border-gray-300 text-black-700">
															{bank.openingamt.toFixed(2)}{" "}
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-black-700">
															---
														</td>
													</tr>
												)
											)}
										</>
									)}

									<tr
										className=" cursor-pointer mt-4"
										onClick={toggleCashAccount}
									>
										<td className="md:px-8 px-6 py-2 font-semibold border-gray-300">
											<div className="flex items-center text-[#008065]">
												<span className="mr-2 ">
													{isCaseAccount ? (
														<FaChevronDown />
													) : (
														<FaChevronRight />
													)}
												</span>
												Cash Accounts
											</div>
										</td>
										{/* <td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]"> */}
										<td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
											{parseFloat(profitData.positive_cashinhand.toFixed(2)) !==
											0
												? profitData.positive_cashinhand.toFixed(2)
												: "---"}
										</td>
										{/* </td> */}
										<td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
											{parseFloat(profitData.negative_cashinhand.toFixed(2)) !==
											0
												? profitData.negative_cashinhand.toFixed(2)
												: "---"}
										</td>
									</tr>

									{isCaseAccount && (
										<tr>
											<td className="px-10 py-2 text-black font-bold border-gray-300 text-black-700">
												<ul className="list-disc list-inside">
													<li>Cash</li>
												</ul>
											</td>
											<td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
												{parseFloat(
													profitData.positive_cashinhand.toFixed(2)
												) !== 0
													? profitData.positive_cashinhand.toFixed(2)
													: "---"}
											</td>
											<td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
												{parseFloat(
													profitData.negative_cashinhand.toFixed(2)
												) !== 0
													? profitData.negative_cashinhand.toFixed(2)
													: "---"}
											</td>
										</tr>
									)}

									<tr
										className=" cursor-pointer mt-4"
										onClick={toggleCurrentAssets}
									>
										<td className="px-8 py-2 font-semibold border-gray-300">
											<div className="flex items-cente text-[#008065] ">
												<span className="mr-2  cursor-pointer">
													{isCurrentAssets ? (
														<FaChevronDown />
													) : (
														<FaChevronRight />
													)}
												</span>
												Other Current Assets
											</div>
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
											{profitData.other_current_asset.toFixed(2)}
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
											---
										</td>
									</tr>
									{isCurrentAssets && (
										<tr className="font-bold">
											<td className="px-10 py-2 text-black border-gray-300">
												<ul className="list-disc list-inside">
													<li>Undeposited Cheque</li>
												</ul>
											</td>
											<td className="px-4 py-2 text-right border-gray-300 text-black">
												{profitData.other_current_asset.toFixed(2)}
											</td>
											<td className="px-4 py-2 text-right border-gray-300 text-black">
												---
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
						<br />
						<tbody>
							<div className="md:px-4 px-4  w-72">
								<h2 className="font-bold mb-2  text-lg text-[#008065] ">
									Equities & Liabilities
								</h2>
							</div>
							<tr
								className=" cursor-pointer hover:bg-gray-300"
								onClick={toggleCapital}
							>
								<td className="md:px-4 px-3 py-2 font-semibold border-gray-300 space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md ">
									<div className="flex items-center text-[#008065]">
										<span className="mr-2 ">
											{isCapital ? <FaChevronDown /> : <FaChevronRight />}
										</span>
										Capital Accounts
									</div>
								</td>
								<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
									{`${profitData.cashReducing.toFixed(2)}`}
								</td>
								<td className="px-4 py-2 text-right border-gray-300 rounded-r-md text-[#008065] font-bold">
									{`${ownersEquity.toFixed(2)}`}
								</td>
							</tr>

							{isCapital && (
								<>
									<tr className=" cursor-pointer " onClick={toggleEquities}>
										<td className="px-8 py-2 font-semibold border-gray-300">
											<div className="flex items-center text-[#008065]">
												<span className="mr-2 ">
													{isEquities ? <FaChevronDown /> : <FaChevronRight />}
												</span>
												Owner's Equities
											</div>
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
											{`${profitData.cashReducing.toFixed(2)}`}
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
											{`${ownersEquity.toFixed(2)}`}
										</td>
									</tr>

									{isEquities && (
										<>
											<tr className=" cursor-pointer " onClick={toggleOpening}>
												<td className="px-12 py-2 font-semibold border-gray-300">
													<div className="flex items-center text-[#008065]">
														<span className="mr-2 ">
															{isOpening ? (
																<FaChevronDown />
															) : (
																<FaChevronRight />
															)}
														</span>
														Opening Balance Equities
													</div>
												</td>
												<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
													---
												</td>
												<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
													{`${openingBalanceEquity.toFixed(2)}`}
												</td>
											</tr>
											{isOpening && (
												<>
													<tr className="">
														<td className="px-14 py-2 text-black font-bold border-gray-300">
															<ul className="list-disc list-inside">
																<li>Fixed Asset Opening Balance</li>
															</ul>
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-black-700">
															---
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-black-700">
															{`${profitData.openingBalFixedData.toFixed(2)}`}
														</td>
													</tr>
													<tr className="">
														<td className="px-14 py-2 text-black font-bold border-gray-300">
															<ul className="list-disc list-inside">
																<li>Opening Bank Balance</li>
															</ul>
														</td>
														<td className="px-4 py-2 text-right border-gray-300 font-bold text-black">
															---
														</td>
														<td className="px-4 py-2 text-right border-gray-300  font-bold text-black">
															{`${profitData.total_opening_amt.toFixed(2)}`}
														</td>
													</tr>
												</>
											)}

											<tr className="cursor-pointer " onClick={toggleCash}>
												<td className="px-12 py-2 font-semibold border-gray-300">
													<div className="flex items-center text-[#008065]">
														<span className="mr-2 ">
															{isCase1 ? <FaChevronDown /> : <FaChevronRight />}
														</span>
														Adjustments
													</div>
												</td>
												<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
													{`${profitData.cashReducing.toFixed(2)}`}
												</td>
												<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
													{`${profitData.cashAdding.toFixed(2)}`}
												</td>
											</tr>
											{isCase1 && (
												<>
													<tr
														className="cursor-pointer"
														onClick={toggleCaseAdjument}
													>
														<td className="px-14 py-2 font-semibold border-gray-300">
															<div className="flex items-center text-[#008065]">
																<span className="mr-2 ">
																	{isCaseAdjument ? (
																		<FaChevronDown />
																	) : (
																		<FaChevronRight />
																	)}
																</span>
																Cash Adjument
															</div>
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
															{`${profitData.cashReducing.toFixed(2)}`}
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
															{`${profitData.cashAdding.toFixed(2)}`}
														</td>
													</tr>

													{isCaseAdjument && (
														<>
															<tr className="">
																<td className="px-16 py-2 text-black font-bold border-gray-300">
																	<ul className="list-disc list-inside">
																		<li>Add Cash</li>
																	</ul>
																</td>
																<td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
																	---
																</td>
																<td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
																	{`${profitData.cashAdding.toFixed(2)}`}
																</td>
															</tr>
															<tr className="">
																<td className="px-16 py-2  border-gray-300 text-black font-bold">
																	<ul className="list-disc list-inside">
																		<li>Remove Cash</li>
																	</ul>
																</td>
																<td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
																	{`${profitData.cashReducing.toFixed(2)}`}
																</td>
																<td className="px-4 py-2 text-right border-gray-300 text-black font-bold">
																	---
																</td>
															</tr>
														</>
													)}
												</>
											)}
										</>
									)}
									<tr className="mt-4 font-bold">
										<td className="px-10 py-2 text-black-700 border-gray-300">
											<ul className="list-disc list-inside">
												<li>Retained Earnings</li>
											</ul>
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-black-700">
											---
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-black-700">
											---
										</td>
									</tr>
								</>
							)}
						</tbody>

						<tbody>
							<tr className="mt-4 font-bold">
								<td className="px-8 py-2 text-black-700 border-gray-300">
									<ul className="list-disc list-inside">
										<li>Long-term Liabilities</li>
									</ul>
								</td>
								<td className="px-4 py-2 text-right border-gray-300 text-black-700">
									---
								</td>
								<td className="px-4 py-2 text-right border-gray-300 text-black-700">
									---
								</td>
							</tr>
						</tbody>
						<tbody>
							{/* Parent Row (Main Accordion) */}
							<tr
								className="cursor-pointer hover:bg-gray-300"
								onClick={toggleCurrentLiabilities}
							>
								<td className="md:px-4 px-3 py-2 font-semibold border-gray-300 space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md">
									<div className="flex items-center text-[#008065]">
										<span className="mr-2 ">
											{isCurrentLiabilities ? (
												<FaChevronDown />
											) : (
												<FaChevronRight />
											)}
										</span>
										Current Liabilities
									</div>
								</td>
								<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
									---
								</td>
								<td className="px-4 py-2 text-right border-gray-300 rounded-r-md text-[#008065] font-bold">
									{`${currentLiabilities.toFixed(2)}`}
								</td>
							</tr>

							{/* Child Accordions (Second-Level) */}
							{isCurrentLiabilities && (
								<>
									{/* Child Row 1 - Sundry Debtors */}
									<tr className="cursor-pointer " onClick={toggleSundry}>
										<td className="px-8 py-2 font-semibold border-gray-300">
											<div className="flex items-center text-[#008065]">
												<span className="mr-2  cursor-pointer">
													{isSundry ? <FaChevronDown /> : <FaChevronRight />}
												</span>
												Sundry Creditors
											</div>
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
											---
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
											{`${profitData.total_balance_amount_creditors.toFixed(
												2
											)}`}
										</td>
									</tr>
									{isSundry && (
										<>
											{profitData.sundry_creditors.map(
												(creditor: any, index) => (
													<tr key={index} className="mt-4 font-bold">
														<td className="md:px-9 px-8 py-2 text-black border-gray-300">
															<ul className="list-disc list-inside">
																<li>{creditor.vendorName}</li>
															</ul>
														</td>

														<td className="px-4 py-2 text-right border-gray-300 text-black-700">
															---
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-black-700">
															{creditor.balanceAmount.toFixed(2)}{" "}
														</td>
													</tr>
												)
											)}
										</>
									)}
									{/* Child Row 2 - Outward Duties and Taxes */}
									<tr className="cursor-pointer" onClick={toggleSubAccordion2}>
										<td className="px-8 py-2 font-semibold border-gray-300">
											<div className="flex items-center text-[#008065]">
												<span className="mr-2  cursor-pointer">
													{isSubAccordion2 ? (
														<FaChevronDown />
													) : (
														<FaChevronRight />
													)}
												</span>
												Outward Duties and Taxes
											</div>
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
											---
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
											{totaloutputtax.toFixed(2)}
										</td>
									</tr>
									{isSubAccordion2 && (
										<>
											<tr className="cursor-pointer" onClick={toggleOutput}>
												<td className="px-12 py-2 font-semibold border-gray-300">
													<div className="flex items-center text-[#008065]">
														<span className="mr-2  cursor-pointer">
															{isOutput ? (
																<FaChevronDown />
															) : (
																<FaChevronRight />
															)}
														</span>
														Output GST
													</div>
												</td>
												<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
													---
												</td>
												<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
													{totaloutputtax.toFixed(2)}
												</td>
											</tr>
											{isOutput && (
												<>
													<tr className="mt-4  font-bold">
														<td className="px-14 py-2 text-black border-gray-300">
															<ul className="list-disc list-inside">
																<li>Output CGST</li>
															</ul>
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-blac">
															---
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-black-700">
															{profitData.total_outward_cgst.toFixed(2)}
														</td>
													</tr>
													<tr className="mt-4 font-bold">
														<td className="px-14 py-2 text-black-700 border-gray-300">
															<ul className="list-disc list-inside">
																<li>Output SGST</li>
															</ul>
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-black-700">
															---
														</td>
														<td className="px-4 py-2 text-right border-gray-300 text-black-700">
															{profitData.total_outward_cgst.toFixed(2)}
														</td>
													</tr>
													<tr className="mt-4">
														<td className="md:px-14 px-12 py-2 text-black font-bold border-gray-300">
															<ul className="list-disc list-inside">
																<li>IGST </li>
															</ul>
														</td>
														<td className="px-4 py-2 text-right border-gray-300 font-bold text-black">
															---
														</td>
														<td className="px-4 py-2 text-right border-gray-300 font-bold text-black">
															{profitData.total_outward_igst.toFixed(2)}
														</td>
													</tr>
												</>
											)}
										</>
									)}

									{/* Child Row 3 - Other Current Liabilities */}
									{/* <tr className="cursor-pointer " onClick={toggleCurrent}>
                    <td className="px-8 py-2 font-semibold border-gray-300">
                      <div className="flex items-center text-[#008065]">
                        <span className="mr-2  cursor-pointer">
                          {isCurrents ? <FaChevronDown /> : <FaChevronRight />}
                        </span>
                        Other Current Liabilities
                      </div>
                    </td>
                    <td className="px-4 py-2 text-[#008065] border-gray-300">
                      500000.00
                    </td>
                    <td className="px-4 py-2 text-[#008065] border-gray-300">
                      ---
                    </td>
                  </tr> */}
									<tr className="cursor-pointer" onClick={toggleCurrent}>
										<td className="px-8 py-2 font-semibold border-gray-300">
											<div className="flex items-center text-[#008065]">
												<span className="mr-2  cursor-pointer">
													{isCurrents ? <FaChevronDown /> : <FaChevronRight />}
												</span>
												Other Current Liabilities
											</div>
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
											---
										</td>
										<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold">
											{profitData.other_with_current_asset.toFixed(2)}
										</td>
									</tr>
									{isCurrents && (
										<tr className="font-bold">
											<td className="px-10 py-2 text-black border-gray-300">
												<ul className="list-disc list-inside">
													<li> Unwithdrawn Cheques </li>
												</ul>
											</td>
											<td className="px-4 py-2 text-right border-gray-300 text-black">
												---
											</td>
											<td className="px-4 py-2 text-right border-gray-300 text-black">
												{profitData.other_with_current_asset.toFixed(2)}
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>

						<br />
						<tbody className="">
							<h2 className="font-bold mb-2 px-4 text-lg  text-[#008065]">
								Incomes
							</h2>
							<tr
								className=" cursor-pointer hover:bg-gray-300"
								onClick={toggleSale}
							>
								<td className="md:px-4 px-3 py-2 font-semibold border-gray-300 space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md">
									<div className="flex items-center text-[#008065]">
										<span className="mr-2 ">
											{isSale ? <FaChevronDown /> : <FaChevronRight />}
										</span>
										Sale Accounts
									</div>
								</td>
								<td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
									---
								</td>
								<td className="px-4 py-2 text-right border-gray-300 font-bold rounded-r-md text-[#008065]">
									{`${saleAccounts.toFixed(2)}`}
								</td>
							</tr>

							{/* Child Rows */}
							{isSale && (
								<tr className="font-bold ">
									<td className="px-5  py-2 text-balck-700 border-gray-300">
										<ul className="list-disc list-inside">
											<li>Sale(Revenue)Account</li>
										</ul>
									</td>
									<td className="px-4 py-2 text-right border-gray-300 text-balck-700">
										---
									</td>
									<td className="px-4 py-2 text-right border-gray-300 text-balck-700">
										{`${profitData.totalRevenue.toFixed(2)}`}
									</td>
								</tr>
							)}

							<tr className="font-bold">
								<td className="px-5 py-2 text-black-700 border-gray-300">
									<ul className="list-disc list-inside">
										<li> Profit on Sales of Assets</li>
									</ul>
								</td>
								<td className="px-4 py-2 text-right border-gray-300">
									{parseFloat(profitData.assetnegativeAmount.toFixed(2)) !== 0
										? profitData.assetnegativeAmount.toFixed(2)
										: "---"}
								</td>
								<td className="px-4 py-2 text-right border-gray-300">
									{parseFloat(profitData.assetprofitAmount.toFixed(2)) !== 0
										? profitData.assetprofitAmount.toFixed(2)
										: "---"}
								</td>
							</tr>
						</tbody>

						<br />
						<tbody className="">
							<h2 className="font-bold mb-2 px-4 text-lg  text-[#008065]">
								Expenses
							</h2>
							<tr
								className=" cursor-pointer hover:bg-gray-300"
								onClick={togglePurchase}
							>
								<td className="md:px-4 px-3 py-2 font-semibold border-gray-300 space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md">
									<div className="flex items-center text-[#008065]">
										<span className="mr-2 ">
											{isPurchase ? <FaChevronDown /> : <FaChevronRight />}
										</span>
										Purchase Accounts
									</div>
								</td>
								<td className="px-4 py-2 text-right border-gray-300 font-bold text-[#008065]">
									{`${profitData.totalPurchaseRevenue.toFixed(2)}`}
								</td>
								<td className="px-4 py-2 text-right border-gray-300 font-bold rounded-r-md text-[#008065]">
									---
								</td>
							</tr>

							{isPurchase && (
								<tr className="mt-2 font-bold">
									<td className="px-5 py-2 text-black-700 border-gray-300">
										<ul className="list-disc list-inside">
											<li>Purchase</li>
										</ul>
									</td>
									<td className="px-4 py-2 text-right border-gray-300 text-black-700">
										{`${profitData.totalPurchaseRevenue.toFixed(2)}`}
									</td>
									<td className="px-4 py-2 text-right border-gray-300 text-black-700">
										---
									</td>
								</tr>
							)}
						</tbody>

						<tbody className="">
							<tr
								className=" cursor-pointer hover:bg-gray-300  "
								onClick={toggleDirect}
							>
								<td className="md:px-4 px-3 py-2 font-semibold border-gray-300 space-x-2 md:overflow-hidden sm:overflow-auto rounded-l-md">
									<div className="flex items-center text-[#008065]">
										<span className="mr-2 ">
											{isDirect ? <FaChevronDown /> : <FaChevronRight />}
										</span>
										Direct Expenses
									</div>
								</td>
								<td className="px-4 py-2 text-right text-[#008065] font-bold ">
									{`${profitData.directExpenses.toFixed(2)}`}
								</td>
								<td className="px-4 py-2 text-right rounded-r-md   font-bold text-[#008065]">
									---
								</td>
							</tr>

							{isDirect && (
								<>
									{directArray?.map((expence: any, index) => (
										<tr key={index} className="mt-4 font-bold">
											<td className="md:px-9 px-8 py-2 text-black border-gray-300">
												<ul className="list-disc list-inside">
													<li>{expence.category}</li>
												</ul>
											</td>
											<td className="px-4 py-2 text-right border-gray-300 text-black-700">
												{expence?.amount.toFixed(2)}{" "}
											</td>
											<td className="px-4 py-2 text-right border-gray-300 text-black-700">
												---
											</td>
										</tr>
									))}
								</>
							)}
						</tbody>
						<tbody className="">
							<tr
								className=" cursor-pointer hover:bg-gray-300"
								onClick={toggleIndirect}
							>
								<td className="md:px-4 px-3 py-2 font-semibold border-gray-300 space-y-2 md:overflow-hidden sm:overflow-auto rounded-l-md">
									<div className="flex items-center text-[#008065]">
										<span className="mr-2 ">
											{isIndirect ? <FaChevronDown /> : <FaChevronRight />}
										</span>
										Indirect Expenses
									</div>
								</td>
								<td className="px-4 py-2 text-right border-gray-300 text-[#008065] font-bold  ">
									{`${profitData.indirectExpenses.toFixed(2)}`}
								</td>
								<td className="px-4 py-2 text-right border-gray-300 rounded-r-md text-[#008065] font-bold">
									---
								</td>
							</tr>

							{/* Child Rows */}
							{isIndirect && (
								<>
									{indirectArray?.map((expence: any, index) => (
										<tr key={index} className="mt-4 font-bold">
											<td className="md:px-9 px-8 py-2 text-black border-gray-300">
												<ul className="list-disc list-inside">
													<li>{expence.category}</li>
												</ul>
											</td>
											<td className="px-4 py-2 text-right border-gray-300 text-black-700">
												{expence?.amount.toFixed(2)}{" "}
											</td>
											<td className="px-4 py-2 text-right border-gray-300 text-black-700">
												---
											</td>
										</tr>
									))}
								</>
							)}
							<tr className=" sticky bottom-0 left-0   bg-gray-300">
								<td className="py-3 px-4 text-green font-bold text-lg text-[#008065]">
									Total
								</td>
								<td className="px-4 py-3 text-right border-gray-300 text-[#008065] font-bold">
									{`${currencySymbol}${debitSides.toFixed(2)}`}
								</td>
								<td className="px-4 py-3 text-right border-gray-300 text-[#008065] font-bold">
									{`${currencySymbol}${creditSides.toFixed(2)}`}
								</td>
							</tr>
						</tbody>
						</table>
						</div>
				</StyledDiv>
			</div>
		</>
	);
};

export default TrialBalanceSheet;
