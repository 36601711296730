/* eslint-disable eqeqeq */
import { Box, debounce, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ConfirmationPopup from "../pages/commen/ConfirmationPopup";
import { cashInHandDelete, cashInHandGet } from "../redux/actions/CashInHandActions";
import PaymentAlertEditPopup from "../pages/commen/PaymentAlertEditPopup";
import PaymentAlertDeletePopup from "../pages/commen/PaymentAlertDeletePopup";

const CashInHandTables = () => {
    const [paymentReceiveTable, setPaymentReceiveTable] = useState([] as any);
    const [paymentReceiveSearch, setPaymentReceiveSearch] = useState([] as any);
    const [showPaymentAlertEdit, setPaymentAlertEdit] = useState<boolean>(false);
    const [showPaymentAlertDelete, setPaymentAlertDelete] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState(null as any);

    const dispatch = useDispatch();
    const User_id = sessionStorage.getItem("userInfo");
    const userId = JSON.parse(User_id as any);
    const params = useParams();
    const { id } = params;/*  */
    const usersignin = useSelector((state: any) => state.userSignin);
    const { userInfo } = usersignin;
    const userid = userInfo.token._id
    const navigateTo = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sortOrder, setSortOrder] = useState<string>("asc");

    const handleEdit = (params: any) => {
        if (params.transaction_type !== "Add Cash" && params.transaction_type !== "Reduce Cash") {
            setPaymentAlertEdit(true);
            return;
        }
        navigateTo(`/app/cashInHandPage/${params._id.$oid}`);
    };


    const fetchData = () => {
        dispatch(cashInHandGet(userId?.token?._id) as any).then((res: any) => {
            if (res) {
                setPaymentReceiveTable(res.payload);
                setPaymentReceiveSearch(res.payload)
                setDeleteId(res.payload)
            }
        });
    };

    /*       const fetchData = () => {
            dispatch(ClientAll(userId?.token?._id) as any).then((res: any) => {
              if (res) {
                setClientListValues(res.payload);
                setClientid(res.payload)
                // setPaymentSearch(res.payload)
              }
            });
          }; */

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const debouncedSearch = debounce((query: string) => {
        const filteredData = paymentReceiveSearch.filter((paymentReceiveDetail: any) =>
            paymentReceiveDetail.transaction_type?.toLowerCase().includes(query) ||
            paymentReceiveDetail.name?.toLowerCase().includes(query)
        );
        setPaymentReceiveTable(filteredData);
    }, 300);

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearch(e.target.value.toLowerCase());
    };

    // *****************Shot By Dropdown**************
    const handleSortChange = (newSortOrder: string) => {
        const sortedData = [...paymentReceiveTable].sort((a, b) => {
            if (newSortOrder === "asc") {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        });
        setPaymentReceiveTable(sortedData);
        setSortOrder(newSortOrder);
    };


    /*     const handleDelete = (params: any) => {
            if (params.transaction_type !== "Add Cash" && params.transaction_type !== "Reduce Cash") {
                setPaymentAlertEdit(true)
                return;
            }
            setShowConfirmation(true);
            setDeleteId(params?._id?.$oid);
        };
     */


    const handleDelete = (data: any) => {
        if (data.transaction_type !== "Add Cash" && data.transaction_type !== "Reduce Cash") {
            setPaymentAlertDelete(true)
            return;
        }
        setShowConfirmation(true);
        setDeleteId(data._id?.$oid);
        
    };

    const handleConfirm = () => {

        dispatch(cashInHandDelete(deleteId) as any).then((res: any) => {
            if (res) {
                fetchData();
                setDeleteId(null);
                setShowConfirmation(false);
            }
        });

    };
    const handleCancel = () => {
        setShowConfirmation(false);
        setPaymentAlertEdit(false)
        setPaymentAlertDelete(false)
    };


    const rows = paymentReceiveTable.map((item: any, index: any) => ({
        id: uuidv4(),
        sno: index + 1,
        ...item,
    }));

    const columns: GridColDef[] = [
        { field: "sno", headerName: "S No", width: 120 },
        {
            field: "transaction_type",
            headerName: "Transaction Type",
            flex: 2,
            minWidth: 190,
        },
        {
            field: "name",
            headerName: "Name",
            flex: 2,
            minWidth: 190,
        },
        {
            field: "amount",
            headerName: "Amount",
            flex: 2,
            minWidth: 190,
            renderCell: (params) => (

                <span
                    style={{
                        color: params.row.transaction_type === "SALES" ||
                            params.row.transaction_type === "Add Cash" ||
                            params.row.transaction_type === "PURCHASE RETURN" ||
                            params.row.transaction_type === "SERVICE" ||
                            params.row.transaction_type === "SALES(FA)" ||
                            params.row.transaction_type === "CHEQUE DEPOSIT" ||
                            params.row.transaction_type === "PAYMENTIN"
                            ? "#008065"
                            : "#ff0000",
                        fontWeight: "bold",
                    }}
                >
                    {params?.row?.amount}
                </span>

            ),
        },
        {
            field: "adjustmentDate",
            headerName: "Date",
            flex: 2,
            minWidth: 190,
        },
        {
            field: "action",
            headerName: "Action",
            editable: false,
            flex: 2,
            minWidth: 150,
            renderCell: (params: any) => (
                <>
                    <Tooltip title="Edit">
                        <IconButton aria-label="edit" onClick={() => handleEdit(params?.row)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#008065"
                                className="w-5 h-5 cursor-pointer"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                />
                            </svg>
                        </IconButton>
                    </Tooltip>

                    <IconButton
                        aria-label="delete"
                        onClick={() => {
                            handleDelete(params.row);
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#008065"
                            className="w-5 h-5  cursor-pointer"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                        </svg>
                    </IconButton>

                </>
            ),
        },
    ];

    return (

        <div>
            <div>
                <div className="bg-[#F1F7F6] mt-[10px] rounded-xl px-3 py-3 mx-2">
                    <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                        <div className="flex items-center gap-2">
                            <div className="relative flex  mb-2">
                                <input
                                    type="text"
                                    id="simple-search"
                                    className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                                    placeholder="Search"
                                    required
                                    onChange={(e) => onSearch(e)}
                                />
                                <span
                                    className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]"
                                >
                                    <svg
                                        className="w-4 h-4"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="block mb-2  ">
                            <div className="relative flex items-center ">
                                <select
                                    defaultValue={""}
                                    id="sortDropdown"
                                    className="block w-[11rem] h-10 p-2 text-sm  border border-[#008065] rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 "
                                    onChange={(e) => handleSortChange(e.target.value)}
                                >
                                    <option value="" disabled hidden>
                                        Sort By
                                    </option>
                                    <option value="asc">Name A to Z</option>
                                    <option value="desc">Name Z to A</option>
                                </select>
                            </div>
                        </div>

                    </div>

                    <div className="bg-[#F1F7F6]" style={{ borderRadius: "8px" }}>
                        <Box sx={{ height: "84vh", width: "100%" }}>
                            <DataGrid
                                // autoHeight
                                sx={{
                                    ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                                    {
                                        color: "rgb(0, 128, 101)",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                    },
                                    ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                                        fontWeight: 600,
                                        fontSize: "14px",
                                    },
                                    "& .MuiDataGrid-checkboxInput": {
                                        color: "#008060 !important",
                                    },
                                }}
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 25, 50]}

                                disableRowSelectionOnClick
                            />
                        </Box>
                    </div>
                </div>
            </div>
            {showConfirmation && (
                <ConfirmationPopup
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
            {showPaymentAlertEdit && (
                <PaymentAlertEditPopup
                    message="This Transaction cannot be edited"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
            {showPaymentAlertDelete && (
                <PaymentAlertDeletePopup
                    message="This Transaction cannot be Deleted"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
};

export default CashInHandTables;
