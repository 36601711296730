import { FiPlusCircle } from "react-icons/fi";

import { useNavigate } from "react-router-dom";

import ClientsTable from "./ClientsTable";
import ExpenseCategoryTable from "./ExpenseCategoryTable";

const ExpenseCategoryTab = () => {
    const navigate = useNavigate();

    const addExpenseCategory = () => {
        navigate("/app/expenseCategory");
    };


    return (
        <div>
            <div className="flex justify-between flex-col sm:flex-row xl:flex-row 2xl:flex-row md:flex-row mr-5 lg:mr-0">
                <div className="p-4  font-bold text-[#008065]">Expense Category - Report</div>
                <div className="p-4  font-bold text-[#008065]">
                    <button
                        type="button"
                        className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
                        style={{ backgroundColor: "#008065" }}
                        onClick={addExpenseCategory}
                    >
                        <FiPlusCircle className="h-5 w-5" />
                        <span className={`flex-1 ml-3 text-left whitespace-nowrap  text-sm font-bold`} >Add Expense Category</span>
                    </button></div>


            </div>
            <div className="mt-1">
                <ExpenseCategoryTable/>
            </div>


        </div>
    );
}

export default ExpenseCategoryTab