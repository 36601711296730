import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import NotesTable from '../../Tables/NotesTable';
import { toast } from "react-toastify";
import ConfirmationPopup from "../commen/ConfirmationPopup";
import { NoteGet, NoteSave, noteDelete, notesUpdate } from '../../redux/actions/notesAction';

const NotesPage = () => {
  const dispatch = useDispatch();
  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;
  const [formData, setFormData] = useState({
    notes: "",
    user_id: token?._id,
    defaultNotesInfo: false
  });
  const [errors, setErrors] = useState({} as any);
  const [notesList, setNotesList] = useState([] as any);
  const [noteid, setNoteid] = useState(null as any);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, defaultNotesInfo: e.target.checked });
  };

  const [notesPageErrors, setNotesPageErrors] = useState<{
    notes?: string;
  }>({});

  const validateProdForm = () => {
    if (formData.notes.trim() === "") {
      setNotesPageErrors({
        ...notesPageErrors,
        notes: "Notes is  Required.",
      });
    }
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotesPageErrors({
      ...notesPageErrors,
      [e.target.name]: "",
    });
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const handleCancelClick = () => {
    setNotesPageErrors({
      ...notesPageErrors,
      notes: "",
    });
    resetProduct();
    setNoteid(null);
  };

  const fetchData = () => {
    dispatch(NoteGet(token?._id) as any).then((res: any) => {
      if (res) {
        setNotesList(res.payload);
      }
    });
  };

  const resetProduct = () => {
    setFormData({
      notes: "",
      user_id: token?._id,
      defaultNotesInfo: false
    });
  };



  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

    const trimmedFormData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value,]));

    if (trimmedFormData.notes) {
      if (noteid) {
        dispatch(notesUpdate(noteid, trimmedFormData) as any).then((res: any) => {
          if (res) {
            resetProduct();
            fetchData();
            setNoteid(null);
          }
          toast.success("Update SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        });
      }
      else {
        dispatch(NoteSave(trimmedFormData) as any).then((res: any) => {
          if (res) {
            resetProduct();
            fetchData();
          }
          toast.success("Saved SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        });
      }
    }
    else {
      validateProdForm();
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEdit = (data: any) => {
    setNotesPageErrors({
      ...notesPageErrors,
      notes: "",
    });
    setFormData({
      notes: data.notes,
      user_id: token?._id,
      defaultNotesInfo: data.defaultNotesInfo

    });
    setNoteid(data._id?.$oid);
  };


  const onDelete = (data: any) => {
    setShowConfirmation(true);
    setNoteid(data._id?.$oid);
  };



  const handleConfirm = () => {
    dispatch(noteDelete(noteid) as any).then((res: any) => {
      if (res) {
        fetchData();
        setNoteid(null);
        setShowConfirmation(false);
        resetProduct();
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };
  return (
    <div>
      <fieldset className="mx-3 mb-4 rounded-lg">
        <legend className="p-4  font-bold text-[#045545]  text-lg ml-2">
          Notes
        </legend>
        <div className="rounded-xl px-4 ">
          <div className="flex ml-2 gap-2">
            <div>
              <input
                type="checkbox"
                name="defaultNotesInfo"
                placeholder="defaultNotesInfo"
                checked={formData.defaultNotesInfo}
                onChange={handleCheckboxChange}
                className={
                  "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                }
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              />
            </div>
            <div className="text-[#008065] font-semibold">
              <label>Default Notes Info</label>
            </div>
          </div>
        </div>
        <div className=" rounded-xl px-3  mx-2">
          <div>
            <label className="block mb-2 text-sm font-semibold text-[#008065] mt-2 ml-1">
              Notes:
            </label>
            <textarea
              name="notes"
              id="message"
              rows={4}
              // className={`block p-2.5 w-full mx-1 text-sm border-2 focus:outline-none rounded border-[#008065] border-solid${notesPageErrors.notes
              //   ? " border-2  border-[red] w-full"
              //   : ""
              //   }`}
              className={`block p-2.5 w-full mx-1 text-sm border-2 focus:outline-none rounded border-solid
                  ${notesPageErrors.notes
                  ? "border-[#ff0000]" // Error state
                  : "focus:border-[#07ad7b] border-[#949494]" // Focus and default state
                }`}
              placeholder="Write your thoughts here..."
              onChange={(e: any) => handleChange(e)}
              value={formData.notes ? formData.notes : ""}
            >
              {/* {text.length > 0 && text.split("\n").map((l, i) => <p key={i}>{l}</p>)} */}
            </textarea>
            {notesPageErrors.notes && (
              <span
                style={{
                  fontFamily: "poppins",
                  fontWeight: 700,
                  color: "#ff0000",
                }}
                className="text-red-400 text-xs ml-1"
              >
                {notesPageErrors.notes}
              </span>
            )}
          </div>
          <div className="flex w-full flex-col py-1 sm:flex-row px-4 mb-3 sm:justify-end justify-center">
            {/* <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div> */}

            {/* <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
              <div className="flex flex-col  ">
                <div className="relative"></div>
              </div>
            </div> */}

            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5 justify-center w-full w-full md:w-auto">
              <div className="flex flex-col flex-1 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40  rounded w-full">
                <button
                  type="button"
                  className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-sm h-8   block    bg-[#008065] "
                  style={{ fontFamily: "poppins", fontSize: "14px" }}
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5 justify-center w-full w-full md:w-auto">
              <div className="flex flex-col flex-1 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40  rounded w-full">
                <button
                  onClick={(e: any) => handleSubmit(e)}
                  className="rounded-xl font-bold text-white flex flex-row items-center bg-[#008065] justify-center  text-sm h-8 block  "
                  style={{ fontFamily: "poppins", fontSize: "14px" }}
                >
                  {noteid ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <NotesTable
        notesList={notesList}
        edit={onEdit}
        deletenotes={onDelete}
      />
      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  )
}

export default NotesPage
