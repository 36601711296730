import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ServiceInvoiceSettingDelete, ServiceInvoiceSettingSave, ServiceInvoiceSettingsGet, ServiceInvoiceSettingsUpdate } from '../../../redux/actions/invoiceSettingsActions';
import ServiceInvoiceSettingsTable from '../../../Tables/ServiceInvoiceSettingsTable';
// 
import { FormControl, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/system';
import ConfirmationPopup from '../../commen/ConfirmationPopup';


const InvoiceServiceSettings = () => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            serviceTitle: "",
            serviceNumber: "",
            serviceDate: "",
            serviceYear: "",
            serviceDigit: "00",
        },
    });
    const usersignin = useSelector((state: any) => state.userSignin);
    const token = usersignin?.userInfo?.token;
    const [ServiceInvoiceDetail, setServiceInvoiceDetail] = useState()
    const [defaultSettingInfo, setdefaultSettingInfo] = useState(false);
    const [tittleID, setTittleId] = useState(null as any);
    const [updateId, setUpdateId] = useState(null as any);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const handleDefaultCheckboxChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setdefaultSettingInfo(e.target.checked);
    };

    const resetProduct = () => {
        const defaultValues = {
            serviceTitle: "",
            serviceNumber: "",
            serviceDate: "",
            invoiceYear: "",
            serviceDigit: "00",
        }
        setTittleId(null);
        setdefaultSettingInfo(false)
        setSelectedFormat('MM/DD/YYYY')
        reset({
            ...defaultValues,
        });
    }

    const onSubmit = (data: any) => {

        let serviceSettingData = {
            serviceTitle: data.serviceTitle,
            serviceNumber: data.serviceNumber,
            serviceDate: formattedDate,
            serviceYear: data.serviceYear,
            serviceDigit: data.serviceDigit,
            defaultInfo: defaultSettingInfo,
            user_id: token?._id,
        };
        if (tittleID) {
            dispatch(ServiceInvoiceSettingsUpdate(tittleID, serviceSettingData) as any).then((res: any) => {
                if (res) {
                    fetchData();
                    setTittleId(null);
                    setdefaultSettingInfo(false);
                    resetProduct();
                }
                toast.success("Update SuccessFully !", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 800,
                });
            });
        } else {
            dispatch(ServiceInvoiceSettingSave(serviceSettingData) as any).then((res: any) => {
                if (res) {
                    fetchData();
                    resetProduct();
                    setdefaultSettingInfo(false);
                }
                toast.success("Saved SuccessFully !", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 800,
                });
            });
        }
    };



    const handleEdit = (value: any) => {

        let data = {
            serviceTitle: value.serviceTitle,
            serviceNumber: value.serviceNumber,
            serviceYear: value.serviceYear,
            serviceDate: value.serviceDate,
        }
        setFormattedDate(value.serviceDate)
        setdefaultSettingInfo(value?.defaultInfo);
        setTittleId(value._id?.$oid);
        reset({
            ...data,
        });
    };
    const fetchData = () => {
        dispatch(ServiceInvoiceSettingsGet(token?._id) as any).then((res: any) => {
            if (res) {
                setServiceInvoiceDetail(res.payload);
            }
        });
    };
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDelete = (data: any) => {
        setShowConfirmation(true);
        setUpdateId(data._id?.$oid);
    };

    const handleConfirm = () => {
        dispatch(ServiceInvoiceSettingDelete(updateId) as any).then((res: any) => {
            if (res?.type === 'SERVICESETTING_DELETE_SUCCESS') {
                setShowConfirmation(false);
                reset();
                fetchData();
                setUpdateId(null);
            }
        });
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };


    const [selectedFormat, setSelectedFormat] = useState<string>('MM/DD/YYYY');
    const [formattedDate, setFormattedDate] = useState<string>('');
    useEffect(() => {
        let formattedDateValue = '';
        switch (selectedFormat) {
            case 'MM/DD/YYYY':
                formattedDateValue = `MM/DD/YYYY`;
                break;
            case 'DD/MM/YYYY':
                formattedDateValue = `DD/MM/YYYY`;
                break;
            case 'YYYY-MM-DD':
                formattedDateValue = `YYYY-MM-DD`;
                break;
            case 'DD-MM-YYYY':
                formattedDateValue = `DD-MM-YYYY`;
                break;
            default:
                formattedDateValue = '';
        }
        setFormattedDate(formattedDateValue);
    }, [selectedFormat]);

    const dateFormats = [
        'MM/DD/YYYY',
        'DD/MM/YYYY',
        'YYYY-MM-DD',
        'DD-MM-YYYY',
    ];
    const currentYear: any = new Date().getFullYear();
    setValue("serviceYear", currentYear);


    const CustomFormControl = styled(FormControl)({
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
            },
        },
    });

    const CustomSelect = styled(Select)({
        '&.MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    });

    const invoiceNumberFormat = [
        '00',
        '000',
        '0000',
    ];
    return (
        <div>
            <div className="bg-[#E1E8E7] h-full" style={{ fontFamily: "poppins" }}>
                <div>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <fieldset className="mx-2 mb-4 rounded-lg">
                            <legend className="p-4  font-bold text-[#008065]  ml-2">
                                Invoice Service Settings
                            </legend>
                            <div className="grid gap-4 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 ml-4">
                                <div className="flex ml-2 gap-2 mb-3">
                                    <input
                                        type="checkbox"
                                        name="defaultBankInfo"
                                        placeholder="defaultBankInfo"
                                        onChange={handleDefaultCheckboxChange}
                                        checked={defaultSettingInfo}
                                        className={
                                            "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700 cursor-pointer"
                                        }
                                        style={{
                                            fontFamily: "poppins",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                        }}

                                    />
                                    <div className="text-[#008065] font-semibold">
                                        <label>Default Invoice Service Settings Info</label>
                                    </div>
                                </div>

                            </div>
                            <div className="rounded-xl  py-3 mb-3  bg-[#E1E8E7] ">
                                <div className="flex w-full flex-col pl-4 md:flex-row">
                                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title px-2">
                                        <div className="flex flex-col mb-6 ">
                                            <label
                                                className="required mb-1"
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    color: "#008065",
                                                }}
                                            >

                                                <span> Service Tittle</span>
                                            </label>
                                            <div className="relative flex">
                                                <div className="relative">
                                                    <input
                                                        type="text"
                                                        placeholder="Invoice Tittle"
                                                        {...register("serviceTitle",
                                                            {
                                                                required: "Invoice Title is required",
                                                                validate: (value) => {
                                                                    if (value.trim().length === 0) {
                                                                        return "Invoice Title is required";
                                                                    }
                                                                    return true;
                                                                },
                                                            }
                                                        )}

                                                        // className={
                                                        //     "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48  md:w-28 lg:w-30 xl:w-48 2xl:w-56"
                                                        // }
                                                        className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-48  md:w-28 lg:w-30 xl:w-48 2xl:w-56 +
                                                            ${ errors?.serviceTitle ? 'border-[#FF0000]' : 'border-[#949494]'}
                                                             ${! errors?.serviceTitle  && 'focus:border-[#07ad7b]'}`} 
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            // borderColor: errors?.serviceTitle ? "red" : "#008065",
                                                        }}
                                                    />
                                                    {errors?.serviceTitle && (
                                                        <p
                                                            style={{
                                                                fontFamily: "poppins",
                                                                fontWeight: 700,
                                                                color: "#ff0000",
                                                            }}
                                                            className="text-red-400 text-sm w-48  md:w-28 lg:w-30 xl:w-48 2xl:w-56"
                                                        >
                                                            {errors?.serviceTitle?.message}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 ">
                                        <div className="flex flex-col mb-6 ">
                                            <label
                                                style={{
                                                    fontFamily: "poppins",
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    color: "#008065",
                                                }}
                                                className='ml-2 mb-1 required'
                                            >

                                                <span> Service Number Format</span>
                                            </label>
                                            <div className="relative flex sm:flex-row flex-col gap-2 sm:gap-0">
                                                <div className="relative  mx-2">
                                                    <input
                                                        type="text"
                                                        {...register("serviceNumber",
                                                            {
                                                                required: "Invoice Number is required",
                                                                validate: (value) => {
                                                                    if (value.trim().length === 0) {
                                                                        return "Invoice Number is required";
                                                                    }
                                                                    return true;
                                                                },
                                                            }
                                                        )}

                                                        placeholder=" Ex:Serv/"
                                                        // className={
                                                        //     "border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56"
                                                        // }
                                                        className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56 +
                                                            ${ errors?.serviceNumber ? 'border-[#FF0000]' : 'border-[#949494]'}
                                                             ${! errors?.serviceNumber  && 'focus:border-[#07ad7b]'}`} 
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            // borderColor: errors?.serviceNumber ? "red" : "#008065",
                                                        }}
                                                    />
                                                </div>
                                                <div className="relative  mx-2">
                                                    <input
                                                        type="text"
                                                        {...register("serviceYear", {
                                                            required: true,
                                                        })}
                                                        className={
                                                            "border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56"
                                                        }
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            // borderColor: "#008065",
                                                        }}
                                                    />
                                                </div>
                                                <div className="relative  mx-2">
                                                    <select
                                                        className="text-balance border-2 focus:outline-none border-[#949494] focus:border-[#07ad7b] rounded-[7px] px-2 h-9 block w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56"
                                                        style={{
                                                            fontFamily: "poppins",
                                                            fontSize: "13px",
                                                            fontWeight: 400,
                                                        }}
                                                        {...register("serviceDigit", {
                                                            required: true,
                                                        })}
                                                        defaultValue={""}
                                                    >
                                                        {invoiceNumberFormat.map((format, index) => (
                                                            <option key={index} value={format}>
                                                                {format}
                                                            </option>
                                                        ))}


                                                    </select>
                                                </div>
                                            </div>
                                            {(errors?.serviceNumber || errors?.serviceYear) && (
                                                <p
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontWeight: 700,
                                                        color: "#ff0000",
                                                    }}
                                                    className="ml-2 text-red-400 text-sm"
                                                >
                                                    {errors?.serviceNumber?.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    {/* <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 pl-2">
                                        <div className="flex flex-col mb-6 ">
                                            <label
                                                className='mb-1 whitespace-nowrap'
                                            >
                                                <span
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                        color: "#008065",
                                                    }}>
                                                    Service  Date Format:  </span>
                                            </label>
                                            <CustomFormControl variant="outlined" fullWidth>
                                                <CustomSelect
                                                    labelId="date-format-label"
                                                    id="date-format"
                                                    defaultValue={selectedFormat}
                                                    label="Date Format"
                                                    className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-48 sm:w-full md:w-28 lg:w-30 xl:w-48 2xl:w-56 bg-[white]"
                                                    onChange={(event) => setSelectedFormat(event.target.value as string)}
                                                    renderValue={() => formattedDate}
                                                >
                                                    {dateFormats.map((format, index) => (
                                                        <MenuItem key={index} value={format}>
                                                            {format}
                                                        </MenuItem>
                                                    ))}
                                                </CustomSelect>
                                            </CustomFormControl>

                                        </div>

                                    </div> */}

                                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 ">
                                    </div>

                                </div>




                                <div className="flex w-full flex-col sm:flex-row pl-4 ">
                                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
                                    <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
                                        <div className="flex flex-col w-44 rounded">
                                            <button
                                                type="button"
                                                className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                                                style={{
                                                    backgroundColor: "#008065",
                                                    fontSize: "14px",
                                                    fontFamily: "poppins",
                                                }}
                                                onClick={resetProduct}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                    <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
                                        <div className="flex flex-col w-44 ">
                                            <button
                                                type="submit"
                                                className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                                                style={{
                                                    backgroundColor: "#008065",
                                                    fontSize: "14px",
                                                    fontFamily: "poppins",
                                                }}
                                            >
                                                {tittleID ? "Update" : "Save"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
            {<ServiceInvoiceSettingsTable
                serviceInvoiceDetail={ServiceInvoiceDetail}
                AppSettingList={ServiceInvoiceDetail}
                edit={handleEdit}
                handleDelete={handleDelete}
            />}
            {showConfirmation && (
                <ConfirmationPopup
                    message="Are you sure you want to proceed?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    )
}

export default InvoiceServiceSettings
