import { useSelector } from "react-redux";
import NumberFormat from "../commen/NumberFormat";
import { toWords } from "number-to-words";
import CalculateIndianFormattotal from "../commen/CalculateIndianFormattotal";


const PreviewSalesFAProductWise = ({ Invoice, componentRef, closeModal, selectedFont,roundOffEnable,amountInWords }: any) => {
  const template = useSelector((state: any) => state.userColorTemplate);
  const { userdata } = template;
  const totalAmount = Invoice.toatalAmount;
  const roundedTotalAmount = Math.round(totalAmount);
  const totalAmountInWords = Number.isFinite(roundedTotalAmount)
    ? amountInWords === "International Format"
      ? toWords(roundedTotalAmount)+ " Only"
      : "Rupees " + CalculateIndianFormattotal(roundedTotalAmount) + " Only"
    : "Invalid amount";
  const handleCancelClick = () => {
    closeModal();
  };
  return (
    <div content={componentRef} style={{ fontFamily: selectedFont }}>
      <div style={{ breakBefore: "page" }}></div>
      {/*   image and client information   */}

      <div className="grid grid-cols-6 ">
        <div className="col-start-1 col-end-2">
          {Invoice.imageData ? (
            <div className=" font-title  sm:text-left ">
              <label
                style={{ fontFamily: selectedFont }}
                className="   items-start  text-blue      "
              >
                <img
                  className="pr-0 sm:pr-8 md:pr-8 pt-7 h-20 2xl:ml-7 xl:ml-7 lg:ml-7 md:ml-7 sm:ml-4 ml-2"
                  src={Invoice.imageData}
                  alt="logonew "
                />
              </label>
            </div>
          ) : null}
        </div>

        <div className="col-start-2 col-span-4 ">
          {Invoice.companyName ? (
            <div className=" text-center  pt-7 text-lg font-semibold  sm:font-normal sm:text-4xl ">
              {Invoice.companyName}
            </div>
          ) : null}
        </div>
      </div>

      <div className="border "></div>
      {/*        *******************   invoice name                */}

      <div className="bg-white  overflow-x-auto" id="InvoiceWrapper">
        <div className="justify-center flex flex-row relative ">
          <span
            className="w-64 md:w-11/12 lg:w-11/12 2xl:w-11/12 truncate rounded-[7px] text-md sm:text-2xl text-center  focus:outline-none"
            style={{
              fontFamily: selectedFont,
              fontWeight: 700,
            }}
          >
            {Invoice.invoicehead}
          </span>

        </div>
        {/*     client details           */}
        <div className=" px-4 bg-cover bg-center  rounded-xl flex flex-col sm:flex-row justify-between ">
          <div className="flex-1 sm:mx-2 column-reverse">
            <div className="flex flex-col sm:flex-row pt-3 gap-2 justify-between pre-reverse">
              <div className="lg:w-[90%] xl:w-[60%] 2xl:w-[60%] sm:w-[70%] ">
                <div className="flex flex-row  items-center mb-2 ">
                  <div
                    className="flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    style={{
                      color: "#008065",
                      fontFamily: selectedFont,

                      fontWeight: 700,
                    }}
                  >
                    Client Details
                  </div>
                </div>
                {Invoice.clientName ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "0px",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Name
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%] sm:w-[70%] w-1/2 ">
                        <input
                          className="focus:outline-none  text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.clientName}
                          style={{
                            fontFamily: selectedFont,
                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientMobile ? (
                  <>
                    <div className="flex justify-between ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs whitespace-nowrap lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4 "
                      >
                        Mobile
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%]  w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.clientMobile}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientEmail ? (
                  <>
                    <div className="flex justify-between  ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        Email
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%]  w-1/2 ">
                        <input
                          className="focus:outline-none  text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.clientEmail}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {Invoice.clientgst ? (
                  <>
                    <div className="flex justify-between  ">
                      <div
                        style={{
                          fontFamily: selectedFont,
                          marginRight: "",
                          fontWeight: 700,
                          verticalAlign: "top",
                        }}
                        className="text-left text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-1/2 sm:w-1/4"
                      >
                        GST
                      </div>
                      <div className="lg:w-[70%] xl:w-[70%] 2xl:w-[70%]  sm:w-[70%]  w-1/2">
                        <input
                          className="focus:outline-none w-full text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full"
                          readOnly
                          value={Invoice.clientgst}
                          style={{
                            fontFamily: selectedFont,

                            fontWeight: 400,
                            verticalAlign: "top",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="">
                <div className="flex flex-row items-center justify-between mb-2 ">
                  <div
                    style={{
                      color: "#008065",
                      fontFamily: selectedFont,

                      fontWeight: 700,
                    }}
                        className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Sales FA No
                  </div>
                  <div className="font-title flex-1 text-right ">
                    <input
                      readOnly
                      value={Invoice.invoiceNo}
                      style={{
                        fontFamily: selectedFont,
                         
                      }}
                      className="font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md w-full sm:ml-auto block focus:outline-none "
                    />
                  </div>
                  <div></div>
                </div>

                {Invoice.salesFADate ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2  ">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                        className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                      >
                        Sales FA Date
                      </div>
                      <div className="font-title flex-1 text-right ">
                          <input
                            readOnly
                            value={Invoice.salesFADate}
                            style={{
                              fontFamily: selectedFont,
                            }}
                          className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full sm:ml-auto  focus:outline-none "
                          ></input>
                        </div>
                      <div></div>
                    </div>
                  </>
                ) : null}
                {Invoice.PurchaseBillNo ? (
                  <>
                    <div className="flex flex-row justify-between items-center mb-2  ">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                        className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md "
                      >
                        Purchase Bill No
                      </div>
                      <div className="font-title flex-1 text-right ">
                          <input
                            readOnly
                            value={Invoice.PurchaseBillNo}
                            style={{
                              fontFamily: selectedFont,
                            }}
                          className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md block w-full sm:ml-auto  focus:outline-none "
                          ></input>
                        </div>
                      <div></div>
                    </div>
                  </>
                ) : null}
                {Invoice.purchaseBillDate ? (
                  <>
                    <div className="flex flex-row items-center justify-between mb-2 ">
                      <div
                        style={{
                          color: "#008065",
                          fontFamily: selectedFont,

                          fontWeight: 700,
                        }}
                        className=" flex-1 text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      >
                        purchase Bill Date
                      </div>
                      <div className="font-title flex-1 text-right">
                        <div>
                        <input
                          readOnly
                          value={Invoice.purchaseBillDate}
                          style={{
                            fontFamily: selectedFont,
                          }}
                            className=" font-title text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md sm:ml-auto block w-full  focus:outline-none  "
                        ></input>
                      </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* product details */}
        <div className="px-6 mt-3">
          <div className="hidden sm:flex rounded-lg invisible sm:visible w-full flex-col sm:flex-row px-2 py-2 text-white bg-[#008065]">
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" sm:w-1/6 md:w-1/6 lg:w-2/12 xl:w-1/12 2xl:w-1/12 text-left "
            >
              S No
            </div>
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12 text-left "
            >
              Assets
            </div>

            {userdata?.hsnNo ? (
              <div
                style={{
                  fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className="w-full sm:w-1/4 text-left sm:text-center"
              >
                HSN
              </div>
            ) : (<></>)}

            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" w-full sm:w-1/4  sm:text-right"
            >
              Price
            </div>

            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="w-full sm:w-1/4 text-center sm:text-center   "
            >
              Qty
            </div>
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="w-full sm:w-1/4 text-center sm:text-center"
            >
              Disc(%)
            </div>
            {/* {userdata?.gstValue === "IGST" ? (
              <>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                  className="w-full sm:w-1/4 text-center sm:text-center"
                >
                  IGST(%)
                </div>
              </>
            ) : (<>
              <div
                style={{
                  fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={"w-full sm:w-1/4 text-center sm:text-center"}
              >
                CGST(%)
              </div>
              <div
                style={{
                  fontFamily: selectedFont,
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={"w-full sm:w-1/4 text-center sm:text-center"}
              >
               SGST(%)
              </div>
            </>)} */}
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className="w-full sm:w-1/4 text-center sm:text-center"
            >
              Tax
            </div>
            <div
              style={{
                fontFamily: selectedFont,
                fontSize: "14px",
                fontWeight: 700,
              }}
              className=" w-full sm:w-1/4 text-right 2xl:text-right"
            >
              Total
            </div>
          </div>
        </div>
        {Invoice?.asset?.map((product: any, index: number) => {

          return (
            <div className="px-4 py-1 sm:px-8">
              <div
                key={`${index}_${product.id}`}
                className="flex lg:flex-row flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible md:flex-row xl:flex-row flex-col rounded-lg w-full items-center relative text-sm"
              >
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                className="sm:pl-2 w-full  mb-1 sm:mb-0 sm:w-1/6 md:w-1/6 lg:w-2/12 xl:w-1/12 2xl:w-1/12 text-left flex flex-row sm:block"
                >
                  <span
                   
                  className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    S No
                  </span>



                  <span className="inline-block w-full product">
                    <input
                      readOnly
                      value={index + 1}
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                      className="font-title  block w-full  sm:text-left text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>

                </div>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                className=" w-full   mb-1 sm:mb-0 sm:w-1/4 md:w-8/12 lg:w-8/12 xl:w-6/12 2xl:w-6/12 text-left  flex flex-row  "
                >
                  <span
                    className="sm:hidden w-1/2 flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Assets
                  </span>
                  <span className="inline-block w-full product">
                    <input
                      readOnly
                      value={product.asset_name}
                      style={{
                        fontFamily: selectedFont,
                        fontWeight: 400,
                      }}
                      className="font-title  block w-full sm:text-left  text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
                {userdata?.hsnNo ? (
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontWeight: 700,
                    }}
                  className=" w-full  mb-1 sm:mb-0 sm:w-1/4 text-left flex flex-row   "
                  >
                    <span
                      
                      className="sm:hidden w-full flex flex-row  items-center text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Hsn
                    </span>
                  <span className="inline-block w-full  sm:w-full ">
                      <input
                        readOnly
                        style={{
                          fontFamily: selectedFont,
                          fontWeight: 400,
                        }}
                        value={product.hsn ? product.hsn : "N/A"}
                      className="font-title block w-full sm:text-center text-right focus:outline-none text-xs sm:text-sm  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      />
                    </span>
                  </div>

                ) : (<></>)}


                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                className="w-full mb-1 sm:mb-0  sm:w-1/4  sm:text-left flex flex-row  "
                >
                  <span
                    
                    className="sm:hidden w-full flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Price
                  </span>
                  <span className="inline-block  w-full   mb-1 sm:mb-0 ">
                    <input
                      style={{
                        fontFamily: selectedFont,
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      readOnly
                      value={product?.rate}
                      type={"number"}
                      className="font-title  block w-full  sm:text-right text-right focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>


                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className="w-full mb-1 sm:mb-0 sm:w-1/4 text-center sm:text-center  flex flex-row   "
                >
                  <span
                   
                    className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Qty
                  </span>
                  <span className="inline-block  w-full">
                    <input
                      readOnly
                      value={product.qty}
                      type={"number"}
                      style={{
                        fontFamily: selectedFont,
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      className="font-title text-right block w-full   sm:text-center  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>

                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className="w-full mb-1 sm:mb-0 sm:w-1/4 text-center sm:text-center  flex flex-row  "
                >
                  <span
                    
                    className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Disc(%)
                  </span>
                  <span className="inline-block  w-full">
                    <input
                      readOnly
                      value={product.discount ? product.discount : "N/A"}
                      type=""
                      style={{
                        fontFamily: selectedFont,
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      className="font-title text-right block w-full   sm:text-center  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>

                {/* {userdata?.gstValue === "IGST" ? (
                <>
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontWeight: 700,
                    }}
                    className={"  w-full mb-1 sm:mb-0 sm:w-1/4 text-center sm:text-center  flex flex-row"}
                  >
                    <span
                      style={{
                        marginBottom: "5px",
                      }}
                      className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      IGST(%)
                    </span>
                    <span className="inline-block  w-full">
                      <input
                        readOnly
                        value={product.prodigst}
                        type={"number"}
                        style={{
                          fontFamily: selectedFont,
                          marginTop: "",
                          fontWeight: 400,
                        }}
                        className="font-title text-right block w-full   sm:text-center  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                      />
                    </span>
                  </div>
                </>
              ) : (<>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className={" w-full mb-1 sm:mb-0 sm:w-1/4 text-center sm:text-center  flex flex-row"}
                >
                  <span
                    
                    className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    CGST(%)
                  </span>
                  <span className="inline-block  w-full">
                    <input
                      readOnly
                      value={product.prodcgst}
                      type={"number"}
                      style={{
                        fontFamily: selectedFont,
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      className={`${"font-title text-right block w-full   sm:text-center  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"}`}
                    />
                  </span>
                </div>
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className={"w-full mb-1 sm:mb-0 sm:w-1/4 text-center sm:text-center  flex flex-row "}
                >
                  <span className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md">
                    SGST(%)
                  </span>
                  <span className="inline-block  w-full">
                    <input
                      readOnly
                      value={product.prodsgst}
                      type={"number"}
                      style={{
                        fontFamily: selectedFont,
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      className="font-title text-right block w-full   sm:text-center  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>
              </>)} */}
                <div
                  style={{
                    fontFamily: selectedFont,
                    fontWeight: 700,
                  }}
                  className="w-full mb-1 sm:mb-0 sm:w-1/4 text-center sm:text-center  flex flex-row "
                >
                  <span
                    
                    className="sm:hidden w-1/4 flex flex-row items-center text-xs lg:text-sm md:text-sm xl:text-md sm:text-sm 2xl:text-md"
                  >
                    Tax
                  </span>
                  <span className="inline-block  w-full">
                    <input
                      readOnly
                      // value={product?.assetTax}
                      value={product.assetTax ? product.assetTax : "N/A" }
                      style={{
                        fontFamily: selectedFont,
                        marginTop: "",
                        fontWeight: 400,
                      }}
                      className="font-title text-right block w-full   sm:text-center  focus:outline-none text-xs lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    />
                  </span>
                </div>


                <>
                  <div
                    style={{
                      fontFamily: selectedFont,
                      fontWeight: 700,
                    }}
                    className=" w-full mb-1 sm:mb-0 sm:w-1/4 text-right flex flex-row"
                  >
                    <span
                      
                      className="sm:hidden w-1/2  flex flex-row items-center text-xs  lg:text-sm md: text-sm xl:text-md sm:text-sm 2xl:text-md"
                    >
                      Total
                    </span>
                    <span className="font-block w-full sm:w-full  md:w-full text-xs  sm:text-sm lg:w-full ">
                      <NumberFormat value={product.amount}></NumberFormat>
                    </span>
                  </div>
                </>
              </div>
            </div>
          );
        })}

        {/********************************** * subtotal *******************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end rounded-lg sm:visible w-full px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Subtotal
          </div>

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-block w-1/2 sm:w-1/4 text-right sm:pr-4 flex flex-row justify-end sm:block mb-1"
          >
            <NumberFormat
              value={Invoice.subtotal}

            //   displayType={"text"}
            //   thousandSeparator={true}
            />
          </div>
        </div>
        {/* ******************************cgstPercentage**************/}

        {roundOffEnable == true && (
          
            <div
              className={
                "flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center "
              }
            >
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={
                  "font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
                }
              >
                Round Off
              </div>
              
              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                className={
                  "font-title w-1/2 sm:w-1/4 text-right sm:pr-4 flex flex-row justify-end sm:block mb-1"
                }
              >
                <NumberFormat value={Invoice.roundOff} />
              </div>
            </div>
          
        )}
        {/*************************total****************************/}
        <div className="flex flex-row sm:flex-row sm:justify-end  rounded-lg sm:visible w-full px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-8 flex flex-row sm:block mb-1 sm:mb-0"
          >
            Total
          </div>

          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-1/2 sm:w-1/4 text-right sm:pr-4 flex flex-row justify-end sm:block mb-1"
          >
            <NumberFormat
              value={Invoice.toatalAmount}
            />
          </div>
        </div>
        {/*************************AMOUNT IN WORDS****************************/}
        <div className="flex flex-row sm:flex-row sm:justify-start rounded-lg sm:visible w-full  sm:px-4 py-1 items-center ">
          <div
            style={{
              fontFamily: selectedFont,
              fontSize: "14px",
              fontWeight: 700,
            }}
            className="font-title w-full text-left pl-4 md:pl-5 lg:pl-4 flex flex-row sm:block mb-1 sm:mb-0 whitespace-normal capitalize"
          >
            {totalAmountInWords} 
          </div>
        </div>



        {/********************************Cancel*********************************/}
        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            // onClick={() => setOpenView(!viewOpen)}
            // ref={cancelButtonRef}
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default PreviewSalesFAProductWise
