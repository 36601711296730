/* eslint-disable eqeqeq */
import { Box, Checkbox } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { VendorProductList } from "../../redux/actions/vendorAction";

function PurchaseProductModel({
    closeModal,
    handleSelect,
    prodcutRows,
    vendorId,
}: any) {

    const dispatch = useDispatch();
    const [existingProductList, setExistingProductList] = useState([] as any);
    const [productSearch, setProductSearch] = useState([] as any)
    const [selectedProducts, setSelectedProducts] = useState([] as any);
    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelectedProducts = existingProductList
                .filter(
                    (item: any) =>

                        !prodcutRows.some(
                            (value: any) => value.productName === item.prod_Name
                        )
                )
                .map((item: any) => item.prod_Name);
            setSelectedProducts(newSelectedProducts);
        } else {
            setSelectedProducts([]);
        }
    };

    const handleSelectProduct = (productName: string) => {
        setSelectedProducts((prevSelectedProducts: any) =>
            prevSelectedProducts.includes(productName)
                ? prevSelectedProducts.filter((name: string) => name !== productName)
                : [...prevSelectedProducts, productName]
        );
    };

    const handleOk = () => {
        const selectedProductsData = selectedProducts.map((productName: string) =>
            existingProductList?.find((item: any) => item.prod_Name === productName)
        );

        handleSelect(selectedProductsData);
        closeModal();
    };

    const handleCancelClick = () => {
        closeModal();
    };

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.toLowerCase();


        const filteredData = productSearch.payload.filter((product: any) => {
            return product?.prod_Name?.toLowerCase().includes(query);
        });

        setExistingProductList(filteredData);
    };

    const fetchData = () => {
        dispatch(VendorProductList(vendorId) as any)
            .then((res: any) => {
                if (res) {
                    setExistingProductList(res.payload)
                    setProductSearch(res)
                }
            })
            .catch((error: any) => {
                console.error("Error fetching Vendor Products:", error);
            });

    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rows = existingProductList.map((item: any, index: any) => ({

        id: uuidv4(),
        sno: index + 1,
        ...item,
    }));

    const columns: GridColDef[] = [
        {
            field: "select",
            headerName: "",
            width: 80,
            editable: false,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => (
                <Checkbox
                    indeterminate={
                        selectedProducts?.length > 0 && selectedProducts?.length < rows.length
                    }
                    checked={selectedProducts?.length === rows?.length}
                    onChange={(event) => handleSelectAll(event)}
                />
            ),
            renderCell: (params) => (

                <Checkbox
                    onChange={() => handleSelectProduct(params.row.prod_Name)}
                    checked={
                        selectedProducts?.includes(params.row.prod_Name) &&
                        !prodcutRows.some(
                            (value: any) => value.product_name === params.row.prod_Name
                        )
                    }
                    disabled={
                        prodcutRows.some(
                            (value: any) => value.productName === params.row.prod_Name
                        )
                    }
                />
            ),
        },
        { field: "sno", headerName: "S No", width: 120 },
        {
            field: "prod_Name",
            headerName: "Product Name",
            flex: 2,
            minWidth: 190,
        },
        {
            field: "price",
            headerName: "MRP",
            flex: 2,
            minWidth: 150,
        },
        {
            field: "purchasePrice",
            headerName: "Purchase Price",
            flex: 2,
            minWidth: 150,
        },

        {
            field: "units",
            headerName: "Units",
            flex: 2,
            minWidth: 150,
        },
    ];

    return (
        <div>
            <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
                <motion.div
                    className="modal-container"
                    aria-labelledby="modal-title"
                    role="dialog"
                    aria-modal="true"
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    transition={{
                        type: "spring",
                        damping: 18,
                    }}
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75">
                        <motion.div className="relative rounded-lg overflow-hidden bg-[white] text-center shadow-xl transform transition-all my-8 w-11/12 mx-auto border border-[#008065]">
                            <div className='p-2 bg-[#008065]'>
                                <div className='flex p-1 justify-between  ' >
                                    <h2 className="md:text-xl 2xl:text-xl font-semibold text-white text-xl">Existing Products</h2>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className='cursor-pointer mt-1.5' viewBox="0 0 384 512" fill='#fff' onClick={closeModal}><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
                                </div>
                            </div>
                            <div
                                className="bg-[#F1F7F6] p-[8px] m-[5px]"
                                style={{ borderRadius: "8px" }}
                            >
                                <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                                    <div className="flex items-center">
                                        <div className="relative flex mb-2">
                                            <input
                                                onChange={(e) => onSearch(e)}
                                                type="text"
                                                id="simple-search"
                                                className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                                                placeholder="Search"
                                                required
                                            />
                                            <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                                                <svg
                                                    className="w-4 h-4"
                                                    aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <Box sx={{ height: "60vh", width: "100%" }}>
                                    <DataGrid
                                        // autoHeight
                                        sx={{
                                            ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                                            {
                                                color: "rgb(0, 128, 101)",
                                                fontWeight: 600,
                                                fontSize: "14px",
                                            },
                                            ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                                                fontWeight: 600,
                                                fontSize: "14px",
                                            },
                                            ".css-1rtad1": {
                                                display: "block !important",
                                            },
                                            ".css-j2otgh-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-j2otgh-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate":
                                            {
                                                color: " rgb(0, 128, 101) !important",
                                            },
                                        }}
                                        rows={rows}
                                        columns={columns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: {
                                                    pageSize: 5,
                                                },
                                            },
                                        }}
                                        pageSizeOptions={[5, 10, 25, 50]}
                                        disableRowSelectionOnClick
                                    />
                                </Box>
                            </div>
                            <div className="bg-[white] px-4 py-3 sm:px-6 flex flex-col gap-3 sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[white] text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0  sm:w-auto sm:text-sm"
                                    onClick={handleCancelClick}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#008065] text-base font-medium text-[white] hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:w-auto sm:text-sm"
                                    onClick={handleOk}
                                >
                                    OK
                                </button>
                            </div>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}

export default PurchaseProductModel;
