import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useDispatch } from "react-redux";
import { ProductsGet, ProductsUpdate } from "../../redux/actions/productAction";
import Barcode from "react-barcode";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";

const BarCode = () => {
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);
  const inputRef = useRef<HTMLInputElement>(null);
  const shortcutbtnRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedDropdownRowIndex, setSelectedDropdownRowIndex] =
    useState<number>(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [barcodevalues, setBarcodeValues] = useState([] as any);
  const [productListValues, setProductListValues] = useState([] as any);
  const [searchTerm, setSearchTerm] = useState('');
  const [salePrice, setSalePrice] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([] as any);
  const [barcodeNumber, setBarcodeNumber] = useState("");
  const [company, setCompany] = useState("");
  const [product, setProduct] = useState("");
  const [price, setPrice] = useState("");

  const [barcodeRows, setBarcodeRows] = useState<any>([]);
  const [barcodeSize, setBarcodeSize] = useState({ width: 1, height: 50 });
  const [productList, setProductList] = useState([] as any);
  const [isProductSelected, setIsProductSelected] = useState(false);
  const [existBarcode, setExistBarcode] = useState(false);
  const [labelCount, setLabelCount] = useState<number>(0);  // For label count
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    productName: "",
    barcode_Number: "",
    label_count: "",
    title: "",
    first_line: "",
    second_line: "",
    user_id: userId?.token?._id,
  } as any);

  const [errors, setErrors] = useState<{ productName?: string; barcode_Number?: string; label_count?: string }>({});
  const componentRef = useRef(null);
  const StyledDiv = styled("div")({
    position: "relative",
    marginRight: "0.5rem",
    marginTop: "0.75rem",
    overflowY: "auto",
    maxHeight: "75vh",
    "&::-webkit-scrollbar": {
      width: "7px",
      height: "7px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#FFFFFF",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#d1d5db",
      borderRadius: "4px",
    },
  });
  const dispatch = useDispatch();
  const handleBarcodeDelete = (rowToDelete: any) => {
    setBarcodeValues((prevRows: any) =>
      prevRows.filter(
        (row: any) => row.barcode_Number !== rowToDelete.barcode_Number
      )
    );
    setBarcodeRows((prevRows: any) =>
      prevRows.filter(
        (row: any) => row.barcode_Number !== rowToDelete.barcode_Number
      )
    );
    setFormData({
      productName: "",
      barcode_Number: "",
      label_count: "",
      title: "",
      first_line: "",
      second_line: "",
    });
    toast.success("Delete Successfully !", {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 800,
    });
  };

  const rows = barcodevalues.map((item: any, index: any) => ({
    id: uuidv4(),
    sl_No: index + 1,
    ...item,
  }));

  const columns: GridColDef[] = [
    { field: "sl_No", headerName: "S No", width: 120 },
    { field: "productName", headerName: "Product Name", width: 190 },
    {
      field: "label_count",
      headerName: "Total Labels",
      flex: 2,
      minWidth: 190,
    },
    {
      field: "title",
      headerName: "Tittle Line",
      flex: 2,
      minWidth: 190,
    },
    {
      field: "first_line",
      headerName: "First Line",
      flex: 2,
      minWidth: 190,
    },
    {
      field: "second_line",
      headerName: "Second Line",
      flex: 2,
      minWidth: 190,
    },
    {
      field: "action",
      headerName: "Actions",
      editable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params: any) => (
        <>
          {/* <IconButton aria-label="edit" onClick={() => handleServoiceEdit(params.row)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </IconButton> */}
          <IconButton
            aria-label="delete"
            onClick={() => handleBarcodeDelete(params.row)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#008065"
              className="w-5 h-5  cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </IconButton>
        </>
      ),
    },
  ];
  const focusRow = (index: number) => {
    const row = document.getElementById(`dropdown-row-${index}`);
    if (row) {
      row.focus();
    }
  };
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    if (
      name === "label_count" &&
      (value === "0" || /^0\d/.test(value) || /[a-zA-Z]/.test(value))
    ) {
      return;
    }
    
    setSelectedDropdownRowIndex(0);
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => {
      const updatedErrors: any = { ...prevErrors };
      if (value.trim()) {
        delete updatedErrors[name];
      }
      return updatedErrors;
    });
    if (name === "productName") {
      setSearchTerm(value);
      if (value) {
        const filtered = productListValues.filter((product: any) =>
          product.product_name.toLowerCase().includes(value.toLowerCase())
        );
        const newValue = filtered.find((product: any) => product.product_name == value);
        setBarcodeNumber(newValue?.productBar_code)
        setFilteredProducts(filtered);
        setShowDropdown(filtered?.length > 0);
      } else {
        setFilteredProducts([]);
        setBarcodeNumber("");
        setExistBarcode(false);
        setPrice("")
        setProduct("")
        setCompany("")
      }
    }
    if (name === "label_count") {
      setLabelCount(Number(value));
    }
    if (name === "title" || name === "first_line" || name === "second_line") {
      if (value === companyName) {
        setCompany(value);
      }
      if (value === searchTerm) {
        setProduct(value);
      }
      if (value === salePrice) {
        setPrice(value);
      }
    }
  };

  const handleSelectProduct = (product: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      productName: product.product_name,
      barcode_Number: product.productBar_code
    }))
    setProductList(product)
    setIsProductSelected(true)
    setSearchTerm(product.product_name);
    setSalePrice(product.sellingPrice)
    setCompanyName(product.companyName)
    setBarcodeNumber(product.productBar_code)
    if (product.productBar_code) {
      setExistBarcode(true)
    }
    setFilteredProducts([]);
  };
  const fetchData = () => {
    dispatch(ProductsGet(userId?.token?._id) as any).then((res: any) => {
      if (res) {
        setProductListValues(res.payload);
      }
    });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleGenerateBarcode = () => {
    if (searchTerm) {
      // Generate a unique barcode number (e.g., using timestamp)
      const uniqueBarcode = `BC-${Date.now()}`;
      setBarcodeNumber(uniqueBarcode);
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        barcode_Number: uniqueBarcode,
      }))

    }
  };
  const handlePrint = useReactToPrint({

    content: () => componentRef.current,

    documentTitle: "Invoice Letter",
    onAfterPrint: () => {
      console.log("Print completed!");
    },
  });

  const handleBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value) {
      setBarcodeNumber(value);
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        barcode_Number: value,
      }))
      setErrors((prevErrors) => {
        const updatedErrors: any = { ...prevErrors };
        if (e.target.value.trim()) {
          delete updatedErrors[barcodeNumber];
        }
        return updatedErrors;
      });
      const barcodeExists = productListValues.some((product: any) => product.productBar_code === value);
      if (barcodeExists) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          barcode_Number: "Barcode already exists",
        }));
      } else {
        setErrors((prevErrors) => {
          const updatedErrors: any = { ...prevErrors };
          if (value.trim()) {
            delete updatedErrors.barcode_Number;
          }
          return updatedErrors;
        });
      }
    } else {
      setFormData({
        productName: "",
        barcode_Number: "",
        label_count: "",
        title: "",
        first_line: "",
        second_line: "",
        user_id: userId?.token?._id,
      });
      setBarcodeNumber("");
      setSalePrice("");
    }
  };
  const scrollToSelectedRow = (index: number) => {
    const row = document.getElementById(`dropdown-row-${index}`);
    if (row) {
      row.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const handleDropdownNavigation = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (searchTerm !== "") {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setSelectedDropdownRowIndex((prevIndex) => {
          const nextIndex =
            prevIndex < filteredProducts?.length - 1
              ? prevIndex + 1
              : prevIndex;
          scrollToSelectedRow(nextIndex);
          return nextIndex;
        });
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setSelectedDropdownRowIndex((prevIndex) => {
          const nextIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
          scrollToSelectedRow(nextIndex);
          return nextIndex;
        });
      } else if (e.key === "Enter") {
        setSelectedDropdownRowIndex(0);
        const selectedProduct = filteredProducts[selectedDropdownRowIndex];
        handleSelectProduct(selectedProduct);
        setSearchTerm("");
        setFilteredProducts([]);
      }
    } else {
      setSelectedDropdownRowIndex(0);
    }
  };
  const validateForm = () => {
    const errors: any = {};
    if (!formData.productName.trim()) {
      errors.productName = "Product name is required.";
    }
    if (!formData.barcode_Number.trim() && barcodeNumber) {
      errors.barcode_Number = "Barcode number is required.";
    }
    if (!formData.label_count.trim() || isNaN(Number(formData.label_count))) {
      errors.label_count = "Label count is required.";
    }
    const barcodeExists = productListValues.some((product: any) => product.productBar_code === barcodeNumber);
    if (barcodeExists && !existBarcode) {
      errors.barcode_Number = "Barcode already exists";
    }
    return errors;
  };
  const handleCreateBarcode = () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    let obj = {
      productBar_code: barcodeNumber,
      product_name: searchTerm,
      price: productList.price,
    }
    dispatch(ProductsUpdate(productList._id.$oid, obj) as any).then((res: any) => {
      if (res) {
        toast.success("Update SuccessFully !", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 800,
        });
        fetchData();
      }
    });
    setBarcodeRows((prevRows: any) => [
      ...prevRows,
      {
        barcode_Number: barcodeNumber,
        labelCount: formData.label_count,
        companyName: companyName,
        salePrice: salePrice,
        productName: searchTerm,
      }
    ]);

    setBarcodeValues((prevList: any) => [...prevList, formData]);
    setFormData({
      productName: "",
      barcode_Number: "",
      label_count: "",
      title: "",
      first_line: "",
      second_line: "",
      user_id: userId?.token?._id,
    });
    setBarcodeNumber("");
    setSearchTerm("");
    setSalePrice("");
    setCompanyName("");
    setIsProductSelected(false);
    setExistBarcode(false);
    setPrice("")
    setProduct("")
    setCompany("")
  };
  const handlePreview = () => {
    setShowPopup(true);
  };
  const handleSizeChange = (e: any) => {
    const selectedSize = e.target.value;
    switch (selectedSize) {
      case "65":
        setBarcodeSize({ width: 1, height: 50 }); // Adjust as per your requirement
        break;
      case "48":
        setBarcodeSize({ width: 1.5, height: 60 });
        break;
      case "24":
        setBarcodeSize({ width: 2, height: 70 });
        break;
      case "12":
        setBarcodeSize({ width: 2.5, height: 80 });
        break;
      default:
        setBarcodeSize({ width: 1, height: 50 });
    }
  };
  useEffect(() => {
    const handleKeys = (event: KeyboardEvent) => {
      if (event.key === "F2") {
        inputRef.current?.focus();
        setSearchTerm("");
      }
      if (event.key === "Escape") {
        if (showDropdown) {
          setShowDropdown(false);
        }
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
        inputRef.current?.focus();
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    window.addEventListener("keydown", handleKeys);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("keydown", handleKeys);
    };
  }, [showDropdown]);

  return (
    <>
      <div className="flex items-center justify-center ">
        <div className="p-8 rounded-lg w-full max-w-8xl">
          <div className="mb-6">

            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <h2
                style={{
                  color: "#008065",
                }}
                className="text-lg font-bold text-[#045545] text-center sm:text-left mb-4 sm:mb-0"
              >
                Create Barcode
              </h2>
              <div className="flex flex-col sm:flex-row sm:items-center sm:gap-4 gap-4">
                <div className="flex flex-col sm:flex-row gap-2">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-sm font-medium text-[#045545] sm:text-center sm:self-center"
                  >
                    Printer:
                  </label>
                  <select className="border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-auto">
                    <option>Regular Printer</option>
                  </select>
                </div>
                <div className="flex flex-col sm:flex-row gap-2">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-sm font-medium text-[#045545] sm:text-center sm:self-center"
                  >
                    Size:
                  </label>
                  <select
                    className="border-2 border-[#949494] focus:border-[#07ad7b]  focus:outline-none rounded-[7px] px-2 h-9 w-full sm:w-auto"
                    onChange={handleSizeChange}
                  >
                    <option value="65">65 Labels(38*21mm)</option>
                    <option value="48">48 Labels(48*24mm)</option>
                    <option value="24">24 Labels(64*34mm)</option>
                    <option value="12">12 Labels(100*44mm)</option>

                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-6 items-start mb-8 w-full">
            <div className="flex flex-col w-full lg:w-3/4 gap-6">
              <div className="flex flex-col sm:flex-row gap-4 w-full">
                <div className="relative w-full sm:w-1/3">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-md bold font-medium mb-2 "
                    htmlFor="itemName"
                  >
                    Product Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={searchTerm}
                    autoFocus
                    name="productName"
                    autoComplete="off"
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Search product"
                    // className="border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full"
                    className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full +
                      ${errors?.productName ? 'border-[#FF0000]' : 'border-[#949494] focus:border-[#07ad7b]'}
                    `}
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '13px',
                      fontWeight: 400,
                      // borderColor: errors?.productName ? "red" : "#00A787",
                    }}
                    ref={inputRef}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && selectedDropdownRowIndex !== null) {
                        event.preventDefault(); 
                        if (selectedDropdownRowIndex !== null) {
                          handleSelectProduct(filteredProducts[selectedDropdownRowIndex]); 
                        }
                        
                      } else if (searchTerm !== "" && filteredProducts?.length > 0) {
                        handleDropdownNavigation(event);
                      } else {
                        if (event.key === "ArrowDown") {
                          setSelectedRowIndex(0);
                          focusRow(0);
                          event.preventDefault();
                        } else if (event.key === "ArrowUp") {
                          shortcutbtnRef.current?.focus();
                          event.preventDefault();
                        }
                      }
                    }}
                  />

                  {showDropdown && filteredProducts?.length > 0 && (
                    <div
                      ref={dropdownRef}
                      className="absolute z-20 w-full	 border border-gray-300 rounded-lg bg-white shadow-lg max-h-60 overflow-y-auto"
                    >
                      <table className="table-auto w-full whitespace-nowrap text-left border-collapse">
                        <thead className="bg-gradient-to-r from-blue-100 to-blue-200 text-gray-700">
                          <tr>
                            <th className="border-b border-gray-300 px-4 py-2 w-[7%] text-sm font-semibold">
                              #
                            </th>
                            <th className="border-b border-gray-300 px-4 py-2 w-[15%] text-sm font-semibold">
                              Product Name
                            </th>
                            <th className="border-b border-gray-300 px-4 py-2 w-[18%] text-sm font-semibold">
                              Bar Code
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredProducts.map(
                            (product: any, index: number) => (
                              <tr
                                key={product.productId}
                                id={`dropdown-row-${index}`}
                                className={`hover:bg-blue-50 cursor-pointer transition-all duration-200 ${
                                  selectedDropdownRowIndex === index
                                    ? "bg-blue-200"
                                    : ""
                                }`}
                                onClick={(e) => handleSelectProduct(product)}
                              >
                                <td className="border-b border-gray-200 px-4 py-2 text-sm text-gray-600">
                                  {index + 1}
                                </td>
                                <td className="border-b border-gray-200 px-4 py-2 text-sm text-gray-600 truncate">
                                  {product.product_name}
                                </td>
                                <td className="border-b border-gray-200 px-4 py-2 text-sm text-gray-600">
                                  {product.productBar_code}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {errors?.productName && (
                    <p
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-sm"
                    >
                      {errors?.productName}
                    </p>
                  )}
                </div>
                <div className="w-full sm:w-1/3">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-md bold font-medium mb-2 "
                    htmlFor="itemCode"
                  >
                    Barcode<span className="text-red-500">*</span>
                  </label>
                  {barcodeNumber ? (<>
                    <input
                      type="text"
                      id="itemCode"
                      value={barcodeNumber}
                      name="barcode_Number"
                      onChange={handleBarcodeChange}
                      disabled={existBarcode ? true : false}
                      placeholder="Assign Code"
                      className={`border-2 border-[#008065] focus:outline-none rounded-[7px] px-2 h-9 w-full +
                        ${errors?.barcode_Number ? 'border-[#FF0000]' : 'border-[#949494] focus:border-[#07ad7b]'}
                      `}
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: 400,
                      
                      }}
                    />
                    {errors?.barcode_Number && (

                      <p
                        style={{
                          fontFamily: "poppins",
                          fontWeight: 700,
                          color: "#ff0000",
                        }}
                        className="text-red-400 text-sm"
                      >
                        {errors?.barcode_Number}
                      </p>
                    )}
                  </>


                  ) : (
                    <button
                      style={{
                        backgroundColor: "#008065",
                        fontSize: "14px",
                        fontFamily: "Poppins, sans-serif",
                      }}
                      onClick={handleGenerateBarcode}
                      disabled={!isProductSelected}
                      className={`${isProductSelected
                        ? "rounded-lg font-bold text-white text-sm h-8 w-full block bg-[#008065] flex items-center justify-center gap-2"
                        : "rounded-lg font-bold text-white text-sm h-8 w-full block bg-[#008065] flex items-center justify-center gap-2   opacity-70  cursor-not-allowed disabled"
                        }`}
                    >
                      Assign Barcode
                    </button>
                  )}


                </div>

                <div className="w-full sm:w-1/3">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-md font-medium mb-2 "
                    htmlFor="noOfLabels"
                  >
                    Total Labels<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="label_count"
                    autoComplete="off"
                    value={formData.label_count}
                    onChange={(e) => handleInputChange(e)}
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '13px',
                      fontWeight: 400,
                    
                    }}
                    id="noOfLabels"
                    placeholder="Total Labels"
                    className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full +
                      ${errors?.label_count ? 'border-[#FF0000]' : 'border-[#949494] focus:border-[#07ad7b]'}
                    `}
                  />
                  {errors?.label_count && (

                    <p
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-sm"
                    >
                      {errors?.label_count}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col sm:flex-row gap-4 w-full">
                <div className="w-full sm:w-1/3">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-md bold font-medium mb-2 "
                    htmlFor="line1"
                  >
                    Tittle Line
                  </label>
                  <select
                    className="border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 w-full cursor-pointer"
                    name="title"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "13px",
                      fontWeight: 400,
                      // borderColor: "#008065",
                    }}
                    onChange={(e) => handleInputChange(e)}
                    value={formData.title}
                  >
                    <option value="" hidden selected>
                      Select
                    </option>
                    <option value={companyName}>Company Name</option>
                    <option value={searchTerm}>Product Name</option>
                    <option value={salePrice}>   Sale Price   </option>
                  </select>
                </div>

                {/* First Line */}
                <div className="w-full sm:w-1/3">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-md bold font-medium mb-2 "
                    htmlFor="firstLine"
                  >
                    First Line
                  </label>
                  <select
                    className="border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9 w-full cursor-pointer"
                    name="first_line"
                    value={formData.first_line}
                    style={{
                      fontFamily: "poppins",
                      fontSize: "13px",
                      fontWeight: 400,
                      // borderColor: "#008065",
                    }}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" hidden selected>
                      Select
                    </option>
                    <option value={companyName}>Company Name</option>
                    <option value={searchTerm}>Product Name</option>
                    <option value={salePrice}>  Sale Price   </option>
                  </select>
                </div>

                {/* Second Line */}
                <div className="w-full sm:w-1/3">
                  <label
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="block text-md bold font-medium mb-2 "
                    htmlFor="line2"
                  >
                    Second Line
                  </label>
                  <select
                    className="border-2 border-[#949494] focus:border-[#07ad7b]  focus:outline-none rounded-[7px] px-2 h-9 w-full cursor-pointer"
                    name="second_line"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "13px",
                      fontWeight: 400,
                      // borderColor: "#008065",
                    }}
                    value={formData.second_line}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option value="" hidden selected>
                      Select
                    </option>
                    <option value={companyName}>Company Name</option>
                    <option value={searchTerm}>Product Name</option>
                    <option value={salePrice}> Sale Price </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-1/4   flex flex-col items-center gap-4 ">
              {/* <div className="w-full border-2 border-[#949494] focus:border-[#07ad7b] rounded-md p-6 text-center lg:mt-7 bg-gray-70  "> */}
              <div
                className={`w-full border-2 rounded-md p-6 text-center lg:mt-7 bg-gray-70 focus:outline-none
                  ${barcodeNumber
                  ? 'border-[#949494] focus-within:border-[#07ad7b]'
                  : 'border-[#949494]'
                  }`}
                  tabIndex={0}
              >
                <p
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#008065",
                  }}
                  className="text-sm font-semibold"
                >
                  {company}
                </p>
                <p className=" mx-auto flex justify-center">
                  {barcodeNumber && (
                    <Barcode
                      value={barcodeNumber}
                      width={1}
                      height={50}
                      fontSize={14}
                      background="#ffffff"
                      lineColor="#000000"
                    />
                  )}
                </p>
                <p
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#008065",
                  }}
                  className="text-sm pb-1"
                >
                  {product}
                </p>
                
                {price && (
                  <p
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#008065",
                    }}
                    className="text-sm pt-1"
                  >
                    Sale Price - {price}
                  </p>
                )}
              </div>

              <button
                style={{
                  backgroundColor: "#008065",
                  fontSize: "14px",
                  fontFamily: "Poppins, sans-serif",
                }}
                onClick={handleCreateBarcode}
                className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065] flex items-center justify-center gap-2"
              >
                <IoMdAddCircleOutline className="w-5 h-5" />
                Create Barcode
              </button>
            </div>
          </div>
          <Box
            sx={{
              height: "50vh",
              width: "100%",
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            <DataGrid
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: 500,
                ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                {
                  backgroundColor: "#fff",
                  color: "#008060 !important",
                  fontWeight: 600,
                  fontSize: "14px",
                },
                "& .MuiDataGrid-checkboxInput": {
                  color: "#008060 !important",
                },
                ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                  fontWeight: 600,
                  fontSize: "14px",
                },
              }}
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 50]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>

          <div className="flex justify-end gap-6">
            <button
              style={{
                backgroundColor: "#008065",
                fontSize: "14px",
                fontFamily: "poppins",
              }}
              disabled={barcodevalues.length === 0 ? true : false}
              onClick={handlePreview}
              className={barcodevalues.length === 0 ? "opacity-70 rounded-xl font-bold text-white  text-sm h-8  w-36 block  bg-[#008065]" : " rounded-xl font-bold text-white  text-sm h-8  w-36 block  bg-[#008065] "}
            >
              Preview
            </button>
            <button
              style={{
                backgroundColor: "#008065",
                fontSize: "14px",
                fontFamily: "poppins",
              }}
              className={barcodevalues.length === 0 ? " opacity-70 rounded-xl font-bold text-white  text-sm h-8  w-36 block  bg-[#008065]" : " rounded-xl font-bold text-white  text-sm h-8  w-36 block  bg-[#008065] "}
              disabled={barcodevalues.length === 0 ? true : false}
              onClick={handlePrint}
            >
              Generate
            </button>
          </div>
        </div>
      </div>

      {showPopup && (
        <div className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 transition-opacity">
          <StyledDiv className="bg-white p-5 rounded-md shadow-lg max-h-[80vh] overflow-y-auto">
            <div className={barcodeSize.width === 2.5 ? "grid grid-cols-2 gap-4" : "grid grid-cols-3 gap-4"}>
              {barcodeRows.map((barcodeRow: any, index: any) => (
                Array.from({ length: parseInt(barcodeRow.labelCount) }, (_, labelIndex) => (
                  <div key={`${index}-${labelIndex}`} className="flex flex-col items-center">
                    <span>{barcodeRow.companyName}</span>
                    <Barcode
                      value={`${barcodeRow.barcode_Number}-${labelIndex + 1}`}
                      width={barcodeSize.width}
                      height={barcodeSize.height}
                      fontSize={14}
                      background="#ffffff"
                      lineColor="#000000"
                    />
                    <span>{barcodeRow.productName}</span>
                    <span>Sale Price - {barcodeRow.salePrice}</span>
                  </div>
                ))
              ))}
            </div>

            <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
            </div>
          </StyledDiv>
        </div>
      )}
      <div style={{ display: "none" }}>
        <div ref={componentRef} >
          <div style={{ breakBefore: "page" }}></div>
          <div
            className={
              barcodeSize.width === 2.5
                ? "grid grid-cols-2 gap-4"
                : "grid grid-cols-3 gap-4"
            }
          >
            {barcodeRows.map((barcodeRow: any, index: any) =>
              Array.from(
                { length: parseInt(barcodeRow.labelCount) },
                (_, labelIndex) => (
                  <div key={`${index}-${labelIndex}`} className="flex flex-col items-center">
                    <span>{barcodeRow.companyName}</span>
                    <Barcode
                      value={`${barcodeRow.barcode_Number}-${labelIndex + 1}`}
                      width={barcodeSize.width}
                      height={barcodeSize.height}
                      fontSize={14}
                      background="#ffffff"
                      lineColor="#000000"
                    />
                    <span>{barcodeRow.productName}</span>
                    <span>Sale Price - {barcodeRow.salePrice}</span>
                  </div>
                )
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BarCode;
