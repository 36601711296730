/* eslint-disable eqeqeq */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  findUpiDetail,
  upiPaymentAll,
  upiPaymentDelete,
  upiPaymentSave,
  upiPaymentUpdate,
} from "../../redux/actions/upiPaymentAction";
import ConfirmationPopup from "../commen/ConfirmationPopup";
import { useNavigate, useParams } from "react-router-dom";

interface FieldErrors {
  upi_number?: string;
}

const UPIPaymentPage = () => {
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [upiPaymentList, setUpiPaymentList] = useState([] as any);
  // const [searchValue, setSearchValue] = useState([] as any);
  const [upiId, setUpiId] = useState(null as any);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);


  const fetchData = () => {
    dispatch(upiPaymentAll(userId?.token?._id) as any).then((res: any) => {
      setUpiPaymentList(res.payload);
    });
  };

  useEffect(() => {
    if (userId?.token?._id) {
      fetchData();
    }
  }, []);

  /*   useEffect(() => {
      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); */

  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

  const handleInputChange = (e: any) => {
    setValue("upi_number", e.target.value.trim());
    const { name, value } = e.target;
    const fieldName = name as keyof FieldErrors;
    let error = "";
    if (
      fieldName === "upi_number" &&
      !value.match(/^[a-zA-Z0-9.-]+@[a-zA-Z]+$/) &&
      value
    ) {
      error = "Invalid UPI number format";
    }
    setFieldErrors((prevErrors: any) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const onSubmit = (data: any) => {
    const duplicate = upiPaymentList.some(
      (payment: any) =>
        payment.upi_number === data.upi_number && payment._id.$oid !== id
    );
    if (duplicate) {
      toast.error("UPI number already exists", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 800,
      });
      return;
    }

    if (id) {
      dispatch(upiPaymentUpdate(id, data) as any).then((res: any) => {
        // eslint-disable-next-line eqeqeq
        if (res.type == "UPIPAYMENT_UPDATE_SUCCESS") {
          fetchData();
          setUpiId("");
          let value = {
            upi_pay: "",
            defaultUpiInfo: "",
            upi_number: "",
          };
          reset({
            ...value,
          });
        }
        toast.success("Update SuccessFully !", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 800,
        });
        navigate("/app/upiPaymentReport");
      });
    } else {
      dispatch(upiPaymentSave(data) as any).then((res: any) => {
        if (res.type == "UPIPAYMENT_POST_SUCCESS") {
          fetchData();
          reset();
        }
        toast.success("Saved SuccessFully !", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 800,
        });
      });
    }
  };

  const editUpiPayment = (upi: any) => {
    setUpiId(upi._id.$oid);

    let value = {
      upi_pay: upi.upi_pay,
      defaultUpiInfo: upi.defaultUpiInfo,
      upi_number: upi.upi_number,
    };
    reset({
      ...value,
    });
  };

  // const deleteUpi = (item:any) => {
  //   setShowConfirmation(true);
  //   setUpiId(item._id?.$oid);

  // };


  const handleConfirm = () => {
    dispatch(upiPaymentDelete(upiId) as any).then((res: any) => {
      if (res) {
        fetchData();
        setUpiId(null);
        setShowConfirmation(false);
        let value = {
          upi_pay: "",
          defaultUpiInfo: "",
          upi_number: "",
        };
        reset({
          ...value,
        });
      }
    });
  };

  useEffect(() => {
    if (!id) {
      fetchData();
    } else {
      dispatch(findUpiDetail(id) as any).then((res: any) => {
        // console.log("res---->", res);
        if (res?.type === "UPIPAYMENT_GET_ONE_SUCCESS") {
          setValue("upi_pay", res?.payload?.upi_pay);
          setValue("defaultUpiInfo", res?.payload?.defaultUpiInfo);
          setValue("upi_number", res?.payload?.upi_number);
          setValue("user_id", res?.payload?.user_id || userId?.token?._id);
        }
      });
    }
  }, [id]);

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleCancelClick = () => {
    setUpiId(false);
    let value = {
      upi_pay: "",
      defaultUpiInfo: "",
      upi_number: "",
    };
    reset({
      ...value,
    });
    setFieldErrors({});
    // reset();
  };

  return (
    <div>
      <div className=' className="bg-[#E1E8E7]"  pr-2 sm:pr-0'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="mx-3 mb-4 rounded-lg">
            <legend className="p-4  font-bold text-[#008065]  text-lg   ml-2">
              UPI Payment Info
            </legend>
            <div className="rounded-xl px-2 ">
              <div className="flex ml-2 gap-2">
                <div className="">
                  <input
                    type="checkbox"
                    placeholder="defaultUpiInfo"
                    className={
                      "border-2 focus:outline-none mx-2 rounded-[7px] px-2 h-5 w-5 accent-green-700"
                    }
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                    {...register("defaultUpiInfo")}
                  />
                </div>
                <div className="text-[#008065] font-semibold">
                  <label>Default UPI Payment Info</label>
                </div>
              </div>
              <div className="flex w-full flex-col sm:flex-row ">
                <div className="w-full ">
                  <div className="text-gray-700 md:flex md:items-center mx-2 p-2">
                    <div className="mb-1 md:mb-0 md:w-1/3 2xl:w-1/4">
                      <label
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                        className="text-[#008065] required"
                      >
                        <span>UPI Pay</span>
                      </label>
                    </div>

                    <div className="relative mt-0">
                      <select
                        defaultValue={""}
                        className={`block lg:w-72 md:w-72 xl:w-96 2xl:w-96 w-full p-2 text-sm text-gray-900 border rounded-lg focus:outline-none 
                         ${errors.upi_pay
                            ? "border-2 border-red-500"
                            : "border-2 focus:border-[#07ad7b] border-[#949494]"
                          } 
                         bg-gray-50 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        {...register("upi_pay", {
                          required: "Upi Pay is required",
                        })}
                      >
                        <option value="" disabled hidden>
                          Select UPI Pay
                        </option>
                        <option>PayTM</option>
                        <option>Phone Pe</option>
                        <option>Google Pay</option>
                        <option>BHIM</option>
                        <option>iMobile Pay</option>
                        <option>Bharat Pe</option>
                        <option>BHIM Baroda Pay</option>
                        <option>BHIM Axis Pay</option>
                        <option>BHIM SBI Pay</option>
                        <option>YONO SBI</option>
                        <option>PayZapp</option>
                        <option>NIYO Global</option>
                        <option>Bajaj Markets</option>
                      </select>
                      {errors.upi_pay && (
                        <p
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="text-red-400 text-sm"
                        >
                          {"Upi Pay is required"}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="text-gray-700 md:flex md:items-center mx-2 p-2">
                    <div className="mb-1 md:mb-0 md:w-1/3 2xl:w-1/4">
                      <label
                        style={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                        className="text-[#008065] required"
                      >
                        <span>UPI Number</span>
                      </label>
                    </div>
                    <div className="relative md:w-2/3 md:flex-grow">
                      <input
                        className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full  md:w-72 lg:w-72 xl:w-96 2xl:w-96
                        ${errors.upi_number || fieldErrors.upi_number
                            ? "border-red-500"
                            : "border-2 focus:border-[#07ad7b] border-[#949494]"
                          } `}
                        {...register("upi_number", {
                          required: "UPI number is required",
                          // onChange: (e) => {
                          //   setValue("upi_number", e.target.value.trim())
                          // },
                          onChange: (e) => handleInputChange(e),
                          pattern: {
                            value: /^[a-zA-Z0-9.-]+@[a-zA-Z]+$/,
                            message: "Invalid UPI number format",
                          },
                        })}
                        type="text"
                        placeholder="Enter a UPI NO..."
                      />
                      {fieldErrors.upi_number ? (
                        <p
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="text-red-400 text-sm"
                        >
                          {fieldErrors.upi_number}
                        </p>
                      ) : errors.upi_number ? (
                        <p
                          style={{
                            fontFamily: "poppins",
                            fontWeight: 700,
                            color: "#ff0000",
                          }}
                          className="text-red-400 text-sm"
                        >
                          {"UPI number is required"}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-xl  mb-3">
              <div className="flex w-full flex-col sm:flex-row px-4 sm:justify-end justify-center">
                {/* <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col  mb-4 "></div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2">
                  <div className="flex flex-col  mb-4 "> </div>
                </div> */}

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 justify-center w-full md:w-auto">
                  <div className="flex flex-col flex-1 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4 w-full">
                    <button
                      type="button"
                      className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                      style={{
                        backgroundColor: "#008065",
                        fontFamily: "poppins",
                        fontSize: "14px",
                      }}
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color  flex flex-row  font-title flex-2 px-2 justify-center w-full md:w-auto">
                  <div className="flex flex-col flex-1 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4 w-full">
                    <button
                      // onClick={(e: any) => handleSubmit(e)}
                      className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                      style={{
                        backgroundColor: "#008065",
                        fontFamily: "poppins",
                        fontSize: "14px",
                      }}
                    >
                      {id ? "Update" : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default UPIPaymentPage;
