import { Dialog, Transition } from "@headlessui/react";
import { debounce } from "@mui/material";
import { Box } from '@mui/system';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from "uuid";
import ConfirmationPopup from '../pages/commen/ConfirmationPopup';
import ChequeModal from "../pages/components/ChequeModal";
import { ChequeFindAll } from '../redux/actions/chequeAction';


const ChequeTable = () => {
  const dispatch = useDispatch();
  const [chequeTable, setChequeTable] = useState([] as any);
  const usersignin = useSelector((state: any) => state?.userSignin);
  const token = usersignin?.userInfo?.token;
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);





  const handleConfirm = () => {


  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };
  const [purchaseList, setPurchaseList] = useState([] as any)

  const fetchData = () => {
    dispatch(ChequeFindAll(token?._id) as any).then((res: any) => {
      if (res) {
        setPurchaseList(res.payload);
        setChequeTable(res.payload);
      }
    });

  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedSearch = debounce((query: string) => {
    const filteredData = purchaseList.filter((cheque: any) =>
      cheque.transaction_type?.toLowerCase().includes(query) ||
      cheque.Name?.toLowerCase().includes(query)
    );
    setChequeTable(filteredData);
  }, 300);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value.toLowerCase());
  };

  // const onSearch = (e: any) => {
  //   const query = e.target.value.toLowerCase();
  //   const filteredData = chequeTable.filter((cheque: any) =>
  //     cheque.transaction_type.toLowerCase().includes(query) ||
  //   cheque.Name.toLowerCase().includes(query)
  //   );
  //   setChequeTable(filteredData);
  // }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortOrder, setSortOrder] = useState("asc");


  const handleSortChange = (newSortOrder: any) => {
    const sortedData = [...chequeTable].sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.Name.localeCompare(b.Name);
      } else {
        return b.Name.localeCompare(a.Name);
      }
    });
    setChequeTable(sortedData);
    setSortOrder(newSortOrder);
  }

  const [viewOpen, setOpenView] = useState(false);
  const cancelButtonRef = useRef(null);

  const [selectedRow, setSelectedRow] = useState(null as any);

  const handleClick = (params: any) => {

    setOpenView(true)
    setSelectedRow(params)
  }

  const handleCloseModal = () => {
    setOpenView(false)
    fetchData()
  }



  const rows = chequeTable.map((item: any, index: any) => ({
    id: uuidv4(),
    sl_No: index + 1,
    ...item,
  }));


  const columns: GridColDef[] = [
    {
      field: "type",
      headerName: "Type",
      width: 200,
      editable: false,
      renderCell: (params: any) => (

        <>{params.row.transaction_type ? params.row.transaction_type : "N/A"}</>
      ),
    },
    {
      field: "NAME",
      headerName: "Name",
      width: 200,
      editable: false,
      renderCell: (params: any) => (

        <>{params.row.Name ? params.row.Name : "N/A"}</>
      ),
    },
    {
      field: "chequeNum",
      headerName: "Cheque No",
      width: 200,
      editable: false,
      renderCell: (params: any) => (
        <>{params.row.chequeNum ? params.row.chequeNum : "N/A"}</>
      ),
    },
    {
      field: "InvoiceDate",
      headerName: "Date",
      width: 200,
      editable: false,
      renderCell: (params: any) => (
        <>{params.row.InvoiceDate
          ? params.row.InvoiceDate : "N/A"}</>
      ),
    },
    // {
    //   field: "CreateAt",
    //   headerName: "Date",
    //   width: 200,
    //   editable: false,
    //   renderCell: (params: any) => {
    //     const rawDate = params.row.CreateAt?.$date;
    //     const formattedDate = rawDate
    //   ? new Date(rawDate).toLocaleDateString("en-GB") // Format as DD/MM/YYYY
    //   : "N/A";

    // return <>{formattedDate}</>;
    //   },
    // },
    {
      field: "amount",
      headerName: "Amount",
      editable: false,
      width: 200,
      renderCell: (params: any) => (
        <>{params.row.receivedAmount ? params.row.receivedAmount : "N/A"}</>
      ),
    },
    // {
    //   field: "duedate",
    //   headerName: "Due Date",
    //   editable: false,
    //   width: 200,
    //   renderCell: (params: any) => (
    //     <>{params.row.duedate ? params.row.duedate : "N/A"}</>
    //   ),
    // },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      width: 200,

      renderCell: (params: any) => (
        <>
          <div style={{ textAlign: "left", width: "50%" }}>
            {params.row.chequeStatus
              ? (params.row.chequeStatus)
              : "N/A"}
          </div>

        </>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      editable: false,
      width: 200,
      renderCell: (params: any) => {
        const isDisabled =
          params.row.chequeStatus === "ADD TO CASH IN HAND" ||
          params.row.chequeStatus === "REMOVE FROM CASH IN HAND";
        return (
          <div
            style={{
              textAlign: "left",
              width: "50%",
              color: isDisabled
                ? "gray"
                : params.row.transaction_type === "PURCHASE" ||
                  params.row.transaction_type === "PAYMENTOUT" ||
                  params.row.transaction_type === "PURCHASE(FA)"
                  ? "red"
                  : "green",
              pointerEvents: isDisabled ? "none" : "auto",
              cursor: isDisabled ? "not-allowed" : "pointer"
            }}
            onClick={() => !isDisabled && handleClick(params.row)}
          >
            {isDisabled
              ? "CLOSED"
              : params.row.transaction_type === "PURCHASE" ||
                params.row.transaction_type === "PAYMENTOUT" ||
                params.row.transaction_type === "PURCHASE(FA)"
                ? "WITHDRAW"
                : "DEPOSIT"}
          </div>

        );
      },
    }
  ];


  return (
    <div>


      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 ml-3 w-[86%] md:w-[93%] lg:w-[97%] xl:w-[96.5%] 2xl:w-[96.6%]">
        <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row">
          <div className="flex items-center">
            <div className="relative flex mb-2">
              <input
                onChange={(e) => onSearch(e)}
                type="text"
                id="simple-search"
                className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                placeholder="Search"
                required
              />
              <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="block mb-2  ">
            <div className="relative flex items-center ">
              <select
                defaultValue={""}
                id="sortDropdown"
                className="block w-[11rem] h-10 p-2 text-sm  border border-[#008065] rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 "
                onChange={(e) => handleSortChange(e.target.value)}
              >
                <option value="" disabled hidden>
                  Sort By
                </option>
                <option value="asc">Name A to Z</option>
                <option value="desc">Name Z to A</option>
              </select>
            </div>
          </div>
        </div>
        <Box
          sx={{
            height: "84vh",
            width: "100%",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <DataGrid
            // autoHeight
            sx={{
              color: "#000",
              fontSize: "14px",
              fontWeight: 500,
              ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
              {
                backgroundColor: "#fff",
                color: "#008060 !important",
                fontWeight: 600,
                fontSize: "14px",
              },
              "& .MuiDataGrid-checkboxInput": {
                color: "#008060 !important",
              },
              ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                fontWeight: 600,
                fontSize: "14px",
              },
              "@media (min-width: 2555px)": {
                ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor, .MuiDataGrid-cell.MuiDataGrid-cell--textLeft":
                {
                  minWidth: "265px !important",
                },
              },
            }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      </div>



      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      <Transition.Root show={viewOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpenView}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="sm:flex min-h-full items-end sm:justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:mx-8 sm:w-full  md:w-9/12 lg:w-8/12 xl:w-6/12 2xl:w-4/12 h[600px]">

                  {/* {
                    <PreviewPurchaseBillwise
                      Invoice={formData}
                      componentRef={componentRef}
                      closeModal={closeModal}
                      selectedFont={selectedFont}
                    />
                } */}

                  <ChequeModal selectedRow={selectedRow} onClose={handleCloseModal} />

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

    </div>
  )
}

export default ChequeTable
