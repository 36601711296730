/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { printPlugin } from "@react-pdf-viewer/print";
// import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ConfirmationPopup from "../pages/commen/ConfirmationPopup";
import { currencyGet } from "../redux/actions/currencyAction";
import {
	InternalFindAll,
	InvoiceDelete,
} from "../redux/actions/InternalInvoiceAction";
import {
	serviceFindAll,
	serviceInvoiceDelete,
} from "../redux/actions/invoiceServiceActions";
const DashBoardTable = () => {
	const dispatch = useDispatch();
	const navigateTo = useNavigate();
	const [invoiceTable, setInvoiceTable] = useState([] as any);
	const [isPopupOpen, setPopupOpen] = useState(false);
	const [pdfUrl, setPdfUrl] = useState("");
	const [openTab, setOpenTab] = useState(1 as any);
	const [serviceInvoiceTable, setserviceInvoiceTable] = useState([] as any);
	const [invoiceModel, setInvoiceModel] = useState(null as any);
	const usersignin = useSelector((state: any) => state?.userSignin);
	const token = usersignin?.userInfo?.token;
	const [searchinvoiceTable, setsearchinvoiceTable] = useState([] as any);
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [deleteid, setDeleteId] = useState([] as any);

	useEffect(() => {
		fetchData();
	}, []);

	const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const query = e.target.value.toLowerCase();
		const filteredData = searchinvoiceTable.filter((invoiceDetail: any) =>
			invoiceDetail.clientName?.toLowerCase().includes(query)
		);
		setserviceInvoiceTable(filteredData);
	};
	const onSearchForSales = (e: React.ChangeEvent<HTMLInputElement>) => {
		const query = e.target.value.toLowerCase();
		const filteredData = searchinvoiceTable.filter((invoiceDetail: any) =>
			invoiceDetail.clientName?.toLowerCase().includes(query)
		);
		setInvoiceTable(filteredData);
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [sortOrder, setSortOrder] = useState<string>("asc");
	const handleSortChange = (newSortOrder: string) => {
		const sortedData = [...invoiceTable].sort((a, b) => {
			if (newSortOrder === "asc") {
				return a.clientName.localeCompare(b.clientName);
			} else {
				return b.clientName.localeCompare(a.clientName);
			}
		});
		setInvoiceTable(sortedData);
		setSortOrder(newSortOrder);
	};
	const [sortOrders, setSortOrders] = useState<string>("asc");
	const handleSortChanges = (newSortOrder: string) => {
		const sortedDatas = [...serviceInvoiceTable].sort((a, b) => {
			if (newSortOrder === "asc") {
				return a.clientName.localeCompare(b.clientName);
			} else {
				return b.clientName.localeCompare(a.clientName);
			}
		});
		setserviceInvoiceTable(sortedDatas);
		setSortOrders(newSortOrder);
	};

	const handleEdit = (params: any) => {
		navigateTo(`/app/invoice/${params._id}`, {
			state: params.invoiceModule ? params.billModule : "PRODUCT",
		});
	};
	const handleServoiceEdit = (params: any) => {
		navigateTo(`/app/invoice/${params._id}`, {
			state: params.invoiceModule ? params.billModule : "SERVICE",
		});
	};

	const handleDelete = (params: any) => {
		setInvoiceModel(params.billModule ? params.billModule : "PRODUCT");
		setShowConfirmation(true);
		setDeleteId(params._id);
	};

	const handleServiceDelete = (params: any) => {
		setInvoiceModel(params.billModule ? params.billModule : "SERVICE");
		setShowConfirmation(true);
		setDeleteId(params._id);
	};
	const handleConfirm = () => {
		if (invoiceModel == "PRODUCT") {
			dispatch(InvoiceDelete(deleteid) as any).then((res: any) => {
				if (res) {
					fetchData();
					setDeleteId(null);
					setShowConfirmation(false);
				}
			});
		} else {
			dispatch(serviceInvoiceDelete(deleteid) as any).then((res: any) => {
				if (res) {
					fetchData();
					setDeleteId(null);
					setShowConfirmation(false);
				}
			});
		}
	};

	const handleCancel = () => {
		setShowConfirmation(false);
	};

	const fetchData = () => {
		dispatch(InternalFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				setInvoiceTable(response.payload);
				setsearchinvoiceTable(response.payload);
			}
		});
		dispatch(serviceFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				setserviceInvoiceTable(response.payload);
				setsearchinvoiceTable(response.payload);
			}
		});
	};
	const [currencySymbol, setcurrencySymbol] = useState("₹");

	useEffect(() => {
		dispatch(currencyGet(token?._id) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
	}, []);

	const handleView = (data: any) => {
		setPopupOpen(true);
		const queryParams = new URLSearchParams();
		queryParams.append("param1", "#000000");
		queryParams.append("param2", "#000000");
		queryParams.append("param3", "#000000");
		queryParams.append("param4", "#000000");
		queryParams.append(
			"param5",
			data?.colorTemplateType ? data?.colorTemplateType : ""
		);
		queryParams.append("param6", data?.selectedFont);
		queryParams.append(
			"param7",
			data?.currencySymbol ? data?.currencySymbol : currencySymbol
		);
		queryParams.append(
			"param8",
			data?.invoiceTemplateType ? data?.invoiceTemplateType : ""
		);
		if (data.billModule != "SERVICE") {
			fetch(`/api/invoice-view/${data._id}?${queryParams.toString()}`)
				.then((response) => {
					if (!response.ok) {
						throw new Error("Network response was not ok");
					}
					return response.blob();
				})
				.then((blob) => {
					const url = window.URL.createObjectURL(blob);
					setPdfUrl(url);
				})
				.catch((error) => console.error("Error downloading PDF:", error));
		} else {
			const queryParams = new URLSearchParams();
			queryParams.append("param1", "#000000");
			queryParams.append("param2", "#000000");
			queryParams.append("param3", "#000000");
			queryParams.append("param4", "#000000");
			queryParams.append(
				"param5",
				data?.colorTemplateType ? data?.colorTemplateType : ""
			);
			queryParams.append("param6", data?.selectedFont);
			queryParams.append(
				"param7",
				data?.currencySymbol ? data?.currencySymbol : currencySymbol
			);
			queryParams.append(
				"param8",
				data?.invoiceTemplateType ? data?.invoiceTemplateType : ""
			);
			queryParams.append(
				"param8",
				data?.totalInWords ? data?.totalInWords : ""
			);
			fetch(`/api/service/Invoice-view/${data._id}?${queryParams.toString()}`)
				.then((response) => {
					if (!response.ok) {
						throw new Error("Network response was not ok");
					}
					return response.blob();
				})
				.then((blob) => {
					const url = window.URL.createObjectURL(blob);
					setPdfUrl(url);
				})
				.catch((error) => console.error("Error downloading PDF:", error));
		}
	};

	const rows = invoiceTable.map((item: any, index: any) => ({
		id: uuidv4(),
		sl_No: index + 1,
		...item,
	}));
	const serviceRows = serviceInvoiceTable.map((item: any, index: any) => ({
		id: uuidv4(),
		sl_No: index + 1,
		...item,
	}));

	const columns: GridColDef[] = [
		{
			field: "invoiceNo",
			headerName: "Invoice No",
			editable: false,
			flex: 1,
			minWidth: 180,
			align: "left",
			headerAlign: "left",
			renderCell: (params: any) => (
				<>{params.row.invoiceNo ? params.row.invoiceNo : "N/A"}</>
			),
		},
		{
			field: "clientName",
			headerName: "Client Name",
			editable: false,
			flex: 1.5,
			minWidth: 180,
			align: "left",
			headerAlign: "left",
			renderCell: (params: any) => (
				<>{params.row.clientName ? params.row.clientName : "N/A"}</>
			),
		},
		{
			field: "clientMobile",
			headerName: "Client Mobile",
			editable: false,
			flex: 1,
			minWidth: 150,
			align: "left",
			headerAlign: "left",
			renderCell: (params: any) => (
				<>{params.row.clientMobile ? params.row.clientMobile : "N/A"}</>
			),
		},
		{
			field: "clientEmail",
			headerName: "Client Email",
			editable: false,
			flex: 1.5,
			minWidth: 150,
			align: "left",
			headerAlign: "left",
			renderCell: (params: any) => (
				<>{params.row.clientEmail ? params.row.clientEmail : "N/A"}</>
			),
		},
		{
			field: "invoicedate",
			headerName: "Create Date",
			editable: false,
			flex: 1,
			minWidth: 120,
			align: "left",
			headerAlign: "left",
			renderCell: (params: any) => (
				<>{params.row.invoicedate ? params.row.invoicedate : "N/A"}</>
			),
		},
		{
			field: "duedate",
			headerName: "Due Date",
			editable: false,
			flex: 1,
			minWidth: 120,
			align: "left",
			headerAlign: "left",
			renderCell: (params: any) => (
				<>{params.row.duedate ? params.row.duedate : "N/A"}</>
			),
		},
		{
			field: "toatalAmount",
			headerName: "Total Amount",
			editable: false,
			flex: 1,
			minWidth: 130,
			align: "left",
			headerAlign: "left",
			renderCell: (params: any) => (
				<div style={{ width: "60%", fontWeight: "bold" }}>
					{params.row.toatalAmount
						? Number(params.row.toatalAmount)?.toFixed(2)
						: "N/A"}
				</div>
			),
		},
		{
			field: "balanceAmount",
			headerName: "Balance Amount",
			editable: false,
			flex: 1,
			minWidth: 130,
			align: "left",
			headerAlign: "left",
			renderCell: (params: any) => (
				<div style={{ width: "60%", fontWeight: "bold" }}>
					{params.row.balanceAmount
						? Number(params.row.balanceAmount)?.toFixed(2)
						: "N/A"}
				</div>
			),
		},
		{
			field: "paymentStatus",
			headerName: "Payment Status",
			editable: false,
			flex: 1,
			minWidth: 160,
			align: "left",
			headerAlign: "left",
			renderCell: (params: any) => (
				<span>
					{params.row.paymentStatus ? params.row.paymentStatus : "N/A"}
				</span>
			),
		},
		{
			field: "unit_description",
			headerName: "View",
			editable: false,
			flex: 1,
			minWidth: 120,
			align: "left",
			headerAlign: "left",
			renderCell: (params: any) => (
				<>
					<IconButton onClick={() => handleView(params.row)}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-5 w-5 text-gray-400"
							fill="none"
							viewBox="0 0 24 24"
							stroke="#008065"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
							/>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
							/>
						</svg>
					</IconButton>
				</>
			),
		},
		{
			field: "action",
			headerName: "Actions",
			editable: false,
			flex: 1,
			minWidth: 150,
			align: "left",
			headerAlign: "left",
			renderCell: (params: any) => {
				const isPOS = params.row.saleType === "POS";

				return (
					<>
						<IconButton
							aria-label="edit"
							onClick={() => handleEdit(params.row)}
							disabled={isPOS} // Disable button if POS
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth="1.5"
								stroke={isPOS ? "#A0A0A0" : "#008065"} // Change color if disabled
								className="w-5 h-5"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
								/>
							</svg>
						</IconButton>

						<IconButton
							aria-label="delete"
							onClick={() => handleDelete(params.row)}
							// disabled={isPOS} // Disable button if POS
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth="1.5"
								stroke="#008065" // Change color if disabled
								className="w-5 h-5"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
								/>
							</svg>
						</IconButton>
					</>
				);
			},
		},
	];

	const ServiceColumns: GridColDef[] = [
		{
			field: "invoiceNo",
			headerName: "Invoice No",
			editable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: any) => (
				<>{params.row.invoiceNo ? params.row.invoiceNo : "N/A"}</>
			),
		},
		{
			field: "clientName",
			headerName: "Client Name",
			flex: 1,
			minWidth: 150,
			editable: false,
			renderCell: (params: any) => (
				<>{params.row.clientName ? params.row.clientName : "N/A"}</>
			),
		},
		{
			field: "clientMobile",
			headerName: "Client Mobile",
			flex: 1,
			minWidth: 150,
			editable: false,
			renderCell: (params: any) => (
				<>{params.row.clientMobile ? params.row.clientMobile : "N/A"}</>
			),
		},
		{
			field: "clientEmail",
			headerName: "Client Email",
			flex: 1,
			minWidth: 150,
			editable: false,
			renderCell: (params: any) => (
				<>{params.row.clientEmail ? params.row.clientEmail : "N/A"}</>
			),
		},
		{
			field: "invoicedate",
			headerName: "Create Date",
			editable: false,
			flex: 1,
			minWidth: 120,
			renderCell: (params: any) => (
				<>{params.row.invoicedate ? params.row.invoicedate : "N/A"}</>
			),
		},
		{
			field: "toatalAmount",
			headerName: "Total Amount",
			editable: false,
			align: "right",
			flex: 1,
			minWidth: 120,
			renderCell: (params: any) => (
				<>
					{params.row.toatalAmount
						? Number(params.row.toatalAmount)?.toFixed(2)
						: "N/A"}
				</>
			),
		},
		{
			field: "unit_description",
			headerName: "View",
			editable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: any) => (
				<>
					<IconButton onClick={() => handleView(params.row)}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-5 w-5 text-gray-400"
							fill="none"
							viewBox="0 0 24 24"
							stroke="#008065"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
							/>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
							/>
						</svg>
					</IconButton>
				</>
			),
		},

		{
			field: "action",
			headerName: "Actions",
			editable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: any) => (
				<>
					<IconButton
						aria-label="edit"
						onClick={() => handleServoiceEdit(params.row)}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="#008065"
							className="w-5 h-5 cursor-pointer"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
							/>
						</svg>
					</IconButton>
					<IconButton
						aria-label="delete"
						onClick={() => handleServiceDelete(params.row)}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="#008065"
							className="w-5 h-5  cursor-pointer"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
							/>
						</svg>
					</IconButton>
				</>
			),
		},
	];
	// const zoomPluginInstance = zoomPlugin();
	// const { ZoomIn, ZoomOut, Zoom } = zoomPluginInstance;

	const printPluginInstance = printPlugin();
	const { PrintButton } = printPluginInstance;

	const handleClose = () => {
		setPopupOpen(false);
		setPdfUrl("");
	};
	return (
		<div>
			{isPopupOpen && (
				<>
					<div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
						<div className="w-full  max-w-lg bg-transparent p-4 sm:p-6 lg:p-8 transform scale-[1.05] sm:scale-[1.1]">
							<div
								className="pdf-viewer-container rounded-lg shadow-lg overflow-hidden"
								style={{
									backgroundColor: "white",
									maxWidth: "100%",
									maxHeight: "90vh",
								}}
							>
								{pdfUrl && (
									<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
										<div className="flex justify-end items-center p-4 space-x-4">
											{/* <zoomPluginInstance.ZoomIn />
                      <zoomPluginInstance.ZoomOut />
                      <zoomPluginInstance.Zoom /> */}
											<a href={pdfUrl} download className="btn-download">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													height="18px"
													width="18px"
													viewBox="0 0 24 24"
													strokeWidth="2"
													stroke="#008065"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M4.5 12.75v6a2.25 2.25 0 002.25 2.25h10.5A2.25 2.25 0 0019.5 18.75v-6M7.5 9l4.5 4.5m0 0L16.5 9m-4.5 4.5V3"
													/>
												</svg>
											</a>
											<button
												className="text-blue-500 rounded"
												onClick={() => printPluginInstance?.print()}
											>
												<svg
													fill="#008065"
													strokeWidth="2"
													stroke="#008065"
													height="16px"
													width="16px"
													version="1.1"
													id="Layer_1"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 512 512"
												>
													<g id="SVGRepo_iconCarrier">
														<g>
															<g>
																<path d="M488.747,245.202h-62.473V120.094c0.005-0.139,0.021-0.275,0.021-0.414c0-3.087-1.339-5.861-3.466-7.773L336.945,26.05 c-0.33-0.33-0.69-0.63-1.062-0.914c-0.083-0.063-0.164-0.125-0.249-0.186c-0.372-0.266-0.758-0.515-1.164-0.732 c-0.028-0.015-0.057-0.026-0.086-0.041c-0.383-0.201-0.784-0.372-1.193-0.525c-0.083-0.03-0.164-0.062-0.248-0.091 c-0.436-0.149-0.882-0.278-1.342-0.37c-0.018-0.004-0.037-0.005-0.055-0.008c-0.418-0.08-0.845-0.13-1.279-0.159 c-0.122-0.008-0.245-0.015-0.368-0.019c-0.116-0.004-0.229-0.018-0.346-0.018H96.157c-5.771,0-10.449,4.678-10.449,10.449V245.2 h-62.46C10.428,245.202,0,255.631,0,268.45v29.65v133.452c0,31.683,25.784,57.46,57.477,57.46h397.047 c31.693,0,57.477-25.777,57.477-57.46V298.1v-29.65C512,255.631,501.569,245.202,488.747,245.202z M340.005,58.657l50.589,50.573 h-50.589V58.657z M106.606,43.886h212.501v75.794c0,5.771,4.678,10.449,10.449,10.449h75.82v115.073h-298.77V43.886z M491.102,431.553c0,20.16-16.409,36.562-36.579,36.562H57.477c-20.17,0-36.579-16.402-36.579-36.562V308.549h470.204V431.553z M491.103,287.652H20.898V268.45c0-1.297,1.054-2.352,2.35-2.352h72.91h319.668h72.922c1.299,0,2.355,1.055,2.355,2.352V287.652z"></path>
															</g>
														</g>
													</g>
												</svg>
											</button>
											<button
												className="text-red-500 rounded"
												onClick={() => handleClose()}
											>
												<IoCloseSharp />
											</button>
										</div>

										<div className="h-full max-h-[75vh] overflow-hidden px-4">
											<Viewer
												fileUrl={pdfUrl}
												plugins={[printPluginInstance]}
											/>
										</div>
									</Worker>
								)}
							</div>
						</div>
					</div>
				</>
			)}
			<div className=" pl-4">
				<ul className="flex flex-wrap text-sm font-medium text-center text-[#008065] border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 ">
					<li className="me-2 mt-2 ">
						<button
							onClick={() => setOpenTab(1)}
							className={
								openTab === 1
									? "inline-block p-4 text-[#008065]  border-b-2 border-[#008065] cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065] font-bold"
									: "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] font-bold"
							}
						>
							Product Invoices
						</button>
					</li>
					<li className="me-2 mt-2">
						<button
							onClick={() => setOpenTab(2)}
							className={`
                         ${
														openTab === 2
															? "inline-block p-4 text-[#008065]  border-b-2 border-[#008065] cursor-pointer rounded-t-lg  dark:bg-gray-800 dark:hover:text-[#008065] font-bold"
															: "inline-block p-4 text-[#008065]  cursor-pointer rounded-t-lg active dark:bg-gray-800 dark:hover:text-[#008065] font-bold"
													}`}
						>
							Service Invoices
						</button>
					</li>
				</ul>
			</div>

			{openTab === 1 ? (
				<> 
					<div className="px-3">
						<div className="bg-[#F1F7F6] rounded-xl px-3 py-3  w-full">
							<div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row">
								<div className="flex items-center">
									<div className="relative flex mb-2">
										<input
											onChange={(e) => onSearchForSales(e)}
											type="text"
											id="simple-search"
											className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
											placeholder="Search"
											required
										/>
										<span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
											<svg
												className="w-4 h-4"
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 20 20"
											>
												<path
													stroke="currentColor"
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
												/>
											</svg>
										</span>
									</div>
								</div>
								<div className="block mb-2  ">
									<div className="relative flex items-center ">
										<select
											defaultValue={""}
											id="sortDropdown"
											className="block w-[11rem] h-10 p-2 text-sm  border border-[#008065] rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 "
											onChange={(e) => handleSortChange(e.target.value)}
										>
											<option value="" disabled hidden>
												Sort By
											</option>
											<option value="asc">Client Name A to Z</option>
											<option value="desc">Client Name Z to A</option>
										</select>
									</div>
								</div>
							</div>
							<Box
								sx={{
									height: "65vh",
									width: "100%",
									overflowX: "auto",
									overflowY: "auto",
								}}
							>
								<DataGrid
									sx={{
										color: "#000",
										fontSize: "14px",
										fontWeight: 500,
										".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
											{
												backgroundColor: "#fff",
												color: "#008060 !important",
												fontWeight: 600,
												fontSize: "14px",
											},
										"& .MuiDataGrid-checkboxInput": {
											color: "#008060 !important",
										},
										".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
											fontWeight: 600,
											fontSize: "14px",
										},
										".MuiDataGrid-cell.MuiDataGrid-cell--textRight": {
											fontWeight: 600,
											fontSize: "14px",
										},
										"@media (min-width: 2555px)": {
											".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor, .MuiDataGrid-cell.MuiDataGrid-cell--textLeft":
												{
													// minWidth: "265px !important",
												},
										},
									}}
									rows={rows}
									columns={columns}
									initialState={{
										pagination: {
											paginationModel: {
												pageSize: 10,
											},
										},
									}}
									pageSizeOptions={[5, 10, 25, 50]}
									// checkboxSelection
									disableRowSelectionOnClick
								/>
							</Box>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="px-3">
						<div className="bg-[#F1F7F6] rounded-xl px-3 py-3 w-full">
							<div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row">
								<div className="flex items-center">
									<div className="relative flex mb-2">
										<input
											onChange={(e) => onSearch(e)}
											type="text"
											id="simple-search"
											className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
											placeholder="Search"
											required
										/>
										<span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
											<svg
												className="w-4 h-4"
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 20 20"
											>
												<path
													stroke="currentColor"
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
												/>
											</svg>
										</span>
									</div>
								</div>
								<div className="block mb-2  ">
									<div className="relative flex items-center ">
										<select
											defaultValue={""}
											id="sortDropdown"
											className="block w-[11rem] h-10 p-2 text-sm  border border-[#008065] rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 "
											onChange={(e) => handleSortChanges(e.target.value)}
										>
											<option value="" disabled hidden>
												Sort By
											</option>
											<option value="asc">Client Name A to Z</option>
											<option value="desc">Client Name Z to A</option>
										</select>
									</div>
								</div>
							</div>
							<Box
								sx={{
									height: "65vh",
									width: "100%",
									overflowX: "auto",
									overflowY: "auto",
								}}
							>
								<DataGrid
									sx={{
										color: "#000",
										fontSize: "14px",
										fontWeight: 500,
										".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
											{
												backgroundColor: "#fff",
												color: "#008060 !important",
												fontWeight: 600,
												fontSize: "14px",
											},
										"& .MuiDataGrid-checkboxInput": {
											color: "#008060 !important",
										},
										".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
											fontWeight: 600,
											fontSize: "14px",
										},
										".MuiDataGrid-cell.MuiDataGrid-cell--textRight": {
											fontWeight: 600,
											fontSize: "14px",
										},
										"@media (min-width: 2555px)": {
											".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor, .MuiDataGrid-cell.MuiDataGrid-cell--textLeft":
												{
													minWidth: "265px !important",
												},
										},
									}}
									rows={serviceRows}
									columns={ServiceColumns}
									initialState={{
										pagination: {
											paginationModel: {
												pageSize: 5,
											},
										},
									}}
									pageSizeOptions={[5, 10, 25, 50]}
									// checkboxSelection
									disableRowSelectionOnClick
								/>
							</Box>
						</div>
					</div>
				</>
			)}

			{showConfirmation && (
				<ConfirmationPopup
					message="Are you sure you want to proceed?"
					onConfirm={handleConfirm}
					onCancel={handleCancel}
				/>
			)}
		</div>
	);
};

export default DashBoardTable;
