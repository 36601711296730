import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { InternalFindAll } from "../redux/actions/InternalInvoiceAction";
import { ProductsGet } from "../redux/actions/productAction";
import { stockPurchaseGet } from "../redux/actions/stockAction";

const Stock = () => {
  const dispatch = useDispatch();
  const productdetails = useSelector((state: any) => state.stockPurchaseList);
  const { stocklist } = productdetails;

  const [stockvalue, setStockValue] = useState([] as any);

  const usersignin = useSelector((state: any) => state.userSignin);
  const token = usersignin?.userInfo?.token;

  useEffect(() => {
    setStockValue(stocklist);
  }, [stocklist]);

  useEffect(() => {
    // dispatch(stockGet(token?._id) as any);
    dispatch(stockPurchaseGet(token?._id) as any)
    dispatch(InternalFindAll() as any);
    dispatch(ProductsGet(token?._id) as any);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // / ***********************************search******************************
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    const filteredData = stocklist?.filter((product: any) =>
      product?.productName?.toLowerCase().includes(query)
    );
    setStockValue(filteredData);
  };

  // **************************sort*********************************

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (newSortOrder: any) => {
    const sortedData = [...stockvalue].sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.productName.localeCompare(b.productName);
      } else {
        return b.productName.localeCompare(a.productName);
      }
    });
    setStockValue(sortedData);
    setSortOrder(newSortOrder);
  };

  // Add unique ids to each row
  const rows = stockvalue?.map((item: any, index: any) => ({
    id: uuidv4(),
    sl_No: index + 1,
    ...item,
  }));

  const columns: GridColDef[] = [
    {
      field: "sl_No",
      headerName: "Sl No",
      minWidth:120,
      flex:2,
      editable: false,
    },
    {
      field: "productName",
      headerName: "Goods",
      minWidth:250,
      flex:10,
      editable: false,
    },
    {
      field: "productHsnNo",
      headerName: "HSN/SAC",
      editable: false,
      minWidth:150,
      flex:2,
      renderCell: (params: any) => (
        <>{params?.row?.productHsnNo ? params?.row?.productHsnNo : "N/A"}</>
      ),
    },
    {
      field: "qty",
      headerName: "Quantity",
      editable: false,
      minWidth:150,
      flex:2,
      renderCell: (params: any) => (
        <>
          {params?.row?.product_flow === "Direct Sale"
            ? params?.row?.qty ?? 0
            : params?.row?.qty || "N/A"} 
        </>
      ),
    },  
    /* {
      field: "productRate",
      headerName: "RATE (PERQTY)",
      editable: false,
      width: 180,
      renderCell: (params: any) => (
        <>{params.row.productRate ? params.row.productRate : "N/A"}</>
      ),
    },
    {
      field: "productAmount",
      headerName: "AMOUNT",
      editable: false,
      width: 140,
      renderCell: (params: any) => (
        <>{params?.row?.productAmount ? params?.row?.productAmount : "N/A"}</>
      ),
    }, */

  ];

  return (
    <div>
      <div className="p-4  font-bold text-[#008065]  ml-2">Stock</div>
      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 mx-2">
        <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
          <div className="flex items-center">
            <div className="relative flex mb-2">
              <input
                onChange={(e) => onSearch(e)}
                type="text"
                id="simple-search"
                className="w-44  bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
                placeholder="Search"
                required
              />
              <span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className="block mb-2  ">
            <div className="relative flex items-center ">
              <select
                defaultValue={""}
                id="sortDropdown"
                className="block w-[11rem] h-10 p-2 text-sm  border border-[#008065] rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 "
                onChange={(e) => handleSortChange(e.target.value)}
              >
                <option value="" disabled hidden>
                  Sort By
                </option>
                <option value="asc">Product Name A to Z</option>
                <option value="desc">Product Name Z to A</option>
              </select>
            </div>
          </div>
        </div>
        <Box
          sx={{
            height: "80vh",
            width: "100%",
            // maxHeight: "84vh",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <DataGrid
            // autoHeight
            sx={{
              color: "#000",
              fontSize: "14px",
              fontWeight: 500,
              ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
              {
                backgroundColor: "#fff",
                color: "#008060 !important",
                fontWeight: 600,
                fontSize: "14px",
              },
              "& .MuiDataGrid-checkboxInput": {
                color: "#008060 !important",
              },
              ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                fontWeight: 600,
                fontSize: "14px",
              },
              "@media (min-width: 2555px)": {
                '.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor, .MuiDataGrid-cell.MuiDataGrid-cell--textLeft': {
                  minWidth: '185px !important',
                }
              }
            }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50]}
            // checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      </div>
    </div>
  );
};

export default Stock;
