import { useEffect } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SalesgettMethod } from "../redux/actions/SalesAction";
import PaymentReceiveTable from "./PaymentReceiveTable";

const PaymentReceiveTab = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    


    useEffect(() => {
        const fetchData = () => {
            dispatch(SalesgettMethod() as any).then((response: any) => {
            });
        };
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // *******************sort*********************
   

    const handleAddPaymentReceive = () => {
        navigate("/app/paymentsReceive");
    };

  

    // Add unique ids to each row
  
    return (
        <div>
            <div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
                <div className="p-4  font-bold text-[#008065]">Payment In - Report</div>
                <div className="p-4  font-bold text-[#008065]">
                    <button
                        type="button"
                        className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
                        style={{ backgroundColor: "#008065" }}
                        onClick={handleAddPaymentReceive}
                    >
                        <FiPlusCircle className="h-5 w-5" />
                        <span className={`flex-1 ml-3 text-left whitespace-nowrap  text-sm font-bold`} >Add Payment In </span>
                    </button></div>


            </div>
            <div className="mt-1">
                <PaymentReceiveTable />
            </div>


        </div>
    );
};

export default PaymentReceiveTab;
