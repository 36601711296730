import ClearIcon from "@mui/icons-material/Clear";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SignatureTable from "../../Tables/SignatureTable";
import {
  AppSignGet,
  AppSignSave,
  AppSignUpdate,
  SignatureDelete,
} from "../../redux/actions/appSettingsAction";
import ConfirmationPopup from "../commen/ConfirmationPopup";


const SignaturePage = () => {
  const usersignin = useSelector((state: any) => state.userSignin);
  const {
    userInfo: { token },
  } = usersignin;

  const { handleSubmit } = useForm({
    defaultValues: {
      signature: "",
      othervalue: "",
      dropvalue: "",
      user_id: token?._id,
    },
  });

  const signaturedetails = useSelector((state: any) => state.SignatureList);
  const { signaturelist } = signaturedetails;

  const dispatch = useDispatch();

  const [signlist, setsignlist] = useState([] as any);
  const [sign_id, setSignid] = useState(null as any);

  const fetchData = () => {
    dispatch(AppSignGet(token?._id) as any).then((res: any) => {
      if (res) {
        setsignlist(res.payload);
      }
    });
  };
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [formData, setFormData] = useState({
    defaultInfo: false,
    otherValue: "Authority",
    signPostion: "R",
    signImage: "",
    sealPostion: "L",
    sealImage: "",
    user_id: token?._id,
  });

  const resetProduct = () => {
    setFormData({
      defaultInfo: false,
      otherValue: "Authority",
      signPostion: "R",
      signImage: "",
      sealPostion: "L",
      sealImage: "",
      user_id: token?._id,
    });
    setIsSignatureCheckedL(false);
    setIsSignatureCheckedR(false);
    setIsSealCheckedL(false);
    setIsSealCheckedR(false);
    setSignid(null);
    setOthervalueError(null);
  };

  const handleDefaultCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormData({ ...formData, defaultInfo: e.target.checked });
  };

  const [isSignatureCheckedL, setIsSignatureCheckedL] = useState(false);
  const [isSignatureCheckedR, setIsSignatureCheckedR] = useState(false);
  const [isSealCheckedL, setIsSealCheckedL] = useState(false);
  const [isSealCheckedR, setIsSealCheckedR] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (name === "signatureL") {
      setIsSignatureCheckedL(checked);
      setIsSignatureCheckedR(false);
      setIsSealCheckedL(false);
      setFormData((prev) => ({
        ...prev,
        signPostion: checked ? "L" : "",
        otherValue: checked ? prev.otherValue : "",
        // signImage: checked ? prev.signImage : "",
        sealPostion: checked && isSealCheckedR ? prev.sealPostion : "",
        // sealImage: checked && isSealCheckedR ? prev.sealImage : "",
      }));
    } else if (name === "signatureR") {
      setIsSignatureCheckedR(checked);
      setIsSignatureCheckedL(false);
      setIsSealCheckedR(false);
      setFormData((prev) => ({
        ...prev,
        signPostion: checked ? "R" : "",
        otherValue: checked ? prev.otherValue : "",
        // signImage: checked ? prev.signImage : "",
        sealPostion: checked && isSealCheckedL ? prev.sealPostion : "",
        // sealImage: checked && isSealCheckedL ? prev.sealImage : "",
      }));
    } else if (name === "sealL") {
      setIsSealCheckedL(checked);
      setIsSealCheckedR(false);
      setIsSignatureCheckedL(false);
      setFormData((prev) => ({
        ...prev,
        sealPostion: checked ? "L" : "",
        // sealImage: checked ? prev.sealImage : "",
        signPostion: checked && isSignatureCheckedR ? prev.signPostion : "",
        otherValue: checked ? prev.otherValue : "",
        // signImage: checked && isSignatureCheckedR ? prev.signImage : "",
      }));
    } else if (name === "sealR") {
      setIsSealCheckedR(checked);
      setIsSealCheckedL(false);
      setIsSignatureCheckedR(false);
      setFormData((prev) => ({
        ...prev,
        sealPostion: checked ? "R" : "",
        // sealImage: checked ? prev.sealImage : "",
        signPostion: checked && isSignatureCheckedL ? prev.signPostion : "",
        otherValue: checked ? prev.otherValue : "",
        // signImage: checked && isSignatureCheckedL ? prev.signImage : "",
      }));
    }
  };

  const handleEditSection = (data: any) => {
    setSignid(data?._id?.$oid);
    setOthervalueError(null);
    setFormData({
      defaultInfo: data.defaultInfo,
      otherValue: data.otherValue,
      signPostion: data.signPostion,
      signImage: data.signImage,
      sealPostion: data.sealPostion,
      sealImage: data.sealImage,
      user_id: token?._id,
    });

    if (data.signPostion === "L") {
      setIsSignatureCheckedL(true);
    } else if (data.signPostion === "R") {
      setIsSignatureCheckedR(true);
    }
    if (data.sealPostion === "L") {
      setIsSealCheckedL(true);
    } else if (data.sealPostion === "R") {
      setIsSealCheckedR(true);
    }
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleDeleteSection = (data: any) => {
    setShowConfirmation(true);
    setSignid(data._id?.$oid);
  };

  const handleConfirm = () => {
    dispatch(SignatureDelete(sign_id) as any).then((res: any) => {
      if (res) {
        fetchData();
        resetProduct();
        setSignid(null);
        setShowConfirmation(false);
      }
    });
  };

  const [othervalueError, setOthervalueError] = useState<string | null>(null);

  const validateProdForm = () => {
    if (!formData.otherValue.trim()) {
      setOthervalueError("Other field is required ");
      return false;
    }
    return true;
  };

  const handleOtherChange = (event: any) => {
    setOthervalueError(null);
    const { value } = event.target;
    setFormData((prev) => ({
      ...prev,
      otherValue: value,
    }));
  };

  const inputRef2 = useRef<HTMLInputElement>(null as any);
  const inputRef1 = useRef<HTMLInputElement>(null as any);

  const handleSign = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData((prev) => ({
          ...prev,
          signImage: reader.result as string,
        }));
        if (inputRef2.current) {
          inputRef2.current.value = "";
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSeal = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData((prev) => ({
          ...prev,
          sealImage: reader.result as string,
        }));
        if (inputRef1.current) {
          inputRef1.current.value = "";
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const ClearSign = (e: any) => {
    e.preventDefault();
    formData.signImage = "";
    setFormData({ ...formData });
    inputRef2.current.value = "";
  };

  const ClearSeal = (e: any) => {
    e.preventDefault();
    formData.sealImage = "";
    setFormData({ ...formData });
    inputRef1.current.value = "";
  };

  const onSubmit = (data: any) => {
    if (validateProdForm()) {
      formData.signPostion = formData.signImage ? formData.signPostion : "";
      formData.sealPostion = formData.sealImage ? formData.sealPostion : "";

      if (sign_id) {
        dispatch(AppSignUpdate(sign_id, formData) as any).then((res: any) => {
          if (res && res.type === "SIGNATURE_UPDATE_SUCCESS") {
            fetchData();
            setSignid(null);
            resetProduct();
            toast.success("Update SuccessFully !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
          }
        });
      } else {
        dispatch(AppSignSave(formData) as any).then((res: any) => {
          if (res && res.type === "SIGNATURE_SAVE_SUCCESS") {
            fetchData();
            resetProduct();
            toast.success("Saved SuccessFully !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 800,
            });
          }
        });
      }
    }
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="mx-3 mb-4 rounded-lg p-3">
          <legend className="p-4  font-bold text-[#045545]  text-lg   ml-2">
            Signature
          </legend>
          <div className="grid gap-4 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
            <div className="flex ml-1 gap-2 mb-3">
              <input
                type="checkbox"
                name="defaultBankInfo"
                placeholder="defaultBankInfo"
                checked={formData.defaultInfo}
                onChange={handleDefaultCheckboxChange}
                className={
                  "border-2 focus:outline-none rounded-[7px] px-2 h-5 w-5 accent-green-700"
                }
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              />
              <div className="text-[#008065] font-semibold">
                <label>Default Signature Info</label>
              </div>
            </div>
            <div className="mb-3">
              <input
                className={` border-2 focus:outline-none rounded-[7px] px-2 h-9 w-[100%] sm:w-[83%] md:w-[70%] lg:w-[66%] xl:w-[43%] 2xl:w-[43%] mt-[3%]${othervalueError
                  ? "border-2  border-[#ff0000]"
                  : "border-2  focus:border-[#07ad7b] border-[#949494] "
                  }`}
                name="othervalue"
                value={formData.otherValue}
                onChange={handleOtherChange}
              />
              {othervalueError && (
                <p className="text-red-500 text-sm">{othervalueError}</p>
              )}
            </div>
          </div>
          <div className="grid gap-4 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
            <div className="">
              <div className="flex items-center gap-4">
                <div>
                  <label
                    className="ml-1"
                    style={{
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#008065",
                    }}
                  >
                    Signature:
                  </label>
                </div>
                <div className="flex items-center space-x-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="signatureL"
                      className="form-checkbox h-5 w-5 accent-green-700"
                      checked={isSignatureCheckedL}
                      onChange={handleCheckboxChange}
                    />
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#008065",
                      }}
                      className="ml-2"
                    >
                      L
                    </span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      name="signatureR"
                      className="form-checkbox h-5 w-5 accent-green-700"
                      checked={isSignatureCheckedR}
                      onChange={handleCheckboxChange}
                    />
                    <span
                      style={{
                        fontFamily: "poppins",
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#008065",
                      }}
                      className="ml-2"
                    >
                      R
                    </span>
                  </label>
                </div>
              </div>
              <div>
                <>
                  <div className="mt-3">
                    <div className={"flex flex-row "}>
                      <div className=" items-center justify-center lg:w-[66%] xl:w-[43%] 2xl:w-[43%] md:w-[70%] w-full mt-3">
                        <div
                          className={`flex flex-col items-center justify-center w-full h-36 pb-5  rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 border-2 border-gray-300 border-dashed
                          `}
                        >
                          <div className="relative">
                            <label
                              style={{ fontFamily: "poppins" }}
                              className="   flex flex-col items-center    cursor-pointer "
                            >
                              <input
                                type="file"
                                accept="image/*"
                                ref={inputRef2}
                                className="hidden"
                                onChange={(e) => handleSign(e)}
                              />

                              {formData.signImage ? (
                                <>
                                  <div style={{ width: "80%", height: "20%" }}>
                                    <div style={{ display: "inline" }}>
                                      <ClearIcon
                                        className="clearicon"
                                        sx={{ float: 'right' }}
                                        onClick={(e) => ClearSign(e)}
                                      />
                                    </div>
                                    <img
                                      className="h-[80px] w-[300px]"
                                      alt="sign"
                                      src={formData?.signImage}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <svg
                                    className="w-8 h-8 mt-1"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                  </svg>
                                  <span className="mt-2 text-base leading-normal">
                                    Upload a Signature
                                  </span>
                                </>
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
            <div>
              <div className="">
                <div className="flex items-center gap-4">
                  <div>
                    <label
                      style={{
                        fontFamily: "poppins",
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#008065",
                      }}
                      className=" ml-1"
                    >
                      Seal:
                    </label>
                  </div>
                  <div className="flex items-center space-x-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        name="sealL"
                        className="form-checkbox h-5 w-5 accent-green-700"
                        checked={isSealCheckedL}
                        onChange={handleCheckboxChange}
                      />
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#008065",
                        }}
                        className="ml-2"
                      >
                        L
                      </span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        name="sealR"
                        className="form-checkbox h-5 w-5 accent-green-700"
                        checked={isSealCheckedR}
                        onChange={handleCheckboxChange}
                      />
                      <span
                        style={{
                          fontFamily: "poppins",
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#008065",
                        }}
                        className="ml-2"
                      >
                        R
                      </span>
                    </label>
                  </div>
                </div>
                <div>
                  <>
                    <div className="">
                      <div className={"flex flex-row "}>
                        <div className="block items-center justify-center lg:w-[66%] xl:w-[43%] 2xl:w-[43%] md:w-[70%] w-full mt-[3%]">
                          <div
                            className={`flex flex-col items-center justify-center w-full h-36 pb-5  rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100  border-2 border-gray-300 border-dashed`}
                          >
                            <div className="relative">
                              <label
                                style={{ fontFamily: "poppins" }}
                                className="   flex flex-col items-center    cursor-pointer "
                              >
                                <input
                                  type="file"
                                  accept="image/*"
                                  ref={inputRef1}
                                  className="hidden"
                                  onChange={(e) => handleSeal(e)}
                                />
                                {formData.sealImage ? (
                                  <>
                                    <div
                                      style={{
                                        width: "80%",
                                        height: "20%",
                                      }}
                                    >
                                      <div style={{ display: "inline" }}>
                                        <ClearIcon
                                          className="clearicon"
                                          sx={{ float: 'right' }}
                                          onClick={(e) => ClearSeal(e)}
                                        />
                                      </div>
                                      <img
                                        className="h-[80px] w-[300px]"
                                        alt="sign"
                                        src={formData?.sealImage}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <svg
                                      className="w-8 h-8 mt-1"
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                    </svg>
                                    <span className="mt-2 text-base leading-normal">
                                      Upload a Seal
                                    </span>
                                  </>
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col sm:flex-row pl-4">
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
            <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2"></div>
            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-2 px-2 mt-5">
              <div className="flex flex-col w-44 rounded">
                <button
                  type="button"
                  className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065]"
                  style={{
                    backgroundColor: "#008065",
                    fontSize: "14px",
                    fontFamily: "poppins",
                  }}
                  onClick={resetProduct}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-5 ">
              <div className="flex flex-col w-44 ">
                <button
                  type="submit"
                  className="rounded-xl font-bold text-white  text-sm h-8  w-full block    bg-[#008065] "
                  style={{
                    backgroundColor: "#008065",
                    fontSize: "14px",
                    fontFamily: "poppins",
                  }}
                >
                  {sign_id ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <SignatureTable
        signlist={signlist}
        handleEditSection={handleEditSection}
        handleDeleteSection={handleDeleteSection}
        setsignlist={setsignlist}
        signaturelist={signaturelist}
      />
      {showConfirmation && (
        <ConfirmationPopup
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default SignaturePage;
