import { Transition } from "@headlessui/react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { dashBoardGet } from "../../redux/actions/DashBoardAction";
import DashboardDiagrams from "../dashboards/DashboardDiagrams";
import DashboardWidgets from "../dashboards/DashboardWidgets";

const DashboardHome: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const today = new Date();
	const oneMonthAgo = new Date();
	oneMonthAgo.setMonth(today.getMonth() - 1);

	const [selectedRange, setSelectedRange] = useState({
		range: "Last 1 month",
		startDate: oneMonthAgo.toISOString(),
		endDate: today.toISOString(),
	});
	const [selectedPeriod, setselectedPeriod] = useState("Last 1 month");

	const [startDate, setStartDate] = useState(oneMonthAgo.toISOString());
	const [endDate, setEndDate] = useState(today.toISOString());
	const [startCusDate, setCusStartDate] = useState<string | null>(null);
	const [endCusDate, setCusEndDate] = useState<string | null>(null);
	const [radiovalue, setRadioValue] = useState<string>(selectedPeriod);

	const toggleModal = () => {
		// if (!isOpen) {
		// 	setselectedPeriod(selectedRange.range);
		// }
		setIsOpen(!isOpen);
	};
	const options = [
		"Today",
		"Last 7 days",
		"Last 1 month",
		"Last 3 months",
		"Last 6 months",
		"Last 12 months",
		"Last 16 months",
		"Custom",
	];
	const getDateRange = (
		period: string,
		startCusDate?: string,
		endCusDate?: string
	) => {
		const today = new Date();
		let startDate = new Date(today);
		let endDate = new Date(today);

		switch (period) {
			case "Today":
				startDate.setHours(0, 0, 0, 0);
				endDate.setHours(23, 59, 59, 999);
				break;
			case "Last 7 days":
				startDate.setDate(today.getDate() - 7);
				startDate.setHours(0, 0, 0, 0);
				endDate.setHours(23, 59, 59, 999);
				break;
			case "Last 1 month":
				startDate.setMonth(today.getMonth() - 1);
				startDate.setHours(0, 0, 0, 0);
				endDate.setHours(23, 59, 59, 999);
				break;
			case "Last 3 months":
				startDate.setMonth(today.getMonth() - 3);
				startDate.setHours(0, 0, 0, 0);
				endDate.setHours(23, 59, 59, 999);
				break;
			case "Last 6 months":
				startDate.setMonth(today.getMonth() - 6);
				startDate.setHours(0, 0, 0, 0);
				endDate.setHours(23, 59, 59, 999);
				break;
			case "Last 12 months":
				startDate.setFullYear(today.getFullYear() - 1);
				startDate.setHours(0, 0, 0, 0);
				endDate.setHours(23, 59, 59, 999);
				break;
			case "Last 16 months":
				startDate.setMonth(today.getMonth() - 16);
				startDate.setHours(0, 0, 0, 0);
				endDate.setHours(23, 59, 59, 999);
				break;
			case "Custom":
				if (!startCusDate || !endCusDate) {
					console.error("Custom period requires both start and end dates.");
					return null;
				}
				startDate = new Date(startCusDate);
				endDate = new Date(endCusDate);
				if (startDate > endDate) {
					console.error("Custom start date cannot be later than end date.");
					return null;
				}
				startDate.setHours(0, 0, 0, 0);
				endDate.setHours(23, 59, 59, 999);
				break;
			default:
				console.error("Invalid period selected:", period);
				return null;
		}

		return {
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString(),
		};
	};

	// useEffect(() => {
	// 	const range = getDateRange(selectedRange.range);
	// 	if (range) {
	// 		setSelectedRange((prev) => ({
	// 			...prev,
	// 			startDate: range.startDate,
	// 			endDate: range.endDate,
	// 		}));
	// 	}
	// }, [selectedRange.range]);

	useEffect(() => {
		// Initialize with the current date range for default period
		const range = getDateRange(selectedPeriod);
		if (range) {
			setSelectedRange({
				range: selectedPeriod,
				startDate: range.startDate,
				endDate: range.endDate,
			});
		}
	}, [selectedPeriod]);

	const handleApply = () => {
		setselectedPeriod(radiovalue);

		try {
			if (selectedPeriod !== "Custom") {
				// Handle predefined periods
				const range = getDateRange(selectedPeriod);

				if (range) {
					setStartDate(range.startDate);
					setEndDate(range.endDate);
					setSelectedRange({
						range: selectedPeriod,
						startDate: range.startDate,
						endDate: range.endDate,
					});
				} else {
					console.error("Invalid period selected");
				}
			} else {
				// Handle custom range validation
				if (!startCusDate || !endCusDate) {
					alert("Please select both start and end dates.");
					return;
				}

				if (new Date(startCusDate) > new Date(endCusDate)) {
					alert("Start date cannot be later than end date.");
					return;
				}

				setStartDate(startCusDate);
				setEndDate(endCusDate);
				setSelectedRange({
					range: selectedPeriod,
					startDate: startCusDate,
					endDate: endCusDate,
				});
			}

			// Close the date picker modal
			setIsOpen(false);
		} catch (error) {
			console.error("Error applying date range:", error);
		}
	};

	const dispatch = useDispatch();

	const [profitAndLoss, setProfitAndLoss] = useState<any>(null);
	const [profitData, setProfitData] = useState({
		closingStock: 0,
		salesReturnClosing: 0,
		totalPurchase: 0,
		totalPurchaseReturn: 0,
		totalPurchaseRevenue: 0,
		totalSalesTot: 0,
		totalSalesReturn: 0,
		totalRevenue: 0,
		directExpenses: 0,
		indirectExpenses: 0,
		excategory_totals: [],
		total_profit_amount: 0,
		totalSlesWithTax: 0,
		stockAmount: 0,
		lowStock: [],
		opening_bank_accounts: [],
		total_opening_amt: 0,
		totalInvoices: 0,
		weekinfloww: 0,
		weekoutfloww: 0,
		oveallSAlesinFixed: 0,
		oveallunpaid: 0,
		pverallCollected: 0,
		datasetss: [],
		labels: [],
		linesets: [],
		lineLables: [],
	});
	const fetchData = useCallback(async () => {
		try {
			// let period = selectedPeriod;
			// let startDate = selectedRange.startDate;
			// let endDate = selectedRange.endDate;

			// if (selectedPeriod === "Custom") {
			// 	period = selectedPeriod;
			// 	startDate = startCusDate; // Ensure startCusDate is properly initialized
			// 	endDate = endCusDate; // Ensure endCusDate is properly initialized
			// }
			let startDate: string =
				selectedPeriod === "Custom" ? startCusDate! : selectedRange.startDate!;
			let endDate: string =
				selectedPeriod === "Custom" ? endCusDate! : selectedRange.endDate!;

			const res = await dispatch(
				dashBoardGet(selectedPeriod, startDate, endDate) as any
			);

			if (res) {
				const data = res.payload;
				setProfitAndLoss(data);

				setProfitData({
					closingStock: data.closingStock || 0,
					salesReturnClosing: data.salesReturnClosing || 0,
					totalPurchase: data.totalPurchase || 0,
					totalPurchaseReturn: data.totalPurchaseReturn || 0,
					totalPurchaseRevenue: data.totalPurchaseRevenue || 0,
					totalSalesTot: data.totalSalesTot || 0,
					totalSalesReturn: data.totalSalesReturn || 0,
					totalRevenue: data.totalRevenue || 0,
					directExpenses: data.directExpenses || 0,
					indirectExpenses: data.indirectExpenses || 0,
					excategory_totals: data.category_totals || 0,
					total_profit_amount: data.total_profit_amount || 0,
					totalSlesWithTax: data.totalSlesWithTax || 0,
					stockAmount: data.stockAmount || 0,
					lowStock: data.lowStock || 0,
					opening_bank_accounts: data.opening_bank_accounts || 0,
					total_opening_amt: data.total_opening_amt || 0,
					totalInvoices: data.totalInvoices || 0,
					weekinfloww: data.weekinfloww || 0,
					weekoutfloww: data.weekoutfloww || 0,
					oveallSAlesinFixed: data.oveallSAlesinFixed || 0,
					oveallunpaid: data.oveallunpaid || 0,
					pverallCollected: data.pverallCollected || 0,
					datasetss: data?.data?.datasets || [],
					labels: data?.data?.labels || [],
					linesets: data.dataline?.datasets[0]?.data || [],
					lineLables: data.dataline?.labels || [],
				});
			} else {
				console.error("No data returned from API");
			}
		} catch (error: any) {
			console.error("Error fetching data:", error);
		}
	}, [
		selectedPeriod,
		startCusDate,
		selectedRange.startDate,
		selectedRange.endDate,
		endCusDate,
		dispatch,
	]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<div className="bg-[#E1E8E7] h-full relative">
			<div className="flex justify-between items-center py-4 px-2 sm:px-4">
				<div className="text-2xl font-bold text-[#045545] mx-0 sm:mx-2">
					Dashboard
				</div>

				<div className="relative">
					<button
						onClick={toggleModal}
						className="flex items-center px-1 sm:px-4 py-2 bg-[#008065] text-white sm:text-lg text-sm font-semibold rounded-md"
					>
						<span className="whitespace-nowrap">{radiovalue}</span>
						<svg
							className="w-4 h-4 ml-0 sm:ml-2"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
								clipRule="evenodd"
							/>
						</svg>
					</button>

					{isOpen && (
						<>
							<div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
							<Transition
								show={isOpen}
								as={Fragment}
								enter="transition ease-out duration-200"
								enterFrom="opacity-0 translate-y-1"
								enterTo="opacity-100 translate-y-0"
								leave="transition ease-in duration-150"
								leaveFrom="opacity-100 translate-y-0"
								leaveTo="opacity-0 translate-y-1"
							>
								<div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
									<div className="p-4">
										<h3 className="font-semibold text-lg mb-2">Date range</h3>
										<ul className="space-y-2">
											{options.map((option) => (
												<li key={option} className="flex items-center">
													<input
														type="radio"
														name="range"
														value={option}
														checked={radiovalue === option}
														onChange={(e) => setRadioValue(e.target.value)}
														className="mr-2"
													/>
													<span className="text-gray-700">{option}</span>
												</li>
											))}
										</ul>

										{radiovalue === "Custom" && (
											<div className="items-center mt-4">
												<div>
													<label className="block text-sm">Start date</label>
													<input
														type="date"
														className="w-full border rounded-md p-1 text-sm"
														value={
															startCusDate
																? new Date(startCusDate)
																		.toISOString()
																		.split("T")[0]
																: ""
														}
														onChange={(e) =>
															setCusStartDate(
																new Date(e.target.value).toISOString()
															)
														}
													/>
												</div>
												<div>
													<label className="block text-sm">End date</label>
													<input
														type="date"
														className="w-full border rounded-md p-1 text-sm"
														value={
															endCusDate
																? new Date(endCusDate)
																		.toISOString()
																		.split("T")[0]
																: ""
														}
														onChange={(e) =>
															setCusEndDate(
																new Date(e.target.value).toISOString()
															)
														}
													/>
												</div>
											</div>
										)}

										<div className="flex justify-end space-x-2 mt-4">
											<button
												type="button"
												className="px-4 py-2 bg-red-500 text-white rounded-md"
												onClick={toggleModal}
											>
												Cancel
											</button>
											<button
												type="button"
												className="px-4 py-2 bg-[#008065] text-white rounded-md"
												onClick={handleApply}
												disabled={
													selectedPeriod === "Custom" &&
													(!startCusDate || !endCusDate)
												}
											>
												Apply
											</button>
										</div>
									</div>
								</div>
							</Transition>
						</>
					)}
				</div>
			</div>
			<div className="flex flex-wrap">
				<div className="w-full pr-4 mb-4 sm:mb-1">
					<DashboardWidgets
						// selectedRange={selectedRange}
						totalRevenue={profitData.totalRevenue}
						totalSlesWithTax={profitData.totalSlesWithTax}
						totalPurchaseRevenue={profitData.totalPurchaseRevenue}
						directExpenses={profitData.directExpenses}
						stockAmount={profitData.stockAmount}
					/>
					<div className="mt-1">
						<DashboardDiagrams
							selectedRange={selectedPeriod}
							lowStock={profitData.lowStock}
							opening_bank_accounts={profitData.opening_bank_accounts}
							total_opening_amt={profitData.total_opening_amt}
							totalInvoices={profitData.totalInvoices}
							weekinfloww={profitData.weekinfloww}
							weekoutfloww={profitData.weekoutfloww}
							totalRevenue={profitData.totalRevenue}
							directExpenses={profitData.directExpenses}
							oveallSAlesinFixed={profitData.oveallSAlesinFixed}
							oveallunpaid={profitData.oveallunpaid}
							pverallCollected={profitData.pverallCollected}
							datasetss={profitData.datasetss}
							labels={profitData.labels}
							linesets={profitData.linesets}
							lineLables={profitData.lineLables}
						/>
					</div>
				</div>
				<div className="w-full lg:w-2/6 pl-4 pr-4 sm:pl-4 sm:pr-2">
					{/* Additional components or placeholders can go here */}
				</div>
			</div>
		</div>
	);
};

export default DashboardHome;
