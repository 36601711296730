import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { chequeModalSave } from '../../redux/actions/chequeAction';
import { FaCalendarAlt } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, useState } from 'react';
import moment from 'moment';
import { registerAll } from '../../redux/actions/userAction';

const ChequeModal = ({ selectedRow, onClose }: any) => {
  const usersignin = useSelector((state: any) => state.userSignin);
  const token = usersignin?.userInfo?.token;
  const User_id = sessionStorage.getItem("userInfo");
  const userId = JSON.parse(User_id as any);
  const [transferDate, setTransferDate] = useState(new Date() as any);
  const formatDate = moment(new Date()).format("DD/MM/YYYY");
  const [dateFormat, setDateFormat] = useState("DD/MM/YYYY")
  const [formData, setFormData] = useState({
    transferDate: formatDate,
  } as any);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      paidTo: selectedRow?.Name || "",
      chequeAmount: selectedRow?.toatalAmount || "",
      withdrawFrom: "CASH",
      chequeNo: selectedRow?.chequeNum || "",
      transferDate: formatDate,
      cashinHand_id: selectedRow?.cashinHand_id || "",
      description: "",
    },
  });
  const dispatch = useDispatch<any>();
  const { transaction_type } = selectedRow;
  const label =
    transaction_type === "PURCHASE" ||
      transaction_type === "PAYMENTOUT" ||
      transaction_type === "PURCHASE(FA)"
      ? "Withdraw From:"
      : "Deposit To:";


  const onSubmit = (data: any) => {
    let chequeModalData = {
      paidTo: data?.paidTo,
      chequeAmount: data.chequeAmount,
      withdrawFrom: data?.withdrawFrom,
      chequeNo: data.chequeNo,
      transferDate: formData.transferDate,
      description: data.description,
      user_id: token?._id,
      cheque_id: selectedRow._id.$oid,
      cashinHand_id: selectedRow?.cashinHand_id
    };
    dispatch(chequeModalSave(chequeModalData) as any).then((res: any) => {
      if (res?.type === "CHEQUE_MODAL_POST_SUCCESS")
        onClose();
    })
    // Add save functionality here
  };

  const CustomInputWithIcon = forwardRef(({ value, onClick }: any, ref: any) => (
    <div className='relative'>
      <input
        type="text"
        value={value}
        readOnly
        {...register('transferDate')}
        className={"w-full p-2 mb-2 border-2 rounded-md focus:border-[#07ad7b] border-[#949494] focus:outline-none"}
        style={{
          fontFamily: "poppins",
          fontSize: "14px",
          fontWeight: 400,
          // borderColor: "#008065",
        }}
        ref={ref}
      />
      <FaCalendarAlt
        className="absolute right-3 top-5 transform -translate-y-1/2 cursor-pointer text-[#008065]"
        onClick={onClick}
      />
    </div>
  ));

  const fetchData = () => {
    dispatch(registerAll(userId?.token?._id) as any).then((res: any) => {
      if (res && res.type === "REGISTER_GET_SUCCESS") {

        setDateFormat(res?.payload?.invoiceDateFormat)
        setFormData((prev: any) => ({
          ...prev,
          transferDate: res?.payload?.invoiceDateFormat ? moment(transferDate).format(res?.payload?.invoiceDateFormat ? res?.payload?.invoiceDateFormat : "DD/MM/YYYY") : formatDate,
        }))
      }
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className='p-2 bg-[#008065] rounded-t'>
        <div className='flex p-1 justify-between'>
          <h2 className="md:text-xl 2xl:text-xl font-semibold text-white text-xl">Transfer Cheque</h2>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className='cursor-pointer mt-1.5' viewBox="0 0 384 512" fill='#fff' onClick={onClose}><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" /></svg>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="px-4 py-2 w-full h-full mx-auto bg-white rounded-b shadow-lg border-2 border-[#008065] border-t-0">

      {/* <h1 className="text-2xl font-bold text-[#045545] text-center">Transfer Cheque</h1> */} {/* Centered Header */}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="col-span-1 md:col-span-2">
            <label className="block text-md mt-2 text-[#008065] font-bold">Paid To:</label>
            <input
              type="text"
              className="w-full px-1 rounded-md bg-white cursor-default focus:outline-none"
              {...register('paidTo', { required: true })}
              defaultValue={selectedRow?.Name}
              disabled
            />
            {errors.paidTo && <p className="text-red-500">This field is required</p>}
          </div>

          <div>
            <label className="block text-[#008065] font-bold">Cheque Amount:</label>
            <input
              type="number"
              className="w-full mb-2 px-1 rounded-md bg-white cursor-default focus:outline-none"
              {...register('chequeAmount', { required: true, min: 1 })}
              defaultValue={selectedRow?.toatalAmount}
              disabled
            />
            {errors.chequeAmount && <p className="text-red-500">This field is required and must be greater than 0</p>}
          </div>

          <div>
            <label className="block text-[#008065] font-bold">Cheque Ref No:</label>
            <input
              type="text"
              className="w-full mb-2 px-1 rounded-md bg-white cursor-default focus:outline-none"
              {...register('chequeNo', { required: true })}
              defaultValue={selectedRow?.chequeNum}
              disabled
            />
            {errors.chequeNo && <p className="text-red-500">This field is required</p>}
          </div>

          <div>
            <label className="block mb-1 text-[#008065] font-bold">{label}</label>
            <select
              className="w-full p-2 mb-2 border-2 rounded-md focus:border-[#07ad7b] border-[#949494] focus:outline-none"
              {...register('withdrawFrom', { required: true })}
            >
              <option value="CASH">Cash</option>
              {/* Add more options here as needed */}
            </select>
            {errors.withdrawFrom && <p className="text-red-500">This field is required</p>}
          </div>

          <div>
            <label className="block mb-1 text-[#008065] font-bold">Transfer Date:</label>
            <DatePicker
              selected={transferDate}
              value={formData?.transferDate}
              onChange={(date) => {
                const formattedDate = moment(date).format(dateFormat);
                setTransferDate(formattedDate);
                setFormData({
                  ...formData,
                  transferDate: formattedDate,
                });
              }}
              dateFormat={dateFormat}
              wrapperClassName="return-datepicker"
              scrollableYearDropdown
              customInput={<CustomInputWithIcon />}
              showPopperArrow={false}
            />
          </div>

          <div className="col-span-1 md:col-span-2">
            <label className="block mb-1 text-[#008065] font-bold">Description (Optional):</label>
            <textarea
              className="w-full p-2 mb-2 border-2 rounded-md focus:border-[#07ad7b] border-[#949494] focus:outline-none"
              {...register('description')}
              placeholder="Enter description"
              style={{ height: 'auto', minHeight: '72px' }}
              rows={3}
            />
          </div>
        </div>
        <div className="px-4 py-3 sm:px-0 sm:flex justify-start sm:flex-row-reverse">
          <button
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#008065] text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          >
            SAVE
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </form>

    </>

  );
};

export default ChequeModal;
