import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { InternalFindAll } from "../../redux/actions/InternalInvoiceAction";

function SalesReturnClientModel({ closeModal, handleSelect }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [animate, setAnimate] = useState(true);
	const [searchTerm, setSearchTerm] = useState("");
	const [salesClient, setSalesClient] = useState([] as any);

	const dispatch = useDispatch();

	const fetchData = () => {
		dispatch(InternalFindAll() as any).then((response: any) => {
			if (response && response.payload) {
				const validClients = response.payload.filter(
					(client: any) => client.saleType !== "POS"
				);

				setSalesClient(validClients);
			}
		});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
	};

	const filteredRows = salesClient
		?.filter((client: any) =>
			client.clientName.toLowerCase().includes(searchTerm.toLowerCase())
		)
		.map((item: any, index: any) => ({
			id: uuidv4(),
			...item,
			Sl_No: index + 1,
		}))
		.filter(
			(value: any, index: number, self: any) =>
				index === self.findIndex((t: any) => t.clientId === value.clientId)
		);

	const columns: GridColDef[] = [
		{
			field: "action",
			headerName: "Actions",
			editable: false,
			minWidth: 150,
			flex: 2,
			renderCell: (params) => (
				<>
					<button
						onClick={() => handleSelect(params.row)}
						className="text-white bg-[#008060] font-bold rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
					>
						Select
					</button>
				</>
			),
		},
		{
			field: "Sl_No",
			headerName: "Sl No",
			minWidth: 120,
			editable: false,
			flex: 2,
		},
		{
			field: "client_name",
			headerName: "Name",
			editable: false,
			minWidth: 150,
			flex: 2,
			renderCell: (params: any) => (
				<>{params?.row?.clientName ? params?.row?.clientName : "N/A"}</>
			),
		},
		// {
		//     field: "client_address",
		//     headerName: "Address",
		//     editable: false,
		//     minWidth: 150,
		//     flex: 2,
		//     renderCell: (params: any) => (
		//         <>{params?.row?.clientAddress ? params?.row?.clientAddress : "N/A"}</>
		//     ),
		// },
		// {
		//     field: "city",
		//     headerName: "City",
		//     editable: false,
		//     minWidth: 150,
		//     flex: 2,
		//     renderCell: (params: any) => (
		//         <>{params?.row?.clientCity ? params?.row?.clientCity : "N/A"}</>
		//     ),
		// },
		// {
		//     field: "state",
		//     headerName: "State",
		//     editable: false,
		//     minWidth: 150,
		//     flex: 2,
		//     renderCell: (params: any) => (
		//         <>{params?.row?.clientState ? params?.row?.clientState : "N/A"}</>
		//     ),
		// },
		// {
		//     field: "postal_code",
		//     headerName: "Postal Code",
		//     editable: false,
		//     minWidth: 150,
		//     flex: 2,
		//     renderCell: (params: any) => (
		//         <>{params?.row?.clientPostalCode ? params?.row?.clientPostalCode : "N/A"}</>
		//     ),
		// },
		{
			field: "client_mobile",
			headerName: "Mobile",
			editable: false,
			minWidth: 150,
			flex: 2,
			renderCell: (params: any) => (
				<>{params?.row?.clientMobile ? params?.row?.clientMobile : "N/A"}</>
			),
		},
		{
			field: "client_email",
			headerName: "Email",
			editable: false,
			minWidth: 150,
			flex: 2,
			renderCell: (params: any) => (
				<>{params?.row?.clientEmail ? params?.row?.clientEmail : "N/A"}</>
			),
		},
		{
			field: "pan_no",
			headerName: "Pan No",
			editable: false,
			minWidth: 150,
			flex: 2,
			renderCell: (params: any) => (
				<>{params?.row?.clientPan ? params?.row?.clientPan : "N/A"}</>
			),
		},
		{
			field: "gst",
			headerName: "Gst",
			editable: false,
			minWidth: 150,
			flex: 2,
			renderCell: (params: any) => (
				<>{params?.row?.clientGst ? params?.row?.clientGst : "N/A"}</>
			),
		},
	];

	return (
		<motion.div
			className="modal-container"
			aria-labelledby="modal-title"
			role="dialog"
			aria-modal="true"
			initial={{ opacity: 0 }}
			animate={{ opacity: animate ? 1 : 0 }}
			transition={{ type: "spring", damping: 30, duration: 0.2 }}
		>
			<div className="fixed inset-0 bg-gray-500 bg-opacity-75">
				<motion.div className="relative rounded-lg overflow-hidden bg-[white] text-center shadow-xl transform transition-all my-8 w-11/12 mx-auto border border-[#008065]">
					<div className="p-2 bg-[#008065]">
						<div className="flex p-1 justify-between  ">
							<h2 className="md:text-xl 2xl:text-xl font-semibold text-white text-xl">
								Existing Clients
							</h2>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								className="cursor-pointer mt-1.5"
								viewBox="0 0 384 512"
								fill="#fff"
								onClick={closeModal}
							>
								<path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
							</svg>
						</div>
					</div>
					<div
						className="bg-[#F1F7F6] p-[8px] m-[5px]"
						style={{ borderRadius: "8px" }}
					>
						<div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
							<div className="flex items-center">
								<div className="relative flex mb-2">
									<input
										onChange={onSearch}
										type="text"
										id="simple-search"
										className="w-44 bg-gray-50 border border-[#008065] text-gray-900 text-sm rounded-lg p-2.5 pl-10 focus:outline-none"
										placeholder="Search"
									/>
									<span className="absolute inset-y-0 left-2 flex items-center pl-2 text-[#008065]">
										<svg
											className="w-4 h-4"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 20 20"
										>
											<path
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
											/>
										</svg>
									</span>
								</div>
							</div>
						</div>
						<Box
							sx={{
								height: 400,
								width: "100%",
								overflowX: "hidden !important",
								overflowY: "auto",
							}}
						>
							<DataGrid
								// autoHeight
								sx={{
									color: "#000",
									fontSize: "14px",
									fontWeight: 500,
									".MuiDataGrid-columnHeaderTitle": {
										backgroundColor: "#fff",
										color: "#008060 !important",
										fontWeight: 600,
										fontSize: "14px",
									},
									"& .MuiDataGrid-checkboxInput": {
										color: "#008060 !important",
									},
									".MuiDataGrid-cell": {
										fontWeight: 600,
										fontSize: "14px",
									},
								}}
								rows={filteredRows}
								columns={columns}
								initialState={{
									pagination: {
										paginationModel: {
											pageSize: 5,
										},
									},
								}}
								pageSizeOptions={[5, 10, 25, 50]}
								disableRowSelectionOnClick
							/>
						</Box>
						<div className="bg-[white] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
							<button
								type="button"
								className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
								onClick={closeModal}
							>
								Cancel
							</button>
						</div>
					</div>
				</motion.div>
			</div>
		</motion.div>
	);
}

export default SalesReturnClientModel;
