import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  paymentDelete,
  paymentGet,
  PaymentSave,
  paymentUpdate
} from "../../redux/actions/paymentAction";
import PaymentTable from "../../Tables/PaymentTable";
import ConfirmationPopup from "../commen/ConfirmationPopup";

function AdminPayments() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      paymentType: "",
      description: "",
    },
  });
  const dispatch = useDispatch();

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [updateId, setUpdateId] = useState(null as any);
  const PaymentData = useSelector((state: any) => state.PaymentData);
  const { paymentlist } = PaymentData;
  const [userPage, setUserPage] = useState(0);
  const [rowPerPageUser, setrowPerPageUser] = useState(10);
  const [paginationdata, setpaginationdata] = useState([] as any);
  const [totalCountForUser, settotalCountForUser] = useState(0);

  const resetPayment = () => {
    setShowConfirmation(false);
    setUpdateId(null);
    let value = {
      paymentType: "",
      description: "",
    };
    reset({ ...value });
  };

  const handleEdit = (item: any) => {
    setUpdateId(item?._id?.$oid);
    let value = {
      paymentType: item.paymentType,
      description: item.description,
    };

    reset({
      ...value,
    });
  };
  const handledelete = (data: any) => {
    setShowConfirmation(true);
    setUpdateId(data?._id?.$oid);
  };
  const handleConfirmdelete = () => {
    dispatch(paymentDelete(updateId) as any).then((res: any) => {
      if (res) {
        dispatch(paymentGet() as any);
        setUpdateId(null);
        setShowConfirmation(false);
        let currentdata = {
          pageNumber: userPage + 1,
          pageSize: rowPerPageUser,
        };
        getPagePayment(currentdata);
        setUserPage(userPage);
        resetPayment(); 
      }
    });
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const getPagePayment = (data: any) => {
    // let currentdata = {
    //   pageNumber: data.pageNumber,
    //   pageSize: data.pageSize,
    // }

    // dispatch(paymentpageInvoice(currentdata) as any).then((res: any) => {
    //   let pageValues = JSON.parse(res.payload.invoicedata);
    //   setpaginationdata(pageValues);
    //   settotalCountForUser(res?.payload?.totalCount);
    // })
    dispatch(paymentGet() as any).then((res: any) => {
      setpaginationdata(res.payload);
    });
  };

  useEffect(() => {
    let currentdata = {
      pageNumber: 1,
      pageSize: 10,
    };
    getPagePayment(currentdata);
  }, []);

  const onSubmit = (data: any) => {
    let paymentdata = {
      paymentType: data.paymentType,
      description: data.description,
    };

    if (updateId) {
      dispatch(paymentUpdate(updateId, paymentdata) as any).then((res: any) => {
        if (res) {
          let obj = {
            paymentType: "",
            description: "",
          };
          reset({ ...obj });
          setUpdateId(null);
          let currentdata = {
            pageNumber: userPage + 1,
            pageSize: rowPerPageUser,
          };
          getPagePayment(currentdata);
          setUserPage(userPage);
          toast.success("Update SuccessFully !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 800,
          });
        }
      });
    } else {
      dispatch(PaymentSave(data) as any).then(() => {
        // if ({ type: PAYMENT_POST_SUCCESS }) {
        reset();
        // }
        let currentdata = {
          pageNumber: 1,
          pageSize: rowPerPageUser,
        };
        setUserPage(0);
        getPagePayment(currentdata);
        toast.success("Saved SuccessFully !", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 800,
        });
      });
    }
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="mx-3 mb-4 rounded-lg">
            <legend className="p-4  font-bold text-[#045545]  text-lg    ml-2">
              Payments
            </legend>

            <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row gap-4 px-6 mb-4 mt-2">
              <div className="required flex flex-col mb-2 lg:w-1/3 xl:w-1/4">

                <label
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#008065",
                  }}
                >
                  <span>Payment Type</span>
                </label>
                <div className="relative">
                  <input
                    style={{ fontFamily: "poppins" }}
                    autoComplete="nope"
                    {...register("paymentType", {
                      required: "Payment Type is required",
                    })}
                    className={
                      `${errors?.paymentType ? "border-[#FF0000]" : "border-[#949494] focus:border-[#07ad7b]"} ` +
                      "border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                    }
                    placeholder="Payment Type"
                  />
                  {errors?.paymentType && (
                    <p
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {errors?.paymentType?.message}
                    </p>
                  )}
                </div>

              </div>
              <div className="required flex flex-col mb-2 w-full md:w-1/3 lg:w-1/3 xl:w-1/4">

                <label
                  style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#008065",
                  }}
                >
                  <span>Description</span>
                </label>
                <div className="relative">
                  <input
                    style={{ fontFamily: "poppins" }}
                    {...register("description", {
                      required: "Description is required",
                    })}
                    autoComplete="off"
                    className={
                      `${errors?.description ? "border-[#FF0000]" : "border-[#949494] focus:border-[#07ad7b]"} ` +
                      " border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full"
                    }
                    placeholder="Description"
                  />
                  {errors?.description && (
                    <p
                      style={{
                        fontFamily: "poppins",
                        fontWeight: 700,
                        color: "#ff0000",
                      }}
                      className="text-red-400 text-xs"
                    >
                      {errors?.description?.message}
                    </p>
                  )}
                </div>
              </div>
              {/* <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row  font-title px-2 mt-0 sm:mt-7 md:mt-7 lg:mt-7 xl:mt-7 2xl:mt-7">
                  <div className="flex flex-col">
                    <div className="flex flex-col rounded">
                      <button
                        type="button"
                        className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm h-8 px-2  block w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40"
                        style={{ backgroundColor: "#008065" }}
                        onClick={resetPayment}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div className="sm:mb-0 sm:text-left text-default-color flex flex-row  font-title flex-1 px-2 mt-2 sm:mt-7 md:mt-7 lg:mt-7 xl:mt-7 2xl:mt-7">
                  <div className="flex flex-col">
                    <div className="flex flex-col">
                      <button
                        // size="sm"
                        type="submit"
                        className="rounded-xl font-bold text-white flex flex-row items-center justify-center text-sm h-8 px-2  block w-48 sm:w-40 md:w-40 lg:w-40 xl:w-40 2xl:w-40"
                        style={{ backgroundColor: "#008065" }}
                        // block={1}
                      >
                        {updateId ? "Update" : "Submit"}
                      </button>
                    </div>
                  </div>
                </div> */}
            </div>

            <div className="rounded-xl mb-3">
              <div className="flex w-full flex-col sm:flex-row px-4 sm:justify-end justify-center">
                <div className="mb-2 sm:mb-0 sm:text-left text-default-color flex flex-row font-title flex-2 px-2 justify-center w-full md:w-auto">
                  <div className="flex flex-col flex-1 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4 w-full">
                    <button
                      type="button"
                      className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065] "
                      style={{ fontFamily: "poppins", fontSize: "14px" }}
                      onClick={resetPayment}
                    >
                      Cancel
                    </button>
                  </div>
                </div>

                <div className="mb-2 sm:mb-0 sm:text-left text-default-color  flex flex-row  font-title flex-2 px-2 justify-center w-full md:w-auto">
                  <div className="flex flex-col flex-1 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 rounded mt-4 w-full">
                    <button
                      type="submit"
                      className="rounded-xl font-bold text-white text-sm h-8 w-full block bg-[#008065]"
                      style={{ fontFamily: "poppins", fontSize: "14px" }}
                    >
                      {updateId ? "Update" : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div>
        <PaymentTable
          paymentlist={paymentlist}
          handleEdit={handleEdit}
          handledelete={handledelete}
          userPage={userPage}
          setUserPage={setUserPage}
          rowPerPageUser={rowPerPageUser}
          setrowPerPageUser={setrowPerPageUser}
          setpaginationdata={setpaginationdata}
          paginationdata={paginationdata}
          totalCountForUser={totalCountForUser}
          settotalCountForUser={settotalCountForUser}
        />
        {showConfirmation && (
          <ConfirmationPopup
            message="Are you sure you want to proceed?"
            onConfirm={handleConfirmdelete}
            onCancel={handleCancel}
          />
        )}
      </div>
    </>
  );
}
export default AdminPayments;
