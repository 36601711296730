/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
    InternalFindNotification,
    resetNotificationCount,
} from "../../redux/actions/NotificationAction";

const NotificationPage = () => {
  const dispatch = useDispatch();
  const [invoiceTable, setInvoiceTable] = useState([] as any);

  const fetchData = () => {
    dispatch(InternalFindNotification() as any).then((response: any) => {
      if (response && response.payload) {
        setInvoiceTable(response.payload.invoices);
        sessionStorage.setItem(
          "Counts",
          JSON.stringify(response.payload.total_count)
        );
        dispatch(resetNotificationCount());
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const rows = invoiceTable?.map((item: any) => ({
    id: uuidv4(),
    ...item,
  }));

  const columns: GridColDef[] = [
    {
      field: "invoiceNo",
      headerName: "Invoice NO",
      width: 170,
      editable: false,
    },
    {
      field: "clientName",
      headerName: "Client",
      editable: false,
      width: 170,
      renderCell: (params: any) => (
        <>{params?.row?.clientName ? params?.row?.clientName : "N/A"}</>
      ),
    },
    {
      field: "clientMobile",
      headerName: "Client Mobile",
      editable: false,
      width: 170,
      renderCell: (params: any) => (
        <>{params?.row?.clientMobile ? params?.row?.clientMobile : "N/A"}</>
      ),
    },
    {
      field: "clientEmail",
      headerName: "Client Email",
      width: 170,
      editable: false,
      renderCell: (params: any) => (
        <>{params?.row?.clientEmail ? params?.row?.clientEmail : "N/A"}</>
      ),
    },
    {
      field: "invoicedate",
      headerName: "Create Date",
      editable: false,
      width: 160,
      renderCell: (params: any) => (
        <>{params?.row?.invoicedate ? params?.row?.invoicedate : "N/A"}</>
      ),
    },
    {
      field: "duedate",
      headerName: "Due Date",
      editable: false,
      width: 160,
      renderCell: (params: any) => (
        <>{params?.row?.duedate ? params?.row?.duedate : "N/A"}</>
      ),
    },
    {
      field: "toatalAmount",
      headerName: "Total Amount",
      editable: false,
      width: 160,
      renderCell: (params: any) => (
        <>
          {params?.row?.toatalAmount
            ? Number(params?.row?.toatalAmount).toFixed(2)
            : "N/A"}
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="p-4  font-bold text-[#008065]  ml-2">Notification</div>
      <div className="bg-[#F1F7F6] rounded-xl px-3 py-3 ml-3 w-[86%] md:w-[93%] lg:w-[97%] xl:w-[96.5%] 2xl:w-[96.6%]">
        <Box
          sx={{
            height: "85vh",
            width: "100%",
            // maxHeight: "60vh",
            overflowX: "hidden !important",
            overflowY: "auto",
          }}
        >
          <DataGrid
// autoHeight
            sx={{
              color: "#000",
              fontSize: "14px",
              fontWeight: 500,
              ".MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle":
                {
                  backgroundColor: "#fff",
                  color: "#008060 !important",
                  fontWeight: 600,
                  fontSize: "14px",
                },
              "& .MuiDataGrid-checkboxInput": {
                color: "#008060 !important",
              },
              ".MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                fontWeight: 600,
                fontSize: "14px",
              },
              "@media (min-width: 2555px)": {
                ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor, .MuiDataGrid-cell.MuiDataGrid-cell--textLeft":
                  {
                    minWidth: "315px !important",
                  },
              },
            }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      </div>
    </div>
  );
};

export default NotificationPage;
