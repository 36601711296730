import { useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currencyGet } from "../redux/actions/currencyAction";
import { trialBalanceGet } from "../redux/actions/trialBalanceAction";
import CashInHandTables from "./CashInHandTables";

const CashInHandTab = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const usersignin = useSelector((state: any) => state.userSignin);
	const { userInfo } = usersignin;
	const userid = userInfo.token._id;
	const [currencySymbol, setcurrencySymbol] = useState("₹");
	const [profitData, setProfitData] = useState({
		cash_in_hand: 0,
	});

	useEffect(() => {
		dispatch(currencyGet(userid) as any).then((res: any) => {
			if (res) {
				let data = res.payload.find((value: any) => value.defaultInfo === true);
				if (data?.currencySymbol) {
					setcurrencySymbol(data.currencySymbol);
				} else {
					setcurrencySymbol("₹");
				}
			}
		});
		dispatch(trialBalanceGet("All", new Date(), new Date()) as any)
			.then((res: any) => {
				console.log("API Response:", res);
				if (res) {
					const data = res.payload;
					setProfitData({
						cash_in_hand: data.cash_in_hand || 0,
					});
				} else {
					console.error("No data returned from API");
				}
			})
			.catch((error: any) => {
				console.error("Error fetching data:", error);
			});
	}, [dispatch, userid]);

	const handleAddPaymentReceive = () => {
		navigate("/app/cashInHandPage");
	};

	return (
		<div>
			<div className="flex justify-between flex-col lg:flex-row xl:flex-row 2xl:flex-row md:flex-row">
				<div className=" p-4 font-bold text-[#008065]  ml-2">
					Cash Adjustment - Report
					<br />
					Cash in Hand :{" "}
					{`${currencySymbol}  ${profitData.cash_in_hand.toFixed(2)}`}
				</div>
				<div className="p-4 font-bold text-[#008065]  ml-2">
					<button
						type="button"
						className="rounded-xl font-bold text-white flex flex-row items-center justify-center  text-xs h-8 px-2  block w-full whitespace-nowrap sm:px-10 lg:text-sm md:text-sm"
						style={{ backgroundColor: "#008065" }}
						onClick={handleAddPaymentReceive}
					>
						<FiPlusCircle className="h-5 w-5" />
						<span
							className={`flex-1 ml-3 text-left whitespace-nowrap  text-sm font-bold`}
						>
							Cash Adjustment
						</span>
					</button>
				</div>
			</div>
			<div className="mt-1">
				<CashInHandTables />
			</div>
		</div>
	);
};

export default CashInHandTab;
