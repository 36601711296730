
/* eslint-disable eqeqeq */
import { Button } from '@mui/material';
import moment from 'moment';
import { forwardRef, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import ClientPlusIcon from '../Icons/ClientPlusIcon';
import ClientModel from '../models/ClientModel';

function DeliveryChallanClient({
    handleChange, formData, setFormData, clientErrors,
    invoiceDate, setInvoiceDate,dateFormat

}: any) {


    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleConfirm = () => {
        setIsModalOpen(true)
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSelect = (data: any) => {
        clientErrors.clientName = null;
        setFormData({
            ...formData,
            clientName: data.client_name,
            clientAddress: data.client_address,
            clientCity: data.city,
            clientState: data.state,
            clientPostalCode: data.postal_code,
            clientMobile: data.client_mobile,
            clientEmail: data.client_email,
            clientPan: data.pan_no,
            clientgst: data.gst,
            clientId: data._id?.$oid,
            isClientGst: true,
            isClientPan: true,
            isClientEmail: true,
            isclientPostalCode: true,
            isclientMobile: true,
            errors: { ...formData.errors, clientgstErrorMessage: '', clientEmailErrorMessage: '', clientPanErrorMessage: '', clientMobileErrorMessage: '', clientPostalCodeErrorMessage: "" }
        });

        setIsModalOpen(false);

    };

    const handleDateChange = (date: any) => {
            const formattedDate = moment(date).format(dateFormat);
    
            if (formattedDate !== formData.invoicedate) {
                setInvoiceDate(date);
                setFormData((prevFormData: any) => ({
                    ...prevFormData,
                    invoicedate: formattedDate,
                }));
            }
        };


    const CustomInputWithIcon = forwardRef(({ value, onClick }: any, ref: any) => (
        <div className='flex items-center border-2 border-[#949494] focus:border-[#07ad7b] group focus-within:outline-none focus-within:border-[#07ad7b] focus:outline-none rounded-[7px] px-1 h-9 lg:w-44 md:w-48 xl:w-48 w-full 2xl:w-11/12'>
            <input
                type="text"
                value={value}
                readOnly
          className={"w-full pl-1 sm:px-2 focus:outline-none"}
                style={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                    // borderColor: "#008065",
                }}
                ref={ref}
            />
            <FaCalendarAlt
          className="sm:mr-2 cursor-pointer  "
                onClick={onClick}
            />
        </div>
    ));



    return (
        <div className={"pt-3 sm:px-2"}>
            <div className="  flex-1 mt-1">
                <div className="flex flex-col sm:flex-row gap-2 items-left w-1/2">
                    <div
                        className="flex flex-1 items-center whitespace-nowrap "
                        style={{
                            color: "#008065",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 700,
                        }}
                    >
                        Client Details (Billed To)
                    </div>

                    <div className="flex flex-1 items-center ">
                        <ClientPlusIcon className="w-4 h-4" />
                        <Button onClick={handleConfirm} sx={{ textTransform: 'none', padding: 0, }}>
                            <span
                                style={{
                                    color: "#008065",
                                    fontFamily: "Poppins",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                }}
                                className="text-[#008065] ml-1 mt-1"
                            >
                                Existing
                            </span>
                        </Button>
                    </div>


                    {isModalOpen && (
                        <ClientModel closeModal={closeModal} handleSelect={handleSelect} />
                    )}
                </div>
            </div>
          <div className="flex flex-col sm:flex-row justify-between gap-4">
                <div className='w-full'>
                    <div className="flex flex-row justify-between items-center mb-2 mt-1">
                        <div
                            className="flex-1  required"
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            <span>Name</span>
                        </div>
                        <div className="font-title flex-1 text-left">

                            <input
                                autoComplete="nope"
                                placeholder="Client Name"
                                // className={"border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                className={`
                                border-2 focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12
                                ${clientErrors.clientName ? 'border-[#FF0000]' : 'border-[#949494]'}
                                ${!clientErrors.clientName && 'focus:border-[#07ad7b]'}
                            `}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: clientErrors.clientName ? "red" : "#008065",
                                }}
                                name='clientName'
                                value={formData.clientName}
                                onChange={(e) => handleChange(e)}
                                readOnly
                            />
                            {clientErrors.clientName && (
                                <p
                                    style={{
                                        fontFamily: "poppins",
                                        fontWeight: 700,
                                        color: "#ff0000",
                                    }}
                                    className="text-red-400 text-xs"
                                >
                                    {clientErrors.clientName}
                                </p>
                            )}

                        </div>
                    </div>

                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className=" flex-1 mb-2"
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >

                            Mobile
                        </div>
                        <div className="font-title flex-1 text-left">
                            <input
                                type="text"
                                autoComplete="nope"
                                placeholder="Mobile Number"
                                className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065",
                                }}
                                maxLength={10}
                                name="clientMobile"
                                value={formData.clientMobile}
                                readOnly
                                onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    if (re.test(e.target.value) || (e.target.value == "")) {
                                        handleChange(e)
                                    }

                                }}

                            />

                        </div>
                    </div>

                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className="flex-1"
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            Email
                        </div>
                        <div className="font-title flex-1 text-left ">

                            <input
                                autoComplete="nope"
                                placeholder="Email (Optional)"
                                className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065",
                                }}
                                name='clientEmail'
                                readOnly
                                value={formData.clientEmail}
                                onChange={(e) => handleChange(e)}
                            />


                        </div>
                    </div>

                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className="flex-1"
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            PAN
                        </div>
                        <div className="font-title flex-1 text-left ">

                            <input
                                autoComplete="nope"
                                placeholder="PanNumber (Optional)"
                                className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065",
                                }}
                                name='clientPan'
                                readOnly
                                value={formData.clientPan}
                                onChange={(e) => handleChange(e)}
                            />


                        </div>



                    </div >
                </div>

                <div className="w-full">
                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className=" flex-1 "
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >

                            Challan No
                        </div>
                        <div className="font-title flex-1 text-left">
                            <input
                                type="text"
                                autoComplete="nope"
                                placeholder="Challan No"
                                className={"border-2 border-[#949494] focus:border-[#07ad7b] focus:outline-none rounded-[7px] px-2 h-9  lg:w-44  md:w-48 xl:w-48 w-full 2xl:w-11/12  "}
                                style={{
                                    fontFamily: "poppins",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    // borderColor: "#008065",
                                }}
                                maxLength={10}
                                name="challanNo"
                                value={formData.challanNo}
                                onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    if (re.test(e.target.value) || (e.target.value == "")) {
                                        handleChange(e)
                                    }

                                }}

                            />

                        </div>
                    </div>

                    <div className="flex flex-row justify-between items-center mb-2">
                        <div
                            className="flex-1"
                            style={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontWeight: 700,
                            }}
                        >
                            Challan Date
                        </div>
                        <div className=" font-title flex-1 text-left">
                            <DatePicker
                                // style={{ fill: "red !important" }}
                                selected={invoiceDate}
                                value={formData?.invoicedate}
                                onChange={handleDateChange}
                                // onChange={(date) => {
                                //     const formattedDate = moment(date).format(dateFormat);
                                //     setInvoiceDate(formattedDate);
                                //     setFormData({
                                //         ...formData,
                                //         invoicedate: formattedDate,
                                //     });
                                // }}
                                dateFormat={dateFormat}
                                wrapperClassName="return-datepicker"
                                scrollableYearDropdown
                                customInput={<CustomInputWithIcon />}
                                showPopperArrow={false}

                            />
                        </div>
                    </div>
                </div>
               
            </div>
        </div >
    )

}

export default DeliveryChallanClient


