import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registers } from "../../redux/actions/userAction";
import Swal from "sweetalert2";

function RegisterPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      companyname: "",
    },
    mode: "onChange",
  }

  );
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const onSubmit = (data: any) => {
    data.path = "register";
    data.user_roll = "Admin";
    data.taxType = "Bill Wise";
    data.invoiceType = "invoice-type-01";
    data.billModule = "PRODUCT";
    data.gstValue = "GST";
    data.AmountFormat = "Indian Format";
    data.RoundOffType="";
    data.RoundOffEnabled=false;
    data.invoiceDateFormat = "DD/MM/YYYY"
    dispatch(registers(data) as any).then((res: any) => {
      if (res?.type === "USER_REGISTER_FAIL") {
        Swal.fire({
          position: "center",
          icon: "error",
          text: "This email is already registered",
          confirmButtonText: "Close",
          showConfirmButton: true,
          customClass: {
            confirmButton: "custom-close-button",
          },
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res?.payload.message,
          confirmButtonText: "Ok",
          showConfirmButton: true,
          customClass: {
            confirmButton: "custom-ok-button",
          },
        });
        navigateTo("/login");
      }
    });
  };

  const login = () => {
    navigateTo("/login");
  };

  return (
    <section className="flex items-center px-8 justify-center 2xl:mt-4">
      <div className="flex rounded-2xl">
        <div className="w-full lg:block hidden px-4 mt-4">
          <img
            // src="/images/inv-image.webp"
            src="https://app.invoicefree.in/images/inv-image.webp"
            className="rounded-2xl w-full h-full"
            alt="page img"
          />
        </div>
        <div className="w-full lg:w-full rounded-lg lg:rounded-l-none mt-4">
          <div className="flex flex-col items-center justify-center relative">
            <div className="flex flex-col bg-[#008065] text-center shadow-md px-4 sm:px-6 md:px-10 lg:px-10 py-8 h-full w-full max-w-md rounded-t">
              <span
                style={{ fontFamily: "poppins" }}
                className="text-[#FFF] text-center text-xl font-bold mb-6 py-1 place-self-center"
              >
                Register
              </span>
            </div>
            <div className="flex flex-col shadow-lg shadow-gray-400 px-4 sm:px-6 md:px-10 lg:px-10 py-4 w-full max-w-md rounded-b">
              <div className="flex flex-col bg-white -mt-12 shadow-lg shadow-gray-400 px-4 sm:px-6 md:px-10 lg:px-10 py-4 rounded-md w-full max-w-md">
                <div className="pt-1">
                  <form
                    action="#"
                    method="POST"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="flex flex-col">
                      <label className="mb-1 text-xs sm:text-sm tracking-wide required font-semibold text-[#008065]">
                        <span>First Name:</span>
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          {...register("fname", {
                            required: "Enter First Name",
                          })}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            // borderColor: errors?.fname ? "" : "#008065",
                          }}
                          className={`${errors?.fname ? 'border-[#FF0000]' : 'focus:border-[#07ad7b] border-[#949494]'
                            } border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full`}
                          placeholder="Enter First Name"
                        />
                      </div>
                      <div style={{ position: "absolute", marginTop: "60px" }}>
                        {errors?.fname && (
                          <p style={{
                            fontFamily: "poppins",
                            fontWeight: 600,
                            color: "#ff0000",
                          }}
                            className="text-red-400 text-xs">
                            {errors?.fname?.message as any}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label className="mb-1 mt-4 text-xs sm:text-sm tracking-wide required font-semibold text-[#008065]">
                        <span>Last Name:</span>
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          {...register("lname", {
                            required: "Enter Last Name",
                          })}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            // borderColor: errors?.lname ? "" : "#008065",
                          }}
                          className={`${errors?.lname ? 'border-[#FF0000]' : 'focus:border-[#07ad7b] border-[#949494]'
                            } border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full`}
                          placeholder="Enter Last Name"
                        />
                      </div>
                      <div style={{ position: "absolute", marginTop: "77px" }}>
                        {errors?.lname && (
                          <p style={{
                            fontFamily: "poppins",
                            fontWeight: 600,
                            color: "#ff0000",
                          }} className="text-red-400 text-xs">
                            {errors?.lname?.message as any}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label className="mb-1 mt-4 required text-xs sm:text-sm tracking-wide font-semibold text-[#008065]">
                        <span>Email:</span>
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          {...register("email", {
                            required: "Enter Email",
                            pattern: {
                              value: /^(?!.*\.\.)(?!(?:[a-zA-Z0-9._%+-]{1,4}@gmail\.com$))[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                              message: "Invalid email address",
                            },
                          })}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            // borderColor: errors?.email ? "" : "#008065",
                          }}
                          className={`${errors?.email ? 'border-[#FF0000]' : 'focus:border-[#07ad7b] border-[#949494]'
                            } border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full`}
                          placeholder="Enter Email Address"
                        />
                      </div>
                      <div style={{ position: "absolute", marginTop: "77px" }}>
                        {errors?.email && (
                          <p style={{
                            fontFamily: "poppins",
                            fontWeight: 600,
                            color: "#ff0000",
                          }} className="text-red-400 text-xs">
                            {errors?.email?.message as any}
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      className="flex flex-col"
                      style={{ marginBottom: "17px" }}
                    >
                      <label className="mb-1 mt-4 text-xs sm:text-sm tracking-wide font-semibold text-[#008065]">
                        Phone:
                      </label>
                      <div className="relative">
                        <input
                          type="number"
                          {...register("phone", {
                            pattern: {
                              value: /^[0-9]{10,13}$/,
                              message: "Invalid phone Number",
                            },
                          })}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            // borderColor: errors?.phone ? "red" : "#008065",
                          }}
                          className={`border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full ${errors?.phone ? 'border-[#FF0000]' : 'focus:border-[#07ad7b] border-[#949494]'
                            }`}
                          placeholder="Enter Phone No (Optional)"
                        />
                      </div>
                      <div style={{ position: "absolute", marginTop: "77px" }}>
                        {errors?.phone && (
                          <p style={{
                            fontFamily: "poppins",
                            fontWeight: 600,
                            color: "#ff0000",
                          }}
                            className="text-red-400 text-xs">
                            {errors.phone.message as any}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <label className="mb-1 text-xs sm:text-sm tracking-wide required font-semibold text-[#008065]">
                        <span>Company Name:</span>
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          {...register("companyname", {
                            required: "Enter Company Name",
                          })}
                          style={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            fontWeight: 400,
                            // borderColor: errors?.companyname ? "" : "#008065",
                          }}
                          className={`${errors?.companyname ? 'border-[#FF0000]' : 'focus:border-[#07ad7b] border-[#949494]'
                            } border-2 focus:outline-none rounded-[7px] px-2 h-9 w-full`}
                          placeholder="Enter Company Name"
                        />
                      </div>
                      <div style={{ position: "absolute", marginTop: "62px" }}>
                        {errors?.companyname && (
                          <p style={{
                            fontFamily: "poppins",
                            fontWeight: 600,
                            color: "#ff0000",
                          }} className="text-red-400 text-xs">
                            {errors?.companyname?.message as any}
                          </p>
                        )}
                      </div>
                    </div>

                    <p
                      className=" mt-8 font-bold flex justify-around mb-1 cursor-pointer"
                      style={{ fontSize: "9px", whiteSpace: "nowrap" }}
                    >
                      By Signing up, you agree to the
                      <span className="flex text-[#008065]">
                        Terms of Service
                      </span>
                      {'\u00A0and\u00A0'}
                      <span className="text-[#008065]">Privacy Policy</span>
                    </p>
                    <div className="flex w-full">
                      <button
                        type="submit"
                        className="mt-2 flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#008065] rounded py-2 w-full transition duration-150 ease-in"
                      >
                        <span className="mr-2 uppercase font-semibold">
                          Register
                        </span>
                      </button>
                    </div>
                    <div className="inline-flex items-center justify-center w-full">
                      <hr className="w-64 h-px my-4 bg-[#008065] border-0 dark:bg-green-700" />
                      <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900 font-semibold">
                        OR
                      </span>
                    </div>
                    <div
                      onClick={login}
                      className="flex justify-center cursor-pointer font-semibold"
                    >
                      Already a user?
                      <span className="text-[#008065] font-semibold">

                        Login Here
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RegisterPage;
